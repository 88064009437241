import { gql, useMutation } from "@apollo/client";
import { useAsyncCallback } from "react-use-async-callback";
import { AsyncActionStatus } from "../../../shared/abstractStore";
import { CopyOrganisationProfileMutation, CopyOrganisationProfileMutationVariables } from "../generated/graphql";
import { OrganisationProfile, organisationProfileFieldsFragment } from "../models/OrganisationProfile";




export function useCopyOrganisationProfileMutation(): [(id: string, schoolId: string | null | undefined, trustId: string | null | undefined) => Promise<OrganisationProfile | undefined>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<CopyOrganisationProfileMutation, CopyOrganisationProfileMutationVariables>(
        gql`
            mutation copyOrganisationProfileMutation ($id: ID!, $schoolId: ID, $trustId: ID) {
                copyOrganisationProfile(id: $id, schoolId: $schoolId, trustId: $trustId) {
                    ...organisationProfileFields
                }
            }

            ${organisationProfileFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (id: string, schoolId: string | null | undefined, trustId: string | null | undefined) => {
        const result = await mutationAction({ variables: { id: id, schoolId: schoolId, trustId: trustId } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
        return result.data?.copyOrganisationProfile ?? undefined;
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}