import gql from 'graphql-tag';
import { Guid } from 'guid-string';
import moment from 'moment';
import { UserLearningEventFieldsFragment } from '../generated/graphql';

export const userLearningEventFieldsFragment = gql`
    fragment userLearningEventFields on UserLearningEvent {
        id
        userId
        eventDate
        learningEventType
        archived
        externalName
        externalDescriptionHtml
        cPDMinutes
    }
`;

export type UserLearningEvent = Omit<UserLearningEventFieldsFragment, '__typename' | 'userLearningEvent'>;

/**
 * Default values.
 */
export const userLearningEventDefaultValues = (): Partial<UserLearningEvent> => ({
    id: Guid.newGuid(),
    userId: '',
    eventDate: moment().toISOString(),
    learningEventType: '',
    archived: false,
    externalName: '',
    externalDescriptionHtml: '',
    cPDMinutes: 0,
});