import { EditPodcast, EditPodcastProps } from "../EditPodcast";

/**
 * Create a new admin Podcast
 * @param props
 */
export const CreateAdminPodcast = (props: EditPodcastProps) => (<EditPodcast isAdmin isCreate={true} {...props} />);

/**
 * Create a new trust Podcast
 * @param props
 */
export const CreateTrustPodcast = (props: EditPodcastProps) => (<EditPodcast isTrust isCreate={true} {...props} />);

/**
 * Create a new school Podcast
 * @param props
 */
export const CreateSchoolPodcast = (props: EditPodcastProps) => (<EditPodcast isCreate={true} {...props} />);

/*
 * Component to edit an admin Podcast
*/
export const EditAdminPodcast = (props: EditPodcastProps) => (<EditPodcast isAdmin {...props} />);

/*
 * Component to edit a Podcast inside a trust
*/
export const EditTrustPodcast = (props: EditPodcastProps) => (<EditPodcast isTrust {...props} />);

/*
 * Component to edit a Podcast inside a school
*/
export const EditSchoolPodcast = (props: EditPodcastProps) => (<EditPodcast {...props} />);

/*
 * Component to view a Podcast
*/
export const ViewPodcast = (props: EditPodcastProps) => (<EditPodcast isView={true} {...props} />);