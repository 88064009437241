import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useValidatorCallback } from "pojo-validator-react";
import { ValidatedInput } from "pojo-validator-reactstrap";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useAsyncCallback } from "react-use-async-callback";
import { Button, Col, Form, FormGroup, Input, Label, Row, Spinner } from "reactstrap";
import { ButtonAsync } from "reactstrap-buttonasync";
import { PersonnelFlag, personnelFlagDefaultValues } from "../../../api/main/models/PersonnelFlag";
import { usePersonnelFlag } from "../../../api/main/personnelFlags/usePersonnelFlag";
import { useSavePersonnelFlagMutation } from "../../../api/main/personnelFlags/useSavePersonnelFlagMutation";
import { AlertOnErrors } from "../../../shared/alertOnErrors";
import { HtmlEditor } from "../../../shared/htmlEditor";
import { useChanges } from "../../../shared/useChanges";
import { Banner } from "../../shared/banner/Banner";
import { FormButtons } from "../../shared/formButtons/FormButtons";
import { LoadingIndicator } from "../../shared/loadingIndicator/LoadingIndicator";
import { MainContainer } from "../../shared/mainContainer/MainContainer";
import { StickyToolbar } from "../../shared/stickyToolbar/StickyToolbar";


export interface EditPersonnelFlagProps {
    isCreate?: boolean,
    onCreateDefaultValues?: () => Partial<PersonnelFlag>
}

/**
 * Component to create a PersonnelFlag
 */
export const CreatePersonnelFlag = (props: EditPersonnelFlagProps) => (<EditPersonnelFlag isCreate={true} {...props} />);

/**
 * Component to edit a PersonnelFlag
 */
    export const EditPersonnelFlag= (props: EditPersonnelFlagProps) => {
        const {
            isCreate,
            onCreateDefaultValues,
        } = props

        const { t } = useTranslation();
        const { id } = useParams<{ id: string | undefined }>();
        const navigate = useNavigate();


        // Load all data
        const {
            data: {
                model: storeModel,
            },
            errors: loadErrors, isLoading,
        } = usePersonnelFlag(id);

        // Model (PersonnelFlag)
        const { model, change, changes } = useChanges(storeModel, isCreate ? { ...personnelFlagDefaultValues(), ...(onCreateDefaultValues ? onCreateDefaultValues() : {}) } : undefined);
        const [savePersonnelFlag, { errors: saveErrors }] = useSavePersonnelFlagMutation();

        // Main model validation.
        const [validate, validationErrors] = useValidatorCallback((validation, fieldsToCheck) => {
            const rules = {
                name: () => !model?.name ? t('editPersonnelFlag.errors.name', 'Name cannot be empty') : '',
            };
            validation.checkRules(rules, fieldsToCheck);
        }, [model]);

        // Save everything
        const [saveForm, { isExecuting: isSaving, errors: saveFormErrors }] = useAsyncCallback(async () => {
            if (!model) {
                return;
            }

            if (!validate()) {
                return;
            }

            // Save the main model
            await savePersonnelFlag(model.id, { ...changes }, isCreate ?? false);

            // Go back to previous screen.
            navigate(-1);
        }, [validate, savePersonnelFlag, model, changes, isCreate, id, navigate]);

        return (
            <>
                <Banner>
                    <StickyToolbar>
                        <Row>
                            <Col>
                                <h1>
                                    {
                                        isCreate ? t('editPersonnelFlag.createHeading.default', 'Add personnel flag')
                                            : t('editPersonnelFlag.editHeading.default', 'Edit personnel flag')
                                    }
                                </h1>
                                <h3>{model?.name}</h3>
                            </Col>
                            <ConditionalFragment showIf={isLoading}>
                                <Col xs="auto">
                                    <LoadingIndicator size="sm" />
                                </Col>
                            </ConditionalFragment>
                        </Row>
                    </StickyToolbar>
                </Banner>
                <MainContainer>
                    <AlertOnErrors errors={[
                        loadErrors,
                        saveFormErrors, saveErrors,
                    ]} />

                    <Form onSubmit={e => { e.preventDefault(); saveForm(); }}>
                        <FormGroup>
                            <Label htmlFor="name">{t('editPersonnelFlag.name.label', 'Name')}</Label>
                            <ValidatedInput name="name" type="text" value={model?.name ?? ''} onChange={e => change({ name: e.currentTarget.value })} onBlur={e => validate('name')} validationErrors={validationErrors['name']} />
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="description">{t('editPersonnelFlag.description.label', 'Description')}</Label>
                            <HtmlEditor value={model?.descriptionHtml ?? ''} onChange={e => change({ descriptionHtml: e })} />
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="impactScore">{t('editPersonnelFlag.impactScore.label', 'Impact Score')}</Label>
                            <Input name="impactScore" type="number" value={model?.impactScore ?? ''} onChange={e => change({ impactScore: parseInt(e.currentTarget.value) })} />
                        </FormGroup>

                        <FormButtons>
                            <ConditionalFragment showIf={!isLoading}>
                                <ButtonAsync color="primary" isExecuting={isSaving} onClick={() => saveForm()}
                                    executingChildren={<><Spinner size="sm" /> {t('common.saving', 'Saving...')}</>}>
                                    <FontAwesomeIcon icon="save" />
                                    <> </>
                                    {t('common.save', 'Save')}
                                </ButtonAsync>
                            </ConditionalFragment>

                            <Button type="button" color="primary" outline onClick={e => navigate(-1)}>
                                {t('common.cancel', 'Cancel')}
                            </Button>
                        </FormButtons>
                    </Form>
                </MainContainer>
            </>
        );
    }