import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useMemo } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { LinkContainer } from "react-router-bootstrap";
import { Link } from "react-router-dom";
import { useAsyncCallback } from "react-use-async-callback";
import { Button, ButtonGroup, Col, Form, FormGroup, FormText, Label, Row } from "reactstrap";
import { useToggleState } from "use-toggle-state";
import { useBlobReference } from "../../api/main/blobReferences/useBlobReference";
import { OrganisationalRoleLinkType } from "../../api/main/models/constants/OrganisationalRoleLinkType";
import { RequirementLinkType } from "../../api/main/models/constants/RequirementLinkType";
import { RequirementType } from "../../api/main/models/constants/RequirementType";
import { RequirementLink, requirementLinkDefaultValues } from "../../api/main/models/RequirementLink";
import { useOrganisationalRoleLinksByTargetId } from "../../api/main/organisationalRoleLinks/useUserOrganisationalRoleLinks";
import { useOrganisationalRoles } from "../../api/main/organisationalRoles/useOrganisationalRoles";
import { useCurrentUserProfile } from "../../api/main/profiles/useCurrentUserProfile";
import { useProfileSupportingData } from "../../api/main/profiles/viewModels/useProfileSupportingData";
import { useDeleteRequirementLinkMutation } from "../../api/main/requirementLinks/useDeleteRequirementLinkMutation";
import { useSaveRequirementLinkMutation } from "../../api/main/requirementLinks/useSaveRequirementLinkMutation";
import { useRequirementLinksByTargetId } from "../../api/main/requirementLinks/useUserRequirementLinks";
import { useRequirements } from "../../api/main/requirements/useRequirements";
import { useUserLearningEvents } from "../../api/main/userLearningEvents/useUserLearningEvents";
import { useCurrentUserOrEmulatedSubscription } from "../../globalState/subscriptions/useCurrentUserOrEmulatedSubscription";
import { AlertOnErrors } from "../../shared/alertOnErrors";
import { HtmlDisplay } from "../../shared/htmlEditor";
import { useChangesArray } from "../../shared/useChanges";
import { RequirementTagSelector } from "../administration/requirements/RequirementTagSelector";
import { Footer } from "../layout/footer/Footer";
import { Banner } from "../shared/banner/Banner";
import { LoadingIndicator } from "../shared/loadingIndicator/LoadingIndicator";
import { MainContainer } from "../shared/mainContainer/MainContainer";
import "./myProfile.scss";

export const MyProfile = () => {
    const { t } = useTranslation();
    const [editMode, toggleEditMode] = useToggleState();
    // Load the current user profile
    const {
        data: {
            model
        }, isLoading: isLoadingProfile, errors: loadErrors
    } = useCurrentUserProfile();

    // Get any emulated school or trust details.
    const subscription = useCurrentUserOrEmulatedSubscription();

    // Supporting data (used to get the picture for the users school or trust)
    const {
        data: {
            profileSchoolOrTrustImage: schoolOrTrustImage,
            getSchoolOrTrustName: schoolOrTrustName
        }, isLoading: isLoadingSupportingData, errors: loadSupportingDataErrors
    } = useProfileSupportingData(model?.id, { schoolId: subscription?.schoolId, trustId: subscription?.trustId });

    // Load the profile image
    const {
        data: {
            model: profileImage
        }, isLoading: isProfileImageLoading, errors: profileImageErrors
    } = useBlobReference(model?.photoBlobReferenceId);
    // Load user organisational role links
    const {
        data: {
            items: organisationalRoleLinks
        }, isLoading: isOrganisationalRoleLinksLoading, errors: organisationalRoleLinksErrors
    } = useOrganisationalRoleLinksByTargetId({ pageSize: undefined }, model?.userId);
    // Load organisationalRoles
    const {
            data: {
                items: organisationalRoles
            }, isLoading: isOrganisationalRolesLoading, errors: organisationalRolesErrors
    } = useOrganisationalRoles({ pageSize: undefined });
    // Load user learning events
    const {
        data: {
            items: learningEvents
        }, isLoading: isLearningEventsLoading, errors: learningEventsErrors
    } = useUserLearningEvents({ pageSize: undefined }, model?.userId);
    // Load requirements (Skills, Attributes, and Qualifications)
    const {
            data: {
                items: requirements
        }, isLoading: isRequirementsLoading, errors: requirementsErrors
    } = useRequirements({ pageSize: undefined });
    // Load user requirementLinks
    const {
            data: {
            items: requirementLinks
        }, isLoading: isRequirementLinksLoading, errors: requirementLinksErrors, refresh: refreshRequirementLinks
    } = useRequirementLinksByTargetId({ pageSize: undefined }, model?.userId);

    // Split organisational role links into current and aspirational
    const { currentUserRoleLinks, aspirationalUserRoleLinks } = React.useMemo(() => {
        return {
            currentUserRoleLinks: organisationalRoleLinks?.filter(item => item.archived === false && item.targetId === model?.userId && item.organisationalRoleLinkType === OrganisationalRoleLinkType.User),
            aspirationalUserRoleLinks: organisationalRoleLinks?.filter(item => item.archived === false && item.targetId === model?.userId && item.organisationalRoleLinkType === OrganisationalRoleLinkType.AspirationalUserRole) ?? [],
        }
    }, [organisationalRoleLinks, model]);

    // Use the aboce role links to grab the associated roles
    const { currentUserRoles, aspirationalUserRoles } = React.useMemo(() => {
        return {
                currentUserRoles: organisationalRoles?.filter(item => currentUserRoleLinks?.find(it => it.organisationalRoleId === item.id)),
                aspirationalUserRoles: organisationalRoles?.filter(item => !!aspirationalUserRoleLinks.find(us => us.organisationalRoleId === item.id)) ?? [],
            }
        }, [organisationalRoles, currentUserRoleLinks, aspirationalUserRoleLinks]);

    // Add up the CPD minutes
    const totalCpdMinutes = learningEvents?.reduce((total, item) => total + item.cPDMinutes, 0);

    const isLoading = isLoadingProfile || isProfileImageLoading || isOrganisationalRoleLinksLoading || isLearningEventsLoading || isRequirementLinksLoading || isLoadingSupportingData;

    // Split requirements and requirementLinks into skills, attributes, and qualifications as well as desired variants.
    const { skills, attributes, qualifications, experiences, } = React.useMemo(() => {
        return {
            skills: requirements?.filter(item => item.archived === false && item.requirementType === RequirementType.Skill) ?? [],
            attributes: requirements?.filter(item => item.archived === false && item.requirementType === RequirementType.Attribute) ?? [],
            qualifications: requirements?.filter(item => item.archived === false && item.requirementType === RequirementType.Qualification) ?? [],
            experiences: requirements?.filter(item => item.archived === false && item.requirementType === RequirementType.Experience) ?? [],
        }
    }, [requirements]);
    const { userSkills, userAttributes, userQualifications, userExperiences } = React.useMemo(() => {
        return {
            userSkills: requirementLinks?.filter(item => item.archived === false && item.requirementLinkType === RequirementLinkType.UserSkill) ?? [],
            userAttributes: requirementLinks?.filter(item => item.archived === false && item.requirementLinkType === RequirementLinkType.UserAttribute) ?? [],
            userQualifications: requirementLinks?.filter(item => item.archived === false && item.requirementLinkType === RequirementLinkType.UserQualification) ?? [],
            userExperiences: requirementLinks?.filter(item => item.archived === false && item.requirementLinkType === RequirementLinkType.UserExperience) ?? [],
        }
    }, [requirementLinks]);
    const { desiredUserSkills, desiredUserAttributes, desiredUserQualifications, desiredUserExperiences } = React.useMemo(() => {
        return {
            desiredUserSkills: requirementLinks?.filter(item => item.archived === false && item.requirementLinkType === RequirementLinkType.AspirationalUserSkill) ?? [],
            desiredUserAttributes: requirementLinks?.filter(item => item.archived === false && item.requirementLinkType === RequirementLinkType.AspirationalUserAttribute) ?? [],
            desiredUserQualifications: requirementLinks?.filter(item => item.archived === false && item.requirementLinkType === RequirementLinkType.AspirationalUserQualification) ?? [],
            desiredUserExperiences: requirementLinks?.filter(item => item.archived === false && item.requirementLinkType === RequirementLinkType.AspirationalUserExperience) ?? [],
        }
    }, [requirementLinks]);

    // Requirement link saving and removing
    const requirementLinkManager = useChangesArray<RequirementLink, string>(requirementLinks, item => item.id);
    const [saveRequirementLink, { errors: saveRequirementLinkErrors }] = useSaveRequirementLinkMutation();
    const [removeRequirementLink, { errors: removeRequirementLinkErrors }] = useDeleteRequirementLinkMutation();

    // Returns true if the requirement link is selected (with this type).
    const isRequirementLinkSelected = (requirementId: string, requirementLinkType: RequirementLinkType) => {
        return !!requirementLinkManager.model.find(item => item.requirementId === requirementId && item.requirementLinkType === requirementLinkType);
    };

    // Toggles a requirement link with the type provided.
    const toggleRequirementLink = (requirementId: string, requirementLinkType: RequirementLinkType) => {
        const existing = requirementLinkManager.model.find(item => item.requirementId === requirementId && item.requirementLinkType === requirementLinkType);
        if (existing) {
            requirementLinkManager.removeFor(existing.id);
        } else {
            requirementLinkManager.addFor({ ...requirementLinkDefaultValues(), targetId: model?.userId, targetType: 'User', requirementId: requirementId, requirementLinkType: requirementLinkType });
        }
    };

    

    // Save everything
    const [saveForm, { isExecuting: isSaving, errors: saveFormErrors }] = useAsyncCallback(async () => {
        if (!model)
        {
            return;
        }



        // Save requirement links
        for (const requirementLink of requirementLinkManager.added) { await saveRequirementLink(requirementLink.id, requirementLink, true); }
        for (const requirementLink of requirementLinkManager.updated) { await saveRequirementLink(requirementLink.id, requirementLink, false); }
        for (const requirementLink of requirementLinkManager.removed) { await removeRequirementLink(requirementLink.id); }
        await requirementLinkManager.markAsSaved();
        // Refreshing the page on saving to ensure that the changes are reflected in the UI.
        refreshRequirementLinks();
        toggleEditMode();
    }, [requirementLinkManager, saveRequirementLink, removeRequirementLink, model]);

    // Render the UI
    //
    return (
        <>
            <Banner fluid>
                <Row>
                    <Col xs={12} md="auto">
                        <h1>
                            {t('myProfile.title', 'My profile')}
                        </h1>
                    </Col>
                    <ConditionalFragment showIf={isLoading || isLearningEventsLoading || isLoadingProfile || isOrganisationalRoleLinksLoading
                        || isSaving || isOrganisationalRolesLoading || isRequirementsLoading }>
                        <Col xs="auto">
                            <LoadingIndicator size="sm" />
                        </Col>
                    </ConditionalFragment>
                </Row>
            </Banner>

            <MainContainer fluid>

                <AlertOnErrors errors={[
                    loadErrors, profileImageErrors, organisationalRoleLinksErrors, learningEventsErrors, requirementLinksErrors,
                    saveFormErrors, saveRequirementLinkErrors, removeRequirementLinkErrors, requirementsErrors, organisationalRolesErrors, loadSupportingDataErrors
                ]} />

                <Row>
                    <Col xs={12} md={6}>
                        <div className="profile">
                            <div className="profile-card-inner-wrapper">
                                <img className="profile-card-image" src={profileImage?.url ?? '/img/user-solid.svg'} alt={profileImage?.filename} />

                                <div className="profile-card-details" >
                                    <h2 className="profile-card-details-name">{model?.firstName} {model?.lastName}</h2>

                                    <ConditionalFragment showIf={!!model?.descriptionHtml}>
                                        <div className="profile-card-details-statement">
                                            <h6>{t('editMyProfile.description', 'Bio')}</h6>
                                            <div>
                                                <HtmlDisplay html={model?.descriptionHtml ?? ''} />
                                            </div>
                                        </div>
                                    </ConditionalFragment>

                                    <ConditionalFragment showIf={!!model?.personalStatementHtml}>
                                        <div className="profile-card-details-statement">
                                            <h6>{t('editMyProfile.personalStatement', 'Personal statement')}</h6>
                                            <div>
                                                <HtmlDisplay html={model?.personalStatementHtml ?? ''} />
                                            </div>
                                        </div>
                                    </ConditionalFragment>

                                    <ConditionalFragment showIf={!!model?.strengthsHtml}>
                                        <div className="profile-card-details-statement">
                                            <h6>{t('editMyProfile.strengthsHtml', 'Strengths')}</h6>
                                            <div>
                                                <HtmlDisplay html={model?.strengthsHtml ?? ''} />
                                            </div>
                                        </div>
                                    </ConditionalFragment>

                                    <ConditionalFragment showIf={!!model?.gapsHtml}>
                                        <div className="profile-card-details-statement">
                                            <h6>{t('editMyProfile.gapsHtml', 'Gaps')}</h6>
                                            <div>
                                                <HtmlDisplay html={model?.gapsHtml ?? ''} />
                                            </div>
                                        </div>
                                    </ConditionalFragment>

                                    <div className="mb-4">
                                        <LinkContainer to="/profile/details">
                                            <Button outline color="primary">
                                                <FontAwesomeIcon icon="edit" />
                                                <> </>
                                                <span>{t('editMyProfile.editPersonal', 'Edit my profile details')}</span>
                                            </Button>
                                        </LinkContainer>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} md={6} style={{width: "30vw"}}>
                        <ConditionalFragment showIf={!!schoolOrTrustImage}>
                            <Row style={{ marginBottom: "5px", justifyContent: "center", textAlign: 'center' }}>
                                <ConditionalFragment showIf={!!schoolOrTrustImage?.url}>
                                    <div className="school-or-trust-image" style={{ backgroundImage: `url("${schoolOrTrustImage?.url}")` }}></div>
                                </ConditionalFragment>
                                <p>{schoolOrTrustName?.name}</p>
                            </Row>
                        </ConditionalFragment>

                        <div className="profile-card-details-info">
                            <div className="profile-card-details-info-item">
                                <p className="profile-card-details-info-item-heading">Current roles</p>
                                {
                                    currentUserRoles?.map((role, index) => {
                                        return (
                                            <LinkContainer key={index} to={`/career/view-role/${role.id}`}>
                                                <p className="profile-card-details-info-item-linkValue">{role.name}</p>
                                            </LinkContainer>
                                        )
                                    })
                                }

                                <ConditionalFragment showIf={!currentUserRoles?.length}>
                                    <Link to="/career">
                                        {t('editMyProfile.browseRoles', 'Browse roles')}
                                    </Link>
                                </ConditionalFragment>
                            </div>
                            <div className="profile-card-details-info-item">
                                <p className="profile-card-details-info-item-heading">Aspirational roles</p>
                                {
                                    aspirationalUserRoles?.map((role, index) => {
                                        return (
                                            <LinkContainer key={index} to={`/career/view-role/${role.id}`}>
                                                <p className="profile-card-details-info-item-linkValue">{role.name}</p>
                                            </LinkContainer>
                                        )
                                    })
                                }

                                <ConditionalFragment showIf={!aspirationalUserRoles?.length}>
                                    <Link to="/career">
                                        {t('editMyProfile.browseRoles', 'Browse roles')}
                                    </Link>
                                </ConditionalFragment>
                            </div>
                            <div className="profile-card-details-info-item">
                                <p className="profile-card-details-info-item-heading">CPD minutes</p>
                                <p className="profile-card-details-info-item-value">{totalCpdMinutes}</p>
                            </div>
                        </div>
                    </Col>
                </Row>

                <Form className="mt-4">
                    {/*Current requirements*/}
                    <Row>
                        <Col xs={12} sm="">


                            <FormGroup>
                                <h5>{t('editMyProfile.qualifications.currentQualifications', 'Current qualifications')}</h5>
                                <ConditionalFragment showIf={!editMode}>
                                    <RequirementTagSelector readOnly={true} tags={qualifications.filter(s => !!userQualifications.find(us => us.requirementId === s.id))}
                                        isSelected={tag => isRequirementLinkSelected(tag.id, RequirementLinkType.UserQualification)}

                                    />
                                </ConditionalFragment>
                                <ConditionalFragment showIf={editMode}>
                                    <RequirementTagSelector input tags={qualifications}
                                        isSelected={tag => isRequirementLinkSelected(tag.id, RequirementLinkType.UserQualification)}
                                        toggle={tag => toggleRequirementLink(tag.id, RequirementLinkType.UserQualification)}
                                    />
                                </ConditionalFragment>
                            </FormGroup>

                            <FormGroup>
                                <h5>{t('editMyProfile.experiences.currentExperiences', 'Current experience')}</h5>
                                <ConditionalFragment showIf={!editMode}>
                                    <RequirementTagSelector readOnly={true} tags={experiences.filter(s => !!userExperiences.find(us => us.requirementId === s.id))}
                                        isSelected={tag => isRequirementLinkSelected(tag.id, RequirementLinkType.UserExperience)}

                                    />
                                </ConditionalFragment>
                                <ConditionalFragment showIf={editMode}>
                                    <RequirementTagSelector input tags={experiences}
                                        isSelected={tag => isRequirementLinkSelected(tag.id, RequirementLinkType.UserExperience)}
                                        toggle={tag => toggleRequirementLink(tag.id, RequirementLinkType.UserExperience)}
                                    />
                                </ConditionalFragment>
                            </FormGroup>

                            <FormGroup>
                                <h5>{t('editMyProfile.skills.currentSkills', 'Current skills')}</h5>
                                <ConditionalFragment showIf={!editMode}>
                                    <RequirementTagSelector readOnly={true} tags={skills.filter(s => !!userSkills.find(us => us.requirementId === s.id))}
                                        isSelected={tag => isRequirementLinkSelected(tag.id, RequirementLinkType.UserSkill)}
                                        
                                    />
                                </ConditionalFragment>
                                <ConditionalFragment showIf={editMode}>
                                    <RequirementTagSelector input tags={skills}
                                        isSelected={tag => isRequirementLinkSelected(tag.id, RequirementLinkType.UserSkill)}
                                        toggle={tag => toggleRequirementLink(tag.id, RequirementLinkType.UserSkill)}
                                    />
                                </ConditionalFragment>
                            </FormGroup>

                            <FormGroup>
                                <h5>{t('editMyProfile.attributes.currentAttributes', 'Current attributes')}</h5>
                                <ConditionalFragment showIf={!editMode}>
                                    <RequirementTagSelector readOnly={true} tags={attributes.filter(s => !!userAttributes.find(us => us.requirementId === s.id))}
                                        isSelected={tag => isRequirementLinkSelected(tag.id, RequirementLinkType.UserAttribute)}

                                    />
                                </ConditionalFragment>
                                <ConditionalFragment showIf={editMode}>
                                    <RequirementTagSelector input tags={attributes}
                                        isSelected={tag => isRequirementLinkSelected(tag.id, RequirementLinkType.UserAttribute)}
                                        toggle={tag => toggleRequirementLink(tag.id, RequirementLinkType.UserAttribute)}
                                    />
                                </ConditionalFragment>
                            </FormGroup>
                        </Col>
                        {/*Desired requirements*/}
                        <Col xs={12} sm="">
                            <FormGroup>
                                <h5>{t('editMyProfile.desiredQualifications.desiredQualifications', 'Aspirational qualifications')}</h5>
                                <ConditionalFragment showIf={!editMode}>
                                    <RequirementTagSelector readOnly={true} tags={qualifications.filter(s => !!desiredUserQualifications.find(us => us.requirementId === s.id))}
                                        isSelected={tag => isRequirementLinkSelected(tag.id, RequirementLinkType.AspirationalUserQualification)}

                                    />
                                </ConditionalFragment>
                                <ConditionalFragment showIf={editMode}>
                                    <RequirementTagSelector input tags={qualifications}
                                        isSelected={tag => isRequirementLinkSelected(tag.id, RequirementLinkType.AspirationalUserQualification)}
                                        toggle={tag => toggleRequirementLink(tag.id, RequirementLinkType.AspirationalUserQualification)}
                                    />
                                </ConditionalFragment>
                            </FormGroup>
                            <FormGroup>
                                <h5>{t('editMyProfile.desiredExperiences.desiredExperiences', 'Aspirational experience')}</h5>
                                <ConditionalFragment showIf={!editMode}>
                                    <RequirementTagSelector readOnly={true} tags={experiences.filter(s => !!desiredUserExperiences.find(us => us.requirementId === s.id))}
                                        isSelected={tag => isRequirementLinkSelected(tag.id, RequirementLinkType.AspirationalUserExperience)}

                                    />
                                </ConditionalFragment>
                                <ConditionalFragment showIf={editMode}>
                                    <RequirementTagSelector input tags={experiences}
                                        isSelected={tag => isRequirementLinkSelected(tag.id, RequirementLinkType.AspirationalUserExperience)}
                                        toggle={tag => toggleRequirementLink(tag.id, RequirementLinkType.AspirationalUserExperience)}
                                    />
                                </ConditionalFragment>
                            </FormGroup>
                            <FormGroup>
                                <h5>{t('editMyProfile.desiredSkills.desiredSkills', 'Aspirational skills')}</h5>
                                <ConditionalFragment showIf={!editMode}>
                                    <RequirementTagSelector readOnly={true} tags={skills.filter(s => !!desiredUserSkills.find(us => us.requirementId === s.id))}
                                        isSelected={tag => isRequirementLinkSelected(tag.id, RequirementLinkType.AspirationalUserSkill)}

                                    />
                                </ConditionalFragment>
                                <ConditionalFragment showIf={editMode}>
                                    <RequirementTagSelector input tags={skills}
                                        isSelected={tag => isRequirementLinkSelected(tag.id, RequirementLinkType.AspirationalUserSkill)}
                                        toggle={tag => toggleRequirementLink(tag.id, RequirementLinkType.AspirationalUserSkill)}
                                    />
                                </ConditionalFragment>
                            </FormGroup>
                            <FormGroup>
                                <h5>{t('editMyProfile.desiredAttributes.desiredAttributes', 'Aspirational attributes')}</h5>
                                <ConditionalFragment showIf={!editMode}>
                                    <RequirementTagSelector readOnly={true} tags={attributes.filter(s => !!desiredUserAttributes.find(us => us.requirementId === s.id))}
                                        isSelected={tag => isRequirementLinkSelected(tag.id, RequirementLinkType.AspirationalUserAttribute)}

                                    />
                                </ConditionalFragment>
                                <ConditionalFragment showIf={editMode}>
                                    <RequirementTagSelector input tags={attributes}
                                        isSelected={tag => isRequirementLinkSelected(tag.id, RequirementLinkType.AspirationalUserAttribute)}
                                        toggle={tag => toggleRequirementLink(tag.id, RequirementLinkType.AspirationalUserAttribute)}
                                    />
                                </ConditionalFragment>
                            </FormGroup>
                        </Col>
                    </Row>

                    <div className="mb-4">
                        <ButtonGroup style={{ paddingTop: "10px", }}>
                            <ConditionalFragment showIf={!editMode}>
                                <Button outline color="primary" onClick={() => toggleEditMode()}>
                                    <FontAwesomeIcon icon="edit" />
                                    <> </>
                                    <span>{t('editMyProfile.edit', 'Edit qualifications, experience, skills, and attributes')}</span>
                                </Button>
                            </ConditionalFragment>

                            <ConditionalFragment showIf={editMode}>
                                <Button color="primary" onClick={() => saveForm()}>
                                    <FontAwesomeIcon icon="save" />
                                    <> </>
                                    <span>{t('editMyProfile.save', 'Save changes')}</span>
                                </Button>
                                <Button color="primary" outline onClick={() => toggleEditMode()}>
                                    <span>{t('editMyProfile.cancel', 'Cancel')}</span>
                                </Button>
                            </ConditionalFragment>

                        </ButtonGroup>

                    </div>
                </Form>
            </MainContainer>
            <footer>
                &nbsp;&nbsp;&nbsp;
                <FormText>{t('myProfile.thirdPartyContentDisclaimer', 'In using this software, you acknowledge that Evaluate-Ed holds no responsibility for the ownership, warranties, or liabilities associated with third-party content found on this site.')}</FormText>
            </footer>
        </>
    );
};