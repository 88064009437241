import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useValidatorCallback } from "pojo-validator-react";
import { ValidatedInput } from "pojo-validator-reactstrap";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useAsyncCallback } from "react-use-async-callback";
import { Button, Col, Form, FormGroup, Label, Row, Spinner } from "reactstrap";
import { ButtonAsync } from "reactstrap-buttonasync";
import { OrganisationalRoleGroup, organisationalRoleGroupDefaultValues } from "../../../api/main/models/OrganisationalRoleGroup";
import { useOrganisationalRoleGroup } from "../../../api/main/organisationalRoleGroups/useOrganisationalRoleGroup";
import { useSaveOrganisationalRoleGroupMutation } from "../../../api/main/organisationalRoleGroups/useSaveOrganisationalRoleGroupMutation";
import { AlertOnErrors } from "../../../shared/alertOnErrors";
import { useChanges } from "../../../shared/useChanges";
import { Banner } from "../../shared/banner/Banner";
import { FormButtons } from "../../shared/formButtons/FormButtons";
import { LoadingIndicator } from "../../shared/loadingIndicator/LoadingIndicator";
import { MainContainer } from "../../shared/mainContainer/MainContainer";



interface EditORganisationalRoleGroupProps {
    isCreate?: boolean,
    onCreateDefaultValues?: () => Partial<OrganisationalRoleGroup>,
}

/**
 * Create a new OrganisationalRoleGroup
 */
export const CreateOrganisationalRoleGroup = (props: EditORganisationalRoleGroupProps) => (<EditOrganisationalRoleGroup isCreate={true} {...props} />);

/**
 * Edit an existing OrganisationalRoleGroup
 */
export const EditOrganisationalRoleGroup = (props: EditORganisationalRoleGroupProps) => {
    const {
        isCreate,
        onCreateDefaultValues,
    } = props;

    const { t } = useTranslation();
    const { id } = useParams<{ id: string | undefined }>();
    const navigate = useNavigate();

    // Load all data
    const {
        data: {
            model: storeModel,
        },
        errors: loadingErrors, isLoading
    } = useOrganisationalRoleGroup(id);

    // Model (OrganisationalRoleGroup)
    const { model, change, changes } = useChanges(storeModel, isCreate ? { ...organisationalRoleGroupDefaultValues(), ...(onCreateDefaultValues ? onCreateDefaultValues() : {}) } : undefined);
    const [saveOrganisationalRoleGroup, { errors: saveErrors }] = useSaveOrganisationalRoleGroupMutation();

    // Main model validation.
    const [validate, validationErrors] = useValidatorCallback((validation, fieldsToCheck) => {
        const rules = {
            name: () => !model?.name ? t('editOrganisationalRoleGroup.errors.name', 'Name cannot be empty') : '',
        };
        validation.checkRules(rules, fieldsToCheck);
    }, [model]);

    // Save the model
    const [saveForm, { isExecuting: isSaving, errors: saveFormErrors }] = useAsyncCallback(async () => {
        if (!model) {
            return;
        }

        if (!validate()) {
            return;
        }

        //Save the main model
        await saveOrganisationalRoleGroup(model.id, { ...changes }, isCreate ?? false);

        //Navigate back to the list
        navigate(-1);
    }, [validate, model, saveOrganisationalRoleGroup, changes, isCreate, id, navigate]);


    return (
        <>
            <Banner>
                <Row>
                    <Col xs={12} md="auto">
                        <h1>
                            {
                                isCreate ? t('editOrganisationalRoleGroup.createHeading.default', 'Add role group')
                                : t('editOrganisationalRoleGroup.editHeading.default', 'Edit role group')
                            }
                        </h1>
                        <h3>{model?.name}</h3>
                    </Col>
                    <ConditionalFragment showIf={isLoading}>
                        <Col xs="auto">
                            <LoadingIndicator size="sm" />
                        </Col>
                    </ConditionalFragment>
                </Row>
            </Banner>
            <MainContainer>
                <AlertOnErrors errors={[
                    loadingErrors,
                    saveErrors,
                    saveFormErrors
                ]} />

                <Form onSubmit={e => { e.preventDefault(); saveForm(); }}>
                    <FormGroup>
                        <Label htmlFor="name">{t('editOrganisationalRoleGroup.name.label', 'Name')}</Label>
                        <ValidatedInput name="name" type="text" value={model?.name ?? ''} onChange={e => change({ name: e.currentTarget.value })} onBlur={e => validate('name')} validationErrors={validationErrors['name']} />
                    </FormGroup>
                </Form>
                <FormButtons>
                    <ConditionalFragment showIf={!isLoading}>
                        <ButtonAsync color="primary" isExecuting={isSaving} onClick={() => saveForm()}
                            executingChildren={<><Spinner size="sm" /> {t('common.saving', 'Saving...')}</>}>
                            <FontAwesomeIcon icon="save" />
                            <> </>
                            {t('common.save', 'Save')}
                        </ButtonAsync>
                    </ConditionalFragment>

                    <Button outline onClick={e => navigate(-1)}>
                        {t('common.cancel', 'Cancel')}
                    </Button>
                </FormButtons>
            </MainContainer>
        </>
    )
}