import * as React from 'react';
import { Button, CardImg, Row, Col, ButtonGroup, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, CardTitle } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useOrganisationalRoles } from '../../../api/main/organisationalRoles/useOrganisationalRoles';
import { useReplaceSearchParamsEffect, useSearchParams } from '../../../shared/useURLSearchParams';
import { useNavigate } from "react-router-dom";
import { useToggleState, useToggleStateArray } from "use-toggle-state";
import { ConditionalFragment } from 'react-conditionalfragment';
import { LoadingIndicator } from '../../shared/loadingIndicator/LoadingIndicator';
import { MainContainer } from '../../shared/mainContainer/MainContainer';
import { Banner } from '../../shared/banner/Banner';
import { StickyToolbar } from '../../shared/stickyToolbar/StickyToolbar';
import { AlertOnErrors } from '../../../shared/alertOnErrors';
import { SearchInput } from '../../shared/searchInput/SearchInput';
import { LinkContainer } from 'react-router-bootstrap';
import { CardsOrTable } from '../../shared/cardsOrTable/CardsOrTable';
import { CardsOrTableToggle } from '../../shared/cardsOrTable/CardsOrTableToggle';
import { usePreferredListViewMode } from '../../../globalState/preferredListViewMode/usePreferredListViewMode';
import { Waypoint } from 'react-waypoint';
import { NoResultsFound } from '../../shared/noResultsFound/NoResultsFound';
import { useBlobReferences } from "../../../api/main/blobReferences/useBlobReferences";
import { useAsyncCallback } from 'react-use-async-callback';
import { useCallback, useMemo } from "react";
import { CloneOrganisationalRoleModal } from './CloneOrganisationalRoleModal';
import { Guid } from 'guid-string';


/**
 * List of OrganisationalRoles
 */
export const OrganisationalRoleList = () => {

    const { t } = useTranslation();

    // Load data
    const { data: { items: _allItems }, isLoading, errors: loadingErrors, fetchMore, hasMore } = useOrganisationalRoles({ pageSize: undefined, });

    // Add the images to the same model as the items
    const allItems = React.useMemo(() => _allItems?.map(item => {

        const image = !Guid.isEmpty(item.imageBlobReferenceId) ? { id: item.imageBlobReferenceId, url: `/api/blobs/redirectToUrl/${item.imageBlobReferenceId}`, filename: item.imageBlobReferenceId ?? '' } : undefined;

        return {
            ...item,
            image: image?.url ?? ''

        }

    }), [_allItems]);

    const navigate = useNavigate();

    const [isMenuOpen, toggleMenuOpen] = useToggleStateArray();

    // Use preferred view mode for lists
    const [viewMode] = usePreferredListViewMode();

    const { search: searchParam } = useSearchParams();
    const [search, setSearch] = React.useState<string>(searchParam ?? '');

    // Keep the URL up to date with the search text
    useReplaceSearchParamsEffect({ search: search });

    // Filter by the search
    const items = React.useMemo(() => {
        let ret = (allItems ?? []);

        let lowerSearch = search.toLocaleLowerCase();
        if (lowerSearch) {
            // Just filtering by all the string values that aren't ids to start with. Likely won't need them all for search.
            ret = ret.filter(item =>
                item.name.toLocaleLowerCase().indexOf(lowerSearch) >= 0
            );
        }
        return ret;
    }, [allItems, search,]);

    // Handle toggling of the modal
    const [cloneOrganisationalRoleModalOpen, _toggleCloneOrganisationalRoleModal] = useToggleState(false);
    const toggleCloneOrganisationalRoleModal = useCallback(() => {
        _toggleCloneOrganisationalRoleModal();
    }, [_toggleCloneOrganisationalRoleModal])

    const [roleIdToClone, setRoleIdToClone] = React.useState<string>("");

    // Handle navigating to the cloned role when the modal closes
    const [onCloneOrganisationalRoleModalClosed, { isExecuting: isCloning, errors: cloneErrors }] = useAsyncCallback(async (event: { newRoleId: string}) => {
        if (event.newRoleId) {
            navigate(`edit/${event.newRoleId}`);
        }
        setRoleIdToClone("");
    }, []);

    //Render the UI
    //
    return (
        <>
            <Banner fluid>
                <StickyToolbar>
                    <Row>
                        <Col>
                            <h1>
                                { t('administratorsOrganisationalRoleList.heading.roles', 'Roles') }
                            </h1>
                        </Col>
                        <ConditionalFragment showIf={isLoading}>
                            <Col xs="auto">
                                <LoadingIndicator size="sm" />
                            </Col>
                        </ConditionalFragment>
                        <Col xs="auto">
                            <CardsOrTableToggle />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <SearchInput value={search} onChange={e => setSearch(e.currentTarget.value)} />
                        </Col>
                        <Col xs="auto">
                            <Row>
                                <ButtonGroup>
                                    <LinkContainer to={'add'}>
                                        <Button color="primary">
                                            <FontAwesomeIcon icon="plus" /><>{t('administratorsOrganisationalRoleList.add', 'Add')}</>
                                        </Button>
                                    </LinkContainer>
                                </ButtonGroup>
                            </Row>
                        </Col>
                    </Row>
                </StickyToolbar>
            </Banner>
            <MainContainer fluid>
                <AlertOnErrors errors={[
                    loadingErrors
                ]} />

                <CardsOrTable
                    viewMode={viewMode}
                    items={items}
                    tableHeadings={[
                        null, /* Handles the card specific function for which we don't want a table column. */
                        t('administratorsOrganisationalRoleList.name.heading', 'Name'),
                    ]}
                    columns={[
                        // We handle the card layout as a specific column ignored from the table
                        (item, view) => {
                            // This column doesn't show in the table
                            if (view !== 'cards') {
                                return null;
                            }
                            return (
                                <>
                                    <CardTitle tag="h5">{item.name}</CardTitle>
                                </>
                            );
                        },

                        (item, view) => {
                            // This column doesn't show in the table
                            if (view !== 'cards') {
                                return null;
                            }
                            
                            if (item.image === '') {
                                return null
                            } else {
                                return (
                                    <>
                                        <CardImg src={item.image} style={{ maxHeight: "100px", width: "auto", maxWidth: "100%", }} alt={t('administratorsOrganisationalRoleList.defaultAlt', 'Preview of image')} />
                                    </>
                                );
                            }
                        },


                        // The remaining columns are for table mode only
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return item.name;
                        },
                    ]}

                    buttons={(item) => (
                        <ButtonGroup>
                            <LinkContainer to={`edit/${item.id}`}>
                                <Button color="primary">
                                    <FontAwesomeIcon icon="edit" />
                                    <>{t('common.edit', 'Edit')}</>
                                </Button>
                            </LinkContainer>
                            <ButtonDropdown isOpen={isMenuOpen(item.id)} toggle={() => toggleMenuOpen(item.id)}>
                                <DropdownToggle color="primary" caret>
                                    <span className="visually-hidden">{t('common.menuDropdown', 'More')}</span>
                                </DropdownToggle>
                                <DropdownMenu end>
                                    <DropdownItem onClick={() => { setRoleIdToClone(item.id); toggleCloneOrganisationalRoleModal(); }}>
                                        <FontAwesomeIcon icon="layer-group" /><>{t('administratorsOrganisationalRoleList.clone', 'Clone')}</>
                                    </DropdownItem>
                                    <LinkContainer to={`delete/${item.id}`}>
                                        <DropdownItem className="text-danger">
                                            <FontAwesomeIcon icon="trash" />
                                            <> {t('common.delete', 'Delete')}</>
                                        </DropdownItem>
                                    </LinkContainer>
                                </DropdownMenu>
                            </ButtonDropdown>
                        </ButtonGroup>

                        

                    )}
                    onItemClick={item => navigate(`edit/${item.id}`)}
                />

                <ConditionalFragment showIf={isLoading && !items?.length}>
                    <LoadingIndicator fullWidth />
                </ConditionalFragment>
                <ConditionalFragment showIf={!isLoading && !items?.length}>
                    <NoResultsFound search={search} />
                </ConditionalFragment>
                <ConditionalFragment showIf={!isLoading && hasMore()}>
                    <Waypoint key={items?.length ?? 0} onEnter={fetchMore} />
                    <LoadingIndicator fullWidth />
                </ConditionalFragment>






            </MainContainer>

            <ConditionalFragment showIf={cloneOrganisationalRoleModalOpen}>
                <CloneOrganisationalRoleModal
                    isOpen={cloneOrganisationalRoleModalOpen}
                    toggle={toggleCloneOrganisationalRoleModal}
                    originalRoleId={roleIdToClone}
                    onClose={onCloneOrganisationalRoleModalClosed}
                    newRoleId={Guid.newGuid()}
                />
            </ConditionalFragment>
        </>
    );

}
