import gql from 'graphql-tag';
import { Guid } from 'guid-string';
import { PersonnelFlagFieldsFragment } from '../generated/graphql';

export const personnelFlagFieldsFragment = gql`
    fragment personnelFlagFields on PersonnelFlag {
        id
        name
        impactScore
        descriptionHtml
        archived
    }
`;

export type PersonnelFlag = Omit<PersonnelFlagFieldsFragment, '__typename' | 'personnelFlag'>;

/**
 * Default values.
 */
export const personnelFlagDefaultValues = (): Partial<PersonnelFlag> => ({
    id: Guid.newGuid(),
    name: '',
    impactScore: 0,
    descriptionHtml: '',
    archived: false,
});
