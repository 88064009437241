import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useMemo } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { useAsyncCallback } from "react-use-async-callback";
import { Card, Col, Row } from "reactstrap";
import { useToggleState } from "use-toggle-state";
import { OrganisationalRoleLinkType } from "../../api/main/models/constants/OrganisationalRoleLinkType";
import { OrganisationalRole } from "../../api/main/models/OrganisationalRole";
import { OrganisationalRoleLink } from "../../api/main/models/OrganisationalRoleLink";
import { PersonnelFlag } from "../../api/main/models/PersonnelFlag";
import { PersonnelFlagUser, personnelFlagUserDefaultValues } from "../../api/main/models/PersonnelFlagUser";
import { Profile } from "../../api/main/models/Profile";
import { Requirement } from "../../api/main/models/Requirement";
import { School } from "../../api/main/models/School";
import { useDeletePersonnelFlagUserMutation } from "../../api/main/personnelFlagUsers/useDeletePersonnelFlagUserMutation";
import { useSavePersonnelFlagUserMutation } from "../../api/main/personnelFlagUsers/useSavePersonnelFlagUserMutation";
import { useSchool } from "../../api/main/schools/useSchool";
import { useChangesArray } from "../../shared/useChanges";
import { useDebounce } from "../../shared/useDebounce/useDebounce";
import { truncateText } from "../../utilities/truncateText";
import { RoleMapUserDetailsModal } from "./roleMapUserDetailsModal";
import { UserRoleScore } from "./roleMapUserScoreSort";

export interface RoleMapUserTileProps {
    user: Profile,
    role?: OrganisationalRole,
    roleLinks?: Array<OrganisationalRoleLink>,
    personnelFlagUsers?: Array<PersonnelFlagUser>,
    allPersonnelFlags?: Array<PersonnelFlag>,

    refreshPersonnelFlagUsers: () => Promise<void>,
    refreshRoleLinks: () => Promise<void>,

    isTrust?: boolean,
    school?: School | null,

    userRequirements?: Array<Requirement>,
    allRoles?: Array<OrganisationalRole>,

    score: UserRoleScore;
    userOrganisationRoleLinks: Array<OrganisationalRoleLink>;
}

export const RoleMapUserTile = (props: RoleMapUserTileProps) => {
    const { t } = useTranslation();

    const {
        user,
        role,
        roleLinks,
        allPersonnelFlags,
        personnelFlagUsers,
        refreshPersonnelFlagUsers,
        refreshRoleLinks,
        score,
        userOrganisationRoleLinks,
    } = props;

    //Get the school for the user, if it doesn't match the subscription of the person viewing
    const {
        data: {
            model: userSchool,
        }, isLoading: userSchoolLoading, errors: userSchoolErrors
    } = useSchool(user.schoolId !== props.school?.id ? user.schoolId : null);

    // Model (personnelFlagUsers)
    const personnelFlagUsersManager = useChangesArray<PersonnelFlagUser, string>(personnelFlagUsers, item => item.id);
    const [savePersonnelFlagUsers] = useSavePersonnelFlagUserMutation();
    const [removePersonnelFlagUsers] = useDeletePersonnelFlagUserMutation();

    // Get the user's personnel flags from the personnelFlagUsers
    const userPersonnelFlags = useMemo(() => {
        return allPersonnelFlags?.filter(item => personnelFlagUsers?.find(it => it.personnelFlagId === item.id));
    }, [allPersonnelFlags, personnelFlagUsers]);

    // Handle toggling of the modal
    const [manageUserDetailsModalOpen, _toggleUserDetailsModal] = useToggleState(false);
    const toggleUserDetailsModal = useCallback(() => {
        _toggleUserDetailsModal();
    }, [_toggleUserDetailsModal]);

    // Handle adding of PersonnelFlagUsers when the modal is closed
    const [onAddPersonnelFlagUsersModalClosed, { isExecuting: isAdding, errors: addErrors }] = useAsyncCallback(async (event: { selectedIds: Array<string>, cancelled: boolean, removedIds: Array<string>, needsRoleLinkRefresh: boolean | undefined; }) => {

        // Removed any items in selectedIds that were already in the initialSelectedIds
        const selectedIds = event.selectedIds.filter(it => !initialSelectedIds?.includes(it));

        // For each selected personnel flag, add a personnel flag user
        for (const selectedId of selectedIds) {
            // If we dont already have the personnel flag user saved, add it to the manager.
            if (allPersonnelFlags?.find(item => item.id === selectedId) !== null) {

                personnelFlagUsersManager.addFor({
                    ...personnelFlagUserDefaultValues(),

                    personnelFlagId: selectedId,
                    userId: user?.userId,
                    trustId: user?.trustId,
                    schoolId: user?.schoolId,
                    organisationalRoleId: role?.id,
                });
            }
        }
        // For each removed personnel flag, remove the personnel flag user
        for (const removedId of event.removedIds) {
            const current = personnelFlagUsers?.find(it => it.personnelFlagId === removedId);

            if (current) {
                personnelFlagUsersManager.removeFor(current.id);
            }
        }

        // Refresh the role links
        if (event.needsRoleLinkRefresh) {
            refreshRoleLinks();
        }

        saveDebounce();
    }, [personnelFlagUsersManager, user?.id]);

    // Saving the PersonnelFlagUsers
    const [save, { isExecuting: isSavingPersonnelFlagUsers, errors: personnelFlagUsersSaveErrors }] = useAsyncCallback(async () => {
        if (!user) {
            return;
        }

        // Save the personnel flag users
        for (const item of personnelFlagUsersManager.added) { await savePersonnelFlagUsers(item.id, personnelFlagUsersManager.changesFor(item.id), true); }
        for (const item of personnelFlagUsersManager.updated) { await savePersonnelFlagUsers(item.id, personnelFlagUsersManager.changesFor(item.id), false); }
        for (const item of personnelFlagUsersManager.removed) { await removePersonnelFlagUsers(item.id); }
        personnelFlagUsersManager.markAsSaved();

        refreshPersonnelFlagUsers();
    }, [savePersonnelFlagUsers, user]);

    // Allow ourselves to be saved after a delay
    const saveDebounce = useDebounce(() => {
        save();
    }, { delay: 1000 });

    // Get the initial selected ids
    const initialSelectedIds = useMemo(() => {
        return userPersonnelFlags?.map(it => it.id);
    }, [userPersonnelFlags]);

    const pinned = useMemo(() => {
        return !!roleLinks?.find(it => it.organisationalRoleLinkType === OrganisationalRoleLinkType.RoleMapUserPin
            && it.targetId === user?.userId && it.organisationalRoleId === role?.id);
    }, [role, roleLinks, user]);

    // Render the UI
    //
    return (
        <div style={{ minWidth: '180px' }}>
            <Card style={{ border: 0, cursor: 'pointer', backgroundColor: 'transparent' }} onClick={e => toggleUserDetailsModal()}>
                <div style={{ padding: "1rem" }}>
                    <ConditionalFragment showIf={pinned}>
                        <FontAwesomeIcon icon="thumbtack" style={{ position: "relative", zIndex: 9000, left: "8rem", bottom: "0rem", maxWidth: "1rem", height: "auto", color: '#9D5097' }} />
                    </ConditionalFragment>
                    <ConditionalFragment showIf={!pinned}>
                        <FontAwesomeIcon icon="thumbtack" style={{ position: "relative", zIndex: 9000, left: "8rem", bottom: "0rem", maxWidth: "1rem", height: "auto", color: '#FFFFFF' }} />
                    </ConditionalFragment>
                    <Row>
                        <Col>
                        </Col>
                        <Col xs="auto">
                            <img className="role-map-part user-image" src={user?.photoBlobReferenceId ? `/api/blobs/redirectToUrl/${user?.photoBlobReferenceId}` : '/img/user-solid.svg'} alt={''}
                                style={{ objectFit: 'contain' }}
                            />
                        </Col>
                        <Col>
                        </Col>
                    </Row>
                    <div className="role-map-part user-heading">{t('roleMapRow.heading', `${truncateText(30, user.firstName)}`)}</div>
                    <div className="role-map-part user-heading">{t('roleMapRow.heading', `${truncateText(30, user.lastName)}`)}</div>
                </div>
            </Card>

            {/* Modal for seeing user details and managing personnel flags */}
            <RoleMapUserDetailsModal
                isOpen={manageUserDetailsModalOpen}
                toggle={toggleUserDetailsModal}
                userProfile={user}
                userBlobReferenceUrl={user?.photoBlobReferenceId ? `/api/blobs/redirectToUrl/${user?.photoBlobReferenceId}` : '/img/user-solid.svg'}
                onClose={onAddPersonnelFlagUsersModalClosed}
                isSaving={isAdding}
                allPersonnelFlags={allPersonnelFlags}
                initialSelectedIds={initialSelectedIds}
                roleId={role?.id}
                rolePinLink={roleLinks?.find(it => it.organisationalRoleLinkType === OrganisationalRoleLinkType.RoleMapUserPin)}
                isTrust={props.isTrust}
                school={userSchool ?? props.school}
                userRequirements={props.userRequirements}
                roleLinks={roleLinks}
                allRoles={props.allRoles}
                personnelFlagUsers={personnelFlagUsers}
                score={score}
                userOrganisationRoleLinks={userOrganisationRoleLinks}
            />
        </div>
    );
};