/**
 * Constants for the available roles.
 */
export const IdentityRoles = {
    CustomerServiceSupervision: 'CustomerServiceSupervision',
    Administration: 'Administration',
    ManageTrust: 'ManageTrust',
    ManageSchool: 'ManageSchool',
    SchoolAdministration: 'SchoolAdministration',
    TrustAdministration: 'TrustAdministration',

};

///**
// * Constants (enum) for the available Roles.
// */
//export enum IdentityRoles {
//    Administration = 'Administration'
//}
