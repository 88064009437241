import * as React from 'react';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useUpdateLearningContentMutation } from './useUpdateLearningContentMutation';
import { LearningContent } from '../models/LearningContent';
import { useCreateLearningContentMutation } from './useCreateLearningContentMutation';
import { LearningContentCreateInput, LearningContentUpdateInput } from '../generated/graphql';

/**
 * Get a callback to save a LearningContent in the store using either a create or update.
 */
export function useSaveLearningContentMutation(options: SaveInStoreOptions<LearningContent, string> = {}) {
    const [_create, createStatus] = useCreateLearningContentMutation();
    const create = React.useCallback((model: Partial<LearningContent>) => _create(model as LearningContentCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateLearningContentMutation();
    const update = React.useCallback((id: string, changes: Partial<LearningContent>) => _update(id, changes as LearningContentUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
