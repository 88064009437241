import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Guid } from "guid-string";
import moment from "moment";
import * as React from 'react';
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { useAsyncCallback } from "react-use-async-callback";
import { Badge, Button, ButtonDropdown, Card, CardBody, CardImg, CardImgOverlay, CardTitle, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import { useToggleStateArray } from "use-toggle-state";
import { useBlobReference } from "../../api/main/blobReferences/useBlobReference";
import { BlobReference } from "../../api/main/models/BlobReference";
import { LearningContentType, learningContentTypeDisplayName } from "../../api/main/models/constants/LearningContentType";
import { LearningCostType, learningCostTypeDisplayName } from "../../api/main/models/constants/LearningCostType";
import { LearningContent } from "../../api/main/models/LearningContent";
import { useSchool } from "../../api/main/schools/useSchool";
import { useSaveUserLearningEventMutation } from "../../api/main/userLearningEvents/useSaveUserLearningEventMutation";
import { useCurrentUser } from "../../api/main/users/useCurrentUser";
import { useCurrentUserOrEmulatedSubscription } from "../../globalState/subscriptions/useCurrentUserOrEmulatedSubscription";
import { truncateText } from "../../utilities/truncateText";
import { LoadingIndicator } from "../shared/loadingIndicator/LoadingIndicator";
import "./videoTile.scss";

export interface VideoTileProps {
    model: LearningContent,
    thumbnailBlob?: string | undefined | null,
    blob?: BlobReference, //This and isLoading are for when we want to provide the blob directly instead of the blobReferenceId
    isLoading?: boolean, //The reason for this is that having growList fire off hundreds of requests for a blob each is very inefficient
    consumeContent: () => void,

    size?: 'fixed' | 'auto',
    showCompleted?: boolean;
}

/**
 * Tile for launching a video.
 * @param props
 */
export const VideoTile = (props: VideoTileProps) => {
    const {
        model,
        //thumbnailBlob,
        consumeContent,
        //blob,
        isLoading,
        size = 'auto',
        showCompleted,
    } = props;
    const { t } = useTranslation();
    const [isMenuOpen, toggleMenuOpen] = useToggleStateArray();
    const subscription = useCurrentUserOrEmulatedSubscription();

    // Load the thumbnail from BlobStorage
    //const {
    //    data: {
    //        model: thumbnail,
    //    }, isLoading: thumbnailIsLoading
    //} = useBlobReference(!!blob ? null : thumbnailBlob);

    //const thumbnailImage = blob ?? thumbnail;

    const currentUser = useCurrentUser();
    const [saveCpd, { errors: saveErrors }] = useSaveUserLearningEventMutation();

    const [addToCPD] = useAsyncCallback(async () => {
        
        var newUserLearningEvent = {
        id: Guid.newGuid(),
        userId: currentUser?.id,
        eventDate: moment(),
        learningEventType: model.learningContentType,
        externalName: model.name,
        externalDescriptionHtml: model.descriptionHtml,
        cPDMinutes: model.cPDMinutes,
        archived: false,
        }

        // Save the main model.
        await saveCpd(newUserLearningEvent.id, { ...newUserLearningEvent }, true);

        // Show a popup notification saying it was successful


    }, [currentUser, model, saveCpd]);

    // Get the publish date of the learning content as a moment object
    const publishDate = moment(model.publishDate);
    // Get one week ago as a moment object
    const oneWeekAgo = moment().subtract(7, 'days');
    // Get whether the learning content is new in bool form
    const isNew = publishDate.isAfter(oneWeekAgo);
    // Get whether its from the same school or trust
    const isFromSameSchoolOrTrust = model.schoolId === subscription?.schoolId || model.trustId === subscription?.trustId;

    // Render the UI
    //
    return (
        <>
            <Card className={`video-tile video-tile-browse-video video-tile-${size}`} onClick={() => consumeContent()}>
                {/*<ConditionalFragment showIf={!!isLoading || !!thumbnailIsLoading}>*/}
                {/*    <LoadingIndicator />*/}
                {/*</ConditionalFragment>*/}

                <CardImg className="video-tile-video-thumbnail img-fluid" src={`/api/blobs/redirectToUrl/${model.imageBlobReferenceId ?? ''}` /*(thumbnailImage && thumbnailImage?.url) || ''*/} />

                {
                    isNew ? (
                        <>
                            <Badge pill color="primary" className="video-tile-badge" style={{ marginRight: "3rem" }}>{learningContentTypeDisplayName(model.learningContentType as LearningContentType, t)}</Badge>
                            <Badge pill color="warning" className="video-tile-badge">{t('videoTile.new', 'New!')}</Badge>
                            <ButtonDropdown className="video-tile-options" onClick={e => e.stopPropagation()} isOpen={isMenuOpen(model.id)} toggle={() => toggleMenuOpen(model.id)}>
                                <DropdownToggle color="primary" caret>
                                    <span className="visually-hidden">{t('common.menuDropdown', 'More')}</span>
                                </DropdownToggle>
                                <DropdownMenu end>
                                    <Button style={{ backgroundColor: "transparent", borderColor: "white" }} onClick={() => addToCPD()}>
                                        <DropdownItem>
                                            <FontAwesomeIcon icon="plus" />
                                            <> {t('common.addToCPD', 'Add to CPD')}</>
                                        </DropdownItem>
                                    </Button>
                                </DropdownMenu>
                            </ButtonDropdown>
                        </>
                    ) : isFromSameSchoolOrTrust ? (
                            <>
                                <Badge pill color="primary" className="video-tile-badge" style={{ marginRight: "2rem" }}>{learningContentTypeDisplayName(model.learningContentType as LearningContentType, t)}</Badge>
                                <Badge pill color="warning" className="video-tile-badge">
                                    <FontAwesomeIcon icon={"graduation-cap"} />
                                </Badge>
                                <ButtonDropdown className="video-tile-options" onClick={e => e.stopPropagation()} isOpen={isMenuOpen(model.id)} toggle={() => toggleMenuOpen(model.id)}>
                                    <DropdownToggle color="primary" caret>
                                        <span className="visually-hidden">{t('common.menuDropdown', 'More')}</span>
                                    </DropdownToggle>
                                    <DropdownMenu end>
                                        <Button style={{ backgroundColor: "transparent", borderColor: "white" }} onClick={() => addToCPD()}>
                                            <DropdownItem>
                                                <FontAwesomeIcon icon="plus" />
                                                <> {t('common.addToCPD', 'Add to CPD')}</>
                                            </DropdownItem>
                                        </Button>
                                    </DropdownMenu>
                                </ButtonDropdown>
                            </>
                    ) :
                        (
                        <>
                            <Badge pill color="primary" className="video-tile-badge">{learningContentTypeDisplayName(model.learningContentType as LearningContentType, t)}</Badge>
                            <ButtonDropdown className="video-tile-options" onClick={e => e.stopPropagation()} isOpen={isMenuOpen(model.id)} toggle={() => toggleMenuOpen(model.id)}>
                                <DropdownToggle color="primary" caret>
                                    <span className="visually-hidden">{t('common.menuDropdown', 'More')}</span>
                                </DropdownToggle>
                                    <DropdownMenu end>
                                        <Button style={{ backgroundColor: "transparent", borderColor: "white" }} onClick={() => addToCPD()}>
                                            <DropdownItem>
                                                <FontAwesomeIcon icon="plus" />
                                                <> {t('common.addToCPD', 'Add to CPD')}</>
                                            </DropdownItem>
                                    </Button>
                                </DropdownMenu>
                            </ButtonDropdown>
                        </>
                    )
                }


                 
                <CardImgOverlay className="video-tile-video-thumbnail-overlay">
                    <FontAwesomeIcon icon="play" />
                </CardImgOverlay>
                <CardBody tag="div" className="video-tile-body">
                    <CardTitle className="video-tile-browse-video-name">
                        {t('videoTile.model.name', model.name)}

                        <ConditionalFragment showIf={!!model.learningContentType}>
                            <>  </>
                            <Badge pill>
                                {learningCostTypeDisplayName(model.learningCostType as LearningCostType, t)}
                            </Badge>
                        </ConditionalFragment>
                        <ConditionalFragment showIf={!!showCompleted}>
                            <>  </>
                            <Badge pill color="success">
                                <FontAwesomeIcon icon="check" />
                                <> </>
                                {t('In your CPD')}
                            </Badge>
                        </ConditionalFragment>
                    </CardTitle>
                </CardBody>
                    
            </Card>
        </>
    );
};