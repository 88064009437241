import { EditEvent, EditEventProps } from "../EditEvent";

/**
 * Create a new admin Event
 * @param props
 */
export const CreateAdminEvent = (props: EditEventProps) => (<EditEvent isAdmin isCreate={true} {...props} />);

/**
 * Create a new trust Event
 * @param props
 */
export const CreateTrustEvent = (props: EditEventProps) => (<EditEvent isTrust isCreate={true} {...props} />);

/**
 * Create a new school Event
 * @param props
 */
export const CreateSchoolEvent = (props: EditEventProps) => (<EditEvent isCreate={true} {...props} />);

/*
 * Component to edit an admin Event
*/
export const EditAdminEvent = (props: EditEventProps) => (<EditEvent isAdmin {...props} />);

/*
 * Component to edit a Event inside a trust
*/
export const EditTrustEvent = (props: EditEventProps) => (<EditEvent isTrust {...props} />);

/*
 * Component to edit a Event inside a school
*/
export const EditSchoolEvent = (props: EditEventProps) => (<EditEvent {...props} />);

/*
 * Component to view a Event
*/
export const ViewEvent = (props: EditEventProps) => (<EditEvent isView={true} {...props} />);