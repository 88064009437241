import { RouteEntry } from "../../shared/routes";
import { CpdList } from "./CpdList";
import { CreateCpd, EditCpd } from "./EditCpd";
import { DeleteCpd } from "./DeleteCpd";

export const cpdRoutes: Array<RouteEntry> = [
    { path: '/cpd', element: <CpdList />, authorize: true},
    { path: '/cpd/add', element: <CreateCpd />, authorize: true},
    { path: '/cpd/edit/:id', element: <EditCpd />, authorize: true},
    { path: '/cpd/delete/:id', element: <DeleteCpd />, authorize: true},
];
