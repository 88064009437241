import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useValidatorCallback } from "pojo-validator-react";
import { ValidatedInput } from "pojo-validator-reactstrap";
import { useCallback, useMemo } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useAsyncCallback } from "react-use-async-callback";
import { Button, ButtonGroup, Col, Form, FormGroup, Label, Row, Spinner } from "reactstrap";
import { ButtonAsync } from "reactstrap-buttonasync";
import { useToggleState } from "use-toggle-state";
import { BlobUploadService } from "../../../api/main/blobReferences/BlobUploadService";
import { useBlobReference } from "../../../api/main/blobReferences/useBlobReference";
import { OrganisationalRoleLinkType } from "../../../api/main/models/constants/OrganisationalRoleLinkType";
import { OrganisationalRoleLink, organisationalRoleLinkDefaultValues } from "../../../api/main/models/OrganisationalRoleLink";
import { OrganisationProfile, organisationProfileDefaultValues } from "../../../api/main/models/OrganisationProfile";
import { useDeleteOrganisationalRoleLinkMutation } from "../../../api/main/organisationalRoleLinks/useDeleteOrganisationalRoleLinkMutation";
import { useSaveOrganisationalRoleLinkMutation } from "../../../api/main/organisationalRoleLinks/useSaveOrganisationalRoleLinkMutation";
import { useSaveOrganisationProfileMutation } from "../../../api/main/organisationProfiles/useSaveOrganisationProfileMutation";
import { useEditOrganisationProfileViewModel } from "../../../api/main/organisationProfiles/viewModels/useEditOrganisationProfileViewModel";
import { useSaveSchoolMutation } from "../../../api/main/schools/useSaveSchoolMutation";
import { useSchool } from "../../../api/main/schools/useSchool";
import { useSaveTrustMutation } from "../../../api/main/trusts/useSaveTrustMutation";
import { useTrust } from "../../../api/main/trusts/useTrust";
import { useCurrentUserOrEmulatedSubscription } from "../../../globalState/subscriptions/useCurrentUserOrEmulatedSubscription";
import { AlertOnErrors } from "../../../shared/alertOnErrors";
import { HtmlEditor } from "../../../shared/htmlEditor";
import { useChanges, useChangesArray } from "../../../shared/useChanges";
import { useDebounce } from "../../../shared/useDebounce/useDebounce";
import { Banner } from "../../shared/banner/Banner";
import { FileUploadButton } from "../../shared/fileUploadButton/FileUploadButton";
import { FormButtons } from "../../shared/formButtons/FormButtons";
import { LoadingIndicator } from "../../shared/loadingIndicator/LoadingIndicator";
import { MainContainer } from "../../shared/mainContainer/MainContainer";
import { UploadedImagePreview } from "../../shared/uploadedImagePreview/UploadedImagePreview";
import { useDisplayOrder } from "../../shared/useDisplayOrder/useDisplayOrder";
import { AddOrganisationalRolesModal } from "./AddOrganisationalRolesModal";
import "./EditOrganisationProfile.scss";

export interface EditOrganisationProfileProps {
    isCreate?: boolean,
    onCreateDefaultValues?: () => Partial<OrganisationProfile>,
    isSchool?: boolean,
    isTrust?: boolean,
}

/**
 * Create a new Trust
 * @param props
 * @returns
 */
export const CreateOrganisationProfile = (props: EditOrganisationProfileProps) => (<EditOrganisationProfile isCreate={true} {...props} />);

/**
 * Edit an existing OrganisationProfile
 * @param props
 * @returns
 */
export const EditOrganisationProfile = (props: EditOrganisationProfileProps) => {
    const {
        isCreate,
        onCreateDefaultValues,
        isSchool,
        isTrust,
    } = props;
    const { t } = useTranslation();
    const { id } = useParams<{ id: string | undefined; }>();
    const navigate = useNavigate();
    const profile = useCurrentUserOrEmulatedSubscription();
    const schoolIdForTrustCustomisingSchool = sessionStorage.getItem('schoolIdForCustomisingTrust');
   
    // Load the data
    const {
        data: {
            model: storeModel,
            organisationalRoleLinks: storeOrganisationalRoleLinks,
            organisationalRoles: storeOrganisationalRoles
        }, isLoading, errors: loadErrors, refresh
    } = useEditOrganisationProfileViewModel(id);

    // If we're a school, we should get the school so we can assign the profile to it.
    const {
        data: {
            model: school,
        }, isLoading: schoolLoading, errors: schoolErrors
    } = useSchool(schoolIdForTrustCustomisingSchool ? schoolIdForTrustCustomisingSchool : profile?.schoolId ? profile.schoolId : null);

    //Same for trusts
    const {
        data: {
            model: trust,
        }, isLoading: trustLoading, errors: trustErrors
    } = useTrust(profile?.trustId ?? null);


    // Model (OrganisationProfile)
    const { model, change, changes } = useChanges(storeModel, isCreate ? { ...organisationProfileDefaultValues(), ...(onCreateDefaultValues ? onCreateDefaultValues() : {}) } : undefined);
    const [saveOrganisationProfile, { errors: saveErrors }] = useSaveOrganisationProfileMutation();

    // change and save schools and trusts
    const { change: changeSchoolOrTrust, changes: schoolOrTrustChanges } = useChanges(isSchool ? school : isTrust ? trust : undefined, undefined);
    const [saveSchool] = useSaveSchoolMutation();
    const [saveTrust] = useSaveTrustMutation();

    // Model (OrganisationalRoleLink)
    const organisationalRoleLinksManager = useChangesArray<OrganisationalRoleLink, string>(storeOrganisationalRoleLinks, item => item.id);
    const [saveOrganisationalRoleLink] = useSaveOrganisationalRoleLinkMutation();
    const [removeOrganisationalRoleLink] = useDeleteOrganisationalRoleLinkMutation();

    // Get all the Ids of the OrganisationalRoleLinks we are already assigned - enables us to filter out these inside the AddOrganisationalRolesModal
    const storeOrganisationalRoleLinkIds = useMemo(() => organisationalRoleLinksManager.model?.map(item => item.organisationalRoleId), [organisationalRoleLinksManager]);


    // Order the items so they show in and can be managed by displayOrder.
    const [orderedOrganisationalRoleLinks, {
        canMoveUp,
        moveUp,
        canMoveDown,
        moveDown,
    }] = useDisplayOrder(organisationalRoleLinksManager);

    // Get all the OrganisationalRoles from the OrganisationalRoleLinks
    const organisationalRoles = useMemo(() => orderedOrganisationalRoleLinks?.map(item => {
        const role = storeOrganisationalRoles.find(it => it.id === item.organisationalRoleId);

        return {
            ...role,
            roleLinkId: item.id,
            displayOrder: item.displayOrder
        };
    }), [orderedOrganisationalRoleLinks, storeOrganisationalRoles]);

    // OrganisationProfile image upload (photo)
    const { data: { model: image }, errors: imageLoadErrors } = useBlobReference(model?.imageBlobReferenceId);
    const [onUploadImage, { errors: imageUploadErrors, isExecuting: isUploadingImage }] = useAsyncCallback(async (files: FileList | null) => {
        if (!files) {
            return;
        }

        let uploadService: BlobUploadService = new BlobUploadService("/api/blobs");
        let result = await uploadService.upload(files);

        if (!!result) {
            change({ imageBlobReferenceId: result.id });
        }
    }, [change]);

    // Clear image functionality
    const [clearImage, { isExecuting: isClearingImage }] = useAsyncCallback(async () => {
        change({ imageBlobReferenceId: null });
    }, [change]);

    // Main model validation
    const [validate, validationErrors] = useValidatorCallback((validation, fieldsToCheck) => {
        const rules = {
            name: () => !model?.name ? t('editOrganisationProfile.errors.nameRequired', 'Name is required') : '',
        };
        validation.checkRules(rules, fieldsToCheck);
    }, [
        model,
    ]);

    // Handle toggling the OrganisationalRoles Modal
    const [manageOrganisationalRolesModalIsOpen, _toggleAddOrganisationalRolesModal] = useToggleState();
    const toggleAddOrganisationalRolesModal = useCallback(() => {
        _toggleAddOrganisationalRolesModal();
    }, [_toggleAddOrganisationalRolesModal]);

    // Handle adding of OrganisationalRoles when the modal is closed
    const [onAddOrganisationalRolesModalClosed, { isExecuting: isAdding, errors: addErrors }] = useAsyncCallback(async (event: { selectedIds: Array<string>, cancelled: boolean; }) => {
        if (event.cancelled || !event.selectedIds || !event.selectedIds.length) {
            return;
        }

        // Get the current number of items in the list, set the displayOrder to that number
        // Enables us to always add the next item with the displayOrder plus 1.
        let displayOrder = orderedOrganisationalRoleLinks.length;

        // For each selected OrganisationalRole, add a new OrganisationalLink
        for (const selectedId of event.selectedIds) {
            organisationalRoleLinksManager.addFor({
                ...organisationalRoleLinkDefaultValues(),

                organisationalRoleId: selectedId,
                targetId: model?.id,
                targetType: OrganisationalRoleLinkType.OrganisationProfile,
                organisationalRoleLinkType: OrganisationalRoleLinkType.RoleInOrganisationProfile,
                displayOrder: displayOrder++,
            });
            storeOrganisationalRoleLinkIds?.push(selectedId);
        }

        saveDebounce();
    }, [organisationalRoleLinksManager, model?.id]);

    // Save the form
    const [saveForm, { isExecuting: _isSaving, errors: saveFormErrors }] = useAsyncCallback(async () => {
        if (!model) {
            return;
        }

        if (!validate()) {
            return;
        }

        if (isSchool) {
            // Set the school's organisationProfileId to the current model's id
            changeSchoolOrTrust({ organisationProfileId: model?.id });
            await saveSchool(school?.id ?? '', schoolOrTrustChanges, false);
        }
        if (isTrust) {
            // Set the trust's organisationProfileId to the current model's id
            changeSchoolOrTrust({ organisationProfileId: model?.id });
            await saveTrust(trust?.id ?? '', schoolOrTrustChanges, false);
        }


        // Save the main model.
        await saveOrganisationProfile(model.id, { ...changes }, isCreate ?? false);

        // Save the OrganisationalRoleLink items
        for (const item of organisationalRoleLinksManager.added) { await saveOrganisationalRoleLink(item.id, organisationalRoleLinksManager.changesFor(item.id), true); }
        for (const item of organisationalRoleLinksManager.updated) { await saveOrganisationalRoleLink(item.id, organisationalRoleLinksManager.changesFor(item.id), false); }
        for (const item of organisationalRoleLinksManager.removed) { await removeOrganisationalRoleLink(item.id); }
        organisationalRoleLinksManager.markAsSaved();

        // Go back to previous screen.
        navigate(-1);
    }, [
        validate,
        saveOrganisationProfile,
        model,
        changes,
        isCreate,
        id,
        navigate,
    ]);

    // Saving the OrganisationalRoleLinks
    const [save, { isExecuting: isSavingOrganisationalRoleLinks, errors: organisationalRoleLinksSaveFormErrors }] = useAsyncCallback(async () => {
        if (!model) {
            return;
        }

        // Save the OrganisationalRoleLink items
        for (const item of organisationalRoleLinksManager.added) { await saveOrganisationalRoleLink(item.id, organisationalRoleLinksManager.changesFor(item.id), true); }
        for (const item of organisationalRoleLinksManager.updated) { await saveOrganisationalRoleLink(item.id, organisationalRoleLinksManager.changesFor(item.id), false); }
        for (const item of organisationalRoleLinksManager.removed) { await removeOrganisationalRoleLink(item.id); }
        organisationalRoleLinksManager.markAsSaved();
        
    }, [
        saveOrganisationProfile,
        model,
        changes,
        isCreate,
        id,
        navigate,
    ]);
    const isSaving = _isSaving || isSavingOrganisationalRoleLinks;

    // Allow ourselves to be saved after a delay.
    const saveDebounce = useDebounce(() => {
        save();
    }, { delay: 1000 });

    // Handle moving an item up or down
    const moveItem = useCallback(async (item: string, direction: 'up' | 'down') => {
        if (!item) {
            return;
        }

        if (direction === 'up') {
            moveUp(item);
        } else {
            moveDown(item);
        }

        // Save after a short delay
        saveDebounce();
    }, [moveDown, moveUp, saveDebounce]);

    // Handle removing an item
    const removeItem = useCallback(async (item: string) => {
        if (!item) {
            return;
        }

        organisationalRoleLinksManager.removeFor(item);

        // Save after a short delay
        saveDebounce();

        // Refresh the list
        refresh();
    }, [organisationalRoleLinksManager, saveDebounce, refresh]);

    const loading = isLoading || schoolLoading || trustLoading;
    // Render the UI
    //
    return (
        <>
            <Banner>
                <Row>
                    <Col xs={12} md="auto">
                        <h1>
                            {
                                isCreate ? t('editOrganisationProfile.createHeading.default', 'Add organisational profile')
                                    : t('editOrganisationProfile.editHeading.default', 'Edit organisational profile')
                            }
                        </h1>
                    </Col>
                    <ConditionalFragment showIf={loading}>
                        <Col xs="auto">
                            <LoadingIndicator size="sm" />
                        </Col>
                    </ConditionalFragment>
                </Row>
            </Banner>

            <MainContainer>
                <AlertOnErrors
                    errors={[
                        loadErrors,
                        saveFormErrors,
                        saveErrors,
                        imageLoadErrors,
                        imageUploadErrors,
                        addErrors,
                        organisationalRoleLinksSaveFormErrors,
                        schoolErrors,
                        trustErrors
                    ]}
                />

                <Form onSubmit={e => { e.preventDefault(); saveForm(); }}>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label htmlFor="name">{t('editOrganisationProfile.name.label', 'Name')}</Label>
                                <ValidatedInput name="name" type="text" value={model?.name ?? ""} onChange={e => change({ name: e.currentTarget.value })} onBlur={e => validate('name')} validationErrors={validationErrors['name']} />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <FormGroup>
                                <Label htmlFor="name">{t('editOrganisationProfile.image.label', 'Organisation profile photo:')}</Label>
                                <UploadedImagePreview size="lg" src={image?.url ?? ''} />
                                <Row>
                                    <Col>
                                        <ButtonGroup>
                                            <FileUploadButton
                                                color={`primary`}
                                                isExecuting={isUploadingImage}
                                                executingChildren={<><Spinner size="sm" /> {t('common.uploading', 'Uploading...')}</>}
                                                onUpload={onUploadImage}
                                                outline={false}>
                                                <FontAwesomeIcon icon="image" />
                                                <> </>
                                                {t('editOrganisationProfile.uploadButtonText', 'Upload an image')}
                                            </FileUploadButton>
                                            <ButtonAsync color="primary"
                                                outline
                                                isExecuting={isClearingImage}
                                                type="button"
                                                onClick={clearImage}
                                                executingChildren={<><Spinner size="sm" /> {t('editOrganisationProfile.clearingImage', 'Clearing image...')}</>}>
                                                {t('editOrganisationProfile.clearImageButton', 'Clear image')}
                                            </ButtonAsync>
                                        </ButtonGroup>
                                    </Col>
                                </Row>
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <FormGroup>
                            <Label htmlFor="descriptionHtml">{t('editOrganisationProfile.description.label', 'Description')}</Label>
                            <HtmlEditor size="sm" value={model?.descriptionHtml ?? ''} onChange={text => change({ descriptionHtml: text })} />
                        </FormGroup>
                    </Row>
                </Form>

                {/* OrganisationalRoles */}
                <Row>
                    <div className="organisation-profile-timeline">
                        {organisationalRoles?.map((item, index) => (
                            <div key={index} className="organisation-profile-timeline-item">
                                <div className="organisation-profile-timeline-circle">
                                    <FontAwesomeIcon icon="person-half-dress" size="xl" />
                                </div>

                                <div className="organisation-profile-timeline-content">
                                    <h3>{item.name}</h3>
                                </div>

                                <div>
                                    <Button color="danger" size="sm" onClick={() => removeItem(item.roleLinkId)}>
                                        <FontAwesomeIcon icon="trash" />
                                    </Button>
                                    <> </>
                                    <ConditionalFragment showIf={canMoveUp(item.roleLinkId)}>
                                        <FontAwesomeIcon style={{ cursor: 'pointer' }} icon="arrow-up" onClick={() => moveItem(item.roleLinkId, 'up')} />
                                    </ConditionalFragment>
                                    <> </>
                                    <ConditionalFragment showIf={canMoveDown(item.roleLinkId)}>
                                        <FontAwesomeIcon style={{ cursor: 'pointer' }} icon="arrow-down" onClick={() => moveItem(item.roleLinkId, 'down')} />
                                    </ConditionalFragment>
                                </div>
                            </div>
                        ))}
                    </div>
                </Row>

                {/* Add OrganisationalRoles */}
                <Row>
                    <Button color="primary" outline onClick={() => toggleAddOrganisationalRolesModal()}>
                        {t('editOrganisationProfile.manageOrganisationalRoles', 'Add organisational role(s)')}
                    </Button>
                </Row>

                <AddOrganisationalRolesModal
                    isOpen={manageOrganisationalRolesModalIsOpen}
                    toggle={toggleAddOrganisationalRolesModal}
                    onClose={onAddOrganisationalRolesModalClosed}
                    isSaving={isAdding}
                    storeOrganisationalRoleLinkIds={storeOrganisationalRoleLinkIds}
                />

                <FormButtons>
                    <ConditionalFragment showIf={!loading}>
                        <ButtonAsync color="primary" isExecuting={isSaving} onClick={() => saveForm()}
                            executingChildren={<><Spinner size="sm" /> {t('common.saving', 'Saving...')}</>}>
                            <FontAwesomeIcon icon="save" />
                            <> </>
                            {t('common.save', 'Save')}
                        </ButtonAsync>
                    </ConditionalFragment>

                    <Button type="button" color="primary" outline onClick={e => navigate(-1)}>
                        {t('common.cancel', 'Cancel')}
                    </Button>
                </FormButtons>
            </MainContainer>
        </>
    );
};