import { useCurrentUserProfile } from '../../api/main/profiles/useCurrentUserProfile';
import { useSubscriptionEmulation } from './useSubscriptionEmulation';

/**
 * Details about the current subscription.  These can be used to limit what the user sees on top of the roles.
 */
export interface Subscription {
    trustId?: string | null | undefined,
    schoolId?: string | null | undefined,
}

/**
 * Returns the current user's subscription details, or if the user is currently emulating a different subscription, the emulated subscription.
 */
export function useCurrentUserOrEmulatedSubscription(): Subscription | null | undefined {
    const [emualtedSubscription] = useSubscriptionEmulation();

    const { data: { model: currentUserProfile } } = useCurrentUserProfile();

    if (emualtedSubscription) {
        return emualtedSubscription;
    }

    return currentUserProfile;
}