import * as React from "react";
import { Navigate } from 'react-router-dom';
import { useCurrentUserRoles } from "../../api/account";
import { IdentityRoles } from "../../configure/security/IdentityRoles";
import { useSubscriptionEmulation } from "../../globalState/subscriptions/useSubscriptionEmulation";

/**
 * Default page for / url.
 */
export const HomeIndex = () => {

    // Emulating being in a trust
    const [currentEmulatedSubscription] = useSubscriptionEmulation();
    //Get the current user roles, so we can check if they are a manager/admin/whatever.
    const {
        data: {
            roles: userRoles,
        }
    } = useCurrentUserRoles();
    const redirectUrl = React.useMemo(() => {

        
        //if (!!currentEmulatedSubscription?.schoolId) {
        //    if (userRoles?.find(r => r === IdentityRoles.ManageSchool || r === IdentityRoles.SchoolAdministration)) {
        //        // If the user has a school Id and one of the above roles, redirect to the school details page.
        //        return "/manage-school/school-details/" + currentEmulatedSubscription?.schoolId
        //    }
        //    else {
        //        return "/my-profile"
        //    }

        //} else if (!!currentEmulatedSubscription?.trustId) {
        //    if (userRoles?.find(r => r === IdentityRoles.ManageTrust || r === IdentityRoles.TrustAdministration)) {
        //        // If the user has a trust Id and one of the above roles, redirect to the trust details page.
        //        return "/manage-trust/trust-details/" + currentEmulatedSubscription?.trustId
        //    }
        //    else {
        //        return "/my-profile"
        //    }
        //}

        //// If we have neither of the above, then we should be an admin, so redirect to the admin page.
        //return "/administration/trusts"

        return "/my-profile"

    }, [currentEmulatedSubscription, userRoles]);

    return (<Navigate to={redirectUrl } replace />);
};