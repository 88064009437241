import * as React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useMemo, useState } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { LinkContainer } from "react-router-bootstrap";
import { useNavigate } from "react-router-dom";
import { Waypoint } from "react-waypoint";
import { Badge, Button, ButtonDropdown, ButtonGroup, CardImg, CardSubtitle, CardTitle, Col, DropdownItem, DropdownMenu, DropdownToggle, Row } from "reactstrap";
import { useToggleStateArray } from "use-toggle-state";
import { useSchoolsListViewModel } from "../../../api/main/schools/viewModels/useSchoolsListViewModel";
import { usePreferredListViewMode } from "../../../globalState/preferredListViewMode/usePreferredListViewMode";
import { useSubscriptionEmulation } from "../../../globalState/subscriptions/useSubscriptionEmulation";
import { AlertOnErrors } from "../../../shared/alertOnErrors";
import { HtmlDisplay } from "../../../shared/htmlEditor";
import { useSearchParams } from "../../../shared/useURLSearchParams";
import { truncateText } from "../../../utilities/truncateText";
import { Banner } from "../../shared/banner/Banner";
import { CardsOrTable } from "../../shared/cardsOrTable/CardsOrTable";
import { CardsOrTableToggle } from "../../shared/cardsOrTable/CardsOrTableToggle";
import { LoadingIndicator } from "../../shared/loadingIndicator/LoadingIndicator";
import { MainContainer } from "../../shared/mainContainer/MainContainer";
import { NoResultsFound } from "../../shared/noResultsFound/NoResultsFound";
import { SearchInput } from "../../shared/searchInput/SearchInput";
import { StickyToolbar } from "../../shared/stickyToolbar/StickyToolbar";
import { Subscription, useCurrentUserOrEmulatedSubscription } from '../../../globalState/subscriptions/useCurrentUserOrEmulatedSubscription';
import { School } from '../../../api/main/models/School';
import { Guid } from 'guid-string';


export interface SchoolListProps {

    isAdmin?: boolean,
    isCustomise?: boolean,
}

/**
 * List of all Schools
 */
export const SchoolsList = (props: SchoolListProps) => {

    const {
        // If the user is on the administration section or not.
        isAdmin = false,
        // If the user is on the trust level and looking to customise a school organisation profile.
        isCustomise = false,
    } = props;

    // If the user is not an admin and not customising, then they are a trust user, which means they shouldn't be able to add or delete schools.
    const isTrust = !isAdmin && !isCustomise;
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { search: searchParam } = useSearchParams();
    const [search, setSearch] = useState<string>(searchParam ?? '');
    const [isMenuOpen, toggleMenuOpen] = useToggleStateArray();
    const [viewMode] = usePreferredListViewMode();

    // Load the data
    const {
        data: {
            items: _allItems,
            trusts,
            organisationProfiles
        }, isLoading, errors: loadErrors, hasMore, fetchMore
    } = useSchoolsListViewModel();

    // Combine the data so it is all on one model
    const allItems = useMemo(() => _allItems?.map(item => {
        const trust = trusts?.find(it => it.id === item.trustId);
        const organisationProfile = organisationProfiles?.find(it => it.id === item.organisationProfileId);
        const image = !Guid.isEmpty(item.imageBlobReferenceId) ? { id: item.imageBlobReferenceId, url: `/api/blobs/redirectToUrl/${item.imageBlobReferenceId}`, filename: item.imageBlobReferenceId ?? ''} : undefined;

        return {
            ...item,
            trust,
            organisationProfile,
            image: image ?? undefined
        };
    }), [_allItems, trusts, organisationProfiles]);

    const subscription = useCurrentUserOrEmulatedSubscription(); // Grabs current/emulated subscription

    // Emulating being in a school
    const [currentEmulatedSubscription, emulateSubscription] = useSubscriptionEmulation();

    // which trust to open when navigating to overview
    const [trustIdToUse, setTrustIdToUse] = React.useState<string>();

    React.useEffect(() => {
        // Clear states each time we are checking to prevent values getting stuck (e.g. you stop managing)
        setTrustIdToUse(undefined);

        // Use emulated trust
        if (subscription?.trustId) {
            setTrustIdToUse(subscription.trustId)
        }
    }, [subscription, setTrustIdToUse]);

    // Begin emulating
    const onManage = React.useCallback((subscription: React.SetStateAction<Subscription | null | undefined>) => {
        emulateSubscription(subscription);
    }, [emulateSubscription]);

    //stop emulating
    const onStopManaging = React.useCallback(() => {
            // We are on the school list, so when stopping we want to keep the emulated TrustId.
            emulateSubscription({ schoolId: null, trustId: trustIdToUse });
    }, [emulateSubscription, trustIdToUse]);


    // Filtering 
    const items = useMemo(() => {
        let ret = (allItems ?? []);

        let lowerSearch = search.toLocaleLowerCase();
        if (lowerSearch) {
            // Just filtering by all string values that arent ids to start with. Most likely wont need them all for search.
            ret = ret.filter(item =>
                item.name.toLocaleLowerCase().indexOf(lowerSearch) >= 0
                || (item?.trust?.name ?? '').toLocaleLowerCase().indexOf(lowerSearch) >= 0
                || (item?.organisationProfile?.name ?? '').toLocaleLowerCase().indexOf(lowerSearch) >= 0
                || (item?.descriptionHtml).toLocaleLowerCase().indexOf(lowerSearch) >= 0
                || t('common.fullName', '{{ firstName }} {{ lastName }}', { firstName: item.subscriptionOwnerFirstName, lastName: item.subscriptionOwnerLastName }).toLocaleLowerCase().indexOf(lowerSearch) >= 0
                || (item.subscriptionOwnerEmail).toLocaleLowerCase().indexOf(lowerSearch) >= 0
                || (item.subscriptionOwnerId).toLocaleLowerCase().indexOf(lowerSearch) >= 0
            );
        }
        if (!!trustIdToUse && !isAdmin) {
            // If we are viewing from inside a trust we want to only show schools under that trust.
            ret = ret.filter(item => item.trustId === trustIdToUse);
        }

        return ret;
    }, [allItems, search, trustIdToUse, isAdmin, t]);

    // Function that can truncate text if over 200 chars
    const trucatedDescription = useCallback((text: string) => {
        return truncateText(200, text);
    }, []);

    //Put schoolId into session storage so we can use it in the customise organisation profile page
    const customiseSchoolAsTrust = useCallback((school: School) =>
    {
        sessionStorage.setItem('schoolIdForCustomisingTrust', school.id);
        navigate(`${school.organisationProfileId}`);
    }, [navigate]);

    // Render the UI
    //
    return (
        <>
            <Banner fluid>
                <StickyToolbar>
                    <Row>
                        <Col xs={12} md="auto">
                            <h1>
                                {t('schoolsList.title', isCustomise ? 'Select a school to customise' : 'Schools')}
                            </h1>
                        </Col>
                        <ConditionalFragment showIf={isLoading}>
                            <Col xs="auto">
                                <LoadingIndicator size="sm" />
                            </Col>
                        </ConditionalFragment>
                    </Row>
                    <Row>
                        <Col>
                            <SearchInput value={search} onChange={e => setSearch(e.currentTarget.value)} />
                        </Col>
                        <Col xs="auto">
                            <Row>
                                <ConditionalFragment showIf={!isTrust && !isCustomise}>
                                    <ButtonGroup>
                                        <LinkContainer to={'add'}>
                                            <Button color="primary">
                                                <FontAwesomeIcon icon="plus" /><> {t('schoolsList.add', 'Add')}</>
                                            </Button>
                                        </LinkContainer>
                                    </ButtonGroup>
                                </ConditionalFragment>
                            </Row>
                        </Col>
                        <Col xs={12} md="auto">
                            <CardsOrTableToggle />
                        </Col>
                    </Row>
                </StickyToolbar>
            </Banner>

            <MainContainer fluid>
                <AlertOnErrors
                    errors={[
                        loadErrors
                    ]}
                />

                <CardsOrTable
                    viewMode={viewMode}
                    items={items}
                    tableHeadings={[
                        null, /* Handles the card specific function for which we don't want a table column. */
                        t('schoolsList.name.heading', 'Name'),
                        t('schoolsList.trust.heading', 'Trust'),
                        t('schoolsList.organisation.heading', 'Organisation'),
                        t('schoolsList.description.heading', 'Description'),
                        ...isAdmin? [
                            t('schoolsList.subscriptionOwner.heading', 'Subscription Owner'),
                            t('schoolsList.subscriptinOnwerEmail.heading', 'Subscription Owner Email'),
                            t('schoolsList.subscriptionId.heading', 'Subscription Unique Identifier'),
                        ]: [],

                    ]}
                    columns={[
                        // We handle the card layout as a specific column ignored from the table.
                        (item, view) => {
                            // This column doesn't show in the table.
                            if (view !== 'cards') {
                                return null;
                            }

                            return (
                                <>
                                    <CardTitle tag="h5">{item.name}</CardTitle>
                                    <CardSubtitle>
                                        <Row>
                                            <Col>
                                                <Badge color="primary">{item?.trust?.name ?? t('schoolsList.notPartOfATrust', 'Not part of a trust')}</Badge>
                                                <> </>
                                                <Badge color="secondary">{item.organisationProfile?.name}</Badge>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <HtmlDisplay html={trucatedDescription(item.descriptionHtml)} />
                                        </Row>

                                        <Row>
                                            <Col>
                                                <CardImg src={item.image?.url} style={{ maxHeight: "100px", width: "auto", maxWidth: "100%", }} alt={item.image?.filename} />
                                            </Col>
                                        </Row>

                                        <ConditionalFragment showIf={isAdmin}>
                                            <div className="text-muted mt-1">
                                                {t('common.fullName', '{{ firstName }} {{ lastName }}', { firstName: item.subscriptionOwnerFirstName, lastName: item.subscriptionOwnerLastName })}
                                                <> </>
                                                {item.subscriptionOwnerEmail}
                                            </div>
                                        </ConditionalFragment>
                                    </CardSubtitle>
                                </>
                            );
                        },
                        // The remaining columns are for table mode only.

                        // Name
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return item.name;
                        },

                        // Trust
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return item.trust?.name ?? t('schoolsList.notPartOfATrust', 'Not part of a trust');
                        },

                        // Organisation
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return item.organisationProfile?.name;
                        },

                        // Description
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return <HtmlDisplay html={item.descriptionHtml} />;
                        },

                        // Subscription Owner
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            if (!isAdmin) {
                                return null;
                            }

                            return t('common.fullName', '{{firstName}} {{lastName}}', { firstName: item.subscriptionOwnerFirstName, lastName: item.subscriptionOwnerLastName });
                        },

                        // Subscription Owner Email
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            if (!isAdmin) {
                                return null;
                            }

                            return item.subscriptionOwnerEmail;
                        },

                        // Subscription Unique Identifier
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            if (!isAdmin) {
                                return null;
                            }

                            return item.subscriptionOwnerId;
                        },
                    ]}
                    buttons={(item) => (
                        <>
                            <ConditionalFragment showIf={!isCustomise}>
                                <ButtonGroup>
                                    <LinkContainer to={`edit/${item.id}`}>
                                        <Button color="primary" outline>
                                            <FontAwesomeIcon icon="edit" />
                                            <> {t('common.edit', 'Edit')}</>
                                        </Button>
                                    </LinkContainer>
                                    <ConditionalFragment showIf={currentEmulatedSubscription?.schoolId !== item.id}>
                                        <Button color="primary" outline onClick={() => onManage({ schoolId: item.id, trustId: item.trustId  })}>
                                            <FontAwesomeIcon icon="atom" />
                                            <> {t('subscriptionList.manage', 'Manage')}</>
                                        </Button>
                                    </ConditionalFragment>
                                    <ConditionalFragment showIf={currentEmulatedSubscription?.schoolId === item.id}>
                                        <Button color="primary" onClick={() => onStopManaging()}>
                                            <FontAwesomeIcon icon="stop" />
                                            <> {t('subscriptionList.stopManaging', 'Stop managing')}</>
                                        </Button>
                                    </ConditionalFragment>
                                    <ConditionalFragment showIf={!isTrust}>
                                        <ButtonDropdown isOpen={isMenuOpen(item.id)} toggle={() => toggleMenuOpen(item.id)}>
                                            <DropdownToggle color="primary" outline caret>
                                                <span className="visually-hidden">{t('common.menuDropdown', 'More')}</span>
                                            </DropdownToggle>
                                            <DropdownMenu end>
                                                <LinkContainer to={`delete/${item.id}`}>
                                                    <DropdownItem className="text-danger">
                                                        <FontAwesomeIcon icon="trash" />
                                                        <> {t('common.delete', 'Delete')}</>
                                                    </DropdownItem>
                                                </LinkContainer>
                                            </DropdownMenu>
                                        </ButtonDropdown>
                                    </ConditionalFragment>
                                </ButtonGroup>
                            </ConditionalFragment>

                            <ConditionalFragment showIf={isCustomise}>
                                <ButtonGroup>
                                    <Button onClick={e => customiseSchoolAsTrust(item)} color="primary" outline>
                                        <FontAwesomeIcon icon="edit" />
                                        <> {t('common.edit', 'Customise')}</>
                                    </Button>
                                </ButtonGroup>
                            </ConditionalFragment>
                        </>

                    )}
                    onItemClick={item => isCustomise ? customiseSchoolAsTrust(item) : navigate(`edit/${item.id}`)}
                />

                <ConditionalFragment showIf={isLoading && !items?.length}>
                    <LoadingIndicator fullWidth />
                </ConditionalFragment>

                <ConditionalFragment showIf={!isLoading && !items?.length}>
                    <NoResultsFound search={search} />
                </ConditionalFragment>

                <ConditionalFragment showIf={!isLoading && hasMore()}>
                    <Waypoint key={items?.length ?? 0} onEnter={fetchMore} />
                    <LoadingIndicator fullWidth />
                </ConditionalFragment>
            </MainContainer>
        </>
    );
};