import { IdentityRoles } from "../../../configure/security/IdentityRoles";
import { RouteEntry } from "../../../shared/routes";
import { DeleteOrganisationalRole } from "./DeleteOrganisationalRole";
import { CreateOrganisationalRole, EditOrganisationalRole } from "./EditOrganisationalRole";
import { OrganisationalRoleList } from "./OrganisationalRoleList";


/**
 * Route that should be added to configure/routeConfig.ts to provide access to these components via routing
 */
export const roleRoutes: Array<RouteEntry> = [
    { path: '/administration/roles', element: <OrganisationalRoleList />, authorize: true, requireRole: IdentityRoles.Administration, },
    { path: '/administration/roles/add', element: <CreateOrganisationalRole />, authorize: true, requireRole: IdentityRoles.Administration, },
    { path: '/administration/roles/edit/:id', element: <EditOrganisationalRole />, authorize: true, requireRole: IdentityRoles.Administration, },
    { path: '/administration/roles/delete/:id', element: <DeleteOrganisationalRole />, authorize: true, requireRole: IdentityRoles.Administration, },
];