import { gql, useMutation } from "@apollo/client";
import { useAsyncCallback } from "react-use-async-callback";
import { AsyncActionStatus } from "../../../shared/abstractStore";
import { CloneOrganisationalRoleMutation, CloneOrganisationalRoleMutationVariables } from "../generated/graphql";
import { organisationalRoleFieldsFragment } from "../models/OrganisationalRole";


/**
 * Get a callback to clone a OrganisationalRole in the store.
 * @returns
 */
export function useCloneOrganisationalRoleMutation(): [(originalOrganisationalRoleId: string, newName: string, newOrganisationalRoleId: string) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<CloneOrganisationalRoleMutation, CloneOrganisationalRoleMutationVariables>(
        gql`
            mutation cloneOrganisationalRoleMutation ($originalOrganisationalRoleId: ID!, $newName: String!, $newOrganisationalRoleId: ID!) {
                cloneOrganisationalRole(originalOrganisationalRoleId: $originalOrganisationalRoleId, newName: $newName, newOrganisationalRoleId: $newOrganisationalRoleId) {
                    ...organisationalRoleFields
                }
            }

            ${organisationalRoleFieldsFragment}
        `
    );



    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (originalOrganisationalRoleId: string, newName: string, newOrganisationalRoleId: string) => {
        const result = await mutationAction({ variables: { originalOrganisationalRoleId: originalOrganisationalRoleId, newName: newName, newOrganisationalRoleId: newOrganisationalRoleId } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);


    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}