import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useMemo, useState } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { LinkContainer } from "react-router-bootstrap";
import { useNavigate } from "react-router-dom";
import { Button, CardBody, CardTitle, Col, NavItem, NavLink, Row } from "reactstrap";
import { GetToggleStateArrayValue, useToggleState, useToggleStateArray } from "use-toggle-state";
import { OrganisationalRole } from "../../api/main/models/OrganisationalRole";
import { useOrganisationalRoleGroups } from "../../api/main/organisationalRoleGroups/useOrganisationalRoleGroups";
import { useOrganisationalRoleLinks } from "../../api/main/organisationalRoleLinks/useOrganisationalRoleLinks";
import { useOrganisationalRoleLinksByTargetId } from "../../api/main/organisationalRoleLinks/useUserOrganisationalRoleLinks";
import { useOrganisationalRoles } from "../../api/main/organisationalRoles/useOrganisationalRoles";
import { useOrganisationProfileFromSubscription } from "../../api/main/organisationProfiles/useOrganisationProfileFromSubscription";
import { Subscription, useCurrentUserOrEmulatedSubscription } from "../../globalState/subscriptions/useCurrentUserOrEmulatedSubscription";
import { AlertOnErrors } from "../../shared/alertOnErrors";
import { HtmlDisplay } from "../../shared/htmlEditor";
import { useLocalStorage } from "../../shared/useLocalStorage";
import { truncateText } from "../../utilities/truncateText";
import { Banner } from "../shared/banner/Banner";
import { CardsOrTable } from "../shared/cardsOrTable/CardsOrTable";
import { LoadingIndicator } from "../shared/loadingIndicator/LoadingIndicator";
import { MainContainer } from "../shared/mainContainer/MainContainer";
import { NoResultsFound } from "../shared/noResultsFound/NoResultsFound";
import { PillsNavBar } from "../shared/pillsNavBar/PillsNavBar";
import { StickyToolbar } from "../shared/stickyToolbar/StickyToolbar";
import { CareerJourney } from "./CareerJourney";

/**
 * Career section
 */
export const Career = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    // Load the current subscription
    const subscription = useCurrentUserOrEmulatedSubscription();
    const { trustId, schoolId } = subscription as Subscription ?? null;

    // Load the OrganisationProfile
    const {
        data: {
            model: organisationProfile
        }, isLoading: isLoadingOrganisationProfile, errors: organisationProfileErrors
    } = useOrganisationProfileFromSubscription(subscription ?? {});

    // Load the OrganisationalRoleLinks
    const {
        data: {
            items: storeOrganisationalRoleLinks
        }, isLoading: isLoadingOrganisationalRoleLinks, errors: organisationalRoleLinksErrors
    } = useOrganisationalRoleLinksByTargetId({ pageSize: undefined }, organisationProfile?.id ?? null);

    // Load the OrganisationalRoles
    const {
        data: {
            items: storeOrganisationalRoles
        }, isLoading: isLoadingOrganisationalRoles, errors: organisationalRolesErrors
    } = useOrganisationalRoles({ pageSize: undefined, });

    // Load all roleLinks, so we can filter them by the roles and get only the role group links
    const {
        data: {
            items: storeAllOrganisationalRoleLinks
        }, isLoading: isLoadingAllOrganisationalRoleLinks, errors: allOrganisationalRoleLinksErrors
    } = useOrganisationalRoleLinks({ pageSize: undefined, });

    // Load all roleGroups
    const {
        data: {
            items: storeAllOrganisationalRoleGroups
        }, isLoading: isLoadingAllOrganisationalRoleGroups, errors: allOrganisationalRoleGroupsErrors
    } = useOrganisationalRoleGroups();

    const isLoading = isLoadingOrganisationProfile || isLoadingOrganisationalRoleLinks || isLoadingOrganisationalRoles || isLoadingAllOrganisationalRoleLinks;

    // Get all the OrganisationalRoles from the OrganisationalRoleLinks
    const organisationalRoles = useMemo(() => storeOrganisationalRoleLinks?.map(item => {
        const role = storeOrganisationalRoles?.find(it => it.id === item.organisationalRoleId);

        return {
            ...role,
            roleLinkId: item.id,
            displayOrder: item.displayOrder
        } as OrganisationalRole;
    }), [storeOrganisationalRoleLinks, storeOrganisationalRoles]);

    const organisationalRoleGroupLinks = storeAllOrganisationalRoleLinks?.filter(item => organisationalRoles?.find(it => it.id === item.organisationalRoleId) &&
        item.targetType === "OrganisationalRoleGroup"); 

    const organisationalRoleGroups = storeAllOrganisationalRoleGroups?.filter(item => organisationalRoleGroupLinks?.find(it => it.targetId === item.id));

    /*console.log(organisationalRoleGroups);*/

    // Handle the number of road segments based on the screen size
    let [roadSegments, setRoadSegments] = useState(10);
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 768) {
                setRoadSegments(4);
            } else {
                setRoadSegments(10);
            }
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    // Handle the active tab
    const [activeTab, setActiveTab] = useLocalStorage<string | undefined>('career.activeTab', 'showAll');

    // Render the UI
    //
    return (
        <>
            <Banner fluid>
                <Row>
                    <Col xs={12} md="auto">
                        <h1>
                            {t('career.title', 'Career')}
                        </h1>
                        <h3>{organisationProfile?.name}</h3>
                    </Col>
                    <ConditionalFragment showIf={isLoading}>
                        <Col xs="auto">
                            <LoadingIndicator size="sm" />
                        </Col>
                    </ConditionalFragment>
                </Row>
                <Row>
                    <StickyToolbar>
                        <PillsNavBar>
                            <NavItem>
                                <NavLink active={activeTab === 'showAll'} onClick={() => setActiveTab('showAll')}>
                                    <> <FontAwesomeIcon icon="eye" className="nav-icon" /> {t('editOrganisationalRole.roleTab', 'All roles')}</>
                                </NavLink>
                            </NavItem>
                            {
                                organisationalRoleGroups?.map((item, index) => {
                                    return (
                                        <NavItem>
                                            <NavLink active={activeTab === item.id} onClick={() => setActiveTab(item.id)}>
                                                <> <FontAwesomeIcon icon="id-card" className="nav-icon" /> {t('editOrganisationalRole.roleTab', item.name)}</>
                                            </NavLink>
                                        </NavItem>
                                    )
                                })
                            }
                        </PillsNavBar>
                    </StickyToolbar>
                </Row>
            </Banner>

            <ConditionalFragment showIf={trustId === null && schoolId === null}>
                <NoResultsFound children={(<p>{t('career.noSchoolOrTrust', 'You must be a member of a Trust or a School to see this page')}</p>) } />
            </ConditionalFragment>

            <ConditionalFragment showIf={trustId !== null || schoolId !== null}>
                <MainContainer fluid>
                    <AlertOnErrors errors={[
                        organisationProfileErrors,
                        organisationalRoleLinksErrors,
                        organisationalRolesErrors,
                    ]} />

                    {
                        organisationalRoleGroups?.map((item, index) => {
                            var rolesInGroup = organisationalRoles.filter(role => organisationalRoleGroupLinks.find(it => it.organisationalRoleId === role.id &&
                                it.targetId === item.id));
                            return (
                                <ConditionalFragment showIf={activeTab === item.id}>
                                    <CareerJourney
                                        organisationalRoles={rolesInGroup}
                                        numberOfStraightSegments={roadSegments}
                                    />
                                </ConditionalFragment>
                            )
                        })
                    }
                    <ConditionalFragment showIf={activeTab === 'showAll'}>
                        <CareerJourney
                            organisationalRoles={organisationalRoles}
                            numberOfStraightSegments={roadSegments}
                        />
                    </ConditionalFragment>

                    

                    <ConditionalFragment showIf={isLoading && !organisationalRoles?.length}>
                        <LoadingIndicator fullWidth />
                    </ConditionalFragment>
                </MainContainer>
            </ConditionalFragment>
        </>
    );
};