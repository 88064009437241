import { RequirementType } from "../../../api/main/models/constants/RequirementType";
import { IdentityRoles } from "../../../configure/security/IdentityRoles";
import { RouteEntry } from "../../../shared/routes";
import { DeleteRequirement } from "./DeleteRequirement";
import { CreateRequirement, EditRequirement } from "./EditRequirement";
import { RequirementList } from "./RequirementList";



/**
 * Routes that should be added to configure/routeConfig.ts to provide access to these components via routing
 */
export const requirementRoutes: Array<RouteEntry> = [
    { path: '/administration/skills', element: <RequirementList type={RequirementType.Skill} />, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/skills/delete/:id', element: <DeleteRequirement />, authorize: true, requireRole: IdentityRoles.Administration, },
    { path: '/administration/skills/add', element: <CreateRequirement type={RequirementType.Skill} onCreateDefaultValues={() => ({ requirementType: RequirementType.Skill })} />, authorize: true, requireRole: IdentityRoles.Administration, },
    { path: '/administration/skills/edit/:id', element: <EditRequirement type={RequirementType.Skill} />, authorize: true, requireRole: IdentityRoles.Administration, },

    { path: '/administration/attributes', element: <RequirementList type={RequirementType.Attribute} />, authorize: true, requireRole: IdentityRoles.Administration, },
    { path: '/administration/attributes/delete/:id', element: <DeleteRequirement />, authorize: true, requireRole: IdentityRoles.Administration, },
    { path: '/administration/attributes/add', element: <CreateRequirement type={RequirementType.Attribute} onCreateDefaultValues={() => ({ requirementType: RequirementType.Attribute })} />, authorize: true, requireRole: IdentityRoles.Administration, },
    { path: '/administration/attributes/edit/:id', element: <EditRequirement type={RequirementType.Attribute} />, authorize: true, requireRole: IdentityRoles.Administration, },

    { path: '/administration/qualifications', element: <RequirementList type={RequirementType.Qualification} />, authorize: true, requireRole: IdentityRoles.Administration, },
    { path: '/administration/qualifications/delete/:id', element: <DeleteRequirement />, authorize: true, requireRole: IdentityRoles.Administration, },
    { path: '/administration/qualifications/add', element: <CreateRequirement type={RequirementType.Qualification} onCreateDefaultValues={() => ({ requirementType: RequirementType.Qualification })} />, authorize: true, requireRole: IdentityRoles.Administration, },
    { path: '/administration/qualifications/edit/:id', element: <EditRequirement type={RequirementType.Qualification} />, authorize: true, requireRole: IdentityRoles.Administration, },

    { path: '/administration/experiences', element: <RequirementList type={RequirementType.Experience} />, authorize: true, requireRole: IdentityRoles.Administration, },
    { path: '/administration/experiences/delete/:id', element: <DeleteRequirement />, authorize: true, requireRole: IdentityRoles.Administration, },
    { path: '/administration/experiences/add', element: <CreateRequirement type={RequirementType.Experience} onCreateDefaultValues={() => ({ requirementType: RequirementType.Experience })} />, authorize: true, requireRole: IdentityRoles.Administration, },
    { path: '/administration/experiences/edit/:id', element: <EditRequirement type={RequirementType.Experience} />, authorize: true, requireRole: IdentityRoles.Administration, },
];