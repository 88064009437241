import { RouteEntry } from "../../shared/routes";
import { AdministrationNavigation } from "./AdministrationNavigation";
import { IdentityRoles } from "../../configure/security/IdentityRoles";
import { AdministrationIndex } from "./AdministrationIndex";

export const administrationRoutes: Array<RouteEntry> = [
    { path: '/administration', element: <AdministrationIndex />, authorize: true, requireRole: IdentityRoles.Administration },
];

export const administrationSidebarRoutes: Array<RouteEntry> = [
    { path: '/administration/*', element: (<AdministrationNavigation />), authorize: true, requireRole: IdentityRoles.Administration },
];
