import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import * as React from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { LinkContainer } from "react-router-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Waypoint } from "react-waypoint";
import { Button, Card, CardBody, CardHeader, CardSubtitle, Col, Collapse, Row } from "reactstrap";
import { useUserLearningEvents } from "../../api/main/userLearningEvents/useUserLearningEvents";
import { useCurrentUser } from "../../api/main/users/useCurrentUser";
import { AlertOnErrors } from "../../shared/alertOnErrors";
import { Banner } from "../shared/banner/Banner";
import { LoadingIndicator } from "../shared/loadingIndicator/LoadingIndicator";
import { MainContainer } from "../shared/mainContainer/MainContainer";
import { NoResultsFound } from "../shared/noResultsFound/NoResultsFound";
import { UserLearningEvent } from "../../api/main/generated/graphql";
import { HtmlDisplay } from "../../shared/htmlEditor";
import { truncateText } from "../../utilities/truncateText";
import { SearchInput } from "../shared/searchInput/SearchInput";
import { LearningContentType, learningContentTypeDisplayName } from "../../api/main/models/constants/LearningContentType";

interface EventDateList {
    title: string;
    events: Array<LearningEventWithColor>;

}

interface LearningEventWithColor {
    content: UserLearningEvent;
    color: string;
}

export const CpdList = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();

    const currentUser = useCurrentUser();

    //Load data
    const {
        data: {
            items,
        }, isLoading, errors: loadErrors, fetchMore, hasMore
    } = useUserLearningEvents({ pageSize: undefined }, currentUser?.id);

    const [searchText, setSearchText] = React.useState<string>('');

    // Keep track of which dates are open (for the collapsible months)
    const [openDates, setOpenDates] = React.useState<Array<string>>([])

    // Swap the dates open status
    const toggleDateIsOpen = React.useCallback((currentDate: string) => {
        if (openDates.find(item => item === currentDate)) {

            // Means the item is already open so we should close it
            setOpenDates(openDates.filter(item => item !== currentDate));

        } else {

            // Means the item isnt open so we need to set it to be
            setOpenDates(openDates.concat([currentDate]))

        }
    }, [openDates, setOpenDates]);

    // Check if the current section has been set to open
    const isDateOpen = React.useCallback((currentDate: string) => {
        if (openDates?.find(item => item === currentDate)) {
            return true;
        } else {
            return false;
        }
    }, [openDates]);

    // return a list of the months and the events that are in them.
    const dates = React.useMemo(() => {

        // Only do this if we have items to show.
        if (isLoading || items === undefined) {
            return [];
        }

        var ret: Array<EventDateList> = [];

        items.forEach(item => {
            // If we have a search value we only show items that fit the criteria
            const lowerSearchText = searchText.toLocaleLowerCase();

            if (!lowerSearchText || (item.externalName.toLocaleLowerCase().indexOf(lowerSearchText) !== -1 || item.externalDescriptionHtml.toLocaleLowerCase().indexOf(lowerSearchText) !== -1)) {

                var dateString = moment(item.eventDate).format('MMMM YYYY');

                // Check if we already have this date in the list.
                var date = ret.find(d => d.title === dateString);

                var color = "";

                // Give each event a color based on the type of event it is.
                switch (item.learningEventType) {

                    case "Course":
                        color = "#FD5000";
                        break;

                    case "VideoLinkYouTube":
                    case "VideoLink":
                    case "VideoFile":
                    case "Video":
                        color = "#3d4ffa";
                        break;

                    case "PodcastLinkYouTube":
                    case "PodcastLink":
                    case "PodcastFileVideo":
                    case "PodcastFileAudio":
                    case "Podcast":
                        color = "lightskyblue";
                        break;

                    case "Event":
                        color = "#9D5097";
                        break;

                    case "BookLink":
                    case "Book":
                        color = "#14d6c2";
                        break;
                    case "StandardLink":
                    case "StandardFile":
                    case "Standard":
                        color = "#edf2f7";
                        break;

                    default:
                        color = "white"
                        break;
                }

                // If we already have date, add the event to the list.
                if (date !== undefined) {

                    var index = ret.indexOf(date);
                    ret[index].events.push({ content: item, color });

                } else {

                    // If we don't have it, add it.
                    ret.push({ title: dateString, events: [{ content: item, color }] });
                }
            }
        })
        
        return ret;

    }, [isLoading, items, searchText]);

    // Gets the total CPD minutes for the month from the events and puts it in a displayable format.
    const getMonthTotal = React.useCallback((date: EventDateList) => {

        var monthTotal = 0;
        var minuteText = "";
        // Go through each event for the current month
        date.events.forEach(event => {

            // add up the CPD minutes for each event.
            monthTotal = monthTotal + event.content.cPDMinutes;
        });

        // If our CPD minutes is over an hour, we want to show the hours and minutes.
        if (monthTotal >= 60) {

            // Get hours without decimal.
            var hours = parseInt((monthTotal / 60).toString());

            // calculate how many minutes extra we have above the hours.
            var remainingMinutes = monthTotal - (60 * hours)

            minuteText = " minute"
            if (remainingMinutes !== 1) minuteText = " minutes";

            var hourText = " hour"
            if (hours > 1) hourText = " hours";

            return " - " + hours + hourText + " " + remainingMinutes + minuteText;

        } else {

            // Doubt we would ever get a month where only 1 minute was done but we can handle this anyway.
            minuteText = " minute"
            if (monthTotal !== 1) minuteText = " minutes";

            // We could show something different if there is no CPD minutes but the way its currently handled will show "0 minutes" so I think its fine.

            return " - " + monthTotal + minuteText;

        }

    }, []);
    

    // Get the day of the week and the day number to be shown on the cards.
    const getCardSubtitle = React.useCallback((date: Date, cpdMinutes: number) => {

        var dayNumber = moment(date).format('D').toString();

        // Handling suffix for day number.
        if (dayNumber.endsWith("1")) dayNumber = dayNumber + "st";
        else if (dayNumber.endsWith("2")) dayNumber = dayNumber + "nd";
        else if (dayNumber.endsWith("3")) dayNumber = dayNumber + "rd";
        else dayNumber = dayNumber + "th";

        var day = moment(date).format('dddd');
        
        var minuteText = " minute"
        if (cpdMinutes > 1) minuteText = " minutes";

        var durationText = cpdMinutes + minuteText

        // If our CPD minutes is over an hour, we want to show the hours and minutes.
        if (cpdMinutes >= 60) {

            // Get hours without decimal.
            var hours = parseInt((cpdMinutes / 60).toString());

            // calculate how many minutes extra we have above the hours.
            var remainingMinutes = cpdMinutes - (60 * hours)

            var remainingMinuteText = " minute"
            if (remainingMinutes !== 1) remainingMinuteText = " minutes";

            var hourText = " hour"
            if (hours > 1) hourText = " hours";

            durationText = hours + hourText + " " + remainingMinutes + remainingMinuteText;            
        }

        return day + " " + dayNumber + " - " + durationText;

    }, []);

    return (
        <>
            <Banner fluid>
                <Row>
                    <Col xs={12} md="auto">
                        <h1>
                            {t('cpdList.title', 'Your CPD')}
                        </h1>
                    </Col>
                    <ConditionalFragment showIf={isLoading}>
                        <Col xs="auto">
                            <LoadingIndicator size="sm" />
                        </Col>
                    </ConditionalFragment>
                </Row>
            </Banner>

            <>
                <div className="mb-2">
                    <Row>
                        <Col>
                            <SearchInput onChange={e => setSearchText(e.currentTarget.value)} placeholder="Search CPD" />
                        </Col>
                        <Col xs={12} sm="auto">
                            <LinkContainer to={'add'}>
                                <Button color="primary">
                                    <FontAwesomeIcon icon="plus" />
                                    <> </>
                                    {t('cpdList.add', 'Add CPD')}
                                </Button>

                            </LinkContainer>
                        </Col>
                    </Row>
                </div>

                <AlertOnErrors errors={[loadErrors]}/>

                {
                    dates.map(date => {
                        return (
                            <div key = {date.title}>
                                <MainContainer fluid style={{marginBottom: '10px', marginLeft: '5px' }}>
                                    <Row>
                                        <div style={{ cursor: 'pointer', fontWeight: 'bold' }} onClick={() => toggleDateIsOpen(date.title)}>
                                            <div style={{ textDecoration: 'underline', display: 'inline-block', marginLeft: '10px', marginBottom: '5px' }}>
                                                {date.title}
                                            </div>
                                                <bdi style={{ fontWeight: 'normal', marginRight: '5px'}}> {getMonthTotal(date)} </bdi>
                                                <FontAwesomeIcon icon={isDateOpen(date.title) ? 'caret-up' : 'caret-down'} />
                                        </div>
                                        <Collapse isOpen={isDateOpen(date.title)}>
                                            <Row>
                                    
                                                {                                    
                                                    date.events.map(event => {
                                                        return (
                                                            <Col key={event.content.id} xs={12} sm={6} lg={3}>
                                                                <Card onClick={() => navigate(`/cpd/edit/${event.content.id}`)} style={{ cursor: 'pointer',  minHeight: '95%', marginBottom: '0.5rem', borderRadius:'0' }} >
                                                                    <CardHeader style={{ borderRadius: '0', backgroundColor: event.color }} >
                                                                        <Row>
                                                                            <Col>
                                                                                <h5>{event.content.externalName}</h5>
                                                                                <p style={{ marginBottom: '3px' }} >{learningContentTypeDisplayName(event.content.learningEventType as LearningContentType, t)}</p>
                                                                            </Col>
                                                                        </Row>
                                                                    </CardHeader>
                                                                    <CardBody >
                                                                        <CardSubtitle>
                                                                            <Row>
                                                                                <b>{getCardSubtitle(event.content.eventDate, event.content.cPDMinutes) }</b>
                                                                            </Row>
                                                                            <Row>
                                                                                <HtmlDisplay html={truncateText(200, event.content.externalDescriptionHtml)} />
                                                                            </Row>
                                                                        </CardSubtitle>
                                                                    </CardBody>
                                                                </Card>

                                                            </Col>
                                                        );
                                                    })
                                                }
                                            </Row>
                                        </Collapse>
                                    </Row>
                                </MainContainer>
                                <ConditionalFragment showIf={dates.indexOf(date) !== (dates.length - 1)}>
                                    <hr />
                                </ConditionalFragment>
                            </div>
                        );

                        

                    })
                }

                <ConditionalFragment showIf={isLoading && !items?.length}>
                    <LoadingIndicator fullWidth />
                </ConditionalFragment>

                <ConditionalFragment showIf={!isLoading && !dates.length}>
                    <NoResultsFound search={searchText}>
                        {
                            searchText !== "" ?
                                t('notResultsFound.nothingMatchedSearch', 'Sorry there were no matches for "{{ search }}."', { search: searchText })
                                :
                                (
                                    <>
                                        <p>{t('cpdList.noResultsFound', 'No content found.')}</p>
                                        {t('cpdList.noResultsFound.before', 'You can create your own using the "Add CPD" button or add existing content from the')}
                                        <Link to="/grow">
                                            {t('cpdList.noResultsFound.link', 'Grow')}
                                        </Link>
                                            {t('cpdList.noResultsFound.after', ' page.')}
                                    </>
                                )
                                
                                
                        }
                    </NoResultsFound>
                </ConditionalFragment>

                <ConditionalFragment showIf={!isLoading && hasMore()}>
                    <Waypoint key={items?.length ?? 0} onEnter={fetchMore} />
                    <LoadingIndicator fullWidth />
                </ConditionalFragment>
            </>
        </>
    );
}
