import * as React from 'react';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useUpdateRequirementLinkMutation } from './useUpdateRequirementLinkMutation';
import { RequirementLink } from '../models/RequirementLink';
import { useCreateRequirementLinkMutation } from './useCreateRequirementLinkMutation';
import { RequirementLinkCreateInput, RequirementLinkUpdateInput } from '../generated/graphql';

/**
 * Get a callback to save a RequirementLink in the store using either a create or update.
 */
export function useSaveRequirementLinkMutation(options: SaveInStoreOptions<RequirementLink, string> = {}) {
    const [_create, createStatus] = useCreateRequirementLinkMutation();
    const create = React.useCallback((model: Partial<RequirementLink>) => _create(model as RequirementLinkCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateRequirementLinkMutation();
    const update = React.useCallback((id: string, changes: Partial<RequirementLink>) => _update(id, changes as RequirementLinkUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
