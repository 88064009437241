import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useCallback, useMemo } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useAsyncCallback } from "react-use-async-callback";
import { Button, Col, Row } from "reactstrap";
import { useViewOrganisationalRoleSupportingData } from "../../api/main/career/viewModels/useViewCareerOrganisationalRoleSupportingData";
import { useViewCareerOrganisationalRoleViewModel } from "../../api/main/career/viewModels/useViewCareerOrganisationalRoleViewModel";
import { OrganisationalRoleLinkType } from "../../api/main/models/constants/OrganisationalRoleLinkType";
import { RequirementLinkType } from "../../api/main/models/constants/RequirementLinkType";
import { RequirementType } from "../../api/main/models/constants/RequirementType";
import { LearningContent } from "../../api/main/models/LearningContent";
import { OrganisationalRoleLink, } from "../../api/main/models/OrganisationalRoleLink";
import { useDeleteOrganisationalRoleLinkMutation } from "../../api/main/organisationalRoleLinks/useDeleteOrganisationalRoleLinkMutation";
import { useSaveOrganisationalRoleLinkMutation } from "../../api/main/organisationalRoleLinks/useSaveOrganisationalRoleLinkMutation";
import { useCurrentUserProfile } from "../../api/main/profiles/useCurrentUserProfile";
import { useRequirementLinksByTargetId } from "../../api/main/requirementLinks/useUserRequirementLinks";
import { useUserLearningEvents } from "../../api/main/userLearningEvents/useUserLearningEvents";
import { useCurrentUserOrEmulatedSubscription } from "../../globalState/subscriptions/useCurrentUserOrEmulatedSubscription";
import { AlertOnErrors } from "../../shared/alertOnErrors";
import { HtmlDisplay } from "../../shared/htmlEditor";
import { useChanges, } from "../../shared/useChanges";
import { generateTrackedUrl } from "../../utilities/generateTrackedUrl";
import { RequirementTagSelector } from "../administration/requirements/RequirementTagSelector";
import { Banner } from "../shared/banner/Banner";
import { HorizontalScroll } from "../shared/horizontalScroll/HorizontalScroll";
import { LoadingIndicator } from "../shared/loadingIndicator/LoadingIndicator";
import { MainContainer } from "../shared/mainContainer/MainContainer";
import { NoResultsFound } from "../shared/noResultsFound/NoResultsFound";
import { getLearningContentUrlOrBlob } from "../shared/Utilities/getLearningContentUrlOrBlob";
import { VideoTile } from "./VideoTile";

export interface ViewCareerRoleProps {
};

export const ViewCareerRole = () => {
    const { t } = useTranslation();
    const { id } = useParams<{ id: string | undefined; }>();
    const navigate = useNavigate();
    const subscription = useCurrentUserOrEmulatedSubscription();
    // Load the data
    const {
        data: {
            model: storeModel,
            organisationalRoleLinks: storeOrganisationalRoleLinks,
        },
        errors: loadErrors, isLoading: _isLoading, refresh: refreshData
    } = useViewCareerOrganisationalRoleViewModel(id);

    // Load the current user
    const {
        data: {
            model: currentUser,
        }, isLoading: isCurrentUserLoading, errors: currentUserErrors
    } = useCurrentUserProfile();

    // Load the supporting data
    const {
        data: {
            requirements,
            learningContents
        },
        errors: supportingDataLoadErrors, isLoading: isLoadingSupportingData,
    } = useViewOrganisationalRoleSupportingData(subscription?.schoolId, subscription?.trustId);
    

    // Load the requirement links for this role
    const {
        data: {
            items: requirementLinks
        }, isLoading: isRequirementLinksLoading, errors: requirementLinksErrors
    } = useRequirementLinksByTargetId({ pageSize: undefined }, storeModel?.id ?? null);


    // Load the current user's CPD so we can highlight completed items.
    const {
        data: {
            items: myCPD,
        }, isLoading: isLoadingMyCPD, errors: myCPDErrors
    } = useUserLearningEvents({}, currentUser?.userId);

    const isLoading = _isLoading || isLoadingSupportingData || isCurrentUserLoading || isRequirementLinksLoading || isLoadingMyCPD;

    // Model (OrganisationalRoleLinks)
    const { model, change, changes } = useChanges<OrganisationalRoleLink>({ organisationalRoleId: id, targetId: currentUser?.userId, organisationalRoleLinkType: OrganisationalRoleLinkType.User } as OrganisationalRoleLink, undefined);
    const [saveOrganisationalRoleLink, { errors: saveOrganisationalRoleLinkErrors, }] = useSaveOrganisationalRoleLinkMutation();
    const [removeOrganisationalRoleLink, { errors: removeOrganisationalRoleLinkErrors, }] = useDeleteOrganisationalRoleLinkMutation();

    // Is in our current roles
    const currentRole = useMemo(() => {
        return storeOrganisationalRoleLinks?.find(item => item.organisationalRoleLinkType === OrganisationalRoleLinkType.User && item.targetId === currentUser?.userId && item.organisationalRoleId === id);
    }, [currentUser, id, storeOrganisationalRoleLinks]);

    const aspirationalRole = useMemo(() => {
        return storeOrganisationalRoleLinks?.find(item => item.organisationalRoleLinkType === OrganisationalRoleLinkType.AspirationalUserRole && item.targetId === currentUser?.userId && item.organisationalRoleId === id);
    }, [storeOrganisationalRoleLinks, currentUser?.userId, id]);

    // Remove form
    const [onRemoveFormSubmit, { isExecuting: isRemoving, errors: removeFormErrors }] = useAsyncCallback(async () => {
        if (!model) {
            return;
        }

        await removeOrganisationalRoleLink(currentRole?.id ?? '');

        navigate(-1);
    }, [model, navigate]);

    // Growth resources
    const learningOrganisationalRoleLinks = storeOrganisationalRoleLinks?.filter(item => item.organisationalRoleLinkType === OrganisationalRoleLinkType.LearningForRole);

    //Save to current roles
    const [makeCurrentRole, { errors: makeCurrentRoleErrors }] = useAsyncCallback(async() => {
        await saveOrganisationalRoleLink(model.id, { organisationalRoleId: id, targetId: currentUser?.userId, organisationalRoleLinkType: OrganisationalRoleLinkType.User, targetType: 'User', displayOrder: moment().unix() }, true)
        refreshData();
    }, [saveOrganisationalRoleLink, currentUser, id, model, refreshData]);
    //Remove from current roles
    const [removeCurrentRole, { errors: removeCurrentRoleErrors }] = useAsyncCallback(async () => {
        await removeOrganisationalRoleLink(currentRole?.id ?? '');
        refreshData();
    }, [currentRole, removeOrganisationalRoleLink, refreshData]);
    //Add this role as an aspiration
    const [addAspirationalRole, {errors: addAspirationalRoleErrors }] = useAsyncCallback(async () => {
        await saveOrganisationalRoleLink(model.id, { organisationalRoleId: id, targetId: currentUser?.userId, organisationalRoleLinkType: OrganisationalRoleLinkType.AspirationalUserRole, targetType: 'User', displayOrder: moment().unix() }, true)
        refreshData();
    }, [saveOrganisationalRoleLink, currentUser, id, model, refreshData]);
    //Remove this role from aspirations
    const [removeAspirationalRole, { errors: removeAspirationalRoleErrors }] = useAsyncCallback(async () => {
        await removeOrganisationalRoleLink(aspirationalRole?.id ?? '');
        refreshData();
    }, [removeOrganisationalRoleLink, aspirationalRole, refreshData]);

    // Add skills and attributes to a single object and filter out the ones that are not linked to the role
    const role = useMemo(() => {
        const keyQualifications = !!requirementLinks ?
            requirements?.filter(s => s.requirementType === RequirementType.Qualification && !!requirementLinks.find(sl => sl.requirementId === s.id && sl.requirementLinkType === RequirementLinkType.KeyQualification))
            : [];
        const requiredSkills = !!requirementLinks ?
            requirements?.filter(s => s.requirementType === RequirementType.Skill && !!requirementLinks.find(sl => sl.requirementId === s.id && sl.requirementLinkType === RequirementLinkType.RequiredSkill))
            : [];
        const requiredAttributes = !!requirementLinks ?
            requirements?.filter(s => s.requirementType === RequirementType.Attribute && !!requirementLinks.find(sl => sl.requirementId === s.id && sl.requirementLinkType === RequirementLinkType.RequiredAttribute))
            : [];
        const requiredQualifications = !!requirementLinks ?
            requirements?.filter(s => s.requirementType === RequirementType.Qualification && !!requirementLinks.find(sl => sl.requirementId === s.id && sl.requirementLinkType === RequirementLinkType.RequiredQualification))
            : [];
        const requiredExperiences = !!requirementLinks ?
            requirements?.filter(s => s.requirementType === RequirementType.Experience && !!requirementLinks.find(sl => sl.requirementId === s.id && sl.requirementLinkType === RequirementLinkType.RequiredExperience))
            : [];
        const recommendedSkills = !!requirementLinks ?
            requirements?.filter(s => s.requirementType === RequirementType.Skill && !!requirementLinks.find(sl => sl.requirementId === s.id && sl.requirementLinkType === RequirementLinkType.RecommendedSkill))
            : [];
        const recommendedAttributes = !!requirementLinks ?
            requirements?.filter(s => s.requirementType === RequirementType.Attribute && !!requirementLinks.find(sl => sl.requirementId === s.id && sl.requirementLinkType === RequirementLinkType.RecommendedAttribute))
            : [];
        const recommendedQualifications = !!requirementLinks ?
            requirements?.filter(s => s.requirementType === RequirementType.Qualification && !!requirementLinks.find(sl => sl.requirementId === s.id && sl.requirementLinkType === RequirementLinkType.RecommendedQualification))
            : [];
        const recommendedExperiences = !!requirementLinks ?
            requirements?.filter(s => s.requirementType === RequirementType.Experience && !!requirementLinks.find(sl => sl.requirementId === s.id && sl.requirementLinkType === RequirementLinkType.RecommendedExperience))
            : [];

        // Get the growth resources
        const growthResources = learningOrganisationalRoleLinks?.reduce((acc, item) => {
            const current = learningContents?.find(it => it.id === item.targetId);
            const publishDate = moment(current?.publishDate);
            const today = moment();
            const published = publishDate.isSameOrBefore(today);
            if (current && published) {
                acc.push(current);
            }
            return acc;
        }, [] as LearningContent[]);

        // Return the model
        return {
            ...storeModel,
            keyQualifications,
            requiredSkills,
            requiredAttributes,
            requiredQualifications,
            requiredExperiences,
            recommendedSkills,
            recommendedAttributes,
            recommendedQualifications,
            recommendedExperiences,
            growthResources,
        };
    }, [storeModel, requirements, learningOrganisationalRoleLinks, learningContents, requirementLinks]);

    const consumeContent = useCallback((url: string) => {
        // If the URL starts with https:// then we are dealing with an external link
        if (url.startsWith('https://')) {
            // If we don't have a URL to work with do nothing.
            if (!url) { return; }

            // Open in a new window.
            window.open(url);
        } else {
            // We have a local link, so we need to navigate to it.
            navigate(url);
        }
    }, [navigate]);

    // Render the UI
    //
    return (
        <>
            <Banner>
                <Row>
                    <Col xs={12} md="auto">
                        <h1>
                            {t('viewCareerRole.heading.default', 'Organisational role')}
                        </h1>
                        <h3>{role?.name + (currentRole?.organisationalRoleId === id ? ' (Current role)' : '')}</h3>
                    </Col>

                    {/* Forces the buttons to the right side */}
                    <Col></Col>

                    <Col xs="auto">
                        <ConditionalFragment showIf={currentRole?.organisationalRoleId !== id}>
                            <Button color='primary' onClick={() =>makeCurrentRole()}>
                                <FontAwesomeIcon icon="user-check" />
                                <> </>
                                {t('viewCareerRole.makeCurrentRole.button', 'Add to current roles')}
                            </Button>
                        </ConditionalFragment>
                        <ConditionalFragment showIf={currentRole?.organisationalRoleId === id}>
                            <Button color='primary' onClick={() => removeCurrentRole()}>
                                <FontAwesomeIcon icon="user-xmark" />
                                <> </>
                                {t('viewCareerRole.removeCurrentRole.button', 'Remove from current roles')}
                            </Button>
                        </ConditionalFragment>
                        <> </>
                        {
                            aspirationalRole ? (
                                <Button onClick={() => removeAspirationalRole()}>
                                    <FontAwesomeIcon icon="star-half-stroke" />
                                    <> </>
                                    {t('viewCareerRole.assignRole.button', 'Remove aspiration')}
                                </Button>
                            ) : (
                                <Button onClick={() => addAspirationalRole()}>
                                    <FontAwesomeIcon icon="star" />
                                    <> </>
                                    {t('viewCareerRole.assignRole.button', 'Add role as an aspiration')}
                                </Button>
                            )
                        }
                    </Col>

                    <ConditionalFragment showIf={isLoading}>
                        <Col xs="auto">
                            <LoadingIndicator size="sm" />
                        </Col>
                    </ConditionalFragment>
                </Row>
            </Banner>

            <MainContainer>
                <AlertOnErrors errors={[
                    loadErrors, supportingDataLoadErrors,
                    currentUserErrors,
                    saveOrganisationalRoleLinkErrors,
                    removeOrganisationalRoleLinkErrors, removeFormErrors,
                    requirementLinksErrors, makeCurrentRoleErrors, removeCurrentRoleErrors,
                    addAspirationalRoleErrors, removeAspirationalRoleErrors

                ]} />

                <div className="organisational-role">
                    <h5>{t('viewCareerRole.aboutThisRole.heading', 'About this role')}</h5>
                    <Row>
                        <Col>
                            <HtmlDisplay html={role.descriptionHtml ?? ''} />
                        </Col>
                    </Row>

                    {/* Skills, Attributes, and Qualifications */}
                    <Row>
                        <Col>
                            <h5>{t('viewCareerRole.qualifications.key.label', 'Key qualifications')}</h5>
                            <RequirementTagSelector readOnly tags={role.keyQualifications} />
                            <ConditionalFragment showIf={!role.keyQualifications?.length}>
                                <p style={{ fontWeight: "500" }}>{t('viewCarrerRole.qualifications.key.none', 'None')}</p>
                            </ConditionalFragment>
                        </Col>

                        <Col>
                        </Col>

                        <Col>
                        </Col>

                        <Col>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <h5>{t('viewCareerRole.qualifications.required.label', 'Required qualifications')}</h5>
                            <RequirementTagSelector readOnly tags={role.requiredQualifications} />
                            <ConditionalFragment showIf={!role.requiredQualifications?.length}>
                                <p style={{ fontWeight: "500" }}>{t('viewCarrerRole.qualifications.required.none', 'None')}</p>
                            </ConditionalFragment>
                        </Col>

                        <Col>
                            <h5>{t('viewCareerRole.experiences.required.label', 'Required experience')}</h5>
                            <RequirementTagSelector readOnly tags={role.requiredExperiences} />
                            <ConditionalFragment showIf={!role.requiredExperiences?.length}>
                                <p style={{ fontWeight: "500" }}>{t('viewCarrerRole.experiences.required.none', 'None')}</p>
                            </ConditionalFragment>
                        </Col>

                        <Col>
                            <h5>{t('viewCareerRole.skills.required.label', 'Required skills')}</h5>
                            <RequirementTagSelector readOnly tags={role.requiredSkills} />
                            <ConditionalFragment showIf={!role.requiredSkills?.length}>
                                <p style={{ fontWeight: "500" }}>{t('viewCarrerRole.skills.required.none', 'None')}</p>
                            </ConditionalFragment>
                        </Col>

                        <Col>
                            <h5>{t('viewCareerRole.attributes.required.label', 'Required attributes')}</h5>
                            <RequirementTagSelector readOnly tags={role.requiredAttributes} />
                            <ConditionalFragment showIf={!role.requiredAttributes?.length}>
                                <p style={{ fontWeight: "500" }}>{t('viewCarrerRole.attributes.required.none', 'None')}</p>
                            </ConditionalFragment>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <h5>{t('viewCareerRole.qualifications.recommended.label', 'Recommended qualifications')}</h5>
                            <RequirementTagSelector readOnly tags={role.recommendedQualifications} />
                            <ConditionalFragment showIf={!role.recommendedQualifications?.length}>
                                <p style={{ fontWeight: "500" }}>{t('viewCarrerRole.qualifications.recommend.none', 'None')}</p>
                            </ConditionalFragment>
                        </Col>

                        <Col>
                            <h5>{t('viewCareerRole.experiences.recommended.label', 'Recommended experience')}</h5>
                            <RequirementTagSelector readOnly tags={role.recommendedExperiences} />
                            <ConditionalFragment showIf={!role.recommendedExperiences?.length}>
                                <p style={{ fontWeight: "500" }}>{t('viewCarrerRole.experiences.recommend.none', 'None')}</p>
                            </ConditionalFragment>
                        </Col>

                        <Col>
                            <h5>{t('viewCareerRole.skills.recommended.label', 'Recommended skills')}</h5>
                            <RequirementTagSelector readOnly tags={role.recommendedSkills} />
                            <ConditionalFragment showIf={!role.recommendedSkills?.length}>
                                <p style={{ fontWeight: "500" }}>{t('viewCarrerRole.skills.recommend.none', 'None')}</p>
                            </ConditionalFragment>
                        </Col>

                        <Col>
                            <h5>{t('viewCareerRole.attributes.recommended.label', 'Recommended attributes')}</h5>
                            <RequirementTagSelector readOnly tags={role.recommendedAttributes} />
                            <ConditionalFragment showIf={!role.recommendedAttributes?.length}>
                                <p style={{ fontWeight: "500" }}>{t('viewCarrerRole.attributes.recommend.none', 'None')}</p>
                            </ConditionalFragment>
                        </Col>
                    </Row>
                </div>

                {/* Growth Resources */}
                <h5 style={{ margin: '1rem 0' }}>{t('viewCareerRole.growthResources.heading', 'Growth resources')}</h5>
                <ConditionalFragment showIf={!!role.growthResources?.length}>
                    <HorizontalScroll>
                        {
                            role.growthResources?.map((item, index) => {
                                const linkToContent = getLearningContentUrlOrBlob(item);

                                return (
                                    <VideoTile
                                        key={index}
                                        model={item}
                                        thumbnailBlob={item.imageBlobReferenceId}
                                        size="fixed"
                                        consumeContent={() => consumeContent(generateTrackedUrl(linkToContent ?? '', item.id) ?? '')}
                                        showCompleted={myCPD?.some(ule => ule.externalName === item.name)}
                                    />
                                );
                            })
                        }
                    </HorizontalScroll>
                </ConditionalFragment>

                <ConditionalFragment showIf={!role.growthResources?.length}>
                    <NoResultsFound />
                </ConditionalFragment>
                <Button style={{ marginTop: "1rem" }} onClick={() => navigate(-1)}>
                    {t('viewCareerRole.back.button', 'Back')}
                </Button>
            </MainContainer>
        </>
    );
};