import { gql, useLazyQuery } from "@apollo/client";
import React from "react";
import { mainApiConfig } from "../../../configure/mainApiConfig";
import { AsyncLoadPagedOptions, AsyncLoadPagedResult } from "../../../shared/abstractStore";
import { useFetchMoreCallback } from "../../../shared/useFetchMoreCallback";
import { RequirementLinksQuery } from "../generated/graphql";
import { requirementLinkFieldsFragment } from "../models/RequirementLink";



/**
 * Get a list of RequirementLinks from the store that are linked to a user.
 * @param options
 * @param targetId
 * @returns A list of RequirementLinks where the targetId matches the userId provided
 */
export function useRequirementLinksByTargetId(options: AsyncLoadPagedOptions = {}, targetId?: string | null | undefined,): AsyncLoadPagedResult<RequirementLinksQuery> {
    //Query data we need from the api.
const [load, { data, loading, error, refetch, fetchMore: fetchMoreFromStore }] = useLazyQuery<RequirementLinksQuery>(
        gql`
        query useRequirementLinksByTargetIdQuery ($offset: Int, $first: Int, $targetId: ID!){
            items: requirementLinks (offset: $offset, first: $first, targetId: $targetId) {
                ...requirementLinkFields
            }
        }

        ${requirementLinkFieldsFragment}
        `,
        {

            variables: {
                offset: options.startOffset,
                first: options.pageSize,
                targetId: targetId ?? null,
            },
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    //Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load]);

    // Get the functions used to manage paging
    const { fetchMore, hasMore: _hasMore } = useFetchMoreCallback<RequirementLinksQuery>(
            fetchMoreFromStore,
            (options?.startOffset ?? 0) + (data?.items?.length ?? 0),
            (prevResults, newResults) => ({
                ...prevResults,
                ...newResults,

                items: [...(prevResults?.items ?? []), ...(newResults?.items ?? [])]
            }),
            (newResults) => newResults?.items?.length
    );
    const hasMore = React.useCallback(() => !options.pageSize ? false : _hasMore(), [options.pageSize, _hasMore]);

    // Return the results
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return React.useMemo(() => ({
            data: data ?? ({} as any),
            refresh: refresh,
            fetchMore: fetchMore,
            hasMore: hasMore,
            isLoading: loading,
            errors: error
        }), [data, refresh, fetchMore, hasMore, loading, error]);
}