import gql from "graphql-tag";
import { Guid } from "guid-string";
import { ProfileFieldsFragment } from "../generated/graphql";
import { userFieldsFragment } from "./User";

export const profileFieldsFragment = gql`
    fragment profileFields on Profile {
        id
        userId
        firstName
        lastName
        descriptionHtml
        photoBlobReferenceId
        archived
        personalStatementHtml
        strengthsHtml
        gapsHtml
        trustId
        schoolId

        user {
            ...userFields,
        }
    }

    ${userFieldsFragment}
`;


export type Profile = Omit<ProfileFieldsFragment, '__typename' | 'user'>;

/**
 * Default values.
 */
export const profileDefaultValues = (): Partial<Profile> => ({
    id: Guid.newGuid(),
    firstName: '',
    lastName: '',
    descriptionHtml: '',
    photoBlobReferenceId: null,
    archived: false,
    personalStatementHtml: '',
    strengthsHtml: '',
    gapsHtml: '',
    trustId: null,
    schoolId: null,
});