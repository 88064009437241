import { useMemo } from "react";
import { getRoleGroupsForUserLevel, RoleGroup } from "../../../api/main/models/constants/RoleGroup";
import { CreateUser } from "../EditUser";


/**
 * Container for creating a user at the admin level.
 * @returns
 */
export const AdminCreateUserContainer = () =>
{
    const myRoleGroups = useMemo(() => getRoleGroupsForUserLevel("Administrator"), []);
    const defaultRoleGroup = myRoleGroups.length ? myRoleGroups[0] : RoleGroup.SchoolTrustUser;
    return (
        <CreateUser
            filterRoleGroups={groups => groups.filter(group => !!myRoleGroups.find(myGroupName => group.name === myGroupName))}
            defaultRoleGroup={defaultRoleGroup}
        />
    );
};