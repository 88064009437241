import { IdentityRoles } from "../../../configure/security/IdentityRoles";
import { RouteEntry } from "../../../shared/routes";
import { AdminBooksList } from "./containers/BookListContainer";
import { CreateAdminBook, EditAdminBook } from "./containers/EditBookContainers";
import { DeleteBook } from "./DeleteBook";

export const bookRoutes: Array<RouteEntry> = [
    { path: '/administration/books/add', element: <CreateAdminBook/>, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/books/edit/:id', element: <EditAdminBook />, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/books/delete/:id', element: <DeleteBook />, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/books', element: <AdminBooksList />, authorize: true, requireRole: IdentityRoles.Administration },
];