import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { CreateSchoolMutation, CreateSchoolMutationVariables, SchoolCreateInput } from '../generated/graphql';
import { schoolFieldsFragment } from '../models/School';

/**
 * Get a callback to create a School in the store.
 */
export function useCreateSchoolMutation(): [(model: SchoolCreateInput) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<CreateSchoolMutation, CreateSchoolMutationVariables>(
        gql`
            mutation createSchoolMutation ($model: SchoolCreateInput!) {
                createSchool(model: $model) {
                    ...schoolFields
                }
            }

            ${schoolFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (model: SchoolCreateInput) => {
        const result = await mutationAction({ variables: { model: model } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
