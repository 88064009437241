import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { CreateRequirementLinkMutation, CreateRequirementLinkMutationVariables, RequirementLinkCreateInput } from '../generated/graphql';
import { requirementLinkFieldsFragment } from '../models/RequirementLink';

/**
 * Get a callback to create a RequirementLink in the store.
 */
export function useCreateRequirementLinkMutation(): [(model: RequirementLinkCreateInput) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<CreateRequirementLinkMutation, CreateRequirementLinkMutationVariables>(
        gql`
            mutation createRequirementLinkMutation ($model: RequirementLinkCreateInput!) {
                createRequirementLink(model: $model) {
                    ...requirementLinkFields
                }
            }

            ${requirementLinkFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (model: RequirementLinkCreateInput) => {
        const result = await mutationAction({ variables: { model: model } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
