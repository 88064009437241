import * as React from 'react';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useUpdateOrganisationalRoleMutation } from './useUpdateOrganisationalRoleMutation';
import { OrganisationalRole } from '../models/OrganisationalRole';
import { useCreateOrganisationalRoleMutation } from './useCreateOrganisationalRoleMutation';
import { OrganisationalRoleCreateInput, OrganisationalRoleUpdateInput } from '../generated/graphql';

/**
 * Get a callback to save a OrganisationalRole in the store using either a create or update.
 */
export function useSaveOrganisationalRoleMutation(options: SaveInStoreOptions<OrganisationalRole, string> = {}) {
    const [_create, createStatus] = useCreateOrganisationalRoleMutation();
    const create = React.useCallback((model: Partial<OrganisationalRole>) => _create(model as OrganisationalRoleCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateOrganisationalRoleMutation();
    const update = React.useCallback((id: string, changes: Partial<OrganisationalRole>) => _update(id, changes as OrganisationalRoleUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
