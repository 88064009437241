import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { LinkContainer } from "react-router-bootstrap";
import { useNavigate } from "react-router-dom";
import { Button, CardBody, CardTitle } from "reactstrap";
import { OrganisationalRole } from "../../api/main/models/OrganisationalRole";
import { HtmlDisplay } from "../../shared/htmlEditor";
import { truncateText } from "../../utilities/truncateText";
import { CardsOrTable } from "../shared/cardsOrTable/CardsOrTable";
import "./careerJourney.scss";

export interface CareerJourneyProps {
    organisationalRoles: Array<OrganisationalRole> | undefined | null,
    numberOfStraightSegments?: number;
}

/**
 * Career Journey (RoadMap)
 * Takes an array of OrganisationalRoles, and an optional numberOfStraightSegments: default is 10
 */
export const CareerJourney = (props: CareerJourneyProps) => {
    const {
        organisationalRoles,
        numberOfStraightSegments = 10
    } = props;
    const navigate = useNavigate();
    const { t } = useTranslation();

    // Render the UI
    //
    return (
        <>
            <section className="career-journey">
                {organisationalRoles?.map((item, index) => (
                    <article className="career-journey-item" key={index}>
                        {index % 2 === 0 ? (
                            <>
                                {/* Even Rows */}
                                {/* When working with an even row (0,2,4,etc) the Role Name should be the first child of "career-journey-role" */}
                                <div className="career-journey-item-down-right">
                                    <div className="career-journey-item-role" onClick={() => navigate(`view-role/${item.id}`)}>{item.name}</div>
                                </div>

                                {/* If we are not the last item, we want to render the StrightRoad and the Next Turn */}
                                <ConditionalFragment showIf={index !== organisationalRoles.length - 1}>
                                    {Array.from({ length: numberOfStraightSegments }).map((_, segmentIndex) => (
                                        <div className="career-journey-item-straight" key={segmentIndex}></div>
                                    ))}

                                    <div className="career-journey-item-up-left"></div>
                                </ConditionalFragment>

                                {/* If we are the last item, we need to render some white space, so the road doesn't break */}
                                <ConditionalFragment showIf={index === organisationalRoles.length - 1}>
                                    {Array.from({ length: numberOfStraightSegments }).map((_, segmentIndex) => (
                                        <div className="career-journey-item-blank-straight" key={segmentIndex}></div>
                                    ))}

                                    <div className="career-journey-item-blank-corner"></div>
                                </ConditionalFragment>
                            </>
                        ) : (
                            <>
                                {/* Odd Rows */}
                                {/* If we are not the last item, we want to render the StrightRoad and the Next Turn */}
                                <ConditionalFragment showIf={index !== organisationalRoles.length - 1}>
                                    <div className="career-journey-item-up-right"></div>

                                    {Array.from({ length: numberOfStraightSegments }).map((_, segmentIndex) => (
                                        <div className="career-journey-item-straight" key={segmentIndex}></div>
                                    ))}
                                </ConditionalFragment>

                                {/* If we are the last item, we need to render some white space, so the road doesn't break */}
                                <ConditionalFragment showIf={index === organisationalRoles.length - 1}>
                                    <div className="career-journey-item-blank-corner"></div>

                                    {Array.from({ length: numberOfStraightSegments }).map((_, segmentIndex) => (
                                        <div className="career-journey-item-blank-straight" key={segmentIndex}></div>
                                    ))}
                                </ConditionalFragment>

                                {/* When working with an even row (1,3,5,etc) the Role Name should be the last child of "career-journey-role" */}
                                <div className="career-journey-item-down-left">
                                    <div className="career-journey-item-role" onClick={() => navigate(`view-role/${item.id}`)}>{item.name}</div>
                                </div>
                            </>
                        )}
                    </article>
                ))}
            </section>
            <ConditionalFragment showIf={!!organisationalRoles && organisationalRoles.length > 0}>
                <CardsOrTable
                    viewMode={"table"}
                    items={organisationalRoles ?? []}
                    tableHeadings={[
                        null,  // Handles the card specific function for which we don't want a table column. 
                        t('career.name.heading', 'Name'),
                        t('career.description.heading', 'Description'),
                    ]}
                    columns={[
                        // We handle the card layout as a specific column ignored from the table
                        (item, view) => {
                            // This column doesn't show in the table
                            if (view !== 'cards') {
                                return null;
                            }
                            return (
                                <>
                                    <CardTitle tag="h5">{item.name}</CardTitle>
                                    <CardBody>
                                        <HtmlDisplay html={truncateText(200, item.descriptionHtml)} />
                                    </CardBody>
                                </>
                            );
                        },

                        // Name
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return item.name;
                        },

                        // Description
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return <HtmlDisplay html={item.descriptionHtml} />;
                        },
                    ]}

                    buttons={(item) => (
                        <LinkContainer to={`view-role/${item.id}`}>
                            <Button color="primary">
                                <FontAwesomeIcon icon="eye" />
                                <> {t('common.view', 'View')}</>
                            </Button>
                        </LinkContainer>
                    )}
                    onItemClick={item => navigate(`view-role/${item.id}`)}
                />
            </ConditionalFragment>
        </>
    );
};