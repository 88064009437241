import gql from 'graphql-tag';
import { Guid } from 'guid-string';
import { LearningContentFieldsFragment } from '../generated/graphql';

export const learningContentFieldsFragment = gql`
    fragment learningContentFields on LearningContent {
        id
        name
        learningContentType
        descriptionHtml
        publishDate
        imageBlobReferenceId
        archived
        cPDMinutes
        contentHtml
        blobReferenceId
        blobReference2Id
        url
        trustId
        schoolId
        startDate
        endDate
        allDayEvent
        location
        provider
        learningCostType
    }
`;

export type LearningContent = Omit<LearningContentFieldsFragment, '__typename' | 'learningContent'>;

/**
 * Default values.
 */
export const learningContentDefaultValues = (): Partial<LearningContent> => ({
    id: Guid.newGuid(),
    name: '',
    learningContentType: '',
    descriptionHtml: '',
    publishDate: null,
    imageBlobReferenceId: null,
    archived: false,
    cPDMinutes: 0,
    blobReferenceId: null,
    blobReference2Id: null,
    url: '',
    trustId: null,
    schoolId: null,
    contentHtml: '',
    startDate: null,
    endDate: null,
    allDayEvent: null,
    location: '',
    provider: '',
});