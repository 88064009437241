import { IdentityRoles } from "../../../configure/security/IdentityRoles";
import { RouteEntry } from "../../../shared/routes";
import { DeleteTrust } from "./DeleteTrust";
import { CreateTrust, EditTrust } from "./EditTrust";
import { TrustsList } from "./TrustsList";

export const trustRoutes: Array<RouteEntry> = [
    { path: '/administration/trusts/add', element: <CreateTrust />, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/trusts/edit/:id', element: <EditTrust />, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/trusts/delete/:id', element: <DeleteTrust />, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/trusts', element: <TrustsList />, authorize: true, requireRole: IdentityRoles.Administration },
];