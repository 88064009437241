import { useMemo, useState } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { Form, FormGroup, Label, Input, Row, Col } from "reactstrap";
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MainContainer } from "../../shared/mainContainer/MainContainer";
import { useLearningContentsSchoolTrustFilter } from "../../../api/main/learningContents/viewModels/useLearningContentsSchoolTrustFilter";
import { useAnalyticEventExportSupportingData } from "../../../api/main/analyticEvents/viewModels/useAnalyticEventExportSupportingData";
import { Banner } from "../../shared/banner/Banner";
import { LoadingIndicator } from "../../shared/loadingIndicator/LoadingIndicator";

export const ExportAnalyticEvents = () => {
    const { t } = useTranslation();

    const { data: { schools, trusts, learningContents }, isLoading: isloadingSupportingData, errors: supportingDataErrors } = useAnalyticEventExportSupportingData();

    const [schoolId, setSchoolId] = useState<string>();
    const [trustId, setTrustId] = useState<string>();
    const [learningContentId, setLearningContentId] = useState<string>();

    const schoolList = useMemo(() => {

        let ret = (schools ?? []);

        if (!!trustId) {

            ret = ret.filter(item => item.trustId === trustId)

        }

        return ret;

    }, [schools, trustId]);

    const learningContentList = useMemo(() => {

        let ret = (learningContents ?? []);

        if (!!trustId) {

            ret = ret.filter(item => item.trustId === trustId)

        }

        if(!!schoolId) {

            ret = ret.filter(item => item.schoolId === schoolId)

        }

        return ret;

    }, [learningContents, trustId, schoolId]);



    return (
        <>

            <Banner fluid>
                <Row>
                    <Col xs={12} md="auto">
                        <h1>
                            {t('exportAnalyticEvents.title', 'Export analytic events')}
                        </h1>
                    </Col>
                    <ConditionalFragment showIf={isloadingSupportingData}>
                        <Col xs="auto">
                            <LoadingIndicator size="sm" />
                        </Col>
                    </ConditionalFragment>
                </Row>
            </Banner>

            <Form>

                <FormGroup>
                    <Label htmlFor="trust">{t('exportAnalyticEvents.trust', 'Select a specific trust')}</Label>
                    <Input name="trust" type="select" value={trustId} onChange={e => setTrustId(e.currentTarget.value)}>
                        <option value="">{t('exportAnalyticEvents.pleaseSelect.school', '(Please select a trust to export)')}</option>
                        {
                            trusts?.map(item => (
                                <option key={item.name} value={item.id}>
                                    {item.name}
                                </option>
                            ))
                        }
                    </Input>
                </FormGroup>

                <FormGroup>
                    <Label htmlFor="school">{t('exportAnalyticEvents.school', 'Select a specific school')}</Label>
                <Input name="school" type="select" value={schoolId} onChange={e => setSchoolId(e.currentTarget.value)}>
                        <option value="">{t('exportAnalyticEvents.pleaseSelect.school', '(Please select a school to export)')}</option>
                    {
                        schoolList?.map(item => (
                            <option key={item.name} value={item.id}>
                                {item.name}
                            </option>
                        ))
                    }
                    </Input>
                </FormGroup>

                <FormGroup>
                    <Label htmlFor="learningContent">{t('exportAnalyticEvents.learningContent', 'Select a specific learning content')}</Label>
                <Input name="learningContent" type="select" value={learningContentId} onChange={e => setLearningContentId(e.currentTarget.value)}>
                        <option value="">{t('exportAnalyticEvents.pleaseSelect.school', '(Please select a learning content to export)')}</option>
                    {
                        learningContentList?.map(item => (
                            <option key={item.name} value={item.id}>
                                {item.name}
                            </option>
                        ))
                    }
                    </Input>
                </FormGroup>

                <ConditionalFragment showIf={ !!schoolId || !!trustId || !!learningContentId }>
                    <a className=" btn btn-outline-primary" href={`/api/export/AnalyticEventExport?schoolId=${encodeURIComponent(schoolId ?? "")}&trustId=${encodeURIComponent(trustId ?? "")}&learningContentId=${encodeURIComponent(learningContentId ?? "")}&format=${"xlsx"}`} download>
                        <FontAwesomeIcon icon="download" /> <> {t('exportAnalyticEvents.export', 'Export to Excel')} </>
                    </a>
                </ConditionalFragment>
                
            </Form>
        </>    
    );

};