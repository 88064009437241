import * as React from 'react';
import { useLazyQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { AsyncLoadResult, AsyncLoadOptions } from '../../../../shared/abstractStore';
import { mainApiConfig } from '../../../../configure/mainApiConfig';
import { ProfileSupportingDataQuery } from '../../generated/graphql';
import { blobReferenceFieldsFragment } from '../../models/BlobReference';
import { Guid } from 'guid-string';

export interface ProfileSupportingDataOptions extends AsyncLoadOptions {
    schoolId?: string | null | undefined;
    trustId?: string | null | undefined;
}

/**
 * Get a the supporting data needed to interactivly edit an Profile.
 * @param profileId
 * @param options
 */
export function useProfileSupportingData(profileId: string | undefined | null, options: ProfileSupportingDataOptions = {}): AsyncLoadResult<ProfileSupportingDataQuery> {
    // Query the data we need form the api.
    const [load, { data, loading, error, refetch }] = useLazyQuery<ProfileSupportingDataQuery>(
        gql`
        query profileSupportingDataQuery ($profileId: ID!, $schoolId: ID, $trustId: ID) {
            roleGroups {
                id
                name
            }
            profileSchoolOrTrustImage(profileId: $profileId, schoolId: $schoolId, trustId: $trustId) {
                ...blobReferenceFields
            }
            getSchoolOrTrustName(profileId: $profileId, schoolId: $schoolId, trustId: $trustId) {
                name
            }
        }
        ${blobReferenceFieldsFragment}
        `,
        {
            variables: {
                profileId: profileId ?? Guid.empty,
                schoolId: options.schoolId,
                trustId: options.trustId,
            },
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load])

    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error
    }), [data, refresh, loading, error]);
}
