import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMemo, useState } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { LinkContainer } from "react-router-bootstrap";
import { useNavigate } from "react-router-dom";
import { Waypoint } from "react-waypoint";
import { Button, ButtonDropdown, ButtonGroup, CardSubtitle, CardTitle, Col, DropdownItem, DropdownMenu, DropdownToggle, Row } from "reactstrap";
import { useToggleStateArray } from "use-toggle-state";
import { useOrganisationProfiles } from "../../../api/main/organisationProfiles/useOrganisationProfiles";
import { usePreferredListViewMode } from "../../../globalState/preferredListViewMode/usePreferredListViewMode";
import { AlertOnErrors } from "../../../shared/alertOnErrors";
import { HtmlDisplay } from "../../../shared/htmlEditor";
import { useSearchParams } from "../../../shared/useURLSearchParams";
import { Banner } from "../../shared/banner/Banner";
import { CardsOrTable } from "../../shared/cardsOrTable/CardsOrTable";
import { CardsOrTableToggle } from "../../shared/cardsOrTable/CardsOrTableToggle";
import { LoadingIndicator } from "../../shared/loadingIndicator/LoadingIndicator";
import { MainContainer } from "../../shared/mainContainer/MainContainer";
import { NoResultsFound } from "../../shared/noResultsFound/NoResultsFound";
import { SearchInput } from "../../shared/searchInput/SearchInput";
import { StickyToolbar } from "../../shared/stickyToolbar/StickyToolbar";

/**
 * List of OrganisationProfiles
 * @returns
 */
export const OrganisationProfilesList = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { search: searchParam } = useSearchParams();
    const [search, setSearch] = useState<string>(searchParam ?? '');
    const [isMenuOpen, toggleMenuOpen] = useToggleStateArray();
    const [viewMode] = usePreferredListViewMode();
    

    // Load the data
    const {
        data: {
            items: _items,
        }, isLoading, errors: loadErrors, hasMore, fetchMore
    } = useOrganisationProfiles();

    // Filtering 
    const items = useMemo(() => {
        let ret = (_items ?? []);

        // Filter out anything that has a trust or school as these are custom and not for administrators.
        ret = ret.filter(item => !item.trustId && !item.schoolId);


        // Search.
        let lowerSearch = search.toLocaleLowerCase();
        if (lowerSearch) {
            // Just filtering by all string values that arent ids to start with. Most likely wont need them all for search.
            ret = ret.filter(item =>
                item.name.toLocaleLowerCase().indexOf(lowerSearch) >= 0
                || (item?.descriptionHtml).toLocaleLowerCase().indexOf(lowerSearch) >= 0
            );
        }

        return ret;
    }, [_items, search]);

    // Render the UI
    //
    return (
        <>
            <Banner fluid>
                <StickyToolbar>
                    <Row>
                        <Col xs={12} md="auto">
                            <h1>
                                {
                                   t('organisationProfilesList.title', 'Organisation profiles')
                                }
                            </h1>
                        </Col>
                        <ConditionalFragment showIf={isLoading}>
                            <Col xs="auto">
                                <LoadingIndicator size="sm" />
                            </Col>
                        </ConditionalFragment>
                    </Row>
                    <Row>
                        <Col>
                            <SearchInput value={search} onChange={e => setSearch(e.currentTarget.value)} />
                        </Col>
                        <Col xs="auto">
                            <Row>
                                <ButtonGroup>
                                    <LinkContainer to={'add'}>
                                        <Button color="primary">
                                            <FontAwesomeIcon icon="plus" /><> {t('organisationProfilesList.add', 'Add')}</>
                                        </Button>
                                    </LinkContainer>
                                </ButtonGroup>
                            </Row>
                        </Col>
                        <Col xs={12} md="auto">
                            <CardsOrTableToggle />
                        </Col>
                    </Row>
                </StickyToolbar>
            </Banner>

            <MainContainer fluid>
                <AlertOnErrors
                    errors={[
                        loadErrors,
                    ]}
                />

                <CardsOrTable
                    viewMode={viewMode}
                    items={items}
                    tableHeadings={[
                        null, /* Handles the card specific function for which we don't want a table column. */
                        t('organisationProfilesList.name.heading', 'Name'),
                        t('organisationProfilesList.description.heading', 'Description'),
                    ]}
                    columns={[
                        // We handle the card layout as a specific column ignored from the table.
                        (item, view) => {
                            // This column doesn't show in the table.
                            if (view !== 'cards') {
                                return null;
                            }

                            return (
                                <>
                                    <CardTitle tag="h5">{item.name}</CardTitle>
                                    <CardSubtitle>
                                        <Row>
                                            <HtmlDisplay html={item.descriptionHtml} />
                                        </Row>
                                    </CardSubtitle>
                                </>
                            );
                        },
                        // The remaining columns are for table mode only.

                        // Name
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return item.name;
                        },

                        // Description
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return <HtmlDisplay html={item.descriptionHtml} />;
                        },
                    ]}
                    buttons={(item) => (
                        <>
                            <ButtonGroup>
                                <LinkContainer to={`edit/${item.id}`}>
                                    <Button color="primary" outline>
                                        <FontAwesomeIcon icon="edit" />
                                        <> {t('common.edit', 'Edit')}</>
                                    </Button>
                                </LinkContainer>
                                <ButtonDropdown isOpen={isMenuOpen(item.id)} toggle={() => toggleMenuOpen(item.id)}>
                                    <DropdownToggle color="primary" outline caret>
                                        <span className="visually-hidden">{t('common.menuDropdown', 'More')}</span>
                                    </DropdownToggle>
                                    <DropdownMenu end>
                                        <LinkContainer to={`delete/${item.id}`}>
                                            <DropdownItem className="text-danger">
                                                <FontAwesomeIcon icon="trash" />
                                                <> {t('common.delete', 'Delete')}</>
                                            </DropdownItem>
                                        </LinkContainer>
                                    </DropdownMenu>
                                </ButtonDropdown>
                            </ButtonGroup>
                        </>
                    )}
                    onItemClick={item => navigate(`edit/${item.id}`)}
                />

                <ConditionalFragment showIf={isLoading && !items?.length}>
                    <LoadingIndicator fullWidth />
                </ConditionalFragment>

                <ConditionalFragment showIf={!isLoading && !items?.length}>
                    <NoResultsFound search={search} />
                </ConditionalFragment>

                <ConditionalFragment showIf={!isLoading && hasMore()}>
                    <Waypoint key={items?.length ?? 0} onEnter={fetchMore} />
                    <LoadingIndicator fullWidth />
                </ConditionalFragment>
            </MainContainer>
        </>
    );
};