import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useMemo, useState } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { LinkContainer } from "react-router-bootstrap";
import { useNavigate } from "react-router-dom";
import { Waypoint } from "react-waypoint";
import { Badge, Button, ButtonDropdown, ButtonGroup, CardImg, CardSubtitle, CardTitle, Col, DropdownItem, DropdownMenu, DropdownToggle, Row } from "reactstrap";
import { useToggleStateArray } from "use-toggle-state";
import { usePreferredListViewMode } from "../../../globalState/preferredListViewMode/usePreferredListViewMode";
import { AlertOnErrors } from "../../../shared/alertOnErrors";
import { HtmlDisplay } from "../../../shared/htmlEditor";
import { useSearchParams } from "../../../shared/useURLSearchParams";
import { truncateText } from "../../../utilities/truncateText";
import { Banner } from "../../shared/banner/Banner";
import { CardsOrTable } from "../../shared/cardsOrTable/CardsOrTable";
import { CardsOrTableToggle } from "../../shared/cardsOrTable/CardsOrTableToggle";
import { LoadingIndicator } from "../../shared/loadingIndicator/LoadingIndicator";
import { MainContainer } from "../../shared/mainContainer/MainContainer";
import { NoResultsFound } from "../../shared/noResultsFound/NoResultsFound";
import { SearchInput } from "../../shared/searchInput/SearchInput";
import { useCurrentUserOrEmulatedSubscription } from "../../../globalState/subscriptions/useCurrentUserOrEmulatedSubscription";
import { useSpecificLearningContents } from "../../../api/main/learningContents/viewModels/useSpecificLearningContents";
import { getLearningContentTypes, LearningContentType, learningContentTypeDisplayName } from "../../../api/main/models/constants/LearningContentType";
import { StickyToolbar } from "../../shared/stickyToolbar/StickyToolbar";
import { Guid } from "guid-string";

export interface BooksListProps {

    isAdmin?: boolean,
    isTrust?: boolean,

}

/**
 * List of all Books
 */
export const BooksList = (props: BooksListProps) => {
    const {
        isAdmin = false,
        isTrust = false,
    } = props;

    const { t } = useTranslation();
    const navigate = useNavigate();

    const { search: searchParam } = useSearchParams();
    const [search, setSearch] = useState<string>(searchParam ?? '');
    const [isMenuOpen, toggleMenuOpen] = useToggleStateArray();
    const [viewMode] = usePreferredListViewMode();

    //Load data
    const {
        data: {
            items: _allItems,
        }, isLoading, errors: loadErrors, fetchMore, hasMore
    } = useSpecificLearningContents(null, getLearningContentTypes("Book"), { pageSize: undefined });
    // Combine the data so it is all on one model
    const allItems = useMemo(() => _allItems?.map(item => {
        const image = !Guid.isEmpty(item.imageBlobReferenceId) ? { id: item.imageBlobReferenceId, url: `/api/blobs/redirectToUrl/${item.imageBlobReferenceId}`, filename: item.imageBlobReferenceId ?? '' } : undefined;

        return {
            ...item,
            image: image ?? undefined,
        };
    }), [_allItems]);

    const subscription = useCurrentUserOrEmulatedSubscription(); // Grabs current/emulated subscription

    // Filtering 
    const items = useMemo(() => {
        let ret = (allItems ?? []);
        
        let lowerSearch = search.toLocaleLowerCase();
        if (lowerSearch) {
            // Just filtering by all string values that arent ids to start with. Most likely wont need them all for search.
            ret = ret.filter(item =>
                item.name.toLocaleLowerCase().indexOf(lowerSearch) >= 0
                || (item?.descriptionHtml).toLocaleLowerCase().indexOf(lowerSearch) >= 0
                || (t('common.date', '{{date, DD/MM/YYYY}}', { date: moment(item.publishDate) }) ?? '').toLocaleLowerCase().indexOf(lowerSearch) >= 0
                || item.cPDMinutes.toString().toLocaleLowerCase().indexOf(lowerSearch) >= 0
            );
        }

        if (isAdmin) {
            // If we are on the administration version, we want to filter out content from trusts and schools
            ret = ret.filter(item => item.trustId === null && item.schoolId === null);
        }

        if (isTrust && !isAdmin) {
            // If we are viewing from inside a trust we want to only show content from that trust.
            ret = ret.filter(item => item.trustId === subscription?.trustId);
        }

        if (!isTrust && !isAdmin) {
            // If we are viewing from inside a school we want to only show content from that school.
            ret = ret.filter(item => item.schoolId === subscription?.schoolId);
        }

        return ret;
    }, [allItems, search, t, isAdmin, isTrust, subscription]);

    // Render the UI
    //
    return (
        <>
            <Banner fluid>
                <StickyToolbar>
                    <Row>
                        <Col xs={12} md="auto">
                            <h1>
                                {t('booksList.title', 'Books')}
                            </h1>
                        </Col>
                        <ConditionalFragment showIf={isLoading}>
                            <Col xs="auto">
                                <LoadingIndicator size="sm" />
                            </Col>
                        </ConditionalFragment>
                    </Row>
                    <Row>
                        <Col>
                            <SearchInput value={search} onChange={e => setSearch(e.currentTarget.value)} />
                        </Col>
                        <Col xs="auto">
                            <Row>
                                <ButtonGroup>
                                    <LinkContainer to={'add'}>
                                        <Button color="primary">
                                            <FontAwesomeIcon icon="plus" /><> {t('booksList.add', 'Add')}</>
                                        </Button>
                                    </LinkContainer>
                                </ButtonGroup>
                            </Row>
                        </Col>
                        <Col xs={12} md="auto">
                            <CardsOrTableToggle />
                        </Col>
                    </Row>
                </StickyToolbar>
            </Banner>

            <MainContainer fluid>
                <AlertOnErrors
                    errors={[
                        loadErrors
                    ]}
                />

                <CardsOrTable
                    viewMode={viewMode}
                    items={items}
                    tableHeadings={[
                        null, /* Handles the card specific function for which we don't want a table column. */
                        t('booksList.name.heading', 'Name'),
                        t('booksList.learningContentType.heading', 'Growth resource type'),
                        t('booksList.description.heading', 'Description'),
                        t('booksList.publishDate.heading', 'Publish date'),
                        t('booksList.cpdMinutes.heading', 'CPD mins')
                    ]}
                    columns={[
                        // We handle the card layout as a specific column ignored from the table.
                        (item, view) => {
                            // This column doesn't show in the table.
                            if (view !== 'cards') {
                                return null;
                            }

                            return (
                                <>
                                    <CardTitle tag="h5">{item.name}</CardTitle>
                                    <CardSubtitle>
                                        <Row>
                                            <Col>
                                                <Badge color="secondary">
                                                    {t('common.publishedDate', '{{text}} {{date, DD/MM/YYYY}}', { text: moment(item.publishDate).isBefore(moment.now()) ? 'Published ' : 'Will publish ', date: moment(item.publishDate) })}
                                                </Badge>
                                            </Col>
                                        </Row>

                                        <ConditionalFragment showIf={item.descriptionHtml !== ''}>
                                            <Row style={{ margin: '20px 0' }}>
                                                <HtmlDisplay html={truncateText(200, item.descriptionHtml)} />
                                            </Row>                                            
                                        </ConditionalFragment>

                                        <Row>
                                            <Col>
                                                <CardImg src={item.image?.url} style={{ maxHeight: "100px", width: "auto", maxWidth: "100%", }} alt={item.image?.filename}></CardImg>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col>
                                                {t('common.dateTime', moment(item.publishDate).isBefore(moment.now()) ? 'Published {{dateTime, DD/MM/YYYY}}' : 'Will publish {{dateTime, DD/MM/YYYY}}', { dateTime: moment(item.publishDate) })}
                                            </Col>
                                        </Row>
                                    </CardSubtitle>
                                </>
                            );
                        },
                        // The remaining columns are for table mode only.

                        // Name
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return item.name;
                        },
                        
                        // Learning Content Type
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return <Badge color="primary">{learningContentTypeDisplayName(item.learningContentType as LearningContentType, t)}</Badge>;
                        },

                        // Description
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return <HtmlDisplay html={item.descriptionHtml} />;
                        },

                        // Publish date
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return t('common.date', '{{date, DD/MM/YYYY}}', { date: moment(item.publishDate) });
                        },

                        // CPD Minutes
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return item.cPDMinutes;
                        },
                    ]}
                    buttons={(item) => (
                        <ButtonGroup>
                            <LinkContainer to={`edit/${item.id}`}>
                                <Button color="primary" outline>
                                    <FontAwesomeIcon icon="edit" />
                                    <> {t('common.edit', 'Edit')}</>
                                </Button>
                            </LinkContainer>
                            <ButtonDropdown isOpen={isMenuOpen(item.id)} toggle={() => toggleMenuOpen(item.id)}>
                                <DropdownToggle color="primary" outline caret>
                                    <span className="visually-hidden">{t('common.menuDropdown', 'More')}</span>
                                </DropdownToggle>
                                <DropdownMenu end>
                                    <LinkContainer to={`delete/${item.id}`}>
                                        <DropdownItem className="text-danger">
                                            <FontAwesomeIcon icon="trash" />
                                            <> {t('common.delete', 'Delete')}</>
                                        </DropdownItem>
                                    </LinkContainer>
                                </DropdownMenu>
                            </ButtonDropdown>
                        </ButtonGroup>
                    )}
                    onItemClick={item => navigate(`edit/${item.id}`)}
                />

                <ConditionalFragment showIf={isLoading && !items?.length}>
                    <LoadingIndicator fullWidth />
                </ConditionalFragment>

                <ConditionalFragment showIf={!isLoading && !items?.length}>
                    <NoResultsFound search={search} />
                </ConditionalFragment>

                <ConditionalFragment showIf={!isLoading && hasMore()}>
                    <Waypoint key={items?.length ?? 0} onEnter={fetchMore} />
                    <LoadingIndicator fullWidth />
                </ConditionalFragment>
            </MainContainer>
        </>
    );
};