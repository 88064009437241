import gql from 'graphql-tag';
import { Guid } from 'guid-string';
import { RequirementFieldsFragment } from '../generated/graphql';

export const requirementFieldsFragment = gql`
    fragment requirementFields on Requirement {
        id
        name
        requirementType
        descriptionHtml
        archived
    }
`;

export type Requirement = Omit<RequirementFieldsFragment, '__typename' | 'requirement'>;

/**
 * Default values.
 */
export const requirementDefaultValues = (): Partial<Requirement> => ({
    id: Guid.newGuid(),
    name: '',
    requirementType: '',
    descriptionHtml: '',
    archived: false,
});
