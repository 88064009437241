import { IdentityRoles } from "../../../configure/security/IdentityRoles";
import { RouteEntry } from "../../../shared/routes";
import { DeleteSchool } from "./DeleteSchool";
import { CreateSchool, EditSchool } from "./EditSchool";
import { SchoolsList } from "./SchoolsList";

export const schoolRoutes: Array<RouteEntry> = [
    { path: '/administration/schools/add', element: <CreateSchool isAdmin={true} />, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/schools/edit/:id', element: <EditSchool isAdmin={true} />, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/schools/delete/:id', element: <DeleteSchool />, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/schools', element: <SchoolsList isAdmin={true} />, authorize: true, requireRole: IdentityRoles.Administration },
];