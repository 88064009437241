import { useMemo } from "react";
import { getRoleGroupsForUserLevel, RoleGroup } from "../../../api/main/models/constants/RoleGroup";
import { EditUser } from "../EditUser";


/**
 * Container for editing a user at the admin level.
 * @returns
 */
export const AdminEditUserContainer = () => {

    const myRoleGroups = useMemo(() => getRoleGroupsForUserLevel("Administrator"), []);
    const defaultRoleGroup = myRoleGroups.length ? myRoleGroups[0] : RoleGroup.SchoolTrustUser;

    return (
        <EditUser isSchool={true}
            filterRoleGroups={groups => groups.filter(group => !!myRoleGroups.find(myGroupName => group.name === myGroupName))} //filter role groups so that schools can't give themselves trust/admin perms
            defaultRoleGroup={defaultRoleGroup}
        />
    )
};