import gql from 'graphql-tag';
import { Guid } from 'guid-string';
import { OrganisationalRoleGroupFieldsFragment } from '../generated/graphql';


export const organisationalRoleGroupFieldsFragment = gql`
    fragment organisationalRoleGroupFields on OrganisationalRoleGroup {
        id
        name
        archived
    }
`;

export type OrganisationalRoleGroup = Omit<OrganisationalRoleGroupFieldsFragment, '__typename' | 'organisationalRoleLink'>;

/**
 * Default values.
 */
export const organisationalRoleGroupDefaultValues = (): Partial<OrganisationalRoleGroup> => ({
    id: Guid.newGuid(),
    name: '',
    archived: false,
});