import { TFunction } from "i18next";

/**
 * LearningContentTypes
 */
export enum LearningContentType {
    Course = "Course",

    // Books
    BookFile = "BookFile",
    BookLink = "BookLink",

    // Events
    Event = "Event",

    // Podcasts
    PodcastFileAudio = "PodcastFileAudio",
    PodcastFileVideo = "PodcastFileVideo",
    PodcastLink = 'PodcastLink',
    PodcastLinkYouTube = "PodcastLinkYouTube",

    // Standards
    StandardFile = "StandardFile",
    StandardLink = "StandardLink",

    // Videos
    VideoFile = "VideoFile",
    VideoLink = "VideoLink",
    VideoLinkYouTube = "VideoLinkYouTube"
}

/**
 * Returns all LearningContentType options, suitable for use in a list.
 */
export function getLearningContentTypes(prefix: string = ''): Array<LearningContentType> {
    const ret = Object.keys(LearningContentType)
        .filter(item => item.startsWith(prefix))
        .map(key => key as LearningContentType);
    return ret;
}

/**
 * Return all LearningContentTypes
 * @param t
 */
export function learningContentTypeDisplayName(type: LearningContentType, t: TFunction): string {
    switch (type) {
        case LearningContentType.Course: return t('learningContentType.Course', 'Course');

        // Books
        case LearningContentType.BookFile: return t('learningContentType.BookFile', 'Book');
        case LearningContentType.BookLink: return t('learningContentType.BookLink', 'Book store link');

        // Events
        case LearningContentType.Event: return t('learningContentType.Event', 'Event');

        // Podcasts
        case LearningContentType.PodcastFileAudio: return t('learningContentType.PodcastFileAudio', 'Podcast audio');
        case LearningContentType.PodcastFileVideo: return t('learningContentType.PodcastFileVideo', 'Podcast video');
        case LearningContentType.PodcastLink: return t('learningContentType.PodcastLink', 'Podcast link');
        case LearningContentType.PodcastLinkYouTube: return t('learningContentType.PodcastLinkYouTube', 'Podcast YouTube link');

        // Standards
        case LearningContentType.StandardFile: return t('learningContentType.StandardFile', 'Standard file');
        case LearningContentType.StandardLink: return t('learningContentType.StandardLink', 'Standard link');

        // Videos
        case LearningContentType.VideoFile: return t('learningContentType.VideoFile', 'Video');
        case LearningContentType.VideoLink: return t('learningContentType.VideoLink', 'Video link');
        case LearningContentType.VideoLinkYouTube: return t('learningContentType.VideoLinkYouTube', 'Video YouTube link');
    }
}