import gql from 'graphql-tag';
import { Guid } from 'guid-string';
import { OrganisationalRoleLinkFieldsFragment } from '../generated/graphql';

export const organisationalRoleLinkFieldsFragment = gql`
    fragment organisationalRoleLinkFields on OrganisationalRoleLink {
        id
        organisationalRoleId
        targetId
        targetType
        organisationalRoleLinkType
        displayOrder
        archived
    }
`;

export type OrganisationalRoleLink = Omit<OrganisationalRoleLinkFieldsFragment, '__typename' | 'organisationalRoleLink'>;

/**
 * Default values.
 */
export const organisationalRoleLinkDefaultValues = (): Partial<OrganisationalRoleLink> => ({
    id: Guid.newGuid(),
    organisationalRoleId: undefined,
    targetId: undefined,
    targetType: '',
    organisationalRoleLinkType: '',
    displayOrder: 99,
    archived: false,
});