import * as React from 'react';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useUpdatePersonnelFlagUserMutation } from './useUpdatePersonnelFlagUserMutation';
import { PersonnelFlagUser } from '../models/PersonnelFlagUser';
import { useCreatePersonnelFlagUserMutation } from './useCreatePersonnelFlagUserMutation';
import { PersonnelFlagUserCreateInput, PersonnelFlagUserUpdateInput } from '../generated/graphql';

/**
 * Get a callback to save a PersonnelFlagUser in the store using either a create or update.
 */
export function useSavePersonnelFlagUserMutation(options: SaveInStoreOptions<PersonnelFlagUser, string> = {}) {
    const [_create, createStatus] = useCreatePersonnelFlagUserMutation();
    const create = React.useCallback((model: Partial<PersonnelFlagUser>) => _create(model as PersonnelFlagUserCreateInput), [_create]);

    const [_update, updateStatus] = useUpdatePersonnelFlagUserMutation();
    const update = React.useCallback((id: string, changes: Partial<PersonnelFlagUser>) => _update(id, changes as PersonnelFlagUserUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
