import { IdentityRoles } from "../../../configure/security/IdentityRoles";
import { RouteEntry } from "../../../shared/routes";
import { DeleteOrganisationProfile } from "./DeleteOrganisationProfile";
import { CreateOrganisationProfile, EditOrganisationProfile } from "./EditOrganisationProfile";
import { OrganisationProfilesList } from "./OrganisationProfilesList";

export const organisationProfileRoutes: Array<RouteEntry> = [
    { path: '/administration/organisation-profiles/add', element: <CreateOrganisationProfile />, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/organisation-profiles/edit/:id', element: <EditOrganisationProfile />, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/organisation-profiles/delete/:id', element: <DeleteOrganisationProfile />, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/organisation-profiles', element: <OrganisationProfilesList />, authorize: true, requireRole: IdentityRoles.Administration },
];