import { TFunction } from "i18next";

/**
 * LearningCostTypes
 */
export enum LearningCostType {
    Free = 'Free',
    Discount = 'Discount',
    Paid = 'Paid',
}

/**
 * Returns all LearningCostType options, suitable for use in a list.
 */
export function getLearningCostTypes(): Array<LearningCostType> {
    const ret = Object.keys(LearningCostType)
        .map(key => key as LearningCostType);
    return ret;
}

/**
 * Return all LearningCostTypes
 * @param t
 */
export function learningCostTypeDisplayName(type: LearningCostType, t: TFunction): string {
    switch (type) {
        case LearningCostType.Free: return t('learningCostType.Free', 'Free');
        case LearningCostType.Discount: return t('learningCostType.Discount', 'Discount');
        case LearningCostType.Paid: return t('learningCostType.Paid', 'Paid');
    }
}