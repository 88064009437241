

export function generateYoutubeEmbedLink(link: string | any) {
    if (!link) {
        return '';
    }
    var videoId = '';
    link = link.replace(/(>|<)/gi, '').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    if (link[2] !== undefined) {
        // eslint-disable-next-line no-useless-escape
        videoId = link[2].split(/[^0-9a-z_\-]/i);
        videoId = videoId[0];
    }
    else {
        videoId = '';
    }
    var embedLink = "https://www.youtube-nocookie.com/embed/" + videoId;
    return embedLink;
}