import { Library, library } from "@fortawesome/fontawesome-svg-core";
import { faClock as farClock, faFrown as farFrown, faStar as farStar } from "@fortawesome/free-regular-svg-icons";
import { faLink, faMasksTheater, faFile, faTags, faCheck, faNewspaper, faAngleLeft, faAngleRight, faBars, faCaretLeft, faCaretRight, faCogs, faEdit, faEnvelope, faHome, faLock, faPlus, faSave, faSearch, faSpinner, faThLarge, faThList, faTrash, faUser, faUsers, faFolderOpen, faBriefcase, faFolderTree, faKeyboard, faBus, faRoute, faPeopleRoof, faFileLines, faPerson, faBusSimple, faCircleInfo, faUserTie, faBarsProgress, faPencil, faCubes, faMagnifyingGlass, faVideo, faArrowsSpin, faAnglesUp, faCheckDouble, faLockOpen, faUpload, faDownload, faTimesCircle, faCaretDown, faEye, faCaretUp, faPhotoVideo, faChevronRight, faClipboardUser, faDesktop, faUserGraduate, faIdCard, faBook, faMicrophoneAlt, faCalendarAlt, faChalkboardTeacher, faUserTag, faFileAlt, faSchool, faBuildingColumns, faClipboardList, faScaleUnbalanced, faListCheck, faFlag, faAtom, faArchive, faArrowUp, faArrowDown, faTimes, faTrashAlt, faStop, faPersonHalfDress, faPlay, faCertificate, faThumbTack, faSmile as farSmile, faUserCheck, faCar, faCarSide, faStar, faUserXmark, faCarBurst, faStarHalf, faStarHalfStroke, faImage, faLayerGroup, faGraduationCap, faSeedling, faRoad, faCloudDownloadAlt, faBusinessTime } from "@fortawesome/free-solid-svg-icons";


// Import the fontawsome icons we actually want to use.
export const initializeIcons = () => {
    configureIcons(library);
};

export const configureIcons = (library: Library) => {
    library.add(
        // solid
        faMasksTheater,
        faCheck,
        faFile,
        faLink,
        faNewspaper,
        faTags,
        faSpinner,
        faBars,
        faBusinessTime,
        faCaretLeft,
        faCaretRight,
        faCaretDown,
        faCaretUp,
        faHome,
        faCogs,
        faUser,
        faUserTie,
        faUsers,
        faAngleLeft,
        faAngleRight,
        faSearch,
        faPlus,
        faThList,
        faThLarge,
        faLock,
        faEdit,
        faTrash,
        faSave,
        faEnvelope,
        faFolderOpen,
        faBriefcase,
        faFolderTree,
        faKeyboard,
        faBus,
        faRoute,
        faPeopleRoof,
        faFileLines, 
        faPerson,
        faBusSimple,
        faCircleInfo,
        faBarsProgress,
        faPencil,
        faCubes,
        faMagnifyingGlass,
        faVideo,
        faArrowsSpin,
        faAnglesUp,
        faEye,
        faCheckDouble,
        faLockOpen,
        faUpload,
        faDownload,
        faTimesCircle,
        faPhotoVideo,
        faChevronRight,
        faClipboardUser,
        faDesktop,
        faIdCard,
        faBook,
        faCalendarAlt,
        faChalkboardTeacher,
        faUserTag,
        faFlag,
        faFileAlt,
        faSchool,
        faBuildingColumns,
        faClipboardList,
        faPeopleRoof,
        faMicrophoneAlt,
        faUserGraduate,
        faScaleUnbalanced,
        faListCheck,
        faAtom,
        faArrowUp,
        faArrowDown,
        faArchive,
        faTimes,
        faTrashAlt,
        faStop,
        faPersonHalfDress,
        faPlay,
        faCertificate,
        faThumbTack,
        faUserCheck,
        faUserXmark,
        faCarSide,
        faCarBurst,
        faStar,
        faStarHalf,
        faStarHalfStroke,
        faImage,
        faLayerGroup,
        faGraduationCap,
        faSeedling,
        faRoad,
        faSchool,
        faBuildingColumns,
        faCloudDownloadAlt,
        // regular
        farFrown,
        farClock,
        farSmile,
        farStar
    );
};
