import React from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { Col, Row } from "reactstrap";
import { isUndefined } from "util";
import { useOrganisationalRoleLinks } from "../../api/main/organisationalRoleLinks/useOrganisationalRoleLinks";
import { useEditOrganisationProfileViewModel } from "../../api/main/organisationProfiles/viewModels/useEditOrganisationProfileViewModel";
import { usePersonnelFlags } from "../../api/main/personnelFlags/usePersonnelFlags";
import { useUsersListViewModel } from "../../api/main/profiles/viewModels/useUsersListViewModel";
import { useRequirementLinks } from "../../api/main/requirementLinks/useRequirementLinks";
import { useRequirements } from "../../api/main/requirements/useRequirements";
import { useSchool } from "../../api/main/schools/useSchool";
import { useTrust } from "../../api/main/trusts/useTrust";
import { useCurrentUserOrEmulatedSubscription } from "../../globalState/subscriptions/useCurrentUserOrEmulatedSubscription";
import { AlertOnErrors } from "../../shared/alertOnErrors";
import { RenderIfVisible } from "../../shared/useIsElementVisible";
import { Banner } from "../shared/banner/Banner";
import { LoadingIndicator } from "../shared/loadingIndicator/LoadingIndicator";
import { MainContainer } from "../shared/mainContainer/MainContainer";
import { NoResultsFound } from "../shared/noResultsFound/NoResultsFound";
import { SearchInput } from "../shared/searchInput/SearchInput";
import { RoleMapPart } from "./roleMapPart";

export interface RoleMapProps
{
    isSchool?: boolean;
    isTrust?: boolean;
}

export const RoleMap = (props: RoleMapProps) =>
{
    const { t } = useTranslation();
    const isSchool = props.isSchool ?? false;
    const isTrust = props.isTrust ?? false;
    const subscription = useCurrentUserOrEmulatedSubscription();


    //Get the school or trust from the id, so we can get it's organisation profile.
    // We could just load all the profiles and filter with the schoolOrTrustId,
    // but we want to be sure that we have their current profile and not one that they made and later replaced without archiving.
    const {
        data: {
            model: school,
        }, isLoading: schoolLoading, errors: schoolErrors
    } = useSchool(subscription?.schoolId);
    const {
        data: {
            model: trust,
        }, isLoading: trustLoading, errors: trustErrors
    } = useTrust(isTrust ? subscription?.trustId : null);

    // Use the view model from the organisation profile editor to get the organisational roles and links.
    const {
        data: {
            model: organisationProfile,
            organisationalRoleLinks: storeOrganisationalRoleLinks,
            organisationalRoles: storeOrganisationalRoles
        }, isLoading, errors: loadErrors, refresh
    } = useEditOrganisationProfileViewModel(isSchool ? school?.organisationProfileId : trust?.organisationProfileId);
        
    // Get all the users, we'll filter them to only show the ones from the current school/trust afterwards.
    const {
        data: {
            items: allUsers,
        }, isLoading: allUsersLoading, errors: allUsersErrors
    } = useUsersListViewModel({ trustId: isTrust ? subscription?.trustId : null, schoolId: isSchool ? subscription?.schoolId : null, });

    // Load all the role links for users in the trust, we need these to work out current roles etc.
    const {
        data: {
            items: allUserOrganisationRoleLinks,
        },
        errors: allUserOrganisationRoleLinksErrors
    } = useOrganisationalRoleLinks({ trustId: trust?.id, schoolId: school?.id });
    
    const users = React.useMemo(() => {
        let ret = (allUsers ?? []);

        let trustId = trust?.id;
        let schoolId = school?.id;

        //Filter out archived users
        ret = ret.filter(it => !it.archived);

        //Filter by the trust
        if (!!trustId && isTrust) {
            ret = ret.filter(it => it.trustId === trustId);
        }

        //Filter by the school
        if (!!schoolId && isSchool) {
            ret = ret.filter(it => it.schoolId === schoolId);
        }
        return ret;
    }, [allUsers, isSchool, isTrust, school, trust]);

    // Load all personnel flags, so our children don't need to query for it individually
    const {
        data: {
            items: allPersonnelFlags,
        }, isLoading: allPersonnelFlagsLoading, errors: allPersonnelFlagsErrors
    } = usePersonnelFlags();

    // Load all requirements and requirement links, so our children don't need to query for them individually
    const {
        data: {
            items: allRequirements,
        }, isLoading: allRequirementsLoading, errors: allRequirementsErrors
    } = useRequirements();
    const {
        data: {
            items: allRequirementLinks,
        }, isLoading: allRequirementLinksLoading, errors: allRequirementLinksErrors
    } = useRequirementLinks();

    const loading = schoolLoading || trustLoading || isLoading || allUsersLoading || allPersonnelFlagsLoading || allRequirementsLoading || allRequirementLinksLoading;

    // Search state, we apply the filter within the loop to avoid changing logic of the existing component any further.
    const [search, setSearch] = React.useState<string>('');
    
    return (
        <div style={{ maxWidth: '87vw' }}>
            <Banner fluid>
                <AlertOnErrors errors={[loadErrors, schoolErrors, trustErrors, allUsersErrors, allPersonnelFlagsErrors, allRequirementsErrors, allRequirementLinksErrors, allUserOrganisationRoleLinksErrors]} />
                <Row>
                    <Col xs={12} md="auto">
                        <h1>
                            {
                                isSchool ? t('roleMap.schoolHeading.default', `Role fulfilment: ${school?.name}`)
                                    : t('roleMap.trustHeading.default', `Role fulfilment: ${trust?.name}`)
                            }
                        </h1>
                    </Col>
                    <ConditionalFragment showIf={loading}>
                        <Col xs="auto">
                            <LoadingIndicator size="sm" />
                        </Col>
                    </ConditionalFragment>
                    <Col></Col>
                    <Col xs={12} md="auto">
                        <SearchInput value={search} onChange={e => setSearch(e.currentTarget.value)} placeholder={t('roleMap.searchPlaceholder', 'Search roles')} />
                    </Col>
                </Row>
            </Banner>
            <MainContainer fluid>
                {
                    !!storeOrganisationalRoleLinks?.length ? storeOrganisationalRoleLinks?.map((roleLink, index) =>
                    {
                        if (!!search) {
                            const role = storeOrganisationalRoles?.find(it => it.id === roleLink.organisationalRoleId);
                            if (!role?.name.toLowerCase().includes(search.toLowerCase())) {
                                return null;
                            }
                        }

                        return (
                            <RenderIfVisible key={index} fallback={<div style={{ minWidth: '100%', minHeight: '245px' }} ></div>}>
                                <RoleMapPart
                                    role={storeOrganisationalRoles?.find(it => it.id === roleLink.organisationalRoleId)}
                                    users={users}
                                    roleLinks={storeOrganisationalRoleLinks?.filter(l => l.organisationalRoleId === roleLink.organisationalRoleId)}
                                    allPersonnelFlags={allPersonnelFlags}
                                    allRequirements={allRequirements}
                                    //All requirement links that are linked to the role, or to a user that we're putting under that role.
                                    requirementLinks={allRequirementLinks?.filter(l => l.targetId === roleLink.organisationalRoleId
                                        || users.find(u => u.userId === l.targetId) != null)}
                                    isTrust={isTrust}
                                    school={!isUndefined(school) ? school : null}
                                    allRoles={storeOrganisationalRoles}
                                    refreshRoleLinks={refresh}
                                    allUserOrganisationRoleLinks={allUserOrganisationRoleLinks}
                                    />
                            </RenderIfVisible>
                        )
                    }
                    ) : (
                        <NoResultsFound />
                    )
                }
            </MainContainer>
        </div>
    )
}