import { EditStandard, EditStandardProps } from "../EditStandard";




/**
 * Create a new admin Standard
 * @param props
 */
export const CreateAdminStandard = (props: EditStandardProps) => (<EditStandard isAdmin isCreate={true} {...props} />);

/**
 * Create a new trust Standard
 * @param props
 */
export const CreateTrustStandard = (props: EditStandardProps) => (<EditStandard isTrust isCreate={true} {...props} />);

/**
 * Create a new school Standard
 * @param props
 */
export const CreateSchoolStandard = (props: EditStandardProps) => (<EditStandard isCreate={true} {...props} />);

/*
 * Component to edit an admin Standard
*/
export const EditAdminStandard = (props: EditStandardProps) => (<EditStandard isAdmin {...props} />);

/*
 * Component to edit a Standard inside a trust
*/
export const EditTrustStandard = (props: EditStandardProps) => (<EditStandard isTrust {...props} />);

/*
 * Component to edit a Standard inside a school
*/
export const EditSchoolStandard = (props: EditStandardProps) => (<EditStandard {...props} />);

/*
 * Component to view a Standard
*/
export const ViewStandard = (props: EditStandardProps) => (<EditStandard isView={true} {...props} />);