import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { DeleteSchoolMutation, DeleteSchoolMutationVariables } from '../generated/graphql';
import { schoolFieldsFragment } from '../models/School';

/**
 * Get a callback to create a School in the store.
 */
export function useDeleteSchoolMutation(): [(id: string) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<DeleteSchoolMutation, DeleteSchoolMutationVariables>(
        gql`
            mutation deleteSchoolMutation ($id: ID!) {
                deleteSchool(id: $id) {
                    ...schoolFields
                }
            }

            ${schoolFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (id: string) => {
        const result = await mutationAction({ variables: { id: id } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
