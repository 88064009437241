import { IdentityRoles } from "../../../configure/security/IdentityRoles";
import { RouteEntry } from "../../../shared/routes";
import { CreateAdminEvent, EditAdminEvent } from "./containers/EditEventContainers";
import { AdminEventsList } from "./containers/EventListContainers";
import { DeleteEvent } from "./DeleteEvent";


/**
 * Route that should be added to configure/routeConfig.ts to provide access to these components via routing
 */
export const eventRoutes: Array<RouteEntry> = [
    { path: '/administration/events', element: <AdminEventsList />, authorize: true, requireRole: IdentityRoles.Administration, },
    { path: '/administration/events/add', element: <CreateAdminEvent onCreateDefaultValues={() => ({ learningContentType: "Event" })}/>, authorize: true, requireRole: IdentityRoles.Administration, },
    { path: '/administration/events/edit/:id', element: <EditAdminEvent />, authorize: true, requireRole: IdentityRoles.Administration, },
    { path: '/administration/events/delete/:id', element: <DeleteEvent />, authorize: true, requireRole: IdentityRoles.Administration, },
];