import { OrganisationalRoleLinkType } from "../../api/main/models/constants/OrganisationalRoleLinkType";
import { RequirementLinkType } from "../../api/main/models/constants/RequirementLinkType";
import { RequirementType } from "../../api/main/models/constants/RequirementType";
import { OrganisationalRole } from "../../api/main/models/OrganisationalRole";
import { OrganisationalRoleLink } from "../../api/main/models/OrganisationalRoleLink";
import { PersonnelFlag } from "../../api/main/models/PersonnelFlag";
import { PersonnelFlagUser } from "../../api/main/models/PersonnelFlagUser";
import { Profile } from "../../api/main/models/Profile";
import { Requirement } from "../../api/main/models/Requirement";
import { RequirementLink } from "../../api/main/models/RequirementLink";
import { roleMapCalculationConfiguration } from "../../configure/roleMapCalculationConfiguration";


// Take a list of users, their role links, personnel flag users, and requirement links,
// then iterate through the users and return an ordered list of users based on their score.
// The score will be calculated based on the requirement links (i.e how many of the roles requirements are met),
// and the impact score of the personnel flags (i.e how much of an impact does the personnel flag have on the user).
export function roleMapUserScoreSort(users: Array<Profile>, role: OrganisationalRole | undefined, roleLinks: Array<OrganisationalRoleLink>,
    personnelFlagUsers: Array<PersonnelFlagUser>, personnelFlags: Array<PersonnelFlag>, requirements: Array<Requirement>, requirementLinks: Array<RequirementLink>)
    : UserRoleScore[]
{

    return users.map((user, index) => {
        const roleId = role?.id;

        // Get the user's personnel flags from the personnelFlagUsers
        const myPersonnelFlagsForUser = personnelFlags?.filter(item => personnelFlagUsers?.find(it => it.personnelFlagId === item.id && it.userId === user.userId));
        // Only personnel flags relevent to the role need be considered
        const personnelFlagsScore = myPersonnelFlagsForUser?.filter(it => personnelFlagUsers.find(pf => pf.organisationalRoleId === roleId && pf.personnelFlagId === it.id))
            .reduce((total, flag) => total + flag.impactScore, 0);

        // Get the user's requirements from the requirementLinks
        const requirementsForUser = requirements?.filter(item => requirementLinks?.find(it =>
            it.requirementId === item.id && it.targetId === user.userId
            && (it.requirementLinkType === RequirementLinkType.UserQualification || it.requirementLinkType === RequirementLinkType.UserExperience || it.requirementLinkType === RequirementLinkType.UserAttribute || it.requirementLinkType === RequirementLinkType.UserSkill)
        ));
        // Get the required and recommended requirements for the role
        const keyRequirementsForRole = requirements?.filter(item => requirementLinks?.find(it => it.requirementId === item.id && it.targetId === roleId
            && (it.requirementLinkType === RequirementLinkType.KeyQualification)
        ));
        const requiredRequirementsForRole = requirements?.filter(item => requirementLinks?.find(it => it.requirementId === item.id && it.targetId === roleId
            && (it.requirementLinkType === RequirementLinkType.RequiredQualification || it.requirementLinkType === RequirementLinkType.RequiredAttribute || it.requirementLinkType === RequirementLinkType.RequiredSkill)
        ));
        const recommendedRequirementsForRole = requirements?.filter(item => requirementLinks?.find(it => it.requirementId === item.id && it.targetId === roleId
            && (it.requirementLinkType === RequirementLinkType.RecommendedQualification || it.requirementLinkType === RequirementLinkType.RecommendedAttribute || it.requirementLinkType === RequirementLinkType.RecommendedSkill)
        ));

        // Qualifications.
        // Add points for every key qualification the user has, and one point for every recommended requirement the user has.
        const keyQualificationsScore = requirementsForUser?.filter(item => keyRequirementsForRole?.filter(it => it.requirementType === RequirementType.Qualification)?.find(it => it.id === item.id)).length * roleMapCalculationConfiguration.pointsPerKeyQualification;
        // Add points for every required qualification the user has, and one point for every recommended requirement the user has.
        const requiredQualificationsScore = requirementsForUser?.filter(item => requiredRequirementsForRole?.filter(it => it.requirementType === RequirementType.Qualification)?.find(it => it.id === item.id)).length * roleMapCalculationConfiguration.pointsPerRequiredQualification;
        // Add points for every recommended qualification the user has, and one point for every recommended requirement the user has.
        const recommendedQualificationsScore = requirementsForUser?.filter(item => recommendedRequirementsForRole?.filter(it => it.requirementType === RequirementType.Qualification)?.find(it => it.id === item.id)).length * roleMapCalculationConfiguration.pointsPerRecommendedQualification;

        // Experience
        // Add points for every required experience the user has.
        const requiredExperiencesScore = requirementsForUser?.filter(item => requiredRequirementsForRole?.filter(it => it.requirementType === RequirementType.Experience)?.find(it => it.id === item.id)).length * roleMapCalculationConfiguration.pointsPerRequiredExperience;
        // Add points for every recommended experience the user has, and 1 point for every recommended experience the user has.
        const recommendedExperiencesScore = requirementsForUser?.filter(item => recommendedRequirementsForRole?.filter(it => it.requirementType === RequirementType.Experience)?.find(it => it.id === item.id)).length * roleMapCalculationConfiguration.pointsPerRecommendedExperience;

        // Attributes
        // Add points for every required attribute the user has.
        const requiredAttributesScore = requirementsForUser?.filter(item => requiredRequirementsForRole?.filter(it => it.requirementType === RequirementType.Attribute)?.find(it => it.id === item.id)).length * roleMapCalculationConfiguration.pointsPerRequiredAttribute
        // Add points for every recommended attribute the user has.
        const recommendedAttributesScore = requirementsForUser?.filter(item => recommendedRequirementsForRole?.filter(it => it.requirementType === RequirementType.Attribute)?.find(it => it.id === item.id)).length * roleMapCalculationConfiguration.pointsPerRecommendedAttribute

        // Skills
        // Add points for every required skill the user has.
        const requiredSkillsScore = requirementsForUser?.filter(item => requiredRequirementsForRole?.filter(it => it.requirementType === RequirementType.Skill)?.find(it => it.id === item.id)).length * roleMapCalculationConfiguration.pointsPerRequiredSkill;
        // Add points for every recommended skill the user hass.
        const recommendedSkillsScore = requirementsForUser?.filter(item => recommendedRequirementsForRole?.filter(it => it.requirementType === RequirementType.Skill)?.find(it => it.id === item.id)).length * roleMapCalculationConfiguration.pointsPerRecommendedSkill

        // If the user has this role as a current role, add a big chunk of points as we know they can do the role.
        let currentRoleScore = 0;
        if (!!roleLinks?.find(it => it.organisationalRoleLinkType === OrganisationalRoleLinkType.User && it.targetId === user.userId && it.organisationalRoleId === roleId)) {
            currentRoleScore = roleMapCalculationConfiguration.pointsForCurrentRole;
        }

        // If the user has this role as an aspiration, add some points.
        let aspirationalRoleScore = 0;
        if (!!roleLinks?.find(it => it.organisationalRoleLinkType === OrganisationalRoleLinkType.AspirationalUserRole && it.targetId === user.userId && it.organisationalRoleId === roleId)) {
            aspirationalRoleScore = roleMapCalculationConfiguration.pointsForAspirationalRole;
        }

        // If the user is pinned for this role, add a huge amount to the score so they show first but get filtered within themselves.
        let pinnedRoleScore = 0;
        if (!!roleLinks?.find(it => it.organisationalRoleLinkType === OrganisationalRoleLinkType.RoleMapUserPin
            && it.targetId === user.userId && it.organisationalRoleId === roleId)) {
            pinnedRoleScore = roleMapCalculationConfiguration.pointsForPinnedRole;
        }

        // Calculate the total score by summing everything together.
        const score = personnelFlagsScore
            + keyQualificationsScore
            + requiredQualificationsScore
            + recommendedQualificationsScore
            + requiredExperiencesScore
            + recommendedExperiencesScore
            + requiredAttributesScore
            + recommendedAttributesScore
            + requiredSkillsScore
            + recommendedSkillsScore
            + currentRoleScore
            + aspirationalRoleScore
            + pinnedRoleScore;
        
        return {
            user,
            score, 

            // Breakdown.
            keyQualificationsScore,
            requiredQualificationsScore,
            recommendedQualificationsScore,
            requiredExperiencesScore,
            recommendedExperiencesScore,
            requiredAttributesScore,
            recommendedAttributesScore,
            requiredSkillsScore,
            recommendedSkillsScore,
            currentRoleScore,
            aspirationalRoleScore,
            pinnedRoleScore,
            personnelFlagsScore,
        } as UserRoleScore;
    })
        .filter(it => it.score >= roleMapCalculationConfiguration.minimumPointsToShowForRole /* Minimum score required to show */)
        .sort((a, b) => b.score - a.score); // sort by score
}

/**
 * User with their score for a role.
 */
export interface UserRoleScore {
    // User's profile.
    user: Profile;

    // Score.
    score: number;

    // Breakdown of the score.
    //
    keyQualificationsScore: number;
    requiredQualificationsScore: number;
    recommendedQualificationsScore: number;
    requiredExperiencesScore: number;
    recommendedExperiencesScore: number;
    requiredAttributesScore: number;
    recommendedAttributesScore: number;
    requiredSkillsScore: number;
    recommendedSkillsScore: number;
    currentRoleScore: number;
    aspirationalRoleScore: number;
    pinnedRoleScore: number;
    personnelFlagsScore: number;
}