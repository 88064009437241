import { ScrollTo } from "@scottbamford/react-scrollto";
import React from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { OrganisationalRoleLinkType } from "../../api/main/models/constants/OrganisationalRoleLinkType";
import { useOrganisationalRoleLinksByTargetId } from "../../api/main/organisationalRoleLinks/useUserOrganisationalRoleLinks";
import { useCurrentUserProfile } from "../../api/main/profiles/useCurrentUserProfile";
import { useUserLearningEvents } from "../../api/main/userLearningEvents/useUserLearningEvents";
import { useCurrentUserOrEmulatedSubscription } from "../../globalState/subscriptions/useCurrentUserOrEmulatedSubscription";
import { AlertOnErrors } from "../../shared/alertOnErrors";
import { useReplaceSearchParamsEffect, useSearchParams } from "../../shared/useURLSearchParams";
import { Banner } from "../shared/banner/Banner";
import { LoadingIndicator } from "../shared/loadingIndicator/LoadingIndicator";
import { NoResultsFound } from "../shared/noResultsFound/NoResultsFound";
import { SearchInput } from "../shared/searchInput/SearchInput";
import { GrowListPart } from "./growListPart";
import { SchoolTrustGrowListPart } from "./schoolTrustGrowListPart";
import { SearchResultsGrowListPart } from "./searchResultsGrowListPart";

export const GrowList = () =>
{
    const { t } = useTranslation();

    // Load the current user
    const {
        data: {
            model: currentUser,
        }, isLoading: isCurrentUserLoading, errors: currentUserErrors
    } = useCurrentUserProfile();

    // Load the subscription also, for school/trust parts to show even if you're only emulating being a part of a school or trust
    const subscription = useCurrentUserOrEmulatedSubscription();

    // Load the current user's CPD so we can highlight completed items.
    const {
        data: {
            items: myCPD,
        }, isLoading: isLoadingMyCPD, errors: myCPDErrors
    } = useUserLearningEvents({}, currentUser?.userId);

    //Load data
    const {
        data: {
            items,
        }, isLoading: _isLoading, errors: loadErrors
    } = useOrganisationalRoleLinksByTargetId({ pageSize: undefined }, currentUser?.userId);

    const isLoading = _isLoading || isCurrentUserLoading || isLoadingMyCPD;

    const linksForCurrentRoles = items?.filter(item => item.organisationalRoleLinkType === OrganisationalRoleLinkType.User);
    const linksForAspirationalRoles = items?.filter(item => item.organisationalRoleLinkType === OrganisationalRoleLinkType.AspirationalUserRole);
    let { state } = useLocation();  
    const idToScrollTo = state ? state.idToScrollTo : "";

    const { search: searchParam } = useSearchParams();
    const [search, setSearch] = React.useState<string>(searchParam ?? '');

    // Keep the URL up to date with the search text
    useReplaceSearchParamsEffect({ search: search });

    return (
        <>
            <Banner fluid>
                <Row>
                    <Col xs={12} md="auto">
                        <h1>
                            {t('growList.title', 'Grow')}
                        </h1>
                    </Col>
                    <ConditionalFragment showIf={isLoading}>
                        <Col xs="auto">
                            <LoadingIndicator size="sm" />
                        </Col>
                    </ConditionalFragment>
                </Row>
                <Row>
                    <Col>
                        <SearchInput value={search} onChange={e => setSearch(e.currentTarget.value)} />
                    </Col>
                </Row>
            </Banner>

            <AlertOnErrors simple errors={[currentUserErrors, loadErrors, myCPDErrors]} />

            {/*<ConditionalFragment showIf={!!subscription?.schoolId}>
                <SchoolTrustGrowListPart schoolId={subscription?.schoolId} />
            </ConditionalFragment>

            <ConditionalFragment showIf={!!subscription?.trustId}>
                <SchoolTrustGrowListPart TrustId={subscription?.trustId} />
            </ConditionalFragment>*/}
            <ConditionalFragment showIf={search === ""}>
                {
                    linksForCurrentRoles ? linksForCurrentRoles.map(item => (
                        <ScrollTo key={item.id} shouldScrollTo={idToScrollTo === item.organisationalRoleId}>
                            <GrowListPart organisationalRoleId={item.organisationalRoleId} userLearningEvents={myCPD} />
                        </ScrollTo>
                    ))
                        : null
                }

                {
                    linksForAspirationalRoles ? linksForAspirationalRoles.map(item => (
                        <ScrollTo position='nearest' key={item.id} shouldScrollTo={idToScrollTo === item.organisationalRoleId}>
                            <GrowListPart organisationalRoleId={item.organisationalRoleId} userLearningEvents={myCPD} />
                        </ScrollTo>
                    ))
                        : null
                }
            </ConditionalFragment>
            <ConditionalFragment showIf={search !== ""}>
                <SearchResultsGrowListPart search={search} />
            </ConditionalFragment>

            <ConditionalFragment showIf={!items?.length && !isLoading}>
                <NoResultsFound>
                    {t('growList.noResultsFound.before', 'Browse the roles under the ')}
                    <Link to="/career">
                        {t('growList.noResultsFound.link', 'Career')}
                    </Link>
                    {t('growList.noResultsFound.after', ' page and set your current and aspirational roles so we can show you personalised growth resources.')}
                </NoResultsFound>
            </ConditionalFragment>
        </>
    );



}