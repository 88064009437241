import gql from 'graphql-tag';
import { Guid } from 'guid-string';
import { RequirementLinkFieldsFragment } from '../generated/graphql';

export const requirementLinkFieldsFragment = gql`
    fragment requirementLinkFields on RequirementLink {
        id
        requirementId
        targetId
        targetType
        requirementLinkType
        archived
    }
`;

export type RequirementLink = Omit<RequirementLinkFieldsFragment, '__typename' | 'requirementLink'>;

/**
 * Default values.
 */
export const requirementLinkDefaultValues = (): Partial<RequirementLink> => ({
    id: Guid.newGuid(),
    requirementId: undefined,
    targetId: undefined,
    targetType: '',
    requirementLinkType: '',
    archived: false,
});