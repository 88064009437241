import * as React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useValidatorCallback } from "pojo-validator-react";
import { ValidatedInput } from "pojo-validator-reactstrap";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useAsyncCallback } from "react-use-async-callback";
import { Button, ButtonGroup, Col, Form, FormGroup, Input, Label, Row, Spinner } from "reactstrap";
import { ButtonAsync } from "reactstrap-buttonasync";
import { BlobUploadService } from "../../../api/main/blobReferences/BlobUploadService";
import { useBlobReference } from "../../../api/main/blobReferences/useBlobReference";
import { useLearningContent } from "../../../api/main/learningContents/useLearningContent";
import { useSaveLearningContentMutation } from "../../../api/main/learningContents/useSaveLearningContentMutation";
import { LearningContent, learningContentDefaultValues } from "../../../api/main/models/LearningContent";
import { AlertOnErrors } from "../../../shared/alertOnErrors";
import { HtmlEditor } from "../../../shared/htmlEditor";
import { useChanges, useChangesArray } from "../../../shared/useChanges";
import { Banner } from "../../shared/banner/Banner";
import { FileUploadButton } from "../../shared/fileUploadButton/FileUploadButton";
import { FormButtons } from "../../shared/formButtons/FormButtons";
import { ValidatedISODateTimeInput } from "../../shared/isoDateTimeInput/ValidatedISODateTimeInput";
import { LoadingIndicator } from "../../shared/loadingIndicator/LoadingIndicator";
import { MainContainer } from "../../shared/mainContainer/MainContainer";
import { UploadedFilePreview } from "../../shared/uploadedFilePreview/UploadedFilePreview";
import { UploadedImagePreview } from "../../shared/uploadedImagePreview/UploadedImagePreview";
import { useCurrentUserOrEmulatedSubscription } from '../../../globalState/subscriptions/useCurrentUserOrEmulatedSubscription';
import { getLearningContentTypes, LearningContentType, learningContentTypeDisplayName } from '../../../api/main/models/constants/LearningContentType';
import { useOrganisationalRoleLinksByTargetId } from '../../../api/main/organisationalRoleLinks/useUserOrganisationalRoleLinks';
import { OrganisationalRoleLink, organisationalRoleLinkDefaultValues } from '../../../api/main/models/OrganisationalRoleLink';
import { useSaveOrganisationalRoleLinkMutation } from '../../../api/main/organisationalRoleLinks/useSaveOrganisationalRoleLinkMutation';
import { useDeleteOrganisationalRoleLinkMutation } from '../../../api/main/organisationalRoleLinks/useDeleteOrganisationalRoleLinkMutation';
import { OrganisationalRoleLinkType } from '../../../api/main/models/constants/OrganisationalRoleLinkType';
import { useOrganisationProfileFromSubscription } from '../../../api/main/organisationProfiles/useOrganisationProfileFromSubscription';
import { useOrganisationalRolesArray } from '../../../api/main/organisationalRoles/useOrganisationalRolesArray';
import { OrganisationalRoleTagSelector } from '../organisationalRoles/OrganisationalRoleTagSelector';
import { getLearningCostTypes, learningCostTypeDisplayName } from '../../../api/main/models/constants/LearningCostType';

export interface EditBookProps {
    isCreate?: boolean,
    isView?: boolean,
    bookId?: string,

    isAdmin?: boolean,
    isTrust?: boolean,
    onCreateDefaultValues?: () => Partial<LearningContent>,
}

/**
 * Create a new Book
 * @param props
 */
export const CreateBook = (props: EditBookProps) => (<EditBook isCreate={true} {...props} />);

/*
 * Component to view a book
*/
export const ViewBook = (props: EditBookProps) => (<EditBook isView={true} {...props} />);

/**
 * Edit an existing Book
 * @param props
 */
export const EditBook = (props: EditBookProps) => {
    const {
        isCreate,
        onCreateDefaultValues,

        // If the user is on the view version or not (should disable all input boxes and hide all buttons if true).
        isView = false,
        // Viewing will most likely be done in a list, so we need to pass in the id (wont be in the url).
        bookId,

        // If the user is on the administration version or not.
        isAdmin = false,
        //If the user is on the trust overview or not
        isTrust = false,
    } = props;

    const { t } = useTranslation();
    const subscription = useCurrentUserOrEmulatedSubscription(); // Grabs current/emulated subscription
    const { id } = useParams<{ id: string | undefined; }>();
    const navigate = useNavigate();

    // If we get passed in an id use that one instead of params (used when viewing a list of specific books)
    const idToUse = !!bookId ? bookId : id;

    // Load the data
    const {
        data: {
            model: storeModel,
        }, isLoading, errors: loadErrors
    } = useLearningContent(idToUse);

    // Model (LearningContent -> Book)
    const { model, change, changes } = useChanges(storeModel, isCreate ? { ...learningContentDefaultValues(), ...(onCreateDefaultValues ? onCreateDefaultValues() : {}) } : undefined);
    const [saveBook, { errors: saveErrors }] = useSaveLearningContentMutation();

    // OrganisationalRoleLinks
    const {
        data: {
            items: organisationalRoleLinks,
        }, isLoading: isOrganisationalRoleLinksLoading, errors: organisationalRoleLinksErrors
    } = useOrganisationalRoleLinksByTargetId({ pageSize: undefined }, model?.id);

    // Rolelink manager for attaching to roles
    const organisationalRoleLinkManager = useChangesArray<OrganisationalRoleLink, string>(organisationalRoleLinks, item => item.id);
    const [saveOrganisationalRoleLink, { errors: saveOrganisationalRoleLinkErrors, }] = useSaveOrganisationalRoleLinkMutation();
    const [removeOrganisationalRoleLink, { errors: removeOrganisationalRoleLinkErrors, }] = useDeleteOrganisationalRoleLinkMutation();

    const isRoleSelected = (roleId: string) => {
        return !!organisationalRoleLinkManager.model.find(it => it.organisationalRoleId === roleId &&
            it.organisationalRoleLinkType === OrganisationalRoleLinkType.LearningForRole);
    }

    const setRoleSelected = (roleId: string) => {
        const existing = organisationalRoleLinkManager.model.find(it => it.organisationalRoleId === roleId &&
            it.organisationalRoleLinkType === OrganisationalRoleLinkType.LearningForRole);
        if (existing) {
            organisationalRoleLinkManager.removeFor(existing.id);
        }
        else {
            organisationalRoleLinkManager.addFor({ ...organisationalRoleLinkDefaultValues(), targetId: model.id, targetType: isAdmin ? model.learningContentType : isTrust ? "Trust" + model.learningContentType : "School" + model.learningContentType, organisationalRoleId: roleId, organisationalRoleLinkType: OrganisationalRoleLinkType.LearningForRole });
        }
    }

    // Load the organisationProfile from the subscription so we can fetch the roles to populate our tag selector
    const {
        data: {
            model: organisationProfile,
        }, isLoading: isOrganisationProfileLoading, errors: organisationProfileErrors
    } = useOrganisationProfileFromSubscription(subscription ?? {});

    // Load the RoleInOrganisationProfileLinks, so we know what roles we can attach to
    const {
        data: {
            items: roleInOrganisationProfileLinks,
        }, isLoading: isRoleInOrganisationProfileLinksLoading, errors: roleInOrganisationProfileLinksErrors
    } = useOrganisationalRoleLinksByTargetId({ pageSize: undefined }, organisationProfile?.id);

    // Load the roles from the RoleInOrganisationProfileLinks
    const {
        data: {
            items: roles,
        }, isLoading: isRolesLoading, errors: rolesErrors
    } = useOrganisationalRolesArray(roleInOrganisationProfileLinks?.map(it => it.organisationalRoleId));

    // Book (image) upload (photo)
    const { data: { model: image }, errors: imageLoadErrors } = useBlobReference(model?.imageBlobReferenceId);
    const [onUploadImage, { errors: imageUploadErrors, isExecuting: isUploadingImage }] = useAsyncCallback(async (files: FileList | null) => {
        if (!files) {
            return;
        }

        let uploadService: BlobUploadService = new BlobUploadService("/api/blobs");
        let result = await uploadService.upload(files);

        if (!!result) {
            change({ imageBlobReferenceId: result.id });
        }
    }, [change]);

    // Clear image functionality
    const [clearImage, { isExecuting: isClearingImage }] = useAsyncCallback(async () => {
        change({ imageBlobReferenceId: null });
    }, [change]);

    // Book (file) upload
    const { data: { model: book }, errors: bookLoadErrors } = useBlobReference(model?.blobReferenceId);
    const [onUploadBook, { errors: bookUploadErrors, isExecuting: isUploadingBook }] = useAsyncCallback(async (files: FileList | null) => {
        if (!files) {
            return;
        }

        let uploadService: BlobUploadService = new BlobUploadService("/api/blobs");
        let result = await uploadService.upload(files);

        // Set the blobReference and also clear the URL. If we are uplaoding a file then we don't want to add any URL.
        if (!!result) {
            change({ blobReferenceId: result.id, url: '' });
        }
    }, [change]);

    // Clear image functionality
    const [clearBook, { isExecuting: isClearingBook }] = useAsyncCallback(async () => {
        change({ blobReferenceId: null });
    }, [change]);

    // Main model validation
    const [validate, validationErrors] = useValidatorCallback((validation, fieldsToCheck) => {
        const rules = {
            name: () => !model?.name ? t('editBook.errors.nameRequired', 'Name is required') : '',
            publishDate: () => !model.publishDate ? t('editBook.errors.publishDate', 'Publish date cannot be empty') : '',
            learningContentType: () => !model.learningContentType ? t('editBook.errors.learningContentType', 'Growth resource type cannot be empty') : '',
        };
        validation.checkRules(rules, fieldsToCheck);
    }, [model,]);


    // If we are not on admin and are not in a trust then we must be in a school
    React.useEffect(() => {
        if (!isAdmin) {
            if (!isTrust) {
                // If we are not on admin and are not in a trust then we must be in a school
                if (!!subscription?.schoolId) {
                    change({ schoolId: subscription.schoolId });
                }

            } else {
                // We are on trust overview
                if (!!subscription?.trustId) {
                    change({ trustId: subscription.trustId });
                }
            }
        }
        // Dont set anything if we are on admin
    }, [subscription, change, isAdmin, isTrust]);

    // Save the form
    const [saveForm, { isExecuting: isSaving, errors: saveFormErrors }] = useAsyncCallback(async () => {
        if (!model) {
            return;
        }

        if (!validate()) {
            return;
        }

        // Save the book
        await saveBook(model.id, { ...changes }, isCreate ?? false);

        // Save the role links
        for (const organisationalRoleLink of organisationalRoleLinkManager.added) { saveOrganisationalRoleLink(organisationalRoleLink.id, organisationalRoleLink, true); }
        for (const organisationalRoleLink of organisationalRoleLinkManager.updated) { saveOrganisationalRoleLink(organisationalRoleLink.id, organisationalRoleLink, false); }
        for (const organisationalRoleLink of organisationalRoleLinkManager.removed) { removeOrganisationalRoleLink(organisationalRoleLink.id); }
        organisationalRoleLinkManager.markAsSaved();

        // Go back to previous screen.
        navigate(-1);
    }, [
        validate,
        saveBook,
        model,
        changes,
        isCreate,
        navigate,
        organisationalRoleLinkManager,
        saveOrganisationalRoleLink,
        removeOrganisationalRoleLink
    ]);

    // Render the UI
    //
    return (
        <>
            <ConditionalFragment showIf={!isView}>
                <Banner>
                    <Row>
                        <Col xs={12} md="auto">
                            <h1>
                                {
                                    isCreate ? t('editBook.createHeading.default', 'Add book')
                                        : t('editBook.editHeading.default', 'Edit book')
                                }
                            </h1>
                        </Col>
                        <ConditionalFragment showIf={isLoading}>
                            <Col xs="auto">
                                <LoadingIndicator size="sm" />
                            </Col>
                        </ConditionalFragment>
                    </Row>
                </Banner>
            </ConditionalFragment>

            <MainContainer>
                <AlertOnErrors
                    errors={[
                        loadErrors,
                        saveFormErrors,
                        saveErrors,
                        imageLoadErrors,
                        imageUploadErrors,
                        bookLoadErrors,
                        bookUploadErrors
                    ]}
                />

                <Form onSubmit={e => { e.preventDefault(); saveForm(); }}>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label htmlFor="name">{t('editBook.name.label', 'Name')}</Label>
                                <ValidatedInput disabled={isView} name="name" type="text" value={model?.name ?? ""} onChange={e => change({ name: e.currentTarget.value })} onBlur={e => validate('name')} validationErrors={validationErrors['name']} />
                            </FormGroup>

                            <FormGroup>
                                <Label htmlFor="publishDate">{t('editBook.publishDate.label', 'Publish date')}</Label>
                                <ValidatedISODateTimeInput disabled={isView} name="publishDate" type="date" value={model?.publishDate ?? ''} onChange={e => change({ publishDate: e.currentTarget.value })} onBlur={e => validate('publishDate')} validationErrors={validationErrors['publishDate']} />
                            </FormGroup>

                            <FormGroup>
                                <Label htmlFor="cpdHours">{t('editBook.cpdHours.label', 'CPD minutes')}</Label>
                                <Input disabled={isView} name="cpdHours" type="number" value={model?.cPDMinutes ?? 0} onChange={e => change({ cPDMinutes: e.currentTarget.valueAsNumber })} />
                            </FormGroup>

                            <FormGroup>
                                <Label htmlFor="learningCostType">{t('editBook.learningCostType.label', 'Cost type')}</Label>
                                <ValidatedInput disabled={isView} name="learningCostType" type="select" value={model.learningCostType ?? ''} onChange={(e) => change({ learningCostType: e.currentTarget.value })} onBlur={e => validate('learningCostType')} validationErrors={validationErrors['learningCostType']}>
                                    <option value="">{t('editBook.learningCostType.pleaseSelect', '(Please select a cost type)')}</option>
                                    {
                                        getLearningCostTypes().map(item => (
                                            <option key={item} value={item}>{learningCostTypeDisplayName(item, t)}</option>
                                        ))
                                    }
                                </ValidatedInput>
                            </FormGroup>
                        </Col>

                        {/* Book Image */}
                        <Col>
                            <FormGroup>
                                <Label htmlFor="name">{t('editBook.image.label', 'Book photo:')}</Label>
                                <UploadedImagePreview size="lg" src={image?.url ?? ''} />
                                <ConditionalFragment showIf={!isView}>
                                    <Row>
                                        <Col>
                                            <ButtonGroup>
                                                <FileUploadButton
                                                    color={`primary`}
                                                    isExecuting={isUploadingImage}
                                                    executingChildren={<><Spinner size="sm" /> {t('common.uploading', 'Uploading...')}</>}
                                                    onUpload={onUploadImage}
                                                    outline={false}>
                                                    <FontAwesomeIcon icon="image" />
                                                    <> </>
                                                    {t('editBook.uploadImageButtonText', 'Upload an image')}
                                                </FileUploadButton>
                                                <ButtonAsync color="primary"
                                                    outline
                                                    isExecuting={isClearingImage}
                                                    type="button"
                                                    onClick={clearImage}
                                                    executingChildren={<><Spinner size="sm" /> {t('editBook.clearingImage', 'Clearing image...')}</>}>
                                                    {t('editBook.clearImageButton', 'Clear image')}
                                                </ButtonAsync>
                                            </ButtonGroup>
                                        </Col>
                                    </Row>
                                </ConditionalFragment>
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <ConditionalFragment showIf={!isView}>
                            {/*Should probably show this in a different way if we are viewing, but dont want to open to XSS attacks*/}
                            <FormGroup>
                                <Label htmlFor="descriptionHtml">{t('editBook.description.label', 'Description')}</Label>
                                <HtmlEditor size="sm" value={model?.descriptionHtml ?? ''} onChange={text => change({ descriptionHtml: text })} />
                            </FormGroup>
                        </ConditionalFragment>
                    </Row>
                    <FormGroup>
                        <Label htmlFor="learningForRole">{t('editBook.learningForRole.label', 'Assign roles')}</Label>
                        <OrganisationalRoleTagSelector input={!isView}
                            tags={
                                isView ? roles?.filter(role => isRoleSelected(role.id))
                                    : roles
                            }
                            isSelected={tag => isRoleSelected(tag.id)}
                            toggle={tag => {
                                if (isView) {
                                    return;
                                }
                                setRoleSelected(tag.id)
                            }}
                        />
                    </FormGroup>

                    {/* Upload type */}
                    <Row>
                        <Col>
                            <Col xs="auto">
                                <FormGroup>
                                    <Label htmlFor="bookLinkType">{t('editBook.bookLinkType.label', 'Growth resource type')}</Label>
                                    <ValidatedInput disabled={isView} name="bookLinkType" type="select" value={model.learningContentType ?? ''} onChange={(e) => change({ learningContentType: e.currentTarget.value })} onBlur={e => validate('learningContentType')} validationErrors={validationErrors['learningContentType']}>
                                        <option value="">{t('editBook.bookLinkType.pleaseSelect', '(Please select a growth resource type)')}</option>
                                        {
                                            getLearningContentTypes("Book").map(item => (
                                                <option key={item} value={item}>{learningContentTypeDisplayName(item, t)}</option>
                                            ))
                                        }
                                    </ValidatedInput>
                                </FormGroup>
                            </Col>
                        </Col>
                    </Row>

                    {/* Book File */}
                    <Row>
                        <ConditionalFragment showIf={model.learningContentType === LearningContentType.BookFile}>
                            <Col>
                                <FormGroup>
                                    <Label htmlFor="name">{t('editBook.fileUpload.label', 'Book file upload:')}</Label>
                                    <UploadedFilePreview blobReference={book} />
                                    <Row>
                                        <Col>
                                            <ButtonGroup>
                                                <FileUploadButton
                                                    color={`primary`}
                                                    isExecuting={isUploadingBook}
                                                    executingChildren={<><Spinner size="sm" /> {t('common.uploading', 'Uploading...')}</>}
                                                    onUpload={onUploadBook}
                                                    outline={false}>
                                                    {t('editBook.uploadBookButtonText', 'Upload a book')}
                                                </FileUploadButton>
                                                <ButtonAsync color="primary"
                                                    outline
                                                    isExecuting={isClearingBook}
                                                    type="button"
                                                    onClick={clearBook}
                                                    executingChildren={<><Spinner size="sm" /> {t('editBook.removingBook', 'Removing book...')}</>}>
                                                    {t('editBook.removeBookButton', 'Remove book')}
                                                </ButtonAsync>
                                            </ButtonGroup>
                                        </Col>
                                    </Row>
                                </FormGroup>
                            </Col>
                        </ConditionalFragment>

                        {/* Book Link to Store */}
                        <Row>
                            <ConditionalFragment showIf={model.learningContentType === LearningContentType.BookLink}>
                                <FormGroup>
                                    <Label htmlFor="url"><h4>{t('editBook.bookUrl.label', 'Book url (to store)')}</h4></Label>
                                    <Input disabled={isView} name="url" type="text" value={model?.url ?? ''} onChange={e => change({ url: e.currentTarget.value })} />
                                </FormGroup>
                            </ConditionalFragment>
                        </Row>
                    </Row>
                </Form>
                <ConditionalFragment showIf={!isView}>
                    <FormButtons>
                        <ConditionalFragment showIf={!isLoading}>
                            <ButtonAsync color="primary" isExecuting={isSaving} onClick={() => saveForm()}
                                executingChildren={<><Spinner size="sm" /> {t('common.saving', 'Saving...')}</>}>
                                <FontAwesomeIcon icon="save" />
                                <> </>
                                {t('common.save', 'Save')}
                            </ButtonAsync>
                        </ConditionalFragment>

                        <Button type="button" color="primary" outline onClick={e => navigate(-1)}>
                            {t('common.cancel', 'Cancel')}
                        </Button>
                    </FormButtons>
                </ConditionalFragment>
            </MainContainer>
        </>
    );
};