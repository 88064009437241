import { EventsList, EventsListProps } from "../EventsList";

/*
 * Component to view a list of admin events
*/
export const AdminEventsList = (props: EventsListProps) => (<EventsList isAdmin {...props} />);

/*
 * Component to view a list of trust events
*/
export const TrustEventsList = (props: EventsListProps) => (<EventsList isTrust {...props} />);

/*
 * Component to view a list of school events
*/
export const SchoolEventsList = (props: EventsListProps) => (<EventsList {...props} />);