import { EditBook, EditBookProps } from "../EditBook";

/**
 * Create a new admin Book
 * @param props
 */
export const CreateAdminBook = (props: EditBookProps) => (<EditBook isAdmin isCreate={true} {...props} />);

/**
 * Create a new trust Book
 * @param props
 */
export const CreateTrustBook = (props: EditBookProps) => (<EditBook isTrust isCreate={true} {...props} />);

/**
 * Create a new school Book
 * @param props
 */
export const CreateSchoolBook = (props: EditBookProps) => (<EditBook isCreate={true} {...props} />);

/*
 * Component to edit an admin Book
*/
export const EditAdminBook = (props: EditBookProps) => (<EditBook isAdmin {...props} />);

/*
 * Component to edit a Book inside a trust
*/
export const EditTrustBook = (props: EditBookProps) => (<EditBook isTrust {...props} />);

/*
 * Component to edit a Book inside a school
*/
export const EditSchoolBook = (props: EditBookProps) => (<EditBook {...props} />);

/*
 * Component to view a Book
*/
export const ViewBook = (props: EditBookProps) => (<EditBook isView={true} {...props} />);