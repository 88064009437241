import * as React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useValidatorCallback } from "pojo-validator-react";
import { ValidatedInput } from "pojo-validator-reactstrap";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useAsyncCallback } from "react-use-async-callback";
import { Button, ButtonGroup, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Form, FormGroup, FormText, Input, Label, Row, Spinner } from "reactstrap";
import { ButtonAsync } from "reactstrap-buttonasync";
import { useToggleState } from "use-toggle-state";
import { BlobUploadService } from "../../../api/main/blobReferences/BlobUploadService";
import { useBlobReference } from "../../../api/main/blobReferences/useBlobReference";
import { useLearningContent } from "../../../api/main/learningContents/useLearningContent";
import { useSaveLearningContentMutation } from "../../../api/main/learningContents/useSaveLearningContentMutation";
import { LearningContent, learningContentDefaultValues } from "../../../api/main/models/LearningContent";
import { AlertOnErrors } from "../../../shared/alertOnErrors";
import { generateYoutubeEmbedLink } from "../../../shared/generateYoutubeEmbedLink/generateYoutubeEmbedLink";
import { HtmlEditor } from "../../../shared/htmlEditor";
import { useChanges, useChangesArray } from "../../../shared/useChanges";
import { Banner } from "../../shared/banner/Banner";
import { FileUploadButton } from "../../shared/fileUploadButton/FileUploadButton";
import { FormButtons } from "../../shared/formButtons/FormButtons";
import { ValidatedISODateTimeInput } from "../../shared/isoDateTimeInput/ValidatedISODateTimeInput";
import { LoadingIndicator } from "../../shared/loadingIndicator/LoadingIndicator";
import { MainContainer } from "../../shared/mainContainer/MainContainer";
import { UploadedImagePreview } from "../../shared/uploadedImagePreview/UploadedImagePreview";
import { UploadedVideoPreview } from "../../shared/uploadedVideoPreview/UploadedVideoPreview";
import { useCurrentUserOrEmulatedSubscription } from '../../../globalState/subscriptions/useCurrentUserOrEmulatedSubscription';
import { getLearningContentTypes, LearningContentType, learningContentTypeDisplayName } from '../../../api/main/models/constants/LearningContentType';
import { useOrganisationalRoleLinksByTargetId } from '../../../api/main/organisationalRoleLinks/useUserOrganisationalRoleLinks';
import { OrganisationalRoleLink, organisationalRoleLinkDefaultValues } from '../../../api/main/models/OrganisationalRoleLink';
import { useSaveOrganisationalRoleLinkMutation } from '../../../api/main/organisationalRoleLinks/useSaveOrganisationalRoleLinkMutation';
import { useDeleteOrganisationalRoleLinkMutation } from '../../../api/main/organisationalRoleLinks/useDeleteOrganisationalRoleLinkMutation';
import { OrganisationalRoleLinkType } from '../../../api/main/models/constants/OrganisationalRoleLinkType';
import { useOrganisationProfileFromSubscription } from '../../../api/main/organisationProfiles/useOrganisationProfileFromSubscription';
import { useOrganisationalRolesArray } from '../../../api/main/organisationalRoles/useOrganisationalRolesArray';
import { OrganisationalRoleTagSelector } from '../organisationalRoles/OrganisationalRoleTagSelector';
import { getLearningCostTypes, learningCostTypeDisplayName } from '../../../api/main/models/constants/LearningCostType';


export interface EditPodcastProps {
    isCreate?: boolean,
    isView?: boolean,
    podcastId?: string,

    isAdmin?: boolean,
    isTrust?: boolean,
    onCreateDefaultValues?: () => Partial<LearningContent>
}

/**
 * Component to edit a Podcast
 */
export const EditPodcast = (props: EditPodcastProps) => {
    const {
        isCreate,
        onCreateDefaultValues,

        // If the user is on the view version or not (should disable all input boxes and hide all buttons if true).
        isView = false,
        // Viewing will most likely be done in a list, so we need to pass in the id (wont be in the url).
        podcastId,

        // If the user is on the administration version or not.
        isAdmin = false,
        //If the user is on the trust overview or not
        isTrust = false,
    } = props

    const { t } = useTranslation();
    const { id } = useParams<{ id: string | undefined }>();
    const subscription = useCurrentUserOrEmulatedSubscription(); // Grabs current/emulated subscription
    const navigate = useNavigate();

    // If we get passed in an id use that one instead of params (used when viewing a list of specific podcasts)
    const idToUse = !!podcastId ? podcastId : id;

    // Load all data
    const {
        data: {
            model: storeModel,
        },
        errors: loadErrors, isLoading,
    } = useLearningContent(idToUse);

    // Model (LearningContent)
    const { model, change, changes } = useChanges(storeModel, isCreate ? { ...learningContentDefaultValues(), ...(onCreateDefaultValues ? onCreateDefaultValues() : {}) } : undefined);
    const [savePodcast, { errors: saveErrors }] = useSaveLearningContentMutation();

    // OrganisationalRoleLinks
    const {
        data: {
            items: organisationalRoleLinks,
        }, isLoading: isOrganisationalRoleLinksLoading, errors: organisationalRoleLinksErrors
    } = useOrganisationalRoleLinksByTargetId({ pageSize: undefined }, model?.id);

    // Rolelink manager for attaching to roles
    const organisationalRoleLinkManager = useChangesArray<OrganisationalRoleLink, string>(organisationalRoleLinks, item => item.id);
    const [saveOrganisationalRoleLink, { errors: saveOrganisationalRoleLinkErrors, }] = useSaveOrganisationalRoleLinkMutation();
    const [removeOrganisationalRoleLink, { errors: removeOrganisationalRoleLinkErrors, }] = useDeleteOrganisationalRoleLinkMutation();

    const isRoleSelected = (roleId: string) => {
        return !!organisationalRoleLinkManager.model.find(it => it.organisationalRoleId === roleId &&
            it.organisationalRoleLinkType === OrganisationalRoleLinkType.LearningForRole);
    }

    const setRoleSelected = (roleId: string) => {
        const existing = organisationalRoleLinkManager.model.find(it => it.organisationalRoleId === roleId &&
            it.organisationalRoleLinkType === OrganisationalRoleLinkType.LearningForRole);
        if (existing) {
            organisationalRoleLinkManager.removeFor(existing.id);
        }
        else {
            organisationalRoleLinkManager.addFor({ ...organisationalRoleLinkDefaultValues(), targetId: model.id, targetType: isAdmin ? model.learningContentType : isTrust ? "Trust" + model.learningContentType : "School" + model.learningContentType, organisationalRoleId: roleId, organisationalRoleLinkType: OrganisationalRoleLinkType.LearningForRole });
        }
    }

    // Load the organisationProfile from the subscription so we can fetch the roles to populate our tag selector
    const {
        data: {
            model: organisationProfile,
        }, isLoading: isOrganisationProfileLoading, errors: organisationProfileErrors
    } = useOrganisationProfileFromSubscription(subscription ?? {});

    // Load the RoleInOrganisationProfileLinks, so we know what roles we can attach to
    const {
        data: {
            items: roleInOrganisationProfileLinks,
        }, isLoading: isRoleInOrganisationProfileLinksLoading, errors: roleInOrganisationProfileLinksErrors
    } = useOrganisationalRoleLinksByTargetId({ pageSize: undefined }, organisationProfile?.id);

    // Load the roles from the RoleInOrganisationProfileLinks
    const {
        data: {
            items: roles,
        }, isLoading: isRolesLoading, errors: rolesErrors
    } = useOrganisationalRolesArray(roleInOrganisationProfileLinks?.map(it => it.organisationalRoleId));

    // Main model validation.
    const [validate, validationErrors] = useValidatorCallback((validation, fieldsToCheck) => {
        const rules = {
            name: () => !model?.name ? t('editPodcast.errors.name', 'Name cannot be empty') : '',
            publishDate: () => !model.publishDate ? t('editPodcast.errors.publishDate', 'Publish date cannot be empty') : '',
            learningContentType: () => !model.learningContentType ? t('editPodcast.errors.learningContentType', 'Growth resource type cannot be empty') : '',
        };
        validation.checkRules(rules, fieldsToCheck);
    }, [model]);

    // Podcast list thumbnail upload (photo)
    const { data: { model: image }, errors: imageLoadErrors } = useBlobReference(model?.imageBlobReferenceId);
    const [onUploadImage, { errors: imageUploadErrors, isExecuting: isUploadingImage }] = useAsyncCallback(async (files: FileList | null) => {
        if (!files) {
            return;
        }
        let uploadService: BlobUploadService = new BlobUploadService("api/blobs");
        let result = await uploadService.upload(files);

        if (!!result) {
            change({ imageBlobReferenceId: result.id });
        }
    }, [change]);

    // Clear image functionality
    const [clearImage, { isExecuting: isClearingImage }] = useAsyncCallback(async () => {
        change({ imageBlobReferenceId: null });
    }, [change]);


    // Podcast player thumbnail upload (photo)
    const { data: { model: imagePoster }, errors: imagePosterLoadErrors } = useBlobReference(model?.blobReference2Id);
    const [onUploadImagePoster, { errors: imagePosterUploadErrors, isExecuting: isUploadingImagePoster }] = useAsyncCallback(async (files: FileList | null) => {
        if (!files) {
            return;
        }
        let uploadService: BlobUploadService = new BlobUploadService("api/blobs");
        let result = await uploadService.upload(files);

        if (!!result) {
            change({ blobReference2Id: result.id });
        }
    }, [change]);


    // Clear imagePoster functionality
    const [clearImagePoster, { isExecuting: isClearingImagePoster }] = useAsyncCallback(async () => {
        change({ blobReference2Id: null });
    }, [change]);

    const [isAudioVideoDropdownOpen, toggleAudioVideoDropdown] = useToggleState();


    // Video upload
    const { data: { model: video }, errors: videoLoadErrors } = useBlobReference(model?.blobReferenceId);
    const [onUploadVideo, { errors: videoUploadErrors, isExecuting: isUploadingVideo }] = useAsyncCallback(async (files: FileList | null) => {
        if (!files) {
            return;
        }
        let uploadService: BlobUploadService = new BlobUploadService("api/blobs");
        let result = await uploadService.upload(files);

        if (!!result) {
            change({ blobReferenceId: result.id });
        }
    }, [change]);
    // Clear video
    const [clearVideo, { isExecuting: isClearingVideo }] = useAsyncCallback(async () => {
        change({ blobReferenceId: null });
    }, [change]);

    // Audio upload
    const { data: { model: audio }, errors: audioLoadErrors } = useBlobReference(model?.blobReferenceId);
    const [onUploadAudio, { errors: audioUploadErrors, isExecuting: isUploadingAudio }] = useAsyncCallback(async (files: FileList | null) => {
        if (!files) {
            return;
        }
        let uploadService: BlobUploadService = new BlobUploadService("api/blobs");
        let result = await uploadService.upload(files);

        if (!!result) {
            change({ blobReferenceId: result.id });
        }
    }, [change]);
    // Clear video
    const [clearAudio, { isExecuting: isClearingAudio }] = useAsyncCallback(async () => {
        change({ blobReferenceId: null });
    }, [change]);


    React.useEffect(() => {

        if (!isAdmin) {
            if (!isTrust) {
                // If we are not on admin and are not in a trust then we must be in a school
                if (!!subscription?.schoolId) {
                    change({ schoolId: subscription.schoolId })
                }

            } else {
                // We are on trust overview
                if (!!subscription?.trustId) {
                    change({ trustId: subscription.trustId })
                }
            }
        }
        // Dont set anything if we are on admin

    }, [subscription, change, isAdmin, isTrust]);

    // Save everything
    const [saveForm, { isExecuting: isSaving, errors: saveFormErrors }] = useAsyncCallback(async () => {
        if (!model) {
            return;
        }

        if (!validate()) {
            return;
        }

        // Save the main model
        await savePodcast(model.id, { ...changes }, isCreate ?? false);

        // Save the role links
        for (const organisationalRoleLink of organisationalRoleLinkManager.added) { saveOrganisationalRoleLink(organisationalRoleLink.id, organisationalRoleLink, true); }
        for (const organisationalRoleLink of organisationalRoleLinkManager.updated) { saveOrganisationalRoleLink(organisationalRoleLink.id, organisationalRoleLink, false); }
        for (const organisationalRoleLink of organisationalRoleLinkManager.removed) { removeOrganisationalRoleLink(organisationalRoleLink.id); }
        organisationalRoleLinkManager.markAsSaved();

        // Go back to previous screen.
        navigate(-1);
    }, [validate, savePodcast, model, changes, isCreate, navigate,
        organisationalRoleLinkManager, saveOrganisationalRoleLink, removeOrganisationalRoleLink
    ]);

    
    return (
        <>
            <ConditionalFragment showIf={!isView}>
                <Banner>
                    <Row>
                        <Col>
                            <h1>
                                {
                                    isCreate ? t('editPodcast.createHeading.default', 'Add podcast')
                                        : t('editPodcast.editHeading.default', 'Edit podcast')
                                }
                            </h1>
                            <h3>{model?.name}</h3>
                        </Col>
                        <ConditionalFragment showIf={isLoading}>
                            <Col xs="auto">
                                <LoadingIndicator size="sm" />
                            </Col>
                        </ConditionalFragment>
                    </Row>
                </Banner>
            </ConditionalFragment>
            <MainContainer>
                <AlertOnErrors errors={[
                    loadErrors,
                    saveFormErrors, saveErrors,
                    imageLoadErrors, imagePosterLoadErrors,
                    imageUploadErrors, imagePosterUploadErrors,
                    videoLoadErrors, videoUploadErrors,
                    audioLoadErrors, audioUploadErrors
                ]} />

                <Form onSubmit={e => { e.preventDefault(); saveForm(); }}>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label htmlFor="name">{t('editPodcast.name.label', 'Name')}</Label>
                                <ValidatedInput disabled={isView} name="name" type="text" value={model?.name ?? ''} onChange={e => change({ name: e.currentTarget.value })} onBlur={e => validate('name')} validationErrors={validationErrors['name']} />
                            </FormGroup>
                            <FormGroup>
                                <Label htmlFor="publishDate">{t('editPodcast.publishDate.label', 'Publish date')}</Label>
                                <ValidatedISODateTimeInput disabled={isView} name="publishDate" type="date" value={model?.publishDate ?? ''} onChange={e => change({ publishDate: e.currentTarget.value })} onBlur={e => validate('publishDate')} validationErrors={validationErrors['publishDate']} />
                            </FormGroup>
                            <FormGroup>
                                <Label htmlFor="cpdHours">{t('editPodcast.cpdHours.label', 'CPD minutes')}</Label>
                                <Input disabled={isView} name="cpdHours" type="number" value={model?.cPDMinutes ?? 0} onChange={e => change({ cPDMinutes: e.currentTarget.valueAsNumber })} />
                            </FormGroup>

                            <FormGroup>
                                <Label htmlFor="learningCostType">{t('editBook.learningCostType.label', 'Cost type')}</Label>
                                <ValidatedInput disabled={isView} name="learningCostType" type="select" value={model.learningCostType ?? ''} onChange={(e) => change({ learningCostType: e.currentTarget.value })} onBlur={e => validate('learningCostType')} validationErrors={validationErrors['learningCostType']}>
                                    <option value="">{t('editBook.learningCostType.pleaseSelect', '(Please select a cost type)')}</option>
                                    {
                                        getLearningCostTypes().map(item => (
                                            <option key={item} value={item}>{learningCostTypeDisplayName(item, t)}</option>
                                        ))
                                    }
                                </ValidatedInput>
                            </FormGroup>
                        </Col>

                        <Col>
                            <FormGroup>
                                <Label htmlFor="imageBlobReferenceId">{t('editPodcast.imageBlobReferenceId', 'List view thumbnail')}</Label>
                                <UploadedImagePreview size="lg" src={image?.url ?? ''} />
                                <ConditionalFragment showIf={!isView}>
                                    <Row>
                                        <Col>
                                            <ButtonGroup>
                                                <FileUploadButton
                                                    color={'primary'}
                                                    isExecuting={isUploadingImage}
                                                    executingChildren={<><Spinner size="sm" /> {t('common.uploading', 'Uploading...')}</>}
                                                    onUpload={onUploadImage}
                                                    outline={false}>
                                                    <FontAwesomeIcon icon="image" />
                                                    <> </>
                                                    {t('editPodcast.uploadButtonText', 'Upload an image')}
                                                </FileUploadButton>
                                                <ButtonAsync color="primary"
                                                    outline
                                                    isExecuting={isClearingImage}
                                                    type="button"
                                                    onClick={clearImage}
                                                    executingChildren={<><Spinner size="sm" /> {t('editPodcast.clearingImage', 'Clearing image...')}</>}>
                                                    {t('editPodcast.clearImageButton', 'Clear image')}
                                                </ButtonAsync>
                                            </ButtonGroup>
                                        </Col>
                                    </Row>
                                </ConditionalFragment>
                            </FormGroup>
                        </Col>
                    </Row>

                    <ConditionalFragment showIf={!isView}>
                    {/*Should probably show this in a different way if we are viewing, but dont want to open to XSS attacks*/}
                        <FormGroup>
                            <Label htmlFor="descriptionHtml">{t('editPodcast.description.label', 'Thumbnail description')}</Label>
                            <HtmlEditor value={model.descriptionHtml ?? ''} onChange={e => change({ descriptionHtml: e })}></HtmlEditor>
                            <FormText>{t('editPodcast.description.formText', 'This text will appear when looking at the podcast in a tiled list, and should be kept to only a few lines long') }</FormText>
                        </FormGroup>
                    </ConditionalFragment>
                    <FormGroup>
                        <Label htmlFor="learningForRole">{t('editPodcast.learningForRole.label', 'Assign roles')}</Label>
                        <OrganisationalRoleTagSelector input={!isView}
                            tags={
                                isView ? roles?.filter(role => isRoleSelected(role.id))
                                    : roles
                            }
                            isSelected={tag => isRoleSelected(tag.id)}
                            toggle={tag => {
                                if (isView) {
                                    return;
                                }
                                setRoleSelected(tag.id)
                            }}
                        />
                    </FormGroup>

                    {/* Upload type */}
                    <Row>
                        <Col>
                            <Col xs="auto">
                                <FormGroup>
                                    <Label htmlFor="podcastLinkType">{t('editPodcast.podcastLinkType.label', 'Growth resource type')}</Label>
                                    <ValidatedInput name="podcastLinkType" type="select" value={model.learningContentType ?? ''} onChange={(e) => change({ learningContentType: e.currentTarget.value })} onBlur={e => validate('learningContentType')} validationErrors={validationErrors['learningContentType']}>
                                        <option value="">{t('editPodcast.podcastLinkType.pleaseSelect', '(Please select a growth resource type)')}</option>
                                        {
                                            getLearningContentTypes("Podcast").map(item => (
                                                <option key={item} value={item}>{learningContentTypeDisplayName(item, t)}</option>
                                            ))
                                        }
                                    </ValidatedInput>
                                </FormGroup>
                            </Col>
                        </Col>
                    </Row>

                    <ConditionalFragment showIf={model.learningContentType === LearningContentType.PodcastFileVideo}>
                        <FormGroup>
                            <Label htmlFor="blobReferenceId">{t('editPodcast.blobReferenceId.videoLabel', 'Video')}</Label>
                            <UploadedVideoPreview src={video?.url ?? ''} poster={imagePoster?.url ?? ''} style={{ width: "100%", height: "100%", }} />
                            <ConditionalFragment showIf={!isView}>
                                <ButtonGroup>
                                    <FileUploadButton
                                        color={'primary'}
                                        isExecuting={isUploadingVideo}
                                        executingChildren={<><Spinner size="sm" />{t('common.uploading', 'Uploading...')}</>}
                                        onUpload={onUploadVideo}
                                        outline={false}>
                                        {t('editPodcast.uploadVideoButtonText', 'Upload a video')}
                                    </FileUploadButton>
                                    <FileUploadButton
                                        color={'primary'}
                                        isExecuting={isUploadingImagePoster}
                                        executingChildren={<><Spinner size="sm" />{t('common.uploading', 'Uploading...')}</>}
                                        onUpload={onUploadImagePoster}
                                        outline>
                                        {t('editPodcast.uploadVideoPosterButtonText', 'Upload video poster')}
                                    </FileUploadButton>
                                    <Dropdown isOpen={isAudioVideoDropdownOpen} toggle={() => toggleAudioVideoDropdown()}>
                                        <DropdownToggle color="primary" outline caret>
                                            <span className="sr-only">
                                                {t('common.more', 'More')}
                                            </span>
                                        </DropdownToggle>
                                        <DropdownMenu end>
                                            <DropdownItem className="text-danger" onClick={clearVideo}>
                                                {t('editPodcast.clearVideo', 'Clear video')}
                                                <ConditionalFragment showIf={isClearingVideo}>
                                                    <LoadingIndicator />
                                                </ConditionalFragment>
                                            </DropdownItem>
                                            <DropdownItem className="text-danger" onClick={clearImagePoster}>
                                                {t('editPodcast.clearThumbnail', 'Clear poster')}
                                                <ConditionalFragment showIf={isClearingImagePoster}>
                                                    <LoadingIndicator />
                                                </ConditionalFragment>
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                </ButtonGroup>
                            </ConditionalFragment>
                        </FormGroup>
                    </ConditionalFragment>

                    <ConditionalFragment showIf={model.learningContentType === LearningContentType.PodcastFileAudio}>
                        <FormGroup>
                            <Label htmlFor="blobReferenceId">{t('editPodcast.blobReferenceId.audioLabel', 'Audio')}</Label>
                            <Row>
                                <ConditionalFragment showIf={!isView}>
                                    <FormText>{t('editPodcast.audioFormText', 'Supported file types: MP3, WAV, OGG')}</FormText>
                                </ConditionalFragment>
                                <audio controls>
                                    <source src={audio?.url} type={audio?.mimeType}/>
                                </audio>
                            </Row>
                            <ConditionalFragment showIf={!isView}>
                                <ButtonGroup>
                                    <FileUploadButton
                                        color={'primary'}
                                        isExecuting={isUploadingAudio}
                                        executingChildren={<><Spinner size="sm" />{t('common.uploading', 'Uploading...')}</>}
                                        onUpload={onUploadAudio}
                                        outline={false}>
                                        {t('editPodcast.uploadAudioButtonText', 'Upload audio')}
                                    </FileUploadButton>
                                    <FileUploadButton
                                        color={'primary'}
                                        isExecuting={isUploadingImagePoster}
                                        executingChildren={<><Spinner size="sm" />{t('common.uploading', 'Uploading...')}</>}
                                        onUpload={onUploadImagePoster}
                                        outline>
                                        {t('editPodcast.uploadAudioPosterButtonText', 'Upload audio poster')}
                                    </FileUploadButton>
                                    <Dropdown isOpen={isAudioVideoDropdownOpen} toggle={() => toggleAudioVideoDropdown()}>
                                        <DropdownToggle color="primary" outline caret>
                                            <span className="sr-only">
                                                {t('common.more', 'More')}
                                            </span>
                                        </DropdownToggle>
                                        <DropdownMenu end>
                                            <DropdownItem className="text-danger" onClick={clearAudio}>
                                                {t('editPodcast.clearAudio', 'Clear audio')}
                                                <ConditionalFragment showIf={isClearingAudio}>
                                                    <LoadingIndicator />
                                                </ConditionalFragment>
                                            </DropdownItem>
                                            <DropdownItem className="text-danger" onClick={clearImagePoster}>
                                                {t('editPodcast.clearThumbnail', 'Clear poster')}
                                                <ConditionalFragment showIf={isClearingImagePoster}>
                                                    <LoadingIndicator />
                                                </ConditionalFragment>
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                </ButtonGroup>
                            </ConditionalFragment>
                        </FormGroup>
                    </ConditionalFragment>

                    <ConditionalFragment showIf={model.learningContentType === LearningContentType.PodcastLink}>
                        <FormGroup>
                            <Label htmlFor="url"><h4>{t('editPodcast.linkToPodcast.label', 'Link to podcast')}</h4></Label>
                            <Input disabled={isView} name="url" type="text" value={model?.url ?? ''} onChange={e => change({ url: e.currentTarget.value})} />
                        </FormGroup>
                    </ConditionalFragment>

                    <ConditionalFragment showIf={model.learningContentType === LearningContentType.PodcastLinkYouTube}>
                        <FormGroup>
                            <Row>
                                <Label htmlFor="url"><h4>{t('editPodcast.youtubeEmbedLink.label', 'YouTube embed link')}</h4></Label>
                            </Row>
                            <Input disabled={isView} name="url" type="text" value={model?.url ?? ''} onChange={e => change({ url: e.currentTarget.value})} />
                            <iframe width="560" height="315" src={generateYoutubeEmbedLink(model?.url)}
                                title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; 
                                encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                        </FormGroup>
                    </ConditionalFragment>

                    <ConditionalFragment showIf={!isView}>
                    {/*Should probably show this in a different way if we are viewing, but dont want to open to XSS attacks*/}
                        <FormGroup>
                            <Label>{t('editPodcast.contentHtml.label', 'Description')}</Label>
                            <HtmlEditor value={model.contentHtml ?? ''} onChange={e => change({ contentHtml: e })}></HtmlEditor>
                            <FormText>{t('editPodcast.contentHtml.formText', 'This text will appear beneath the podcast when it is played')}</FormText>
                        </FormGroup>
                    

                    
                        <FormButtons>
                            <ConditionalFragment showIf={!isLoading}>
                                <ButtonAsync color="primary" isExecuting={isSaving} onClick={() => saveForm()}
                                    executingChildren={<><Spinner size="sm" /> {t('common.saving', 'Saving...')}</>}>
                                    <FontAwesomeIcon icon="save" />
                                    <> </>
                                    {t('common.save', 'Save')}
                                </ButtonAsync>
                            </ConditionalFragment>

                            <Button type="button" color="primary" outline onClick={e => navigate(-1)}>
                                {t('common.cancel', 'Cancel')}
                            </Button>
                        </FormButtons>
                    </ConditionalFragment>
                </Form>
            </MainContainer>
        </>
    );
}
