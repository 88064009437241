import { useMemo } from "react";
import { getRoleGroupsForUserLevel, RoleGroup } from "../../../../api/main/models/constants/RoleGroup";
import { EditUser } from "../../../users/EditUser";

/**
 * Container for editing a user at the trust level.
 * @returns
 */
export const TrustEditUserContainer = () => {

    const myRoleGroups = useMemo(() => getRoleGroupsForUserLevel("Trust"), []);
    const defaultRoleGroup = myRoleGroups.length ? myRoleGroups[0] : RoleGroup.SchoolTrustUser;

    return (
        <EditUser isTrust={true}
            filterRoleGroups={groups => groups.filter(group => !!myRoleGroups.find(myGroupName => group.name === myGroupName))}
            defaultRoleGroup={defaultRoleGroup}
        />
    );
};