import gql from 'graphql-tag';
import { Guid } from 'guid-string';
import { TrustFieldsFragment } from '../generated/graphql';

export const trustFieldsFragment = gql`
    fragment trustFields on Trust {
        id
        name
        organisationProfileId
        descriptionHtml
        imageBlobReferenceId
        archived
        subscriptionOwnerFirstName
        subscriptionOwnerLastName
        subscriptionOwnerEmail
        subscriptionOwnerId
    }
`;

export type Trust = Omit<TrustFieldsFragment, '__typename' | 'trust'>;

/**
 * Default values.
 */
export const trustDefaultValues = (): Partial<Trust> => ({
    id: Guid.newGuid(),
    name: '',
    organisationProfileId: undefined,
    descriptionHtml: '',
    imageBlobReferenceId: null,
    archived: false,
    subscriptionOwnerFirstName: '',
    subscriptionOwnerLastName: '',
    subscriptionOwnerEmail: '',
    subscriptionOwnerId: '',
});