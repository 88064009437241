import { TFunction } from "i18next";


export enum AnalyticEventType {
    LearningContentClick = "LearningContentClick",
    ExternalLinkClick = "ExternalLinkClick"
}

export function getAnalyticEventTypes(prefix: string = ''): Array<AnalyticEventType> {
    const ret = Object.keys(AnalyticEventType)
        .filter(item => item.startsWith(prefix))
        .map(key => key as AnalyticEventType);
    return ret;
}

export function analyticEventTypeDisplayName(type: AnalyticEventType, t: TFunction): string {
    switch (type) {
        case AnalyticEventType.LearningContentClick: return t('analyticEventType.LearningContentClick', 'Growth resource click');
        case AnalyticEventType.ExternalLinkClick: return t('analyticEventType.ExternalLinkClick', 'External link click');
    }
}