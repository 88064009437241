import { useMemo } from "react";
import { getRoleGroupsForUserLevel, RoleGroup } from "../../../../api/main/models/constants/RoleGroup";
import { CreateUser } from "../../../users/EditUser";

/**
 * Container for creating a user at the school level.
 * @returns
 */
export const SchoolCreateUserContainer = () =>
{
    const myRoleGroups = useMemo(() => getRoleGroupsForUserLevel("School"), []);
    const defaultRoleGroup = myRoleGroups.length ? myRoleGroups[0] : RoleGroup.SchoolTrustUser;
    return (
        <CreateUser isSchool={true}
            filterRoleGroups={groups => groups.filter(group => !!myRoleGroups.find(myGroupName => group.name === myGroupName))} //filter role groups so that schools can't give themselves trust/admin perms
            defaultRoleGroup={defaultRoleGroup}
        />
    );
}