import React, { useRef, useEffect, ReactNode, useCallback, RefObject } from "react";
import './horizontalScroll.scss';

interface HorizontalScrollProps {
    children: ReactNode;
}

/*
* This component renders a container that can be scrolled horizontally
*/
export const HorizontalScroll = (props: HorizontalScrollProps) => {
    // Get the content elements from props
    const { children } = props;

    // Create a ref for the container element
    const containerRef = useRef<HTMLDivElement>(null);
    useHorizontalScrollEvents(containerRef);

    return (
        <div ref={containerRef} className="horizontal-scroll">
            {children}
        </div>
    );
};

/*
* This hook attaches a wheel event listener to the container element
*/
export function useHorizontalScrollEvents(containerRef: RefObject<HTMLDivElement>) {
    // Define the function to handle the wheel event
    const handleWheel = useCallback((e: WheelEvent) => {
        // Prevent the default behavior (vertical scroll)
        e.preventDefault();

        // Get the amount of scroll
        const delta = e.deltaY;

        // Scroll the container horizontally by the same amount
        if (!!containerRef) {
            containerRef.current!.scrollLeft += delta;
        }
    }, [containerRef]);

    // Attach the event listener to the container element when the component mounts
    useEffect(() => {
        const container = containerRef.current;
        if (!container) return;

        container.addEventListener("wheel", handleWheel);

        // Remove the event listener when the component unmounts
        return () => {
            container.removeEventListener("wheel", handleWheel);
        };
    }, [handleWheel, containerRef]);
}

