import * as React from 'react';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useUpdateUserLearningEventMutation } from './useUpdateUserLearningEventMutation';
import { UserLearningEvent } from '../models/UserLearningEvent';
import { useCreateUserLearningEventMutation } from './useCreateUserLearningEventMutation';
import { UserLearningEventCreateInput, UserLearningEventUpdateInput } from '../generated/graphql';

/**
 * Get a callback to save a UserLearningEvent in the store using either a create or update.
 */
export function useSaveUserLearningEventMutation(options: SaveInStoreOptions<UserLearningEvent, string> = {}) {
    const [_create, createStatus] = useCreateUserLearningEventMutation();
    const create = React.useCallback((model: Partial<UserLearningEvent>) => _create(model as UserLearningEventCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateUserLearningEventMutation();
    const update = React.useCallback((id: string, changes: Partial<UserLearningEvent>) => _update(id, changes as UserLearningEventUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
