import * as React from 'react';
import { atom, useRecoilState } from 'recoil';
import { persistAtom } from '../../configure/recoilPersistConfig';
import { Subscription } from './useCurrentUserOrEmulatedSubscription';

export const subscriptionEmulationState = atom<Subscription | null | undefined>({
    key: 'subscriptionEmulationState',
    default: null,
    effects_UNSTABLE: [persistAtom],
});

/**
 * Hook that provides access to the subscription emulation functionality.
 * 
 * For code that just wants to get the current user's subscription (overriden by the emulated subscription if emulation is being used) you
 * should use useCurrentUserOrEmulatedSubscription().
 */
export function useSubscriptionEmulation(): [Subscription | null | undefined, React.Dispatch<React.SetStateAction<Subscription | null | undefined>>] {
    const [value, setValue] = useRecoilState(subscriptionEmulationState);
    return [value, setValue];
}