import gql from "graphql-tag";
import { Guid } from "guid-string";
import { AnalyticEventFieldsFragment } from "../generated/graphql";


export const analyticEventFieldsFragment = gql`
    fragment analyticEventFields on AnalyticEvent {
        id
        userId
        eventDate
        eventType
        learningContentId
        schoolId
        trustId
        url
        archived
    }
`;

export type AnalyticEvent = Omit<AnalyticEventFieldsFragment, "__typename" | "analyticEvent">;

export const analyticEventDefaultValues = (): Partial<AnalyticEvent> => ({
    id: Guid.newGuid(),
    userId: "",
    eventDate: null,
    eventType: "",
    learningContentId: null,
    schoolId: null,
    trustId: null,
    url: "",
    archived: false
})