import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Guid } from "guid-string";
import { useMemo } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAsyncCallback } from "react-use-async-callback";
import { Button, Col, Row } from "reactstrap";
import { useBlobReference } from "../../api/main/blobReferences/useBlobReference";
import { useCopyOrganisationProfileMutation } from "../../api/main/organisationProfiles/useCopyOrganisationProfileMutation";
import { useDeleteOrganisationProfileMutation } from "../../api/main/organisationProfiles/useDeleteOrganisationProfileMutation";
import { useOrganisationProfile } from "../../api/main/organisationProfiles/useOrganisationProfile";
import { useSchool } from "../../api/main/schools/useSchool";
import { useTrust } from "../../api/main/trusts/useTrust";
import { useCurrentUserOrEmulatedSubscription } from "../../globalState/subscriptions/useCurrentUserOrEmulatedSubscription";
import { AlertOnErrors } from "../../shared/alertOnErrors";
import { HtmlDisplay } from "../../shared/htmlEditor";
import { Banner } from "../shared/banner/Banner";
import { LoadingIndicator } from "../shared/loadingIndicator/LoadingIndicator";
import { MainContainer } from "../shared/mainContainer/MainContainer";

export interface CustomiseOrganisationProfileProps {
    isSchool?: boolean;
    isTrust?: boolean;
}


export const CustomiseOrganisationProfile = (props: CustomiseOrganisationProfileProps) =>
{
    const { t } = useTranslation();
    const isSchool = props.isSchool ?? false;
    const isTrust = props.isTrust ?? false;
    const profile = useCurrentUserOrEmulatedSubscription();
    const navigate = useNavigate();

    // If we are a school, we should keep the school handy so that when we copy and edit a profile, we can set the schoolId to the current school.
    // This is also good for knowing which organisation profile is our current one.
    const {
        data: {
            model: school,
        }, isLoading: schoolLoading, errors: schoolErrors
    } = useSchool(profile?.schoolId ?? Guid.empty);

    // If we are a trust, we should keep the trust handy so that when we copy and edit a profile, we can set the trustId to the current trust.
    // This is also good for knowing which organisation profile is our current one.
    const {
        data: {
            model: trust,
        }, isLoading: trustLoading, errors: trustErrors
    } = useTrust(profile?.trustId ?? Guid.empty);

    // If we are a school/trust, get our current organisationProfile so we can keep it seperate from the others.
    const currentOrganisationProfileId = useMemo(() => {
        if (!isSchool && !isTrust) {
            return null;
        }
        if (isSchool) {
            return school?.organisationProfileId;
        }
        if (isTrust) {
            return trust?.organisationProfileId;
        }
    }, [isSchool, isTrust, school, trust]);

    // Load the current organisation profile.
    const {
        data: {
            model: currentOrganisationProfile,
        }, isLoading: currentOrganisationProfileLoading, errors: currentOrganisationProfileErrors
    } = useOrganisationProfile(currentOrganisationProfileId ?? '');

    //functionality for copying an organisation profile.
    const [copyOrganisationProfile, { errors: copyErrors }] = useCopyOrganisationProfileMutation();

    // Get the profile image for the current organisation profile if we have one.
    const {
        data: {
            model: profileImage,
        }, isLoading: profileImageLoading, errors: profileImageErrors
    } = useBlobReference(currentOrganisationProfile?.imageBlobReferenceId ?? Guid.empty);

    // Functionality for deleting the current organisation profile.
    const [removeCurrentOrgProfile, { errors: removeErrors }] = useDeleteOrganisationProfileMutation();

    // When we press customise, if it's a built-in profile, we need to copy it and then edit it.
    // If it's a custom profile, we can just edit it.
    const [customise] = useAsyncCallback(async () => {
        if (!currentOrganisationProfile) {
            return;
        }
        if ((currentOrganisationProfile?.schoolId !== Guid.empty && !!currentOrganisationProfile.schoolId) || (currentOrganisationProfile?.trustId !== Guid.empty && !!currentOrganisationProfile.trustId)) {
            // We have a custom profile, so we can just edit it.
            return navigate(`${currentOrganisationProfile?.id}`);
        }
        else if (isSchool) {
            // We have a built-in profile, so we need to copy it and then edit it.
            var copiedProfile = await copyOrganisationProfile(currentOrganisationProfile?.id ?? '', school?.id ?? '', null);
            console.log(copiedProfile);
            return navigate(`${copiedProfile?.id}`);
            
        }
        else if (isTrust) {
            // We have a built-in profile, so we need to copy it and then edit it.
            var copiedProfile = await copyOrganisationProfile(currentOrganisationProfile?.id ?? '', null, trust?.id ?? '');
            return navigate(`${copiedProfile?.id}`);
        }
    }, [currentOrganisationProfile]);

    const loading = schoolLoading || trustLoading || currentOrganisationProfileLoading || profileImageLoading;

    // Render the UI.
    return (
        <>
            <Banner fluid>
                <h1>{t('organisationProfilesList.currentSchoolProfileTitle', `Current organisation profile for ${isSchool ? school?.name : isTrust ? trust?.name : ''}`)}</h1>
            </Banner>
            <MainContainer fluid>
                <AlertOnErrors
                    errors={[
                        schoolErrors,
                        trustErrors,
                        currentOrganisationProfileErrors,
                        profileImageErrors,
                        copyErrors,
                        removeErrors,       
                    ]}
                />
                <Row>
                    <Col>
                        <h4>{t('organisationProfilesList.currentSchoolProfileName', `${currentOrganisationProfile?.name}`)}</h4>
                        <img style={{ maxHeight: '200px', width: 'auto', maxWidth: '80%' }} src={profileImage?.url} alt={profileImage?.filename} />
                    </Col>
                    <ConditionalFragment showIf={loading}>
                        <Col xs="auto">
                            <LoadingIndicator size="sm" />
                        </Col>
                    </ConditionalFragment>
                </Row>
                <Row>
                    <Col>
                        <HtmlDisplay html={!!currentOrganisationProfile && currentOrganisationProfile.descriptionHtml.length > 0 ? currentOrganisationProfile.descriptionHtml : 'This profile has no description.'} />
                        {
                            currentOrganisationProfile?.schoolId ?
                                (
                                    <p>{t('organisationProfilesList.currentSchoolProfileCustom', 'This is a custom profile unique to your school.')}</p>
                                )
                                : currentOrganisationProfile?.trustId ?
                                    (
                                        <p>{t('organisationProfilesList.currentSchoolProfileCustom', 'This is a custom profile unique to your trust.')}</p>
                                    )
                                    :
                                    (
                                        <p>{t('organisationProfilesList.currentSchoolProfileDefault', 'This is a built-in profile.')}</p>
                                    )
                        }
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button onClick={e => customise()}>
                            <FontAwesomeIcon icon="edit" />
                            <> {t('organisationProfilesList.currentSchoolProfileCustomise', 'Customise')}</>
                            
                        </Button>
                    </Col>
                </Row>
            </MainContainer>
        </>
    );
};