import { gql, useLazyQuery } from "@apollo/client";
import React from "react";
import { AsyncLoadPagedOptions, AsyncLoadPagedResult } from "../../../shared/abstractStore";
import { useFetchMoreCallback } from "../../../shared/useFetchMoreCallback";
import { BlobReferencesArrayQuery, BlobReferencesArrayQueryVariables, Scalars } from "../generated/graphql";
import { blobReferenceFieldsFragment } from "../models/BlobReference";


export function useBlobReferencesArray(ids: Array<Scalars['ID']> | Scalars['ID'], options: AsyncLoadPagedOptions = {}): AsyncLoadPagedResult<BlobReferencesArrayQuery> {
    // Query data we need from the api.
    const [load, { data, loading, error, refetch, fetchMore: fetchMoreFromStore }] = useLazyQuery<BlobReferencesArrayQuery, BlobReferencesArrayQueryVariables>(
        gql`
        query blobReferencesArrayQuery ($ids: [ID!], $offset: Int, $first: Int) {
            items: blobReferences (ids: $ids, offset: $offset, first: $first) {
                ...blobReferenceFields
            }
        }

        ${blobReferenceFieldsFragment}
        `,
        {

            variables: {
                ids: ids,
                offset: options.startOffset,
                first: options.pageSize
            },
            fetchPolicy: options.fetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }

    );

    //Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load])

    // Get the functions used to manage paging
    const { fetchMore, hasMore: _hasMore } = useFetchMoreCallback<BlobReferencesArrayQuery>(
        fetchMoreFromStore,
        (options?.startOffset ?? 0) + (data?.items?.length ?? 0),
        (prevResults, newResults) => ({
            ...prevResults,
            ...newResults,

            items: [...(prevResults?.items ?? []), ...(newResults?.items ?? [])]
        }),
        (newResults) => newResults?.items?.length
    );
    const hasMore = React.useCallback(() => !options.pageSize ? false : _hasMore(), [options.pageSize, _hasMore]);

    // Return the results
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error,
        fetchMore: fetchMore,
        hasMore: hasMore,
    }), [data, refresh, loading, error, fetchMore, hasMore]);
}