import * as React from 'react';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useUpdateSchoolMutation } from './useUpdateSchoolMutation';
import { School } from '../models/School';
import { useCreateSchoolMutation } from './useCreateSchoolMutation';
import { SchoolCreateInput, SchoolUpdateInput } from '../generated/graphql';

/**
 * Get a callback to save a School in the store using either a create or update.
 */
export function useSaveSchoolMutation(options: SaveInStoreOptions<School, string> = {}) {
    const [_create, createStatus] = useCreateSchoolMutation();
    const create = React.useCallback((model: Partial<School>) => _create(model as SchoolCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateSchoolMutation();
    const update = React.useCallback((id: string, changes: Partial<School>) => _update(id, changes as SchoolUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
