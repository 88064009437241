import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAsyncCallback } from "react-use-async-callback";
import { Button, Col, Row, Spinner } from "reactstrap";
import { BlobUploadService } from "../../../api/main/blobReferences/BlobUploadService";
import { ImportUsersMutation } from "../../../api/main/generated/graphql";
import { RoleGroup } from "../../../api/main/models/constants/RoleGroup";
import { useImportUsersCallback } from "../../../api/main/users/useImportUsersCallback";
import { useSubscriptionEmulation } from "../../../globalState/subscriptions/useSubscriptionEmulation";
import { AlertOnErrors } from "../../../shared/alertOnErrors";
import { Banner } from "../../shared/banner/Banner";
import { FileUploadButton } from "../../shared/fileUploadButton/FileUploadButton";
import { FormButtons } from "../../shared/formButtons/FormButtons";
import { MainContainer } from "../../shared/mainContainer/MainContainer";

export interface ImportUsersBaseProps
{
    isSchool: boolean,
}

/**
 * Import users.
 */
export const ImportUsersBase = (props: ImportUsersBaseProps) => {
    const {
        isSchool,
    } = props;

    const { t } = useTranslation();

    const navigate = useNavigate();

    const [currentEmulatedSubscription] = useSubscriptionEmulation();

    // Import users from a blob.
    const [importUsers, { errors: importUsersErrors }] = useImportUsersCallback();

    // Results from an import.
    const [importResults, setImportResults] = useState<ImportUsersMutation['importUsers'] | undefined > (undefined);

    // Perform the actual import.
    const [performImport, { isExecuting: isImporting, errors: performImportErrors }] = useAsyncCallback(async(files: FileList | null) => {
        if (!files) {
            return;
        }

        var currentSchoolId = '';
        var currentTrustId = '';


        // Only want to get the school Id if we are from the school page. 
        //(if we are in a trust and managing a school we will still have a school Id, but that doesnt mean the imported users are for that school)
        if (isSchool && !!currentEmulatedSubscription?.schoolId) {
            currentSchoolId = currentEmulatedSubscription.schoolId;
        }

        // Shouldnt be able to get to this screen without a trustId, but we want to handle the null | undefined cases
        if (!!currentEmulatedSubscription?.trustId) {
            currentTrustId = currentEmulatedSubscription.trustId
        }

        // Upload the file.
        let uploadService: BlobUploadService = new BlobUploadService("/api/blobs");
        let importFile = await uploadService.upload(files);

        if (!importFile) {
            return;
        }

        // Import from the upload.
        const results = await importUsers({  blobId: importFile.id, roleGroup: RoleGroup.SchoolTrustUser, schoolId: currentSchoolId, trustId: currentTrustId });

        setImportResults(results);
    }, [setImportResults, currentEmulatedSubscription, isSchool]);

    return (
        <>
            <Banner>

                <Row>

                    <Col>

                        <h1>{ t('importUsersBase.createHeading', 'Import users')}</h1>

                    </Col>

                </Row>

            </Banner>

            <MainContainer>
            
                <AlertOnErrors errors ={ [importUsersErrors, performImportErrors]}/>

                <ConditionalFragment showIf ={ !importResults?.length}>
                    <h5>
                        { t('importUsersBase.instructions.p1', 'Users can be imported from a file instead of being set up manually within the app.  The file can be in Excel (.xlsx), CSV (.csv), or tab-delimited (.txt or .tsv) format and must contain the correct columns as outlined below.')}
                    </h5>
                    <p>
                        {t('importUsersBase.instructions.p2.notStrong', 'The file must the following headings and the columns can be in any order.  Other columns will be ignored:')}
                    </p>
                    <ul>
                        <li>{ t('importUsersBase.columns.email', 'Email (Used for login)')}</li>
                        <li>{ t('importUsersBase.columns.firstName', 'First name (optional)')}</li>
                        <li>{ t('importUsersBase.columns.lastName', 'Last name (optional)')}</li>
                        <li>{ t('importUsersBase.columns.password', 'Password (optional - if omitted or blank user will be asked to their password when first accessing the system.)')}</li>
                    </ul>
                    <p>
                        {t('importUsersBase.download.preText', 'The easiest way to get your users in the correct format is to')}
                        <> </>
                        <a href={'/examples/Users.xlsx'}>{t('importUsersBase.instructions.downloadTemplate', 'download this Excel template')}</a>
                        <> </>
                        {t('importUsersBase.download.postText', 'and copy your users into the spreadsheet.')}
                    </p>
                    <p style={{ fontWeight: 'bold' }}>
                        {t('importUsersBase.instructions.p4', 'Users will be sent an email and prompted to set a strong password. This must be completed within 48 hours, or the link will expire and will need to be resent.')}
                    </p>
                </ ConditionalFragment >

                <ConditionalFragment showIf ={ !!importResults?.length}>
                    <h5>
                        { t('importUsersBase.results.summary', '{{count}} users have been imported for you.', { count: importResults?.filter(it => it.successful)?.length ?? 0 })}
                    </h5>

                    {
                        importResults?.map(item => (

                            <div key ={ item.rowNumber} className ={ item.successful ? 'text-success' : 'text-danger'}>

                                < Row >

                                    <ConditionalFragment showIf ={ item.rowNumber !== -1 /* Whole file errors. */}>

                                        <Col xs ={ 12} md = "auto">
                                            { t('importUsersBase.results.rowNumber', 'Row {{rowNumber}}', { rowNumber: item.rowNumber })}
                                        </Col>
                                    </ConditionalFragment>
                                    <Col>
                                        <ConditionalFragment showIf ={ item.successful}>
                                            { t('importUsersBase.results.successful', 'User created for {{email}}', { email: item.user?.email })}
                                        </ConditionalFragment>
                                        <ConditionalFragment showIf ={ !!item.errors.length}>
                                            <ConditionalFragment showIf ={ item.errors.length === 1}>
                                                {
                                                    item.errors.map((error, index) => (

                                                        < div key ={ index}>
                                                            { error}
                                                        </ div >
                                                    ))
                                                }
                                            </ConditionalFragment>
                                            <ConditionalFragment showIf ={ item.errors.length > 1}>
                                                <ul>
                                                    {
                                                        item.errors.map((error, index) => (

                                                            <li key ={ index}>
                                                                { error}
                                                            </li>
                                                        ))
                                                    }
                                                </ul>
                                            </ConditionalFragment>
                                        </ConditionalFragment>
                                    </Col>
                                </Row>
                                <hr/>
                            </div>
                        ))
                    }
                </ConditionalFragment>

                <FormButtons>
                    <ConditionalFragment showIf ={!importResults}>
                        <FileUploadButton onUpload ={ files => performImport(files)}
                            isExecuting ={ isImporting} executingChildren ={<>< Spinner size = "sm" /><> </>{ t('importUsersBase.importing', 'Importing...')}</>}>
                            <FontAwesomeIcon icon = "upload" className = "nav-icon"/>
                            <> </>
                            { t('importUsersBase.import', 'Import file...')}
                        </FileUploadButton>
                        <Button type="button" color="primary" outline onClick={e => navigate(-1) }>
                            {t('common.cancel', 'Cancel')}
                        </Button>
                    </ConditionalFragment>
                    <ConditionalFragment showIf ={ !!importResults}>
                        <Button type="button" color="primary" onClick={e => navigate(-1)}>
                            { t('common.close', 'Close')}
                        </Button>
                    </ConditionalFragment>
                </FormButtons>
            </MainContainer>
        </>
    );
};
