import { LearningContentType } from "../../../api/main/models/constants/LearningContentType";
import { LearningContent } from "../../../api/main/models/LearningContent";
import { getFullUrl } from "../../../utilities/getFullUrl";

export interface GetLearningContentUrlOrBlobProps {
    content: LearningContent | undefined;
}

/*
 * Return the URL or Blob for a LearningContent.
*/
export function getLearningContentUrlOrBlob(content: LearningContent) {
    // Base URLs
    const baseUrl = '/career/learning-content/file';

    if (content) {
        let thumbnail = '';

        if (content.blobReference2Id !== null) {
            thumbnail = `${content.blobReference2Id}`;
        }

        switch (content.learningContentType as LearningContentType) {

            // Book content types
            case LearningContentType.BookFile: return `${baseUrl}/${content.blobReferenceId}/${thumbnail}`;
            case LearningContentType.BookLink: return `${getFullUrl(content.url)}`;

            // Course content types
            case LearningContentType.Course: return `${getFullUrl(content.url)}`;

            // Event content types
            case LearningContentType.Event: return `${getFullUrl(content.url)}`;

            // Podcast content types
            case LearningContentType.PodcastFileAudio: return `${baseUrl}/${content.blobReferenceId}/${thumbnail}`;
            case LearningContentType.PodcastFileVideo: return `${baseUrl}/${content.blobReferenceId}/${thumbnail}`;
            case LearningContentType.PodcastLink: return `${getFullUrl(content.url)}`;
            case LearningContentType.PodcastLinkYouTube: return `${getFullUrl(content.url)}`;

            // Standard content types
            case LearningContentType.StandardFile: return `${baseUrl}/${content.blobReferenceId}/${thumbnail}`;
            case LearningContentType.StandardLink: return `${getFullUrl(content.url)}`;

            // Video content types
            case LearningContentType.VideoFile: return `${baseUrl}/${content.blobReferenceId}/${thumbnail}`;
            case LearningContentType.VideoLink: return `${getFullUrl(content.url)}`;
            case LearningContentType.VideoLinkYouTube: return `${getFullUrl(content.url)}`;
        }
    }
};