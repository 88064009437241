import moment from "moment";
import { useCallback, useMemo } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Row } from "reactstrap";
import { useBlobReferencesArray } from "../../api/main/blobReferences/useBlobReferencesArray";
import { useViewCareerOrganisationalRoleViewModel } from "../../api/main/career/viewModels/useViewCareerOrganisationalRoleViewModel";
import { useLearningContentsFromArray } from "../../api/main/learningContents/viewModels/useLearningContentsFromArray";
import { useLearningContentsFromArraySchoolTrustFilter } from "../../api/main/learningContents/viewModels/useLearningContentsFromArraySchoolTrustFilter";
import { useLearningContentsFromSearch } from "../../api/main/learningContents/viewModels/useLearningContentsFromSearch";
import { OrganisationalRoleLinkType } from "../../api/main/models/constants/OrganisationalRoleLinkType";
import { useCurrentUserOrEmulatedSubscription } from "../../globalState/subscriptions/useCurrentUserOrEmulatedSubscription";
import { AlertOnErrors } from "../../shared/alertOnErrors";
import { generateTrackedUrl } from "../../utilities/generateTrackedUrl";
import { VideoTile } from "../career/VideoTile";
import { HorizontalScroll } from "../shared/horizontalScroll/HorizontalScroll";
import { LoadingIndicator } from "../shared/loadingIndicator/LoadingIndicator";
import { MainContainer } from "../shared/mainContainer/MainContainer";
import { NoResultsFound } from "../shared/noResultsFound/NoResultsFound";
import { getLearningContentUrlOrBlob } from "../shared/Utilities/getLearningContentUrlOrBlob";

export interface SearchResultsGrowListPartProps {
    search: string,
}


export const SearchResultsGrowListPart = (props: SearchResultsGrowListPartProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const subscription = useCurrentUserOrEmulatedSubscription();

    //Load data
    const {
        data: {
            items
        }, isLoading, errors: contentLoadErrors
    } = useLearningContentsFromSearch({ pageSize: undefined }, props.search, subscription?.schoolId, subscription?.trustId);

    const uniqueItems = useMemo(() => items?.filter((item, index, self) => self.findIndex(it => it.name === item.name) === index), [items]);

    const today = moment();
    // filter out items that are not published yet
    const publishedItems = uniqueItems?.filter(it => !today.isBefore(moment(it.publishDate)));

    const publishedItemBlobIds = publishedItems?.filter(it => it.imageBlobReferenceId !== null && it.imageBlobReferenceId !== undefined).map(it => it.imageBlobReferenceId) as string[];

    const {
        data: {
            items: blobs,
        }, isLoading: blobsIsLoading, errors: blobsErrors
    } = useBlobReferencesArray(publishedItemBlobIds ?? []);

    const consumeContent = useCallback((url: string) => {
        // If the URL starts with https:// then we are dealing with an external link
        if (url.startsWith('https://')) {
            // If we don't have a URL to work with do nothing.
            if (!url) { return; }

            // Open in a new window.
            window.open(url);
        } else {
            // We have a local link, so we need to navigate to it.
            navigate(url);
        }
    }, [navigate]);





    return (
        <>
            <Row>
                <h4 style={{ margin: '1rem 0', textAlign: 'center' }}>{t('growListPart.searchResult.heading', `Results for "${props.search}"`)}</h4>
            </Row>

            <MainContainer style={{ color: 'white'/*, backgroundColor: '#9D509D'*/ }}>
                <AlertOnErrors errors={[contentLoadErrors]} />
                <ConditionalFragment showIf={isLoading}>
                    <LoadingIndicator size="sm" />
                </ConditionalFragment>
                <ConditionalFragment showIf={publishedItems?.length === 0}>
                    <MainContainer>
                        <NoResultsFound />
                    </MainContainer>
                </ConditionalFragment>
                <ConditionalFragment showIf={!isLoading && publishedItems?.length > 0}>
                    <HorizontalScroll>
                        {
                            publishedItems?.map((item, index) => {
                                const linkToContent = getLearningContentUrlOrBlob(item);

                                return (
                                    <VideoTile key={index}
                                        model={item}
                                        blob={blobs?.find(blob => blob.id === item.imageBlobReferenceId)}
                                        isLoading={blobsIsLoading}
                                        size="fixed"
                                        consumeContent={() => consumeContent(generateTrackedUrl(linkToContent ?? '', item.id) ?? '')}
                                    />
                                );
                            })
                        }
                    </HorizontalScroll>
                </ConditionalFragment>
            </MainContainer>
        </>
    );
};