import { LearningContentType } from "../../../api/main/models/constants/LearningContentType";
import { LearningContent } from "../../../api/main/models/LearningContent";
import { ViewBook } from "../../administration/books/containers/EditBookContainers";
import { ViewCourse } from "../../administration/courses/containers/EditCourseContainers";
import { ViewEvent } from "../../administration/events/containers/EditEventContainers";
import { ViewPodcast } from "../../administration/podcasts/containers/EditPodcastContainers";
import { ViewStandard } from "../../administration/standards/containers/EditStandardContainers";
import { ViewVideo } from "../../administration/videos/containers/EditVideoContainers";

export interface LearningContentViewerProps {
    content: LearningContent | undefined;
}

/*
 * Component to render Learning Content. Works for all LearningContentTypes.
 * Takes a LearningContent object and renders the correct view based on the LearningContentType.
*/
export const LearningContentViewer = (props: LearningContentViewerProps) => {
    const {
        content
    } = props;

    // Return the correct view based on the content type
    return (
        <>
            {content && (
                <>
                    {(() => {
                        switch (content.learningContentType as LearningContentType) {
                            // Book content types
                            case LearningContentType.BookFile: return <ViewBook bookId={content.id} />;
                            case LearningContentType.BookLink: return <ViewBook bookId={content.id} />;

                            // Course content types
                            case LearningContentType.Course: return <ViewCourse courseId={content.id} />;

                            // Event content types
                            case LearningContentType.Event: return <ViewEvent eventId={content.id} />;

                            // Podcast content types
                            case LearningContentType.PodcastFileAudio: return <ViewPodcast podcastId={content.id} />;
                            case LearningContentType.PodcastFileVideo: return <ViewPodcast podcastId={content.id} />;
                            case LearningContentType.PodcastLink: return <ViewPodcast podcastId={content.id} />;
                            case LearningContentType.PodcastLinkYouTube: return <ViewPodcast podcastId={content.id} />;

                            // Standard content types
                            case LearningContentType.StandardFile: return <ViewStandard standardId={content.id} />;
                            case LearningContentType.StandardLink: return <ViewStandard standardId={content.id} />;

                            // Video content types
                            case LearningContentType.VideoFile: return <ViewVideo videoId={content.id} />;
                            case LearningContentType.VideoLink: return <ViewVideo videoId={content.id} />;
                            case LearningContentType.VideoLinkYouTube: return <ViewVideo videoId={content.id} />;
                        }
                    })()}
                </>
            )}
        </>
    );
};