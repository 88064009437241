import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Guid } from "guid-string";
import { useValidatorCallback } from "pojo-validator-react";
import { ValidatedInput } from "pojo-validator-reactstrap";
import { useCallback, useState } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { Button, Form, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from "reactstrap";
import { ButtonAsync } from "reactstrap-buttonasync";
import { useToggleState } from "use-toggle-state";
import { useCloneOrganisationalRoleMutation } from "../../../api/main/organisationalRoles/useCloneOrganisationalRoleMutation";
import { useOrganisationalRole } from "../../../api/main/organisationalRoles/useOrganisationalRole";
import { AlertOnErrors } from "../../../shared/alertOnErrors";
import { FormButtons } from "../../shared/formButtons/FormButtons";
import { LoadingIndicator } from "../../shared/loadingIndicator/LoadingIndicator";
import { TwoValueSwitch } from "../../shared/twoValueSwitch/TwoValueSwitch";


export interface CloneOrganisationalRoleModalCloseEventArgs {
    newRoleId: string,
};


export interface CloneOrganisationalRoleModalProps {
    isOpen: boolean,
    toggle: () => void,
    onClose: (event: CloneOrganisationalRoleModalCloseEventArgs) => Promise<void>,

    originalRoleId: string,
    newRoleId: string,
};


/**
 * Modal for cloning an organisational role.
 * @param props
 */
export const CloneOrganisationalRoleModal = (props: CloneOrganisationalRoleModalProps) => {
    const {
        isOpen,
        toggle,
        onClose,
        originalRoleId,
        newRoleId,
    } = props;

    const { t } = useTranslation();



    // Loading the original role, so we can get its name
    const {
        data: {
            model: originalRole
        }, isLoading, errors: loadErrors
    } = useOrganisationalRole(originalRoleId ?? null);

    const [newName, setNewName] = useState<string>("");

    const [cloneRole, { isExecuting, errors }] = useCloneOrganisationalRoleMutation();

    const closeModal = useCallback(async (event?: { newOrganisationalRoleId: string, navigateToNewRole: boolean }) => {
        if (onClose) {

            const externalEvent: CloneOrganisationalRoleModalCloseEventArgs = {
                ...(event ?? {}),

                newRoleId: event?.newOrganisationalRoleId ?? newRoleId,
            };
            await cloneRole(originalRoleId, newName, newRoleId); 
            await onClose(externalEvent);
        }

        //Close the Modal
        toggle();
    }, [toggle, newRoleId, onClose, cloneRole, originalRoleId, newName]);

    const [validate, validationErrors] = useValidatorCallback((validation, fieldsToCheck) => {
        const rules = {
            name: () => !newName.length ? t('cloneOrganisationalRoleModal.errors.nameRequired', 'Name is required') : '',
        };
        validation.checkRules(rules, fieldsToCheck);
    }, [newName]);

    // Render the UI
    //
    return (
        <Modal
            isOpen={isOpen}
            toggle={toggle}
            size="md"
        >
            <ModalHeader toggle={toggle}>
                {t('cloneOrganisationalRoleModal.title', 'Clone ' + originalRole?.name)}

                <ConditionalFragment showIf={!!isExecuting}>
                    <LoadingIndicator />
                </ConditionalFragment>
            </ModalHeader>

            <AlertOnErrors
                errors={[
                    errors
                ]}
            />

            <ModalBody>
                <Form onSubmit={e => { e.preventDefault(); closeModal(); }}>
                    <Row>
                        <FormGroup>
                            <Label>{t('cloneOrganisationalRoleModal.newName', 'Name')}</Label>
                            <ValidatedInput onBlur={e => validate('name')}
                                validationErrors={validationErrors['name']}
                                type="text" value={newName ?? ""}
                                onChange={e => setNewName(e.currentTarget.value)}
                            />
                        </FormGroup>
                    </Row>
                    <Row>
                        <FormButtons>
                            <ButtonAsync color="primary" isExecuting={isExecuting} onClick={e => { e.preventDefault(); closeModal(); }}
                                executingChildren={<><Spinner size="sm" /> {t('cloneOrganisationalRoleModal.cloning', 'Cloning...')}</>}>
                                <FontAwesomeIcon icon="layer-group" />
                                <> </>
                                {t('cloneOrganisationalRoleModal.clone', 'Clone')}
                            </ButtonAsync>
                            <Button outline onClick={e => toggle()}>
                                {t('common.cancel', 'Cancel')}
                            </Button>
                        </FormButtons>
                    </Row>
                </Form>
            </ModalBody>
        </Modal>
    );
};
