import { CoursesList, CoursesListProps } from "../CoursesList";

/*
 * Component to view a list of admin courses
*/
export const AdminCoursesList = (props: CoursesListProps) => (<CoursesList isAdmin {...props} />);

/*
 * Component to view a list of trust courses
*/
export const TrustCoursesList = (props: CoursesListProps) => (<CoursesList isTrust {...props} />);

/*
 * Component to view a list of school courses
*/
export const SchoolCoursesList = (props: CoursesListProps) => (<CoursesList {...props} />);