import moment from "moment";
import { useCallback } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Row } from "reactstrap";
import { useBlobReferencesArray } from "../../api/main/blobReferences/useBlobReferencesArray";
import { useViewCareerOrganisationalRoleViewModel } from "../../api/main/career/viewModels/useViewCareerOrganisationalRoleViewModel";
import { useLearningContentsFromArray } from "../../api/main/learningContents/viewModels/useLearningContentsFromArray";
import { useLearningContentsFromArraySchoolTrustFilter } from "../../api/main/learningContents/viewModels/useLearningContentsFromArraySchoolTrustFilter";
import { OrganisationalRoleLinkType } from "../../api/main/models/constants/OrganisationalRoleLinkType";
import { UserLearningEvent } from "../../api/main/models/UserLearningEvent";
import { useCurrentUserOrEmulatedSubscription } from "../../globalState/subscriptions/useCurrentUserOrEmulatedSubscription";
import { AlertOnErrors } from "../../shared/alertOnErrors";
import { generateTrackedUrl } from "../../utilities/generateTrackedUrl";
import { VideoTile } from "../career/VideoTile";
import { HorizontalScroll } from "../shared/horizontalScroll/HorizontalScroll";
import { LoadingIndicator } from "../shared/loadingIndicator/LoadingIndicator";
import { MainContainer } from "../shared/mainContainer/MainContainer";
import { NoResultsFound } from "../shared/noResultsFound/NoResultsFound";
import { getLearningContentUrlOrBlob } from "../shared/Utilities/getLearningContentUrlOrBlob";

export interface GrowListPartProps {
    organisationalRoleId: string;
    userLearningEvents?: Array<UserLearningEvent>;
}


export const GrowListPart = (props: GrowListPartProps) => {
    const {
        organisationalRoleId,
        userLearningEvents,
    } = props;

    const { t } = useTranslation();
    const navigate = useNavigate();
    const subscription = useCurrentUserOrEmulatedSubscription();

    //Load data

    const {
        data: {
            model: storeModel,
            organisationalRoleLinks: storeOrganisationalRoleLinks,
        },
        errors: loadErrors, isLoading: _isLoading,
    } = useViewCareerOrganisationalRoleViewModel(props.organisationalRoleId);

    const contentRoleLinks = storeOrganisationalRoleLinks?.filter(item => item.organisationalRoleLinkType === OrganisationalRoleLinkType.LearningForRole);
    const contentRoleLinksContentIds = contentRoleLinks?.map(item => item.targetId);

    const {
        data: {
            items
        }, isLoading, errors: contentLoadErrors
    } = useLearningContentsFromArraySchoolTrustFilter(contentRoleLinksContentIds ?? [], { pageSize: undefined }, subscription?.schoolId, subscription?.trustId);


    const today = moment();
    // filter out items that are not published yet
    const publishedItems = items?.filter(it => !today.isBefore(moment(it.publishDate)));


    //const {
    //    data: {
    //        items: blobs,
    //    }, isLoading: blobsIsLoading, errors: blobsErrors
    //} = useBlobReferencesArray(publishedItemBlobIds ?? []);

    const isLoadingContent = isLoading || _isLoading;


    const consumeContent = useCallback((url: string) => {
        // If the URL starts with https:// then we are dealing with an external link
        if (url.startsWith('https://')) {
            // If we don't have a URL to work with do nothing.
            if (!url) { return; }

            // Open in a new window.
            window.open(url);
        } else {
            // We have a local link, so we need to navigate to it.
            navigate(url);
        }
    }, [navigate]);





    return (
        <>
            <Row>
                <h4 style={{ margin: '1rem 0', textAlign: 'center'}}>{t('growListPart.heading', storeModel?.name)}</h4>
            </Row>

            <MainContainer style={{ color: 'white'/*, backgroundColor: '#9D509D'*/ }}>
                <AlertOnErrors errors={[loadErrors, contentLoadErrors /*, blobsErrors */]} />
                <ConditionalFragment showIf={isLoadingContent}>
                    <LoadingIndicator size="sm" />
                </ConditionalFragment>
                <ConditionalFragment showIf={publishedItems?.length === 0}>
                    <MainContainer>
                        <NoResultsFound />
                    </MainContainer>
                </ConditionalFragment>
                <ConditionalFragment showIf={!isLoadingContent && publishedItems?.length > 0}>
                    <HorizontalScroll>
                        {
                            publishedItems?.map((item, index) => {
                                const linkToContent = getLearningContentUrlOrBlob(item);

                                return (
                                    <VideoTile key={index}
                                        model={item}
                                        size="fixed"
                                        consumeContent={() => consumeContent(generateTrackedUrl(linkToContent ?? '', item.id) ?? '')}
                                        showCompleted={userLearningEvents?.some(ule => ule.externalName === item.name)}
                                    />
                                );
                            })
                        }
                    </HorizontalScroll>
                </ConditionalFragment>
            </MainContainer>
        </>
    );
};