import { NavItem } from 'reactstrap';
import { AuthorizeContainer } from '../../shared/authorizeRoute';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IdentityRoles } from '../../configure/security/IdentityRoles';
import { NavTitle } from '../shared/navTitle/NavTitle';
import { SidebarRequired } from '../layout/sidebar/sidebarRequired/SidebarRequired';
import { NavLinkTrackActive } from '../shared/navLinkTrackActive/NavLinkTrackActive';
import { useCurrentUserOrEmulatedSubscription } from '../../globalState/subscriptions/useCurrentUserOrEmulatedSubscription';

/**
 * Manage Trust navigation menu for the app. 
 */
export const ManageSchoolNavigation = () => {
    const { t } = useTranslation();

    // Render the UI
    //
    return (
        <>
            <SidebarRequired />
            <AuthorizeContainer requireRole={IdentityRoles.ManageSchool}>
                <NavTitle>
                    {t('schoolNavigation.heading.school', 'School')}
                </NavTitle>
                <NavItem>
                    <NavLinkTrackActive to={`/manage-school/school-details/${useCurrentUserOrEmulatedSubscription()?.schoolId}`}>
                        <FontAwesomeIcon icon="clipboard-list" className="nav-icon" />
                        {t('schoolNavigation.schools', 'School details')}
                    </NavLinkTrackActive>
                </NavItem>
                <NavItem>
                    <NavLinkTrackActive to="/manage-school/staff">
                        <FontAwesomeIcon icon="users" className="nav-icon" />
                        {t('schoolNavigation.schoolUsers', 'Staff')}
                    </NavLinkTrackActive>
                </NavItem>

                <NavTitle>
                    {t('schoolNavigation.heading.talentMapping', 'Talent mapping')}
                </NavTitle>
                <NavItem>
                    <NavLinkTrackActive to="/manage-school/role-map">
                        <FontAwesomeIcon icon="scale-unbalanced" className="nav-icon" />
                        {t('schoolNavigation.roleFullfilment', 'Role fulfilment')}
                    </NavLinkTrackActive>
                </NavItem>
                <NavItem>
                    <NavLinkTrackActive to="/manage-school/career-map">
                        <FontAwesomeIcon icon="scale-unbalanced" className="nav-icon" />
                        {t('schoolNavigation.careerFullfilment', 'Career fulfilment')}
                    </NavLinkTrackActive>
                </NavItem>

                <NavTitle>
                    {t('schoolNavigation.heading.schoolLearningLibrary', 'School growth resources')}
                </NavTitle>
                <NavItem>
                    <NavLinkTrackActive to="/manage-school/courses">
                        <FontAwesomeIcon icon="chalkboard-teacher" className="nav-icon" />
                        {t('schoolNavigation.courses', 'Courses')}
                    </NavLinkTrackActive>
                </NavItem>
                <NavItem>
                    <NavLinkTrackActive to="/manage-school/videos">
                        <FontAwesomeIcon icon="video" className="nav-icon" />
                        {t('schoolNavigation.videos', 'Videos')}
                    </NavLinkTrackActive>
                </NavItem>
                <NavItem>
                    <NavLinkTrackActive to="/manage-school/podcasts">
                        <FontAwesomeIcon icon="microphone-alt" className="nav-icon" />
                        {t('schoolNavigation.podcasts', 'Podcasts')}
                    </NavLinkTrackActive>
                </NavItem>
                <NavItem>
                    <NavLinkTrackActive to="/manage-school/events">
                        <FontAwesomeIcon icon="calendar-alt" className="nav-icon" />
                        {t('schoolNavigation.events', 'Events')}
                    </NavLinkTrackActive>
                </NavItem>
                <NavItem>
                    <NavLinkTrackActive to="/manage-school/books">
                        <FontAwesomeIcon icon="book" className="nav-icon" />
                        {t('schoolNavigation.books', 'Books')}
                    </NavLinkTrackActive>
                </NavItem>
                <NavItem>
                    <NavLinkTrackActive to="/manage-school/standards">
                        <FontAwesomeIcon icon="file-alt" className="nav-icon" />
                        {t('schoolNavigation.standards', 'Standards')}
                    </NavLinkTrackActive>
                </NavItem>



                <NavTitle>
                    {t('schoolNavigation.heading.customise', 'Customise')}
                </NavTitle>
                <NavItem>
                    <NavLinkTrackActive to="/manage-school/customise">
                        <FontAwesomeIcon icon="list-check" className="nav-icon" />
                        {t('schoolNavigation.customiseSchool', 'Customise school')}
                    </NavLinkTrackActive>
                </NavItem>
            </AuthorizeContainer>
        </>
    );
};
