import * as React from 'react';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useUpdateRequirementMutation } from './useUpdateRequirementMutation';
import { Requirement } from '../models/Requirement';
import { useCreateRequirementMutation } from './useCreateRequirementMutation';
import { RequirementCreateInput, RequirementUpdateInput } from '../generated/graphql';

/**
 * Get a callback to save a Requirement in the store using either a create or update.
 */
export function useSaveRequirementMutation(options: SaveInStoreOptions<Requirement, string> = {}) {
    const [_create, createStatus] = useCreateRequirementMutation();
    const create = React.useCallback((model: Partial<Requirement>) => _create(model as RequirementCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateRequirementMutation();
    const update = React.useCallback((id: string, changes: Partial<Requirement>) => _update(id, changes as RequirementUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
