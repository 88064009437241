import { IdentityRoles } from "../../../configure/security/IdentityRoles";
import { RouteEntry } from "../../../shared/routes";
import { CreateAdminVideo, EditAdminVideo } from "./containers/EditVideoContainers";
import { AdminVideosList } from "./containers/VideoListContainers";
import { DeleteVideo } from "./DeleteVideo";


/**
 * Route that should be added to configure.routeConfig.ts to provide access to these components via routing
 */
export const videoRoutes: Array<RouteEntry> = [
    { path: '/administration/videos', element: <AdminVideosList />, authorize: true, requireRole: IdentityRoles.Administration, },
    { path: '/administration/videos/add', element: <CreateAdminVideo/>, authorize: true, requireRole: IdentityRoles.Administration, },
    { path: '/administration/videos/edit/:id', element: <EditAdminVideo />, authorize: true, requireRole: IdentityRoles.Administration, },
    { path: '/administration/videos/delete/:id', element: <DeleteVideo />, authorize: true, requireRole: IdentityRoles.Administration, },
];