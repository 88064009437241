import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { DeletePersonnelFlagUserMutation, DeletePersonnelFlagUserMutationVariables } from '../generated/graphql';
import { personnelFlagUserFieldsFragment } from '../models/PersonnelFlagUser';

/**
 * Get a callback to create a PersonnelFlagUser in the store.
 */
export function useDeletePersonnelFlagUserMutation(): [(id: string) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<DeletePersonnelFlagUserMutation, DeletePersonnelFlagUserMutationVariables>(
        gql`
            mutation deletePersonnelFlagUserMutation ($id: ID!) {
                deletePersonnelFlagUser(id: $id) {
                    ...personnelFlagUserFields
                }
            }

            ${personnelFlagUserFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (id: string) => {
        const result = await mutationAction({ variables: { id: id } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
