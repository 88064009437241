import { StandardsList, StandardsListProps } from "../StandardsList";

/*
 * Component to view a list of admin standards
*/
export const AdminStandardsList = (props: StandardsListProps) => (<StandardsList isAdmin {...props} />);

/*
 * Component to view a list of trust standards
*/
export const TrustStandardsList = (props: StandardsListProps) => (<StandardsList isTrust {...props} />);

/*
 * Component to view a list of school standards
*/
export const SchoolStandardsList = (props: StandardsListProps) => (<StandardsList {...props} />);