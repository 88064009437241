import { TFunction } from "i18next";

/**
 * Types of link that could be represented by a RequirementLink.
 */
export enum RequirementLinkType {
    RequiredSkill = "RequiredSkill",
    RecommendedSkill = "RecommendedSkill",
    DisabledSkill = "DisabledSkill",
    TeachesSkill = "TeachesSkill",
    UserSkill = "UserSkill",
    AspirationalUserSkill = "AspirationalUserSkill",

    RequiredAttribute = "RequiredAttribute",
    RecommendedAttribute = "RecommendedAttribute",
    DisabledAttribute = "DisabledAttribute",
    TeachesAttribute = "TeachesAttribute",
    UserAttribute = "UserAttribute",
    AspirationalUserAttribute = "AspirationalUserAttribute",

    KeyQualification = "KeyQualification",
    RequiredQualification = "RequiredQualification",
    RecommendedQualification = "RecommendedQualification",
    UserQualification = "UserQualification",
    AspirationalUserQualification = "AspirationalUserQualification",

    RequiredExperience = "RequiredExperience",
    RecommendedExperience = "RecommendedExperience",
    UserExperience = "UserExperience",
    AspirationalUserExperience = "AspirationalUserExperience",
}

/**
 * Return the display text for a RequirementLinkType.
 * @param t
 */
export function requirementLinkTypeDisplayName(type: RequirementLinkType | string | undefined | null, t: TFunction): string {
    switch (type) {
        // Skills
        case RequirementLinkType.RequiredSkill: return t('requirementLinkTypeDisplayName.requiredSkill', 'Required skill');
        case RequirementLinkType.RecommendedSkill: return t('requirementLinkTypeDisplayName.recommendedSkill', 'Recommended skill');
        case RequirementLinkType.DisabledSkill: return t('requirementLinkTypeDisplayName.disabledSkill', 'Disabled skill');
        case RequirementLinkType.TeachesSkill: return t('requirementLinkTypeDisplayName.teachesSkill', 'Teaches skill');
        case RequirementLinkType.UserSkill: return t('requirementLinkTypeDisplayName.userSkill', 'User skill');
        case RequirementLinkType.AspirationalUserSkill: return t('requirementLinkTypeDisplayName.desiredUserSkill', 'Aspirational user skill');

        // Attributes 
        case RequirementLinkType.RequiredAttribute: return t('requirementLinkTypeDisplayName.requiredAttribute', 'Required attribute');
        case RequirementLinkType.RecommendedAttribute: return t('requirementLinkTypeDisplayName.recommendedAttribute', 'Recommended attribute');
        case RequirementLinkType.DisabledAttribute: return t('requirementLinkTypeDisplayName.disabledAttribute', 'Disabled attribute');
        case RequirementLinkType.TeachesAttribute: return t('requirementLinkTypeDisplayName.teachesAttribute', 'Teaches attribute');
        case RequirementLinkType.UserAttribute: return t('requirementLinkTypeDisplayName.userAttribute', 'User attribute');
        case RequirementLinkType.AspirationalUserAttribute: return t('requirementLinkTypeDisplayName.desiredUserAttribute', 'Aspirational user attribute');

        // Qualifications 
        case RequirementLinkType.RequiredQualification: return t('requirementLinkTypeDisplayName.requiredQualification', 'Required qualification');
        case RequirementLinkType.RecommendedQualification: return t('requirementLinkTypeDisplayName.recommendedQualification', 'Recommended qualification');
        case RequirementLinkType.UserQualification: return t('requirementLinkTypeDisplayName.userQualification', 'User qualification');
        case RequirementLinkType.AspirationalUserQualification: return t('requirementLinkTypeDisplayName.desiredUserQualification', 'Aspirational user qualification');
    }

    return t('requirementLinkTypeDisplayName.unknown', 'Unknown');
}
