import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { CreatePersonnelFlagUserMutation, CreatePersonnelFlagUserMutationVariables, PersonnelFlagUserCreateInput } from '../generated/graphql';
import { personnelFlagUserFieldsFragment } from '../models/PersonnelFlagUser';

/**
 * Get a callback to create a PersonnelFlagUser in the store.
 */
export function useCreatePersonnelFlagUserMutation(): [(model: PersonnelFlagUserCreateInput) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<CreatePersonnelFlagUserMutation, CreatePersonnelFlagUserMutationVariables>(
        gql`
            mutation createPersonnelFlagUserMutation ($model: PersonnelFlagUserCreateInput!) {
                createPersonnelFlagUser(model: $model) {
                    ...personnelFlagUserFields
                }
            }

            ${personnelFlagUserFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (model: PersonnelFlagUserCreateInput) => {
        const result = await mutationAction({ variables: { model: model } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
