import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';

import { organisationalRoleGroupFieldsFragment } from "../models/OrganisationalRoleGroup";
import { DeleteOrganisationalRoleGroupMutation, DeleteOrganisationalRoleGroupMutationVariables } from "../generated/graphql";

/**
 * Get a callback to create a Organisationalrolegroups in the store.
 */
export function useDeleteOrganisationalRoleGroupMutation(): [(id: string) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<DeleteOrganisationalRoleGroupMutation, DeleteOrganisationalRoleGroupMutationVariables>(
        gql`
            mutation deleteOrganisationalRoleGroupMutation ($id: ID!) {
                deleteOrganisationalRoleGroup(id: $id) {
                    ...organisationalRoleGroupFields
                }
            }

            ${organisationalRoleGroupFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (id: string) => {
        const result = await mutationAction({ variables: { id: id } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
