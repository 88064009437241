import * as React from "react";
import { Form, Alert, FormGroup, Label, Spinner, Row, Col, Card, CardHeader, CardBody, Input, } from "reactstrap";
import { AlertOnErrors } from "../../shared/alertOnErrors";
import { Link, useNavigate } from "react-router-dom";
import { useChanges } from "../../shared/useChanges";
import { useValidatorCallback } from "pojo-validator-react";
import { ValidatedInput } from "pojo-validator-reactstrap";
import { ButtonAsync } from "reactstrap-buttonasync"
import { LoadingIndicator } from "../../components/shared/loadingIndicator/LoadingIndicator";
import { useExternalAuthenticationSchemes, useLoginWithPasswordCallback, useStartExternalLoginCallback, useResendConfirmationEmailCallback } from "../../api/account";
import { Login as LoginModel } from '../../api/account/models/Login';
import { useTranslation } from "react-i18next";
import { ExternalLoginButton } from "./ExternalLoginButton";
import { FormButtons } from "../shared/formButtons/FormButtons";
import { MainContainer } from "../shared/mainContainer/MainContainer";

/**
 * Login screen.
 */
export const Login = () => {
	const { t } = useTranslation();
	const { data: { externalAuthenticationSchemes }, isLoading, errors: loadErrors } = useExternalAuthenticationSchemes();
	const [login, { isExecuting: _isLoggingIn, errors: loginErrors }] = useLoginWithPasswordCallback();

	const isLoggingIn = _isLoggingIn;
	const [startExternalLogin] = useStartExternalLoginCallback();
	const [isPendingEmailConfirmation, setIsPendingEmailConfirmation] = React.useState<boolean>(false);
	const navigate = useNavigate();

	const [resendConfirmationEmail, { isExecuting: isResendingConfirmationEmail, errors: resendConfirmationEmailErrors }] = useResendConfirmationEmailCallback();
	const [hasSentConfirmationEmail, setHasSentConfirmationEmail] = React.useState<boolean>(false);

	const { model, change } = useChanges<LoginModel>({ email: '', password: '', rememberMe: true });

	// Need the URL to pass on to registration or 2faif we redirect to it.
	const params = new URLSearchParams(window.location.search);
	const returnUrl = params.get('returnUrl') ?? params.get('ReturnUrl') ?? '';

	/**
	 * Validate the model before trying to use it.
	 */
	const [validate, validationErrors] = useValidatorCallback((validation, fieldsToCheck) => {
		const rules = {
			email: () => !model.email ? t('login.emailRequired', 'Email is required') : '',
			password: () => !model.password ? t('login.passwordRequired', 'Password is required') : '',
		};

		validation.checkRules(rules, fieldsToCheck);
	}, [model]);

	/**
	 * Perform a login by and handle the result.
	 */
	const [isDoingFullPageRedirect, setIsDoingFullPageRedirect] = React.useState<boolean>(false);
	const performEmailLogin = React.useCallback(async (): Promise<void> => {
		if (!validate()) {
			return;
		}

		let result = await login(model);

		if (result) {
			setIsPendingEmailConfirmation(result.requiresEmailConfirmation);

			if (result.requiresTwoFactor) {
				navigate(`/account/loginWithTwoFactor?returnUrl=${encodeURIComponent(result.returnUrl)}`);
			}

			// Redirect the whole page (not just the react app) as its likely the returnUrl is handled on the server.
			if (result.succeeded) {
				// Redirect the whole page (not just react) to the returnUrl to let the server handle as well as the client.
				if (!result.requiresEmailConfirmation && !result.requiresTwoFactor) {
					setIsDoingFullPageRedirect(true);
					window.location.href = result.returnUrl;
				}
			}
		}
	}, [login, model, setIsPendingEmailConfirmation, validate, setIsDoingFullPageRedirect, navigate]);

	// Login using the right style.
	const performLogin = React.useCallback(async () => {
		await performEmailLogin();
	}, [performEmailLogin]);

	// Render the UI.
	return (
		<MainContainer color="transparent" centerChildren="vertically">
			<Card color="">
				<CardHeader>
					<h1>
						{t('login.signInHeading', 'Sign in')}
					</h1>
				</CardHeader>
				<CardBody>
					{/* Main login forms */}
					<AlertOnErrors simple errors={[loadErrors, loginErrors, resendConfirmationEmailErrors]} />

					<Form onSubmit={async e => { e.preventDefault(); await performLogin(); }}>
						{
							isPendingEmailConfirmation ?
								hasSentConfirmationEmail ? (
									<Alert color="success" >
										<>{t('login.confirmationEmailHasBeenResent', 'Confirmation link to verify the email for this account has been resent.  Please check your email to confirm.')} </>
										<ButtonAsync type="button" color="success" onClick={async e => { e.preventDefault(); await resendConfirmationEmail(model.email); setHasSentConfirmationEmail(true); }}
											isExecuting={isResendingConfirmationEmail}
											executingChildren={<><Spinner size="sm" />{t('common.sending', 'Sending...')}</>}>
											{t('common.resendEmail', 'Resend email')}
										</ButtonAsync>
									</Alert>
								) : (
									<Alert color="success">
										<>{t('login.mustConfirmEmailBeforeLogin', 'You need to confirm your account before you can sign in.  Please check your email.')} </>
										<ButtonAsync type="button" color="success" onClick={async e => { e.preventDefault(); await resendConfirmationEmail(model.email); setHasSentConfirmationEmail(true); }}
											isExecuting={isResendingConfirmationEmail}
											executingChildren={<><Spinner size="sm" />{t('common.sending', 'Sending...')}</>}>
											{t('common.resendEmail', 'Resend email')}
										</ButtonAsync>
									</Alert>
								) : null
						}
						<FormGroup>
							<Label htmlFor="email">{t('login.email', 'Email')}</Label>
							<ValidatedInput type="email" name="email" autoComplete="username" value={model.email} onChange={e => change({ email: e.currentTarget.value })} onBlur={e => validate('email')} validationErrors={validationErrors['email']} />
						</FormGroup>
						<FormGroup>
							<Label htmlFor="password">{t('login.password', 'Password')}</Label>
							<ValidatedInput type="password" name="password" autoComplete="current-password" value={model.password} onChange={e => change({ password: e.currentTarget.value })} onBlur={e => validate('password')} validationErrors={validationErrors['password']} />
						</FormGroup>
						<FormGroup switch>
							<Input id="rememberMe" name="rememberMe" type="switch" checked={model.rememberMe} onChange={e => change({ rememberMe: e.currentTarget.checked })} />
							<> </>
							{t('login.rememberMe', 'Remember me on this device?')}
						</FormGroup>

						<FormButtons>
							<Row>
								<Col xs="auto">
									<Link to={'/account/forgotPassword'}>
										{t('login.forgotYourPassword', 'Forgotten your password?')}
									</Link>
								</Col>
								<Col>
									<ButtonAsync type="submit" color="primary" isExecuting={isLoggingIn || isDoingFullPageRedirect}
										executingChildren={<><Spinner size="sm" /> {t('login.loggingIn', 'Signing in...')}</>}>
										{t('login.signIn', 'Sign in')}
									</ButtonAsync>
								</Col>
							</Row>

						</FormButtons>

						<div>
							{
								isLoading ? (
									<LoadingIndicator />
								) : (
									<>
										<div>
											{
												!externalAuthenticationSchemes ? null
													: externalAuthenticationSchemes.length !== 0 ? (
														<>
															<h6>{t('login.useExternalService', 'Or sign in using an identity provider')}</h6>
															{
																externalAuthenticationSchemes.map((item) => (
																	<ExternalLoginButton key={item.name} type="button" provider={item.name} providerDisplayName={item.displayName} onClick={() => startExternalLogin(item.name, returnUrl)} />
																))
															}
														</>
													)
														: null
											}
										</div>
									</>
								)
							}
						</div>
					</Form>
				</CardBody>
			</Card>
		</MainContainer>
	);
};
