import gql from 'graphql-tag';
import { Guid } from 'guid-string';
import { OrganisationProfileFieldsFragment } from '../generated/graphql';

export const organisationProfileFieldsFragment = gql`
    fragment organisationProfileFields on OrganisationProfile {
        id
        name
        descriptionHtml
        imageBlobReferenceId
        archived
        trustId
        schoolId
    }
`;

export type OrganisationProfile = Omit<OrganisationProfileFieldsFragment, '__typename' | 'organisationProfile'>;

/**
 * Default values.
 */
export const organisationProfileDefaultValues = (): Partial<OrganisationProfile> => ({
    id: Guid.newGuid(),
    name: '',
    descriptionHtml: '',
    imageBlobReferenceId: null,
    archived: false,
    trustId: null,
    schoolId: null,
});