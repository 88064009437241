import { OrganisationalRoleLinkType } from "../../api/main/models/constants/OrganisationalRoleLinkType";
import { RequirementLinkType } from "../../api/main/models/constants/RequirementLinkType";
import { RequirementType } from "../../api/main/models/constants/RequirementType";
import { OrganisationalRole } from "../../api/main/models/OrganisationalRole";
import { OrganisationalRoleLink } from "../../api/main/models/OrganisationalRoleLink";
import { PersonnelFlag } from "../../api/main/models/PersonnelFlag";
import { PersonnelFlagUser } from "../../api/main/models/PersonnelFlagUser";
import { Profile } from "../../api/main/models/Profile";
import { Requirement } from "../../api/main/models/Requirement";
import { RequirementLink } from "../../api/main/models/RequirementLink";
import { roleMapCalculationConfiguration } from "../../configure/roleMapCalculationConfiguration";

export function userMapRoleScoreSort(user: Profile, roles: Array<OrganisationalRole>, roleLinks: Array<OrganisationalRoleLink>,
    personnelFlagUsers: Array<PersonnelFlagUser>, personnelFlags: Array<PersonnelFlag>, requirements: Array<Requirement>, requirementLinks: Array<RequirementLink>) {

    // Get the user's personnel flags from the personnelFlagUsers
    var personnelFlagsForUser = personnelFlags?.filter(item => personnelFlagUsers?.find(it => it.personnelFlagId === item.id && it.userId === user.userId));
    // Get the user's requirements from the requirementLinks
    var requirementsForUser = requirements?.filter(item => requirementLinks?.find(it => it.requirementId === item.id && it.targetId === user.userId
        && (it.requirementLinkType === RequirementLinkType.UserQualification || it.requirementLinkType === RequirementLinkType.UserExperience || it.requirementLinkType === RequirementLinkType.UserAttribute || it.requirementLinkType === RequirementLinkType.UserSkill)
    ));

    // Create a dictionary of requirement links by role so we don't have to filter the large requirementLinks array every time.
    var requirementLinksByRole: Record<string, Array<RequirementLink>> = roles.reduce((acc: Record<string, Array<RequirementLink>>, role) => {
        var roleId = role?.id;
        acc[roleId] = requirementLinks?.filter(it => it.targetId === roleId || it.targetId === user.userId);
        return acc;
    }, {});

    return roles.map((role, index) => {
        var roleId = role?.id;
        var score = 0;
        // Add up the impact score of the user's personnel flags. Only personnel flags relevent to the role need be considered
        score += personnelFlagsForUser?.filter(it => personnelFlagUsers.find(pf => pf.organisationalRoleId === roleId && pf.personnelFlagId === it.id))
            .reduce((total, flag) => total + flag.impactScore, 0);

        var myRequirementLinks = requirementLinksByRole[roleId] ?? []; 

        // Get the required and recommended requirements for the role
        var keyRequirementsForRole = requirements?.filter(item => myRequirementLinks?.find(it => it.requirementId === item.id && it.targetId === roleId
            && (it.requirementLinkType === RequirementLinkType.KeyQualification)
        ));
        var requiredRequirementsForRole = requirements?.filter(item => myRequirementLinks?.find(it => it.requirementId === item.id && it.targetId === roleId
            && (it.requirementLinkType === RequirementLinkType.RequiredQualification || it.requirementLinkType === RequirementLinkType.RequiredAttribute || it.requirementLinkType === RequirementLinkType.RequiredSkill)
        ));
        var recommendedRequirementsForRole = requirements?.filter(item => myRequirementLinks?.find(it => it.requirementId === item.id && it.targetId === roleId
            && (it.requirementLinkType === RequirementLinkType.RecommendedQualification || it.requirementLinkType === RequirementLinkType.RecommendedAttribute || it.requirementLinkType === RequirementLinkType.RecommendedSkill)
        ));

        // Qualifications.
        // Add points for every key qualification the user has, and one point for every recommended requirement the user has.
        score += requirementsForUser?.filter(item => keyRequirementsForRole?.filter(it => it.requirementType === RequirementType.Qualification)?.find(it => it.id === item.id)).length * roleMapCalculationConfiguration.pointsPerKeyQualification;
        // Add points for every required qualification the user has, and one point for every recommended requirement the user has.
        score += requirementsForUser?.filter(item => requiredRequirementsForRole?.filter(it => it.requirementType === RequirementType.Qualification)?.find(it => it.id === item.id)).length * roleMapCalculationConfiguration.pointsPerRequiredQualification;
        // Add points for every recommended qualification the user has, and one point for every recommended requirement the user has.
        score += requirementsForUser?.filter(item => recommendedRequirementsForRole?.filter(it => it.requirementType === RequirementType.Qualification)?.find(it => it.id === item.id)).length * roleMapCalculationConfiguration.pointsPerRecommendedQualification;

        // Experience
        // Add points for every required experience the user has.
        score += requirementsForUser?.filter(item => requiredRequirementsForRole?.filter(it => it.requirementType === RequirementType.Experience)?.find(it => it.id === item.id)).length * roleMapCalculationConfiguration.pointsPerRequiredExperience;
        // Add points for every recommended experience the user has.
        score += requirementsForUser?.filter(item => recommendedRequirementsForRole?.filter(it => it.requirementType === RequirementType.Experience)?.find(it => it.id === item.id)).length * roleMapCalculationConfiguration.pointsPerRecommendedExperience;

        // Attributes
        // Add points for every required attribute the user has.
        score += requirementsForUser?.filter(item => requiredRequirementsForRole?.filter(it => it.requirementType === RequirementType.Attribute)?.find(it => it.id === item.id)).length * roleMapCalculationConfiguration.pointsPerRequiredAttribute
        // Add points for every recommended attribute the user has.
        score += requirementsForUser?.filter(item => recommendedRequirementsForRole?.filter(it => it.requirementType === RequirementType.Attribute)?.find(it => it.id === item.id)).length * roleMapCalculationConfiguration.pointsPerRecommendedAttribute

        // Skills
        // Add points for every required skill the user has.
        score += requirementsForUser?.filter(item => requiredRequirementsForRole?.filter(it => it.requirementType === RequirementType.Skill)?.find(it => it.id === item.id)).length * roleMapCalculationConfiguration.pointsPerRequiredSkill
        // Add points for every recommended skill the user has.
        score += requirementsForUser?.filter(item => recommendedRequirementsForRole?.filter(it => it.requirementType === RequirementType.Skill)?.find(it => it.id === item.id)).length * roleMapCalculationConfiguration.pointsPerRecommendedSkill;

        // If the user has this role as a current role, add a big chunk of points as we know they can do the role.
        if (!!roleLinks?.find(it => it.organisationalRoleLinkType === OrganisationalRoleLinkType.User)) {
            score += roleMapCalculationConfiguration.pointsForCurrentRole;
        }

        // If the user has this role as an aspiration, add some points.
        if (!!roleLinks?.find(it => it.organisationalRoleLinkType === OrganisationalRoleLinkType.AspirationalUserRole)) {
            score += roleMapCalculationConfiguration.pointsForAspirationalRole;
        }

        // If the user is pinned for this role, add a huge amount to the score so they show first but get filtered within themselves.
        if (!!roleLinks?.find(it => it.organisationalRoleLinkType === OrganisationalRoleLinkType.RoleMapUserPin
            && it.targetId === user.userId && it.organisationalRoleId === roleId)) {
            score += roleMapCalculationConfiguration.pointsForPinnedRole;
        }

        return { score, role }
    })
        .filter(it => it.score >= roleMapCalculationConfiguration.minimumPointsToShowForRole /* Minimum score required to show */)
        .sort((a, b) => b.score - a.score) //sort by score
        .map(item => item.role); //extract the roles
}