import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useValidatorCallback } from "pojo-validator-react";
import { ValidatedInput } from "pojo-validator-reactstrap";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useAsyncCallback } from "react-use-async-callback";
import { Button, ButtonGroup, Col, Form, FormGroup, Label, Row, Spinner } from "reactstrap";
import { ButtonAsync } from "reactstrap-buttonasync";
import { BlobUploadService } from "../../../api/main/blobReferences/BlobUploadService";
import { useBlobReference } from "../../../api/main/blobReferences/useBlobReference";
import { Trust, trustDefaultValues } from "../../../api/main/models/Trust";
import { useSaveTrustMutation } from "../../../api/main/trusts/useSaveTrustMutation";
import { useEditTrustViewModel } from "../../../api/main/trusts/viewModels/useEditSchoolViewModel";
import { IdentityRoles } from "../../../configure/security/IdentityRoles";
import { useCurrentUserOrEmulatedSubscription } from "../../../globalState/subscriptions/useCurrentUserOrEmulatedSubscription";
import { AlertOnErrors } from "../../../shared/alertOnErrors";
import { AuthorizeContainer } from "../../../shared/authorizeRoute";
import { HtmlEditor } from "../../../shared/htmlEditor";
import { useChanges } from "../../../shared/useChanges";
import { Banner } from "../../shared/banner/Banner";
import { FileUploadButton } from "../../shared/fileUploadButton/FileUploadButton";
import { FormButtons } from "../../shared/formButtons/FormButtons";
import { LoadingIndicator } from "../../shared/loadingIndicator/LoadingIndicator";
import { MainContainer } from "../../shared/mainContainer/MainContainer";
import { UploadedImagePreview } from "../../shared/uploadedImagePreview/UploadedImagePreview";

export interface EditTrustProps {
    isCreate?: boolean,
    onCreateDefaultValues?: () => Partial<Trust>,
}

/**
 * Create a new Trust
 * @param props
 * @returns
 */
export const CreateTrust = (props: EditTrustProps) => (<EditTrust isCreate={true} {...props} />);

/**
 * Edit an existing Trust
 * @param props
 * @returns
 */
export const EditTrust = (props: EditTrustProps) => {
    const {
        isCreate,
        onCreateDefaultValues
    } = props;
    const { t } = useTranslation();
    const { id } = useParams<{ id: string | undefined; }>();
    const navigate = useNavigate();
    const subscription = useCurrentUserOrEmulatedSubscription();
    // Load the data
    const {
        data: {
            model: storeModel,
            organisationProfiles
        }, isLoading, errors: loadErrors
    } = useEditTrustViewModel(id);

    // Filter organisational profiles to include only templates and custom ones belonging to this trust.
    const organisationProfilesFiltered = organisationProfiles?.filter(item =>
        (!item.schoolId && !item.trustId)
        || (!!subscription?.schoolId && item.schoolId === subscription?.schoolId)
        || (!!subscription?.trustId && item.trustId === subscription?.trustId)
    ) ?? [];


    // Model (Trust)
    const { model, change, changes } = useChanges(storeModel, isCreate ? { ...trustDefaultValues(), ...(onCreateDefaultValues ? onCreateDefaultValues() : {}) } : undefined);
    const [saveTrust, { errors: saveErrors }] = useSaveTrustMutation();

    // Trust image upload (photo)
    const { data: { model: image }, errors: imageLoadErrors } = useBlobReference(model?.imageBlobReferenceId);
    const [onUploadImage, { errors: imageUploadErrors, isExecuting: isUploadingImage }] = useAsyncCallback(async (files: FileList | null) => {
        if (!files) {
            return;
        }

        let uploadService: BlobUploadService = new BlobUploadService("/api/blobs");
        let result = await uploadService.upload(files);

        if (!!result) {
            change({ imageBlobReferenceId: result.id });
        }
    }, [change]);

    // Clear image functionality
    const [clearImage, { isExecuting: isClearingImage }] = useAsyncCallback(async () => {
        change({ imageBlobReferenceId: null });
    }, [change]);

    // Main model validation
    const [validate, validationErrors] = useValidatorCallback((validation, fieldsToCheck) => {
        const rules = {
            name: () => !model?.name ? t('editTrust.errors.nameRequired', 'Name is required') : '',
        };
        validation.checkRules(rules, fieldsToCheck);
    }, [
        model,
    ]);

    // Save the form
    const [saveForm, { isExecuting: isSaving, errors: saveFormErrors }] = useAsyncCallback(async () => {
        if (!model) {
            return;
        }

        if (!validate()) {
            return;
        }

        // Save the main model.
        await saveTrust(model.id, { ...changes }, isCreate ?? false);
      
        // Go back to previous screen.
        navigate(-1);
    }, [
        validate,
        saveTrust,
        model,
        changes,
        isCreate,
        id,
        navigate,
    ]);

    // Render the UI
    //
    return (
        <>
            <Banner>
                <Row>
                    <Col xs={12} md="auto">
                        <h1>
                            {
                                isCreate ? t('editTrust.createHeading.default', 'Add trust')
                                    : t('editTrust.editHeading.default', 'Edit trust')
                            }
                        </h1>
                    </Col>
                    <ConditionalFragment showIf={isLoading}>
                        <Col xs="auto">
                            <LoadingIndicator size="sm" />
                        </Col>
                    </ConditionalFragment>
                </Row>
            </Banner>

            <MainContainer>
                <AlertOnErrors
                    errors={[
                        loadErrors,
                        saveFormErrors,
                        saveErrors,
                        imageLoadErrors,
                        imageUploadErrors
                    ]}
                />

                <Form onSubmit={e => { e.preventDefault(); saveForm(); }}>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label htmlFor="name">{t('editTrust.name.label', 'Name')}</Label>
                                <ValidatedInput name="name" type="text" value={model?.name ?? ""} onChange={e => change({ name: e.currentTarget.value })} onBlur={e => validate('name')} validationErrors={validationErrors['name']} />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label htmlFor="organisationProfiles">{t('editTrust.organisationProfile.label', 'Organisation profile')}</Label>
                                <ValidatedInput name="organisationProfiles" type="select" value={model?.organisationProfileId ?? ''} onChange={e => change({ organisationProfileId: e.currentTarget.value })} onBlur={e => validate('organisationProfileId')} validationErrors={validationErrors['organisationProfileId']}>
                                    <option value="">{t('editTrust.organisationProfileId.pleaseSubmit', '(Please select an organisation profile)')}</option>
                                    {
                                        organisationProfilesFiltered?.map(item => (
                                            <option key={item.id} value={item.id}>{
                                                !!item.schoolId || !!item.trustId ? t('editTrust.organisationProfileId.value', '{{ organisationProfile }} [Customised]', { organisationProfile: item.name })
                                                    : t('editTrust.organisationProfileId.value', '{{ organisationProfile }}', { organisationProfile: item.name })
                                            }</option>
                                        ))
                                    }
                                </ValidatedInput>
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <FormGroup>
                            <Label htmlFor="descriptionHtml">{t('editTrust.description.label', 'Description')}</Label>
                            <HtmlEditor size="sm" value={model?.descriptionHtml ?? ''} onChange={text => change({ descriptionHtml: text })} />
                        </FormGroup>
                    </Row>

                    <AuthorizeContainer requireRole={IdentityRoles.Administration}>
                        <Row>
                            <h4>{t('editTrust.subscriptionOwnerDetails', 'Subscription owner details')}</h4>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label htmlFor="subscriptionOwnerFirstName">{t('editTrust.subscriptionOwnerFirstName.label', 'First name')}</Label>
                                    <ValidatedInput name="subscriptionOwnerFirstName" type="text" value={model?.subscriptionOwnerFirstName ?? ""} onChange={e => change({ subscriptionOwnerFirstName: e.currentTarget.value })} onBlur={e => validate('subscriptionOwnerFirstName')} validationErrors={validationErrors['subscriptionOwnerFirstName']} />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label htmlFor="subscriptionOwnerLastName">{t('editTrust.subscriptionOwnerLastName.label', 'Last name')}</Label>
                                    <ValidatedInput name="subscriptionOwnerFirstName" type="text" value={model?.subscriptionOwnerLastName ?? ""} onChange={e => change({ subscriptionOwnerLastName: e.currentTarget.value })} onBlur={e => validate('subscriptionOwnerLastName')} validationErrors={validationErrors['subscriptionOwnerLastName']} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label htmlFor="subscriptionOwnerEmail">{t('editTrust.subscriptionOwnerEmail.label', 'Email')}</Label>
                                    <ValidatedInput name="subscriptionOwnerEmail" type="text" value={model?.subscriptionOwnerEmail ?? ""} onChange={e => change({ subscriptionOwnerEmail: e.currentTarget.value })} onBlur={e => validate('subscriptionOwnerEmail')} validationErrors={validationErrors['subscriptionOwnerEmail']} />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label htmlFor="subscriptionOwnerId">{t('editTrust.subscriptionOwnerId.label', 'Unique identifier (for Evaluate-Ed use)')}</Label>
                                    <ValidatedInput name="subscriptionOwnerId" type="text" value={model?.subscriptionOwnerId ?? ""} onChange={e => change({ subscriptionOwnerId: e.currentTarget.value })} onBlur={e => validate('subscriptionOwnerId')} validationErrors={validationErrors['subscriptionOwnerId']} />
                                </FormGroup>
                            </Col>
                        </Row>
                    </AuthorizeContainer>

                    <Row>
                        <Col>
                            <FormGroup>
                                <Label htmlFor="name">{t('editTrust.image.label', 'Trust logo:')}</Label>
                                <UploadedImagePreview size="lg" src={image?.url ?? ''} />
                                <Row>
                                    <Col>
                                        <ButtonGroup>
                                            <FileUploadButton
                                                color={`primary`}
                                                isExecuting={isUploadingImage}
                                                executingChildren={<><Spinner size="sm" /> {t('common.uploading', 'Uploading...')}</>}
                                                onUpload={onUploadImage}
                                                outline={false}>
                                                <FontAwesomeIcon icon="image" />
                                                <> </>
                                                {t('editTrust.uploadButtonText', 'Upload an image')}
                                            </FileUploadButton>
                                            <ButtonAsync color="primary"
                                                outline
                                                isExecuting={isClearingImage}
                                                type="button"
                                                onClick={clearImage}
                                                executingChildren={<><Spinner size="sm" /> {t('editTrust.clearingImage', 'Clearing image...')}</>}>
                                                {t('editTrust.clearImageButton', 'Clear image')}
                                            </ButtonAsync>
                                        </ButtonGroup>
                                    </Col>
                                </Row>
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>

                <FormButtons>
                    <ConditionalFragment showIf={!isLoading}>
                        <ButtonAsync color="primary" isExecuting={isSaving} onClick={() => saveForm()}
                            executingChildren={<><Spinner size="sm" /> {t('common.saving', 'Saving...')}</>}>
                            <FontAwesomeIcon icon="save" />
                            <> </>
                            {t('common.save', 'Save')}
                        </ButtonAsync>
                    </ConditionalFragment>

                    <Button type="button" color="primary" outline onClick={e => navigate(-1)}>
                        {t('common.cancel', 'Cancel')}
                    </Button>
                </FormButtons>
            </MainContainer>
        </>
    );
};