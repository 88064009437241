import { TFunction } from "i18next";


/**
 * Role groups
 */
export enum RoleGroup {
    SchoolTrustUser = "User",
    Administrator = "Administrator",
    SchoolAdministrator = "School manager",
    TrustAdministrator = "Trust manager",
}

/**
 * Return all Role group names.
 * @param t
 */
export function roleGroupDisplayName(type: RoleGroup, t: TFunction): string {
    switch (type) {
        case RoleGroup.SchoolTrustUser: return t('roleGroup.User', 'User');
        case RoleGroup.Administrator: return t('roleGroup.Administrator', 'Administrator');
        case RoleGroup.SchoolAdministrator: return t('roleGroup.SchoolAdministrator', 'School manager');
        case RoleGroup.TrustAdministrator: return t('roleGroup.TrustAdministrator', 'Trust manager');
    }
}
/**
 * Return all Role group names.
 * @param t
 */
export function roleGroupDisplayNameFromString(name: string, t: TFunction): string {
    switch (name) {
        case RoleGroup.SchoolTrustUser: return t('roleGroup.User', 'User');
        case RoleGroup.Administrator: return t('roleGroup.Administrator', 'Administrator');
        case RoleGroup.SchoolAdministrator: return t('roleGroup.SchoolAdministrator', 'School manager');
        case RoleGroup.TrustAdministrator: return t('roleGroup.TrustAdministrator', 'Trust manager'); 
        default: return t('roleGroup.User', 'User');
    }
}

/**
 * Function to give us the role groups suitable for different use cases.
 */
export function getRoleGroupsForUserLevel(userLevel: "School" | "Trust" | "Administrator"): Array<string> {
    switch (userLevel) {
        case "School": return [RoleGroup.SchoolTrustUser, RoleGroup.SchoolAdministrator];
        case "Trust": return [RoleGroup.SchoolTrustUser, RoleGroup.TrustAdministrator];
        case "Administrator": return [RoleGroup.Administrator];
        default: return [RoleGroup.SchoolTrustUser];
    }
}
