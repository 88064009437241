import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { CreatePersonnelFlagMutation, CreatePersonnelFlagMutationVariables, PersonnelFlagCreateInput } from '../generated/graphql';
import { personnelFlagFieldsFragment } from '../models/PersonnelFlag';

/**
 * Get a callback to create a PersonnelFlag in the store.
 */
export function useCreatePersonnelFlagMutation(): [(model: PersonnelFlagCreateInput) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<CreatePersonnelFlagMutation, CreatePersonnelFlagMutationVariables>(
        gql`
            mutation createPersonnelFlagMutation ($model: PersonnelFlagCreateInput!) {
                createPersonnelFlag(model: $model) {
                    ...personnelFlagFields
                }
            }

            ${personnelFlagFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (model: PersonnelFlagCreateInput) => {
        const result = await mutationAction({ variables: { model: model } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
