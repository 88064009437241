import { VideosList, VideosListProps } from "../VideosList";

/*
 * Component to view a list of admin videos
*/
export const AdminVideosList = (props: VideosListProps) => (<VideosList isAdmin {...props} />);

/*
 * Component to view a list of trust videos
*/
export const TrustVideosList = (props: VideosListProps) => (<VideosList isTrust {...props} />);

/*
 * Component to view a list of school videos
*/
export const SchoolVideosList = (props: VideosListProps) => (<VideosList {...props} />);