import { NavItem } from 'reactstrap';
import { AuthorizeContainer } from '../../shared/authorizeRoute';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IdentityRoles } from '../../configure/security/IdentityRoles';
import { NavTitle } from '../shared/navTitle/NavTitle';
import { SidebarRequired } from '../layout/sidebar/sidebarRequired/SidebarRequired';
import { NavLinkTrackActive } from '../shared/navLinkTrackActive/NavLinkTrackActive';

/**
 * Administration navigation menu for the app. 
 */
export const AdministrationNavigation = () => {
    const { t } = useTranslation();

    return (
        <>
            <SidebarRequired />
            <AuthorizeContainer requireRole={IdentityRoles.Administration}>
                <NavTitle>
                    {t('administration.subscriptions.heading', 'Subscriptions')}
                </NavTitle>
                <NavItem>
                    <NavLinkTrackActive to="/administration/trusts">
                        <FontAwesomeIcon icon="building-columns" className="nav-icon" />
                        {t('administrationNavigation.trusts', 'Trusts')}
                    </NavLinkTrackActive>
                </NavItem>

                <NavItem>
                    <NavLinkTrackActive to="/administration/schools">
                        <FontAwesomeIcon icon="school" className="nav-icon" />
                        {t('administrationNavigation.schools', 'Schools')}
                    </NavLinkTrackActive>
                </NavItem>

                <NavTitle>
                    {t('administration.organisationStructure.heading', 'Organisation structure')}
                </NavTitle>
                <NavItem>
                    <NavLinkTrackActive to="/administration/organisation-profiles">
                        <FontAwesomeIcon icon="people-roof" className="nav-icon" />
                        {t('administrationNavigation.organisationProfiles', 'Profiles of organisations')}
                    </NavLinkTrackActive>
                </NavItem>

                <NavTitle>
                    {t('administration.careers.heading', 'Careers')}
                </NavTitle>
                <NavItem>
                    <NavLinkTrackActive to="/administration/roles">
                        <FontAwesomeIcon icon="id-card" className="nav-icon" />
                        {t('administrationNavigation.roles', 'Roles')}
                    </NavLinkTrackActive>
                </NavItem>
                <NavItem>
                    <NavLinkTrackActive to="/administration/role-groups">
                        <FontAwesomeIcon icon="layer-group" className="nav-icon" />
                        {t('administrationNavigation.roleGroups', 'Role groups')}
                    </NavLinkTrackActive>
                </NavItem>
                <NavItem>
                    <NavLinkTrackActive to="/administration/skills">
                        <FontAwesomeIcon icon="user-graduate" className="nav-icon" />
                        {t('administrationNavigation.skills', 'Skills')}
                    </NavLinkTrackActive>
                </NavItem>
                <NavItem>
                    <NavLinkTrackActive to="/administration/attributes">
                        <FontAwesomeIcon icon="user-tag" className="nav-icon" />
                        {t('administrationNavigation.attributes', 'Attributes')}
                    </NavLinkTrackActive>
                </NavItem>
                <NavItem>
                    <NavLinkTrackActive to="/administration/qualifications">
                        <FontAwesomeIcon icon="certificate" className="nav-icon" />
                        {t('administrationNavigation.qualifications', 'Qualifications')}
                    </NavLinkTrackActive>
                </NavItem>
                <NavItem>
                    <NavLinkTrackActive to="/administration/experiences">
                        <FontAwesomeIcon icon="business-time" className="nav-icon" />
                        {t('administrationNavigation.experience', 'Experience')}
                    </NavLinkTrackActive>
                </NavItem>

                <NavTitle>
                    {t('administration.evaluateEdLearningLibrary.heading', 'Evaluate-Ed growth resources')}
                </NavTitle>
                <NavItem>
                    <NavLinkTrackActive to="/administration/courses">
                        <FontAwesomeIcon icon="chalkboard-teacher" className="nav-icon" />
                        {t('administrationNavigation.courses', 'Courses')}
                    </NavLinkTrackActive>
                </NavItem>
                <NavItem>
                    <NavLinkTrackActive to="/administration/videos">
                        <FontAwesomeIcon icon="video" className="nav-icon" />
                        {t('administrationNavigation.videos', 'Videos')}
                    </NavLinkTrackActive>
                </NavItem>
                <NavItem>
                    <NavLinkTrackActive to="/administration/podcasts">
                        <FontAwesomeIcon icon="microphone-alt" className="nav-icon" />
                        {t('administrationNavigation.podcasts', 'Podcasts')}
                    </NavLinkTrackActive>
                </NavItem>
                <NavItem>
                    <NavLinkTrackActive to="/administration/events">
                        <FontAwesomeIcon icon="calendar-alt" className="nav-icon" />
                        {t('administrationNavigation.events', 'Events')}
                    </NavLinkTrackActive>
                </NavItem>
                <NavItem>
                    <NavLinkTrackActive to="/administration/books">
                        <FontAwesomeIcon icon="book" className="nav-icon" />
                        {t('administrationNavigation.books', 'Books')}
                    </NavLinkTrackActive>
                </NavItem>
                <NavItem>
                    <NavLinkTrackActive to="/administration/standards">
                        <FontAwesomeIcon icon="file-alt" className="nav-icon" />
                        {t('administrationNavigation.standards', 'Standards')}
                    </NavLinkTrackActive>
                </NavItem>
                <NavTitle>
                    {t('administrationNavigation.lookupsTitle', 'Lookups management')}
                </NavTitle>
                <NavItem>
                    <NavLinkTrackActive to="/administration/personnel-flags">
                        <FontAwesomeIcon icon="flag" className="nav-icon" />
                        {t('administrationNavigation.personnelFlags', 'Personnel flags')}
                    </NavLinkTrackActive>
                </NavItem>

                <NavTitle>
                    {t('administrationNavigation.usersTitle', 'User management')}
                </NavTitle>

                <NavItem>
                    <NavLinkTrackActive to="/administration/users">
                        <FontAwesomeIcon icon="users" className="nav-icon" />
                        {t('administrationNavigation.administrators', 'Administrators')}
                    </NavLinkTrackActive>
                </NavItem>

                <NavItem>
                    <NavLinkTrackActive to="/administration/exportAnalyticEvents">
                        <FontAwesomeIcon icon="cloud-download-alt" className="nav-icon" />
                        {t('administrationNavigation.exportAnalyticEvents', 'Export analytic events')}
                    </NavLinkTrackActive>
                </NavItem>

            </AuthorizeContainer>
        </>
    );
};
