import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Button, ButtonGroup, FormGroup, Label, Modal, ModalHeader, ModalBody, Row, Col, Table, ModalFooter } from "reactstrap";
import { useToggleState } from "use-toggle-state";
import { OrganisationalRoleLink, OrganisationalRole } from "../../../api/main/generated/graphql";
import { OrganisationalRoleLinkType } from "../../../api/main/models/constants/OrganisationalRoleLinkType";
import { LearningContent } from "../../../api/main/models/LearningContent";
import { organisationalRoleLinkDefaultValues } from "../../../api/main/models/OrganisationalRoleLink";
import { ModelArrayChanges } from "../../../shared/useChanges";
import { SearchInput } from "../../shared/searchInput/SearchInput";
import { useDisplayOrder } from "../../shared/useDisplayOrder/useDisplayOrder";
import * as React from 'react';
import { ConditionalFragment } from "react-conditionalfragment";
import { LoadingIndicator } from "../../shared/loadingIndicator/LoadingIndicator";
import { NoResultsFound } from "../../shared/noResultsFound/NoResultsFound";
import { RoleLearningContentComponent } from "./RoleLearningContentComponent";
import { LearningContentType, learningContentTypeDisplayName } from "../../../api/main/models/constants/LearningContentType";

export interface SelectLearningContentModalCloseEventArgs {
    /**
     * All currently selected ids.
     * */
    selectedIds: Array<string>,

    /**
     * Added Ids (those in selectedIds that were not in initialSelectedIds).
     */
    addedIds: Array<string>,

    cancelled: boolean,
}

export interface AssessmentItemsTabProps {
    model: OrganisationalRole | undefined,

    learningOrganisationalRoleLinkManager: ModelArrayChanges<OrganisationalRoleLink, string>,

    allLearningContent: Array<LearningContent>,

    isLoading: boolean,

    initialSelectedIds?: Array<string>,
}

export const RoleLearningContentTab = (props: AssessmentItemsTabProps) => {
    const {
        model,

        learningOrganisationalRoleLinkManager,
        allLearningContent,

        isLoading,

    } = props;

    const { t } = useTranslation();

    // Order the items so they show in and can be managed by displayOrder.
    const [orderedItems, {
        canMoveUp: canMoveAnswerUp,
        moveUp: moveAnswerUp,
        canMoveDown: canMoveAnswerDown,
        moveDown: moveAnswerDown,
    }] = useDisplayOrder(learningOrganisationalRoleLinkManager);

    // Showing of the learningContent modal.
    const [learningContentModalIsOpen, togglelearningContentModal] = useToggleState();
    const openLearningContentModal = useCallback(() => {
        togglelearningContentModal();
    }, [togglelearningContentModal]);

    // Manage the learning content that have been selected.
    const [selectedLearningContents, setSelectedLearningContents] = React.useState<Array<string>>([]);

    const toggleSelection = useCallback((id: string) => {
        setSelectedLearningContents(prevState => {
            const existing = prevState.find(it => it === id);
            if (existing) {
                return prevState.filter(it => it !== id);
            } else {
                return [
                    ...prevState,
                    id,
                ];
            }
        });
    }, [setSelectedLearningContents]);

    const modalCancel = useCallback(() => {

        // Clear the selected learning content.
        setSelectedLearningContents([]);

        // Close the modal.
        togglelearningContentModal();

    }, [togglelearningContentModal]);

    // Close the modal and return the results.
    const closeModal = useCallback((event?: { selectedIds: Array<string> }) => {
        
        const selectedIds = event?.selectedIds ?? selectedLearningContents;

        if (!selectedIds.length) {
            return;
        }

        for (const selectedId of selectedIds) {
            // Only want to add to the manager if the item doesn't already exist.
            if (learningOrganisationalRoleLinkManager.model.find(item => item.targetId === selectedId) === undefined) {
                learningOrganisationalRoleLinkManager.addFor({
                    ...organisationalRoleLinkDefaultValues(),

                    organisationalRoleId: model?.id,
                    organisationalRoleLinkType: OrganisationalRoleLinkType.LearningForRole,
                    targetType: allLearningContent.find(it => it.id === selectedId)?.learningContentType,
                    targetId: selectedId,
                });
            }
        }

        // Clear the selected learning content.
        setSelectedLearningContents([]);

        // Close the modal.
        togglelearningContentModal();

    }, [selectedLearningContents, togglelearningContentModal, learningOrganisationalRoleLinkManager, model, allLearningContent]);

    // Filtering the display between all and selected only.
    const [filter, setFilter] = React.useState<'all' | 'selected'>('all');

    // Filtering of learning content.
    const [search, setSearch] = React.useState<string>('');

    // Filter by the assessment's search client side so it can work when offline as well as online.
    const items = useMemo(() => {
        if (!allLearningContent) {
            return allLearningContent;
        }

        let ret = allLearningContent;

        // Filter by the selected filter.
        switch (filter) {
            case 'all':
                break;
            case 'selected': {
                ret = ret.filter(item => selectedLearningContents.find(it => it === item.id));
            }
        }

        // Filter by the user's search text.
        let lowerSearch = search.toLocaleLowerCase();
        if (lowerSearch) {
            // Filter the items being displayed.
            ret = ret.filter(item => {
                

                return (
                    item.name.toLocaleLowerCase().indexOf(lowerSearch) >= 0
                );
            });
        }

        ret = ret.filter(item => learningOrganisationalRoleLinkManager.model.find(it => it.targetId === item.id) === undefined)

        return ret;


    }, [allLearningContent, search, filter, selectedLearningContents, learningOrganisationalRoleLinkManager]);

    const isSelected = useCallback((id: string) => !!selectedLearningContents.find(it => it === id), [selectedLearningContents]);

    return (
        <>
            <FormGroup>
                <Label htmlFor="learning content">
                    {
                        t('editOrganisationalRole.roleLearningContentTab.learningContent', 'Assigned growth resources')
                    }
                </Label>

                <div>
                    {
                        orderedItems.map(item => {
                            return (
                                <div style={{ marginBottom: 10 }}>
                                    <RoleLearningContentComponent  
                                        content={allLearningContent.find(it => it.id === item.targetId)}

                                        moveUp={() => moveAnswerUp(item.id)} canMoveUp={canMoveAnswerUp(item.id) }
                                        moveDown={() => moveAnswerDown(item.id)} canMoveDown={canMoveAnswerDown(item.id)}
                                        remove={() => learningOrganisationalRoleLinkManager.removeFor(item.id)}

                                        isOpenInitially={!!learningOrganisationalRoleLinkManager.added.find(it => it.id === item.id)}
                                    />
                                </div>
                            );
                        })
                    }
                </div>

                <ButtonGroup>
                    <Button color="primary" outline onClick={() => openLearningContentModal()}>
                        <FontAwesomeIcon icon="plus" className="nav-icon" />
                        {t('editOrganisationalRole.roleLearningContentTab.addLearningContent', ' Manage growth resources')}
                    </Button>
                </ButtonGroup>
            </FormGroup>

            <Modal isOpen={learningContentModalIsOpen} toggle={() => togglelearningContentModal()} size="xl" className="select-learning-content-modal">
                <ModalHeader>
                    {
                        t('editOrganisationalRole.roleLearningContentTab.modal.selectLearningContent', 'Select growth resources')
                    }
                </ModalHeader>
                <ModalBody style={{ maxHeight: '70vh', overflowY: 'scroll' }}>
                    <Row>
                        <Col>
                            <SearchInput value={search} onChange={e => setSearch(e.currentTarget.value)}>
                            </SearchInput>
                        </Col>
                        <Col xs="auto">
                            <ButtonGroup>
                                <Button color="secondary" outline={filter !== 'all'} onClick={() => setFilter('all')}>
                                    {t('editOrganisationalRole.roleLearningContentTab.modal.filter.all', 'All')}
                                </Button>
                                <Button color="secondary" outline={filter !== 'selected'} onClick={() => setFilter('selected')}>
                                    {t('editOrganisationalRole.roleLearningContentTab.modal.filter.selected', 'Selected')}
                                </Button>
                            </ButtonGroup>
                        </Col>
                    </Row>
                    <Table className="select-learning-content-modal-table" responsive striped>
                        <thead>
                            <tr>
                                <th>&nbsp;</th>
                                <th className="d-table-cell d-lg-none">{t('editOrganisationalRole.roleLearningContentTab.modal.learningContent', 'Growth resource')}</th>
                                <th className="d-none d-lg-table-cell">{t('editOrganisationalRole.roleLearningContentTab.modal.name', 'Name')}</th>
                                <th className="d-none d-lg-table-cell">{t('editOrganisationalRole.roleLearningContentTab.modal.contentType', 'Resource type')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                items?.filter(it => it.schoolId === null && it.trustId === null)?.map(item => {
                                    const itemIsSelected = isSelected(item.id);

                                    return (
                                        <tr key={item.id} onDoubleClick={e => closeModal({ selectedIds: [...selectedLearningContents.filter(it => it !== item.id), item.id] })}>
                                            <td style={{ width: '1px' /* Sizes to fit contents */ }}>
                                                <ButtonGroup>
                                                    <Button color="secondary" outline={!itemIsSelected} onClick={() => toggleSelection(item.id)}>
                                                        {
                                                            itemIsSelected ? t('editOrganisationalRole.roleLearningContentTab.modal.selected', 'Selected')
                                                                : t('editOrganisationalRole.roleLearningContentTab.modal.select', 'Select')
                                                        }
                                                    </Button>
                                                    <ConditionalFragment showIf={itemIsSelected}>
                                                        <Button color="secondary" outline={!isSelected} onClick={() => toggleSelection(item.id)}>
                                                            <FontAwesomeIcon icon="times" />
                                                        </Button>
                                                    </ConditionalFragment>
                                                </ButtonGroup>
                                            </td>
                                            <td className="d-table-cell d-lg-none">
                                                <div>{item.name}</div>
                                            </td>
                                            <td className="d-none d-lg-table-cell">{item.name}</td>
                                            <td className="d-none d-lg-table-cell">{learningContentTypeDisplayName(item.learningContentType as LearningContentType, t)}</td>
                                        </tr>
                                    );
                                })
                            }
                            <ConditionalFragment showIf={!!isLoading && !items?.length}>
                                <tr><td colSpan={3}><LoadingIndicator fullWidth /></td></tr>
                            </ConditionalFragment>
                            <ConditionalFragment showIf={!isLoading && !items?.length}>
                                <tr><td colSpan={3}>
                                    <NoResultsFound search={search} />
                                </td></tr>
                            </ConditionalFragment>
                        </tbody>
                    </Table>
                </ModalBody>
                <ModalFooter>
                    <ConditionalFragment showIf={!!selectedLearningContents.length}>
                        <Button color="primary" onClick={() => closeModal()}>
                            {
                                t('editOrganisationalRole.roleLearningContentTab.modal.add', 'Add {{count}} selected items', { count: selectedLearningContents.length })
                            }
                        </Button>
                        <> </>
                    </ConditionalFragment>
                    <Button color="primary" outline onClick={() => modalCancel()}>
                        {t('common.cancel', 'Cancel')}
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
}