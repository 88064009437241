import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useMemo, useState } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { Button, ButtonGroup, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap";
import { ButtonAsync } from "reactstrap-buttonasync";
import { useOrganisationalRoles } from "../../../api/main/organisationalRoles/useOrganisationalRoles";
import { AlertOnErrors } from "../../../shared/alertOnErrors";
import { HtmlDisplay } from "../../../shared/htmlEditor";
import { CardsOrTable } from "../../shared/cardsOrTable/CardsOrTable";
import { LoadingIndicator } from "../../shared/loadingIndicator/LoadingIndicator";
import { SearchInput } from "../../shared/searchInput/SearchInput";

export interface AddOrganisationalRolesModalCloseEventArgs {
    /**
     * Selected items
     */
    selectedIds: Array<string>,
    cancelled: boolean,
};

export interface AddOrganisationalRolesModalProps {
    isOpen: boolean,
    toggle: () => void,
    onClose: (event: AddOrganisationalRolesModalCloseEventArgs) => Promise<void>,
    isSaving: boolean,

    storeOrganisationalRoleLinkIds: Array<string> | null | undefined;
};

/**
 * Modal for managing organisational roles under a OrganisationalProfile.
 * @returns
 */
export const AddOrganisationalRolesModal = (props: AddOrganisationalRolesModalProps) => {
    const {
        isOpen,
        toggle,
        onClose,
        isSaving,
        storeOrganisationalRoleLinkIds
    } = props;
    const { t } = useTranslation();

    // Load the data
    const {
        data: {
            items: allItems
        }, isLoading, errors: loadErrors
    } = useOrganisationalRoles();

    // Filtering OrgnaisationalRoles
    const [search, setSearch] = useState<string>('');

    // Filter out the roles we have already assigned
    const items = useMemo(() => {
        if (!allItems) {
            return allItems;
        }

        // Filter out the OrganisationalRoles that are already assigned
        let ret = allItems;
        ret = ret.filter(item => !storeOrganisationalRoleLinkIds?.includes(item.id));

        // Filter by search
        let lowerSearch = search.toLowerCase();
        if (lowerSearch) {
            ret = ret.filter(item =>
                item.name.toLowerCase().indexOf(lowerSearch) >= 0
                || item.descriptionHtml.toLowerCase().indexOf(lowerSearch) >= 0
            );
        }

        // Return the results
        return ret;
    }, [allItems, storeOrganisationalRoleLinkIds, search]);
    console.log(items);
    // Add the selected OrganisationalRoles
    const [selectedIds, setSelectedIds] = useState<Array<string>>([]);
    const toggleSelection = useCallback((id: string) => {
        setSelectedIds(prevState => {
            const existing = prevState.find(it => it === id);
            if (existing) {
                return prevState.filter(it => it !== id);
            }

            return [
                ...prevState,
                id
            ];
        });
    }, [setSelectedIds]);
    const isSelected = useCallback((id: string) => !!selectedIds.find(it => it === id), [selectedIds]);

    // Close the modal and return the results
    const closeModal = useCallback(async (event?: { selectedIds: Array<string>, cancelled?: boolean; }) => {
        if (onClose) {
            const thisSelectedIds = event?.selectedIds ?? selectedIds;

            const externalEvent: AddOrganisationalRolesModalCloseEventArgs = {
                ...(event ?? {}),

                selectedIds: thisSelectedIds,
                cancelled: event?.cancelled ?? false,
            };
            setSelectedIds([]);
            await onClose(externalEvent);
        }

        // Close the modal
        toggle();
    }, [selectedIds, toggle, onClose,]);

    // Render the UI
    //
    return (
        <>
            <Modal
                isOpen={isOpen}
                toggle={toggle}
                size="xl"
            >
                <ModalHeader toggle={toggle}>
                    {t('manageOrganisationalRolesModal.title', 'Add organisational roles')}

                    <ConditionalFragment showIf={!!isLoading}>
                        <LoadingIndicator />
                    </ConditionalFragment>
                </ModalHeader>

                <AlertOnErrors
                    errors={[
                        loadErrors
                    ]}
                />


                <ModalBody>
                    <SearchInput value={search} onChange={e => setSearch(e.currentTarget.value)} />

                    <CardsOrTable
                        viewMode={"table"}
                        items={items}
                        tableHeadings={[
                            t('manageOrganisationalRolesModal.selected.heading', 'Selected'),
                            t('manageOrganisationalRolesModal.name.heading', 'Name'),
                            t('manageOrganisationalRolesModal.description.heading', 'Description'),
                        ]}
                        columns={[
                            // Selected
                            (item, view) => {
                                const itemIsSelected = isSelected(item.id);

                                return (
                                    <>
                                        <ButtonGroup>
                                            <Button color="primary" outline={!itemIsSelected} onClick={() => toggleSelection(item.id)} >
                                                {
                                                    itemIsSelected ? t('manageOrganisationalRolesModal.organisationalRole.selected', 'Selected') : t('manageOrganisationalRolesModal.organisationalRole.select', 'Select')
                                                }
                                            </Button>
                                            <ConditionalFragment showIf={itemIsSelected}>
                                                <Button color="primary" outline={!isSelected} onClick={() => toggleSelection(item.id)}>
                                                    <FontAwesomeIcon icon="times" />
                                                </Button>
                                            </ConditionalFragment>
                                        </ButtonGroup>
                                    </>
                                );
                            },

                            // Name
                            (item, view) => {
                                return item.name;
                            },

                            // Description
                            (item, view) => {
                                return <HtmlDisplay html={item.descriptionHtml} />;
                            },
                        ]}
                    />
                </ModalBody>

                <ModalFooter>
                    <ButtonAsync color="primary" onClick={() => closeModal({ selectedIds: selectedIds, cancelled: false })} isExecuting={isSaving ?? false}
                        executingChildren={<><Spinner size="sm" /> {t('common.adding', 'Adding...')}</>}>
                        <FontAwesomeIcon icon="save" />
                        <> </>
                        {t('manageOrganisationalRolesModal.addSelected.add', 'Add {{count}} selected Organisational {{pluralRoleOrNo}}', { count: selectedIds.length, pluralRoleOrNo: selectedIds.length === 1 ? 'role' : 'roles' })}
                    </ButtonAsync>

                    <Button type="button" color="primary" outline onClick={() => closeModal({ selectedIds: [], cancelled: true })}>
                        {t('common.cancel', 'Cancel')}
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
};