import * as React from 'react';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useUpdateOrganisationalRoleLinkMutation } from './useUpdateOrganisationalRoleLinkMutation';
import { OrganisationalRoleLink } from '../models/OrganisationalRoleLink';
import { useCreateOrganisationalRoleLinkMutation } from './useCreateOrganisationalRoleLinkMutation';
import { OrganisationalRoleLinkCreateInput, OrganisationalRoleLinkUpdateInput } from '../generated/graphql';

/**
 * Get a callback to save a OrganisationalRoleLink in the store using either a create or update.
 */
export function useSaveOrganisationalRoleLinkMutation(options: SaveInStoreOptions<OrganisationalRoleLink, string> = {}) {
    const [_create, createStatus] = useCreateOrganisationalRoleLinkMutation();
    const create = React.useCallback((model: Partial<OrganisationalRoleLink>) => _create(model as OrganisationalRoleLinkCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateOrganisationalRoleLinkMutation();
    const update = React.useCallback((id: string, changes: Partial<OrganisationalRoleLink>) => _update(id, changes as OrganisationalRoleLinkUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
