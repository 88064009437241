import { Guid } from "guid-string";
import moment from "moment";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useCreateAnalyticEventMutation } from "../../api/main/analyticEvents/useCreateAnalyticEventMutation";
import { AnalyticEventType } from "../../api/main/models/constants/AnalyticEventType";
import { useCurrentUserProfile } from "../../api/main/profiles/useCurrentUserProfile";
import { useSearchParams } from "../../shared/useURLSearchParams";
import { LoadingIndicator } from "../shared/loadingIndicator/LoadingIndicator";


/**
 * Redirects the user to a link, logging the event for analytical purposes along the way.
 */
export const RedirectToLink = () => {
    const { url } = useParams<{ url: string }>();
    const { learningContentId } = useSearchParams();
    const  navigate  = useNavigate();
    const profile = useCurrentUserProfile();

    const [createAnalyticEvent] = useCreateAnalyticEventMutation();
    const [hasBeenLogged, setHasBeenLogged] = React.useState<boolean>(false);
    React.useEffect(() => {
        if (hasBeenLogged) {
            return;
        }

        setHasBeenLogged(true);

        (async () => {
            const realUrl = decodeURIComponent(url ?? '');

            await createAnalyticEvent({
                id: Guid.newGuid(),
                userId: profile.data.model?.userId ?? '',
                eventDate: moment(),
                eventType: learningContentId ? AnalyticEventType.LearningContentClick : AnalyticEventType.ExternalLinkClick,
                schoolId: profile.data.model?.schoolId || null,
                trustId: profile.data.model?.trustId || null,
                learningContentId: learningContentId || null,
                url: realUrl,
                archived: false,
            });
            window.open(realUrl);
            navigate(-1);
        })()
    }, [hasBeenLogged, setHasBeenLogged, createAnalyticEvent, url, profile.data.model, learningContentId, navigate]);

    return (
        <LoadingIndicator fullWidth />
    );
};