import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { CreateTrustMutation, CreateTrustMutationVariables, TrustCreateInput } from '../generated/graphql';
import { trustFieldsFragment } from '../models/Trust';

/**
 * Get a callback to create a Trust in the store.
 */
export function useCreateTrustMutation(): [(model: TrustCreateInput) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<CreateTrustMutation, CreateTrustMutationVariables>(
        gql`
            mutation createTrustMutation ($model: TrustCreateInput!) {
                createTrust(model: $model) {
                    ...trustFields
                }
            }

            ${trustFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (model: TrustCreateInput) => {
        const result = await mutationAction({ variables: { model: model } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
