import { TFunction } from "i18next";

/**
 * Types of link that could be represented by a OrganisationRoleLink.
 */
export enum OrganisationalRoleLinkType {
    MainNextRole = "MainNextRole",
    NextRole = "NextRole",
    DisabledNextRole = "DisabledNextRole",

    OrganisationProfile = "OrganisationProfile",
    RoleInOrganisationProfile = "RoleInOrganisationProfile",
    DisabledRoleInOrganisationProfile = "DisabledRoleInOrganisationProfile",

    LearningForRole = "LearningForRole",
    AdditionalLearningForRole = "AdditionalLearningForRole",
    DisabledLearningForRole = "DisabledLearningForRole",

    User = "UserRole",
    AspirationalUserRole = "AspirationalUserRole",

    RoleMapUserPin = "RoleMapUserPin",

    RoleInOrganisationalRoleGroup = "RoleInOrganisationalRoleGroup",
}

/**
 * Return the display text for a OrganisationalRoleLinkType.
 * @param t
 */
export function organisationalRoleLinkTypeDisplayName(type: OrganisationalRoleLinkType | string | undefined | null, t: TFunction): string {
    switch (type) {
        //Next Role
        case OrganisationalRoleLinkType.MainNextRole: return t('organisationRoleLinkTypeDisplayName.MainNextRole', 'Primary next role');
        case OrganisationalRoleLinkType.NextRole: return t('organisationRoleLinkTypeDisplayName.NextRole', 'Secondary next role');
        case OrganisationalRoleLinkType.DisabledNextRole: return t('organisationRoleLinkTypeDisplayName.DisabledNextRole', 'Disabled next role');

        //Role in organisational profile
        case OrganisationalRoleLinkType.OrganisationProfile: return t('organisationRoleLinkTypeDisplayName.OrganisationProfile', 'OrganisationProfile');
        case OrganisationalRoleLinkType.RoleInOrganisationProfile: return t('organisationRoleLinkTypeDisplayName.RoleInOrganisationProfile', 'Role in organisation');
        case OrganisationalRoleLinkType.DisabledRoleInOrganisationProfile: return t('organisationRoleLinkTypeDisplayName.DisabledRoleInOrganisationProfile', 'Disabled role in organisation');

        //Learning
        case OrganisationalRoleLinkType.LearningForRole: return t('organisationRoleLinkTypeDisplayName.learningForRole', 'Learning for role');
        case OrganisationalRoleLinkType.AdditionalLearningForRole: return t('organisationRoleLinkTypeDisplayName.AdditionalLearningForRole', 'Additional learning for role');
        case OrganisationalRoleLinkType.DisabledLearningForRole: return t('organisationRoleLinkTypeDisplayName.DisabledLearningForRole', 'Disabled learning for role'); 

        // Users
        case OrganisationalRoleLinkType.User: return t('organisationRoleLinkTypeDisplayName.user', 'User');
        case OrganisationalRoleLinkType.AspirationalUserRole: return t('organisationRoleLinkTypeDisplayName.desiredUserRole', 'Aspirational role');

        // Pins
        case OrganisationalRoleLinkType.RoleMapUserPin: return t('organisationRoleLinkTypeDisplayName.roleMapUserPin', 'User pinned for role');

        // OrganisationalRoleGroup
        case OrganisationalRoleLinkType.RoleInOrganisationalRoleGroup: return t('organisationRoleLinkTypeDisplayName.roleInOrganisationalRoleGroup', 'Role in organisational role group');
    }

    return t('organisationRoleLinkTypeDisplayName.unknown', 'Unknown');
}
