import gql from 'graphql-tag';
import { Guid } from 'guid-string';
import { PersonnelFlagUserFieldsFragment } from '../generated/graphql';

export const personnelFlagUserFieldsFragment = gql`
    fragment personnelFlagUserFields on PersonnelFlagUser {
        id
        userId
        personnelFlagId
        archived
        noteHtml
        trustId
        schoolId
        organisationalRoleId
    }
`;

export type PersonnelFlagUser = Omit<PersonnelFlagUserFieldsFragment, '__typename' | 'personnelFlagUser'>;

/**
 * Default values.
 */
export const personnelFlagUserDefaultValues = (): Partial<PersonnelFlagUser> => ({
    id: Guid.newGuid(),
    userId: '',
    personnelFlagId: undefined,
    archived: false,
    noteHtml: '',
    trustId: null,
    schoolId: null,
    organisationalRoleId: null,
});
