import { useLazyQuery } from "@apollo/client";
import { AsyncLoadOptions, AsyncLoadResult } from "../../../../shared/abstractStore";
import gql from "graphql-tag";
import { EditSchoolViewModelQuery, EditSchoolViewModelQueryVariables } from '../../generated/graphql';
import { mainApiConfig } from "../../../../configure/mainApiConfig";
import { Guid } from "guid-string";
import React from "react";
import { schoolFieldsFragment } from "../../models/School";
import { organisationProfileFieldsFragment } from "../../models/OrganisationProfile";
import { trustFieldsFragment } from "../../models/Trust";

/**
 * Get all model data required for the EditSchool component
 * @param id
 * @param options
 */
export function useEditSchoolViewModel(id: string | undefined | null, options: AsyncLoadOptions = {}): AsyncLoadResult<EditSchoolViewModelQuery> {
    // Query the data we need from the api
    const [load, { data, loading, error, refetch }] = useLazyQuery<EditSchoolViewModelQuery, EditSchoolViewModelQueryVariables>(
        gql`
        query editSchoolViewModelQuery ($id: ID!) {
            model: school (id: $id) {
                ...schoolFields
            }

            trusts {
                ...trustFields
            }

            organisationProfiles {
                ...organisationProfileFields
            }
        }

        ${schoolFieldsFragment}
        ${organisationProfileFieldsFragment}
        ${trustFieldsFragment}
        `,
        {
            variables: {
                id: id ?? Guid.empty,
            },
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load]);

    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error,
    }), [data, refresh, loading, error]);
}
