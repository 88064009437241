import { IdentityRoles } from "../../configure/security/IdentityRoles";
import { RouteEntry } from "../../shared/routes";
import { AdminCreateUserContainer } from "./containers/AdminCreateUserContainer";
import { AdminEditUserContainer } from "./containers/AdminEditUserContainer";
import { AdminUsersListContainer } from "./containers/AdminUsersListContainer";
import { DeleteUser } from "./DeleteUser";

export const userRoutes: Array<RouteEntry> = [
    { path: '/administration/users/add', element: <AdminCreateUserContainer />, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/users/edit/:id', element: <AdminEditUserContainer />, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/users/delete/:id', element: <DeleteUser />, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/users', element: <AdminUsersListContainer />, authorize: true, requireRole: IdentityRoles.Administration },
];
