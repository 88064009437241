import React from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { Col, Row } from "reactstrap";
import { useEditOrganisationProfileViewModel } from "../../api/main/organisationProfiles/viewModels/useEditOrganisationProfileViewModel";
import { usePersonnelFlags } from "../../api/main/personnelFlags/usePersonnelFlags";
import { useUsersListViewModel } from "../../api/main/profiles/viewModels/useUsersListViewModel";
import { useRequirementLinks } from "../../api/main/requirementLinks/useRequirementLinks";
import { useRequirements } from "../../api/main/requirements/useRequirements";
import { useSchool } from "../../api/main/schools/useSchool";
import { useTrust } from "../../api/main/trusts/useTrust";
import { useCurrentUserOrEmulatedSubscription } from "../../globalState/subscriptions/useCurrentUserOrEmulatedSubscription";
import { AlertOnErrors } from "../../shared/alertOnErrors";
import { RenderIfVisible } from "../../shared/useIsElementVisible";
import { Banner } from "../shared/banner/Banner";
import { LoadingIndicator } from "../shared/loadingIndicator/LoadingIndicator";
import { MainContainer } from "../shared/mainContainer/MainContainer";
import { NoResultsFound } from "../shared/noResultsFound/NoResultsFound";
import { SearchInput } from "../shared/searchInput/SearchInput";
import { UserMapPart } from "./userMapPart";

export interface UserMapProps
{
    isSchool?: boolean;
    isTrust?: boolean;
}

export const UserMap = (props: UserMapProps) => {
    const { t } = useTranslation();
    const isSchool = props.isSchool ?? false;
    const isTrust = props.isTrust ?? false;
    const subscription = useCurrentUserOrEmulatedSubscription();

    // Get the school or trust from the subscription so we can get its organisation profile.
    const {
        data: {
            model: school,
        }, isLoading: schoolLoading, errors: schoolErrors
    } = useSchool(isSchool ? subscription?.schoolId : null);
    const {
        data: {
            model: trust,
        }, isLoading: trustLoading, errors: trustErrors
    } = useTrust(isTrust ? subscription?.trustId : null);

    // Get the organisation profile from the school or trust.
    const {
        data: {
            model: organisationProfile,
            organisationalRoleLinks: storeOrganisationalRoleLinks,
            organisationalRoles: _storeOrganisationalRoles
        }, isLoading, errors: loadErrors, refresh
    } = useEditOrganisationProfileViewModel(isSchool ? school?.organisationProfileId : isTrust ? trust?.organisationProfileId : null);

    // We only care about roles for which we have a link.
    const storeOrganisationalRoles = React.useMemo(() => {
        return _storeOrganisationalRoles?.filter(item => storeOrganisationalRoleLinks?.some(it => it.organisationalRoleId === item.id)) ?? [];
    }, [storeOrganisationalRoleLinks]);

    // Get all the users and filter them to only show the ones from the current school/trust.
    const {
        data: {
            items: allUsers,
        }, isLoading: allUsersLoading, errors: allUsersErrors, refresh: refreshAllUsers
    } = useUsersListViewModel({ trustId: isTrust ? subscription?.trustId : null, schoolId: isSchool ? subscription?.schoolId : null, });

    const users = React.useMemo(() => {
        let ret = (allUsers ?? []);

        let trustId = trust?.id;
        let schoolId = school?.id;

        //Filter out archived users
        ret = ret.filter(it => !it.archived);

        //Filter by the trust
        if (!!trustId && isTrust) {
            ret = ret.filter(it => it.trustId === trustId);
        }

        //Filter by the school
        if (!!schoolId && isSchool) {
            ret = ret.filter(it => it.schoolId === schoolId);
        }

        return ret;
    }, [allUsers, isSchool, isTrust, school, trust]);

    // Load all role links, so our children don't each need to query for it.
    //const {
    //    data: {
    //        items: allOrganisationalRoleLinks,
    //    }, isLoading: allOrganisationalRoleLinksLoading, errors: allOrganisationalRoleLinksErrors
    //} = useOrganisationalRoleLinks();

    // Load all personnel flags, so our children don't need to query for it individually
    const {
        data: {
            items: allPersonnelFlags,
        }, isLoading: allPersonnelFlagsLoading, errors: allPersonnelFlagsErrors
    } = usePersonnelFlags();

    // Load all requirements and requirement links, so our children don't need to query for it individually
    const {
        data: {
            items: allRequirements,
        }, isLoading: allRequirementsLoading, errors: allRequirementsErrors
    } = useRequirements();
    const {
        data: {
            items: allRequirementLinks,
        }, isLoading: allRequirementLinksLoading, errors: allRequirementLinksErrors
    } = useRequirementLinks();

    const loading = schoolLoading || trustLoading || isLoading || allUsersLoading ||  allPersonnelFlagsLoading || allRequirementsLoading || allRequirementLinksLoading;

    // Allow the user to search to filter.
    const [search, setSearch] = React.useState<string>('');
    const filteredUsers = React.useMemo(() => {
        return users?.filter(user => {
            if (!!search) {
                const fullName = `${user.firstName} ${user.lastName}`;

                const lowerSearch = search.toLowerCase();
                if (!fullName?.toLowerCase().includes(lowerSearch)) {
                    return false;
                }
            }

            return true;
        });
    }, [search, users]);

    return (
        <div style={{ maxWidth: '87vw' }}>
            <Banner fluid>
                <AlertOnErrors errors={[loadErrors, schoolErrors, trustErrors, allUsersErrors,  allPersonnelFlagsErrors, allRequirementsErrors, allRequirementLinksErrors]} />
                <Row>
                    <Col xs={12} md="auto">
                        <h1>
                            {
                                isSchool ? t('roleMap.schoolHeading.default', `Career fulfilment: ${school?.name}`)
                                    : t('roleMap.trustHeading.default', `Career fulfilment: ${trust?.name}`)
                            }
                        </h1>
                    </Col>
                    <ConditionalFragment showIf={loading}>
                        <Col xs="auto">
                            <LoadingIndicator size="sm" />
                        </Col>
                    </ConditionalFragment>
                    <Col></Col>
                    <Col xs={12} md="auto">
                        <SearchInput value={search} onChange={e => setSearch(e.currentTarget.value)} placeholder={t('roleMap.searchPlaceholder', 'Search people')} />
                    </Col>
                </Row>
            </Banner>
            <MainContainer fluid> 
                {
                    !!filteredUsers.length ? filteredUsers?.map((user, index) => {
                        return (
                            <RenderIfVisible key={index} fallback={<div style={{ minWidth: '100%', minHeight: '245px' }} ></div>}>
                                <UserMapPart
                                    user={user}
                                    roles={storeOrganisationalRoles}
                                    allRequirements={allRequirements}
                                    allRequirementLinks={allRequirementLinks}
                                    allPersonnelFlags={allPersonnelFlags}
                                    isTrust={isTrust}
                                    school={school}
                                />
                            </RenderIfVisible>
                        )
                    }) : (
                        <NoResultsFound />
                    )
                }
            </MainContainer>
        </div>
    );
};