import { useCallback } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "reactstrap";
import { useBlobReference } from "../../api/main/blobReferences/useBlobReference";
import { AlertOnErrors } from "../../shared/alertOnErrors";
import { LoadingIndicator } from "../shared/loadingIndicator/LoadingIndicator";
import { UploadedFilePreview } from "../shared/uploadedFilePreview/UploadedFilePreview";
import './consumeFile.scss';

/**
 * Modal for watching a video.
 */
export const ConsumeFile = () => {
    const { blobId, thumbnailId } = useParams<{ blobId: string | undefined, thumbnailId: string | undefined; }>();
    const {t } = useTranslation();
    const navigate = useNavigate();

    // Load the content
    const {
        data: {
            model: video
        }, isLoading: _isLoading, errors: videoLoadErrors
    } = useBlobReference(blobId);

    // Load the thumbnail
    const {
        data: {
            model: thumbnail
        }, isLoading: isLoadingThumbnail, errors: thumbnailLoadErrors
    } = useBlobReference(thumbnailId);
    const isLoading = isLoadingThumbnail || _isLoading;

    // Remove the file extension from the filename
    const removeFileExtension = useCallback((filename: string) => {
        const words = filename.split('.');

        return words.slice(0, words.length - 1).join('');
    }, []);

    // Render the UI
    //
    return (
        <>
            <AlertOnErrors
                errors={[
                    videoLoadErrors, thumbnailLoadErrors
                ]}
            />

            <div className="consume-file">

                <div className="embed-responsive embed-responsive-16by9">
                    <ConditionalFragment showIf={!!isLoading}>
                        <LoadingIndicator />
                    </ConditionalFragment>

                    {/* If we are working with an Audio or Video file, then render it here */}
                    <ConditionalFragment showIf={!!video?.mimeType.startsWith('video') || !!video?.mimeType.startsWith('audio')}>
                        <h1 className="consume-file-title">{removeFileExtension(video?.filename ?? '')}</h1>

                        <video
                            className="consume-file-video embed-responsive-item"
                            poster={(thumbnail && thumbnail.url) || ''}
                            src={(video && video.url) || ''}
                            controls>
                        </video>
                    </ConditionalFragment>

                    {/* Any other file will be shown here */}
                    <ConditionalFragment showIf={!video?.mimeType.startsWith('video') && !video?.mimeType.startsWith('audio')}>
                        <UploadedFilePreview blobReference={video} />
                    </ConditionalFragment>
                    <Button onClick={e => navigate(-1)} style={{ position: 'relative', left:"23rem", top: "1rem" }}>
                        {t('common.back.button', 'Back') }
                    </Button>
                </div>
            </div>
        </>
    );
};