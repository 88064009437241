import * as React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useValidatorCallback } from "pojo-validator-react";
import { ValidatedInput } from "pojo-validator-reactstrap";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useAsyncCallback } from "react-use-async-callback";
import { Button, ButtonGroup, Col, Form, FormGroup, Input, Label, Row, Spinner } from "reactstrap";
import { ButtonAsync } from "reactstrap-buttonasync";
import { BlobUploadService } from "../../../api/main/blobReferences/BlobUploadService";
import { useBlobReference } from "../../../api/main/blobReferences/useBlobReference";
import { School, schoolDefaultValues } from "../../../api/main/models/School";
import { useSaveSchoolMutation } from "../../../api/main/schools/useSaveSchoolMutation";
import { useEditSchoolViewModel } from "../../../api/main/schools/viewModels/useEditSchoolViewModel";
import { AlertOnErrors } from "../../../shared/alertOnErrors";
import { HtmlEditor } from "../../../shared/htmlEditor";
import { useChanges } from "../../../shared/useChanges";
import { Banner } from "../../shared/banner/Banner";
import { FileUploadButton } from "../../shared/fileUploadButton/FileUploadButton";
import { FormButtons } from "../../shared/formButtons/FormButtons";
import { LoadingIndicator } from "../../shared/loadingIndicator/LoadingIndicator";
import { MainContainer } from "../../shared/mainContainer/MainContainer";
import { UploadedImagePreview } from "../../shared/uploadedImagePreview/UploadedImagePreview";
import { useCurrentUserOrEmulatedSubscription } from '../../../globalState/subscriptions/useCurrentUserOrEmulatedSubscription';
import { AuthorizeContainer } from '../../../shared/authorizeRoute';
import { IdentityRoles } from '../../../configure/security/IdentityRoles';

export interface EditSchoolProps {
    isCreate?: boolean,
    isAdmin?: boolean,
    onCreateDefaultValues?: () => Partial<School>,
}

/**
 * Create a new School
 * @param props
 * @returns
 */
export const CreateSchool = (props: EditSchoolProps) => (<EditSchool isCreate={true} {...props} />);

/**
 * Edit an existing School
 * @param props
 * @returns
 */
export const EditSchool = (props: EditSchoolProps) => {
    const {
        isCreate,
        onCreateDefaultValues,

        // If the user is on the administration section or not.
        isAdmin = false,
    } = props;
    const { t } = useTranslation();
    const { id } = useParams<{ id: string | undefined; }>();
    const navigate = useNavigate();

    // Load the data
    const {
        data: {
            model: storeModel,
            trusts,
            organisationProfiles
        }, isLoading, errors: loadErrors
    } = useEditSchoolViewModel(id);


    // Model (School)
    const { model, change, changes } = useChanges(storeModel, isCreate ? { ...schoolDefaultValues(), ...(onCreateDefaultValues ? onCreateDefaultValues() : {}) } : undefined);
    const [saveSchool, { errors: saveErrors }] = useSaveSchoolMutation();

    const assignedTrust = React.useMemo(() => { return trusts?.find(it => it.id === model?.trustId) }, [trusts, model.trustId]);

    // School image upload (photo)
    const { data: { model: image }, errors: imageLoadErrors } = useBlobReference(model?.imageBlobReferenceId);
    const [onUploadImage, { errors: imageUploadErrors, isExecuting: isUploadingImage }] = useAsyncCallback(async (files: FileList | null) => {
        if (!files) {
            return;
        }

        let uploadService: BlobUploadService = new BlobUploadService("/api/blobs");
        let result = await uploadService.upload(files);

        if (!!result) {
            change({ imageBlobReferenceId: result.id });
        }
    }, [change]);

    // Clear image functionality
    const [clearImage, { isExecuting: isClearingImage }] = useAsyncCallback(async () => {
        change({ imageBlobReferenceId: null });
    }, [change]);

    // Main model validation
    const [validate, validationErrors] = useValidatorCallback((validation, fieldsToCheck) => {
        const rules = {
            name: () => !model?.name ? t('editSchool.errors.nameRequired', 'Name is required') : '',
            organisationProfileId: () => !model?.organisationProfileId ? t('editSchool.errors.organisationalProfileRequired', 'Organisational profile is required') : '',
        };
        validation.checkRules(rules, fieldsToCheck);
    }, [
        model,
    ]);

    const subscription = useCurrentUserOrEmulatedSubscription(); // Grabs current/emulated subscription

    // Filter out organisation profiles that aren't presets or the one they've currently got.
    const organisationProfilesFiltered = organisationProfiles?.filter(item =>
        (!item.schoolId && !item.trustId)
        || (!!subscription?.schoolId && item.schoolId === subscription?.schoolId)
        || (!!subscription?.trustId && item.trustId === subscription?.trustId)
    ) ?? [];

    React.useEffect(() => {
        if (!isAdmin) {
                // If we are not on admin then we must be in a trust
                if (!!subscription?.trustId) {
                    change({ trustId: subscription.trustId })
                }
        }
        // Dont set anything if we are on admin

    }, [subscription, isAdmin, change]);

    // Save the form
    const [saveForm, { isExecuting: isSaving, errors: saveFormErrors }] = useAsyncCallback(async () => {
        if (!model) {
            return;
        }

        if (!validate()) {
            return;
        }

        // Save the main model.
        await saveSchool(model.id, { ...changes }, isCreate ?? false);

        // Go back to previous screen.
        navigate(-1);
    }, [
        validate,
        saveSchool,
        model,
        changes,
        isCreate,
        id,
        navigate,
    ]);

    // Render the UI
    //
    return (
        <>
            <Banner>
                <Row>
                    <Col xs={12} md="auto">
                        <h1>
                            {
                                isCreate ? t('editSchool.createHeading.default', 'Add school')
                                    : t('editSchool.editHeading.default', 'Edit school')
                            }
                        </h1>
                    </Col>
                    <ConditionalFragment showIf={isLoading}>
                        <Col xs="auto">
                            <LoadingIndicator size="sm" />
                        </Col>
                    </ConditionalFragment>
                </Row>
            </Banner>

            <MainContainer>
                <AlertOnErrors
                    errors={[
                        loadErrors,
                        saveFormErrors,
                        saveErrors,
                        imageLoadErrors,
                        imageUploadErrors
                    ]}
                />

                <Form onSubmit={e => { e.preventDefault(); saveForm(); }}>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label htmlFor="name">{t('editSchool.name.label', 'Name')}</Label>
                                <ValidatedInput name="name" type="text" value={model?.name ?? ""} onChange={e => change({ name: e.currentTarget.value })} onBlur={e => validate('name')} validationErrors={validationErrors['name']} />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label htmlFor="trusts">{t('editSchool.trust.label', 'Trust')}</Label>
                                <Input name="trusts" type="select" value={model?.trustId ?? ''} disabled={!isAdmin} onChange={e => change({ trustId: e.currentTarget.value })}>
                                    <option value="">{t('editSchool.trustId.pleaseSubmit', '(Please select a trust)')}</option>
                                    {
                                        trusts?.map(item => (
                                            <option key={item.id} value={item.id}>{t('editSchool.trustId.value', '{{ trustId }}', { trustId: item.name })}</option>
                                        ))
                                    }
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label htmlFor="organisationProfiles">{t('editSchool.organisationProfile.label', 'Organisation profile')}</Label>
                                <ValidatedInput name="organisationProfiles" type="select" value={model?.organisationProfileId ?? ''} onChange={e => change({ organisationProfileId: e.currentTarget.value })} onBlur={e => validate('organisationProfileId')} validationErrors={validationErrors['organisationProfileId']}>
                                    <option value="">{t('editSchool.organisationProfileId.pleaseSubmit', '(Please select an organisation profile)')}</option>
                                    {
                                        organisationProfilesFiltered?.map(item => (
                                            <option key={item.id} value={item.id}>{
                                                !!item.schoolId || !!item.trustId ? t('editSchool.organisationProfileId.value', '{{ organisationProfile }} [Customised]', { organisationProfile: item.name })
                                                    : t('editSchool.organisationProfileId.value', '{{ organisationProfile }}', { organisationProfile: item.name })
                                            }</option>
                                        ))
                                    }
                                </ValidatedInput>
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <FormGroup>
                            <Label htmlFor="descriptionHtml">{t('editSchool.description.label', 'Description')}</Label>
                            <HtmlEditor size="sm" value={model?.descriptionHtml ?? ''} onChange={text => change({ descriptionHtml: text })} />
                        </FormGroup>
                    </Row>

                    <AuthorizeContainer requireRole={IdentityRoles.Administration}>
                        <Row>
                            <h4>{t('editSchool.subscriptionOwnerDetails', 'Subscription owner details')}</h4>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label htmlFor="subscriptionOwnerFirstName">{t('editSchool.subscriptionOwnerFirstName.label', 'First name')}</Label>
                                    <ValidatedInput disabled={!!assignedTrust || !isAdmin} name="subscriptionOwnerFirstName" type="text"
                                        value={assignedTrust?.subscriptionOwnerFirstName ?? model?.subscriptionOwnerFirstName ?? ""} onChange={e => change({ subscriptionOwnerFirstName: e.currentTarget.value })} onBlur={e => validate('subscriptionOwnerFirstName')} validationErrors={validationErrors['subscriptionOwnerFirstName']} />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label htmlFor="subscriptionOwnerLastName">{t('editSchool.subscriptionOwnerLastName.label', 'Last name')}</Label>
                                    <ValidatedInput disabled={!!assignedTrust || !isAdmin} name="subscriptionOwnerFirstName" type="text" value={assignedTrust?.subscriptionOwnerLastName ?? model?.subscriptionOwnerLastName ?? ""} onChange={e => change({ subscriptionOwnerLastName: e.currentTarget.value })} onBlur={e => validate('subscriptionOwnerLastName')} validationErrors={validationErrors['subscriptionOwnerLastName']} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label htmlFor="subscriptionOwnerEmail">{t('editSchool.subscriptionOwnerEmail.label', 'Email')}</Label>
                                    <ValidatedInput disabled={!!assignedTrust || !isAdmin} name="subscriptionOwnerEmail" type="text" value={assignedTrust?.subscriptionOwnerEmail ?? model?.subscriptionOwnerEmail ?? ""} onChange={e => change({ subscriptionOwnerEmail: e.currentTarget.value })} onBlur={e => validate('subscriptionOwnerEmail')} validationErrors={validationErrors['subscriptionOwnerEmail']} />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label htmlFor="subscriptionOwnerId">{t('editTrust.subscriptionOwnerId.label', 'Unique identifier (for Evaluate-Ed use)')}</Label>
                                    <ValidatedInput disabled={false /* We can set this for each school in a trust subscription */} name="subscriptionOwnerId" type="text" value={model?.subscriptionOwnerId ?? ""} onChange={e => change({ subscriptionOwnerId: e.currentTarget.value })} onBlur={e => validate('subscriptionOwnerId')} validationErrors={validationErrors['subscriptionOwnerId']} />
                                </FormGroup>
                            </Col>
                        </Row>
                    </AuthorizeContainer>
                    
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label htmlFor="name">{t('editSchool.image.label', 'School logo:')}</Label>
                                <UploadedImagePreview size="lg" src={image?.url ?? ''} />
                                <Row>
                                    <Col>
                                        <ButtonGroup>
                                            <FileUploadButton
                                                color={`primary`}
                                                isExecuting={isUploadingImage}
                                                executingChildren={<><Spinner size="sm" /> {t('common.uploading', 'Uploading...')}</>}
                                                onUpload={onUploadImage}
                                                outline={false}>
                                                <FontAwesomeIcon icon="image" />
                                                <> </>
                                                {t('editSchool.uploadButtonText', 'Upload an image')}
                                            </FileUploadButton>
                                            <ButtonAsync color="primary"
                                                outline
                                                isExecuting={isClearingImage}
                                                type="button"
                                                onClick={clearImage}
                                                executingChildren={<><Spinner size="sm" /> {t('editSchool.clearingImage', 'Clearing image...')}</>}>
                                                {t('editSchool.clearImageButton', 'Clear image')}
                                            </ButtonAsync>
                                        </ButtonGroup>
                                    </Col>
                                </Row>
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>

                <FormButtons>
                    <ConditionalFragment showIf={!isLoading}>
                        <ButtonAsync color="primary" isExecuting={isSaving} onClick={() => saveForm()}
                            executingChildren={<><Spinner size="sm" /> {t('common.saving', 'Saving...')}</>}>
                            <FontAwesomeIcon icon="save" />
                            <> </>
                            {t('common.save', 'Save')}
                        </ButtonAsync>
                    </ConditionalFragment>

                    <Button type="button" color="primary" outline onClick={e => navigate(-1)}>
                        {t('common.cancel', 'Cancel')}
                    </Button>
                </FormButtons>
            </MainContainer>
        </>
    );
};