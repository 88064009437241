import { IdentityRoles } from "../../../configure/security/IdentityRoles";
import { RouteEntry } from "../../../shared/routes";
import { DeleteOrganisationalRoleGroup } from "./DeleteOrganisationalRoleGroup";
import { CreateOrganisationalRoleGroup, EditOrganisationalRoleGroup } from "./EditOrganisationalRoleGroup";
import { OrganisationalRoleGroupList } from "./OrganisationalRoleGroupList";





/**
 * Route that should be added to configure/routeConfig.ts to provide access to these components via routing
 */
export const organisationalRoleGroupRoutes: Array<RouteEntry> = [
       { path: '/administration/role-groups', element: <OrganisationalRoleGroupList />, authorize: true, requireRole: IdentityRoles.Administration, },
       { path: '/administration/role-groups/add', element: <CreateOrganisationalRoleGroup />, authorize: true, requireRole: IdentityRoles.Administration, },
       { path: '/administration/role-groups/edit/:id', element: <EditOrganisationalRoleGroup />, authorize: true, requireRole: IdentityRoles.Administration, },
       { path: '/administration/role-groups/delete/:id', element: <DeleteOrganisationalRoleGroup />, authorize: true, requireRole: IdentityRoles.Administration, },
];