import * as React from "react";
import { useLazyQuery } from "@apollo/client";
import { gql } from "graphql-tag";
import { Guid } from "guid-string";
import { mainApiConfig } from "../../../configure/mainApiConfig";
import { OrganisationProfileFromSubscriptionQuery, OrganisationProfileFromSubscriptionQueryVariables } from "../generated/graphql";
import { organisationProfileFieldsFragment } from "../models/OrganisationProfile";
import { AsyncLoadOptions, AsyncLoadResult } from "../../../shared/abstractStore";

interface OrganisationProfileQueryParams {
  trustId?: string | null;
  schoolId?: string | null;
}

/**
 * Get a OrganisationProfile from the store.
 * @param params
 * @param options
 * @returns
 */
export function useOrganisationProfileFromSubscription(params: OrganisationProfileQueryParams = {}, options: AsyncLoadOptions = {}): AsyncLoadResult<OrganisationProfileFromSubscriptionQuery> {
  const { trustId, schoolId } = params;
  const [load, { data, loading, error, refetch }] = useLazyQuery<OrganisationProfileFromSubscriptionQuery, OrganisationProfileFromSubscriptionQueryVariables>(
    gql`
      query organisationProfileFromSubscriptionQuery ($trustId: ID!, $schoolId: ID!) {
          model: organisationProfileFromSubscription(trustId: $trustId, schoolId: $schoolId) {
              ...organisationProfileFields
          }
      }

      ${organisationProfileFieldsFragment}
    `,
    {
      variables: {
        trustId: trustId ?? Guid.empty,
        schoolId: schoolId ?? Guid.empty
      },
      fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
      pollInterval: options.pollInterval,
      onCompleted: (data) => {
        if (options.onCompleted) {
          options.onCompleted(data);
        }
      }
    }
  );

  // Run the query (unless we are being lazy).
  React.useEffect(() => {
    if (!options.lazy) {
      load();
    }
  }, [options.lazy, load])

  // Return the results.
  const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
  return React.useMemo(() => ({
    data: data ?? ({} as any),
    refresh: refresh,
    isLoading: loading,
    errors: error
  }), [data, refresh, loading, error]);
}