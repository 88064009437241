import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { CreateRequirementMutation, CreateRequirementMutationVariables, RequirementCreateInput } from '../generated/graphql';
import { requirementFieldsFragment } from '../models/Requirement';

/**
 * Get a callback to create a Requirement in the store.
 */
export function useCreateRequirementMutation(): [(model: RequirementCreateInput) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<CreateRequirementMutation, CreateRequirementMutationVariables>(
        gql`
            mutation createRequirementMutation ($model: RequirementCreateInput!) {
                createRequirement(model: $model) {
                    ...requirementFields
                }
            }

            ${requirementFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (model: RequirementCreateInput) => {
        const result = await mutationAction({ variables: { model: model } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
