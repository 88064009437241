import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { CreateUserLearningEventMutation, CreateUserLearningEventMutationVariables, UserLearningEventCreateInput } from '../generated/graphql';
import { userLearningEventFieldsFragment } from '../models/UserLearningEvent';

/**
 * Get a callback to create a UserLearningEvent in the store.
 */
export function useCreateUserLearningEventMutation(): [(model: UserLearningEventCreateInput) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<CreateUserLearningEventMutation, CreateUserLearningEventMutationVariables>(
        gql`
            mutation createUserLearningEventMutation ($model: UserLearningEventCreateInput!) {
                createUserLearningEvent(model: $model) {
                    ...userLearningEventFields
                }
            }

            ${userLearningEventFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (model: UserLearningEventCreateInput) => {
        const result = await mutationAction({ variables: { model: model } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
