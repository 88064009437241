import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Guid } from "guid-string";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { Badge, Button, ButtonGroup, ButtonToggle, Col, Collapse, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from "reactstrap";
import { ButtonAsync } from "reactstrap-buttonasync";
import { useToggleState } from "use-toggle-state";
import { OrganisationalRoleLinkType } from "../../api/main/models/constants/OrganisationalRoleLinkType";
import { RequirementType } from "../../api/main/models/constants/RequirementType";
import { OrganisationalRole } from "../../api/main/models/OrganisationalRole";
import { OrganisationalRoleLink} from "../../api/main/models/OrganisationalRoleLink";
import { PersonnelFlag } from "../../api/main/models/PersonnelFlag";
import { PersonnelFlagUser } from "../../api/main/models/PersonnelFlagUser";
import { Profile } from "../../api/main/models/Profile";
import { Requirement } from "../../api/main/models/Requirement";
import { School } from "../../api/main/models/School";
import { useDeleteOrganisationalRoleLinkMutation } from "../../api/main/organisationalRoleLinks/useDeleteOrganisationalRoleLinkMutation";
import { useSaveOrganisationalRoleLinkMutation } from "../../api/main/organisationalRoleLinks/useSaveOrganisationalRoleLinkMutation";
import { HtmlDisplay } from "../../shared/htmlEditor";
import { TagSelector } from "../../shared/tagSelector/TagSelector";
import { RequirementTagSelector } from "../administration/requirements/RequirementTagSelector";
import { CardsOrTable } from "../shared/cardsOrTable/CardsOrTable";
import { MainContainer } from "../shared/mainContainer/MainContainer";
import { SearchInput } from "../shared/searchInput/SearchInput";
import { UserRoleScore } from "./roleMapUserScoreSort";


export interface RoleMapUserDetailsModalCloseEventArgs {
    selectedIds: Array<string>,
    cancelled: boolean,
    removedIds: Array<string>,
    needsRoleLinkRefresh: boolean,
};


export interface RoleMapUserDetailsModalProps {
    isOpen: boolean,
    toggle: () => void,
    onClose: (event: RoleMapUserDetailsModalCloseEventArgs) => Promise<void>,
    isSaving: boolean,

    userProfile: Profile | null | undefined,
    userBlobReferenceUrl: string | null | undefined,
    allPersonnelFlags: Array<PersonnelFlag> | null | undefined,

    initialSelectedIds: Array<string> | null | undefined,

    rolePinLink: OrganisationalRoleLink | null | undefined,
    roleId: string | undefined,

    isTrust?: boolean,
    school?: School | null,

    userRequirements?: Array<Requirement>,
    
    roleLinks?: Array<OrganisationalRoleLink>,
    allRoles?: Array<OrganisationalRole>,
    personnelFlagUsers?: Array<PersonnelFlagUser>,

    score: UserRoleScore;

    userOrganisationRoleLinks: Array<OrganisationalRoleLink>;
};

export const RoleMapUserDetailsModal = (props: RoleMapUserDetailsModalProps) => {
    const {
        isOpen,
        toggle,
        onClose,
        isSaving,
        userProfile,
        userBlobReferenceUrl,
        allPersonnelFlags,
        initialSelectedIds,
        roleId,
        rolePinLink,
        roleLinks,
        allRoles,
        personnelFlagUsers,
        score,
        userOrganisationRoleLinks,
    } = props;
    const { t } = useTranslation();

    // Filtering PersonnelFlags
    const [search, setSearch] = useState<string>('');

    // Allow items to be searched.
    const items = useMemo(() => {
        if (!allPersonnelFlags) {
            return allPersonnelFlags;
        }


        let ret = allPersonnelFlags;


        // Filter by search
        let lowerSearch = search.toLowerCase();
        if (lowerSearch) {
            ret = ret.filter(item =>
                item.name.toLowerCase().indexOf(lowerSearch) >= 0
                || item.descriptionHtml.toLowerCase().indexOf(lowerSearch) >= 0
            );
        }

        return ret;
    }, [allPersonnelFlags, search]);

    // Add the selected PersonnelFlags
    const [selectedIds, setSelectedIds] = useState<Array<string>>([]);
    const toggleSelection = useCallback((id: string) => {
        setSelectedIds(prevState => {
            const existing = prevState.find(it => it === id);
            if (existing) {
                return prevState.filter(it => it !== id);
            }

            return [
                ...prevState,
                id
            ];
        });
    }, [setSelectedIds]);

    // Check if a PersonnelFlag is selected
    const isSelected = useCallback((id: string) => {
        if (!!selectedIds.find(it => it === id)) {
            return true;
        }

        return false;
    }, [selectedIds]);

    // Flag to indicate if we need to refresh the role links when this modal closes
    const [needsRefresh, setNeedsRefresh] = useState<boolean>(false);

    // Close the modal and return the results
    const closeModal = useCallback(async (event?: { selectedIds: Array<string>, cancelled?: boolean; }) => {
        if (onClose) {
            const thisSelectedIds = event?.selectedIds ?? selectedIds;

            const externalEvent: RoleMapUserDetailsModalCloseEventArgs = {
                ...(event ?? {}),

                selectedIds: event?.cancelled ? [] : thisSelectedIds ,
                cancelled: event?.cancelled ?? false,
                removedIds: event?.cancelled ? [] : initialSelectedIds?.filter(id => !selectedIds.find(it => it === id)) ?? [],
                needsRoleLinkRefresh: needsRefresh,
            };
            await onClose(externalEvent);
        }
        // Clear selections on cancel so they don't persist were you to open the modal again without navigating away
        if (event?.cancelled) {
            setSelectedIds([]);
        }
        // Close the modal
        toggle();
    }, [selectedIds, toggle, onClose, initialSelectedIds, needsRefresh]);

    // Set the initial selected ids
    useEffect(() => {
        //if (!initialSelectedIds?.length) return; //Commented this out as things weren't updating fast enough for this to do it's job right the first time
        setSelectedIds(initialSelectedIds ?? []);
    }, [initialSelectedIds]);


    //Save and delete the pin role Link
    const [saveRolePinLink, { isExecuting: isSavingRolePinLink, errors: saveRolePinLinkErrors }] = useSaveOrganisationalRoleLinkMutation();
    const [deleteRolePinLink, { isExecuting: isDeletingRolePinLink, errors: deleteRolePinLinkErrors }] = useDeleteOrganisationalRoleLinkMutation();

    // togglePinButton is for visually swapping out the buttons, toggleRolePinLink is the actual functionality
    const [pinButton, togglePinButton] = useToggleState(!!rolePinLink);
    const toggleRolePinLink = useCallback(async () => {
        var existing = !!rolePinLink?.id;
        if (existing) {
            await deleteRolePinLink(rolePinLink?.id ?? "");
            setNeedsRefresh(true);
            togglePinButton();
        }
        else {
            await saveRolePinLink(Guid.newGuid(), { organisationalRoleId: roleId, targetId: userProfile?.userId, organisationalRoleLinkType: OrganisationalRoleLinkType.RoleMapUserPin, targetType: 'User', displayOrder: 99 }, true);
            setNeedsRefresh(true);
            togglePinButton();
        }
    }, [deleteRolePinLink, saveRolePinLink, roleId, rolePinLink?.id, userProfile?.userId, togglePinButton]);

    const [isFlagListOpen, toggleFlagList] = useToggleState(false);

    const currentPersonnelFlags = useMemo(() => {
        return allPersonnelFlags?.filter(it => initialSelectedIds?.find(iS => it.id === iS))
    }, [allPersonnelFlags, initialSelectedIds]);

    const currentRoles = useMemo(() => {
        const myLinks = userOrganisationRoleLinks?.filter(it => it.organisationalRoleLinkType === OrganisationalRoleLinkType.User);
        return myLinks?.map(link => allRoles?.find(role => role.id === link.organisationalRoleId)) ?? [];
    }, [allRoles, userOrganisationRoleLinks]);

    const aspirationalRoles = useMemo(() => {
        const myLinks = userOrganisationRoleLinks?.filter(it => it.organisationalRoleLinkType === OrganisationalRoleLinkType.AspirationalUserRole);
        return myLinks?.map(link => allRoles?.find(role => role.id === link.organisationalRoleId)) ?? [];
    }, [allRoles, userOrganisationRoleLinks]);
    
    // Render the UI
    //
    return (
        <>
            <Modal
                isOpen={isOpen}
                toggle={toggle}
                size="xl"
                zIndex={9999}
            >
                <ModalHeader toggle={toggle}>
                    <Row>
                        <Col xs="auto" style={!!props.isTrust ? { borderRight: "solid 1px #ccc" } : {}}>
                            <h3 className="role-map-part-role-heading">
                                {t('roleMapUserDetailsModal.heading.name', userProfile?.firstName + " " + userProfile?.lastName)}
                            </h3>
                        </Col>
                        <ConditionalFragment showIf={!!props.isTrust}>
                            <Col xs="auto">
                                <h3 className="role-map-part-role-heading">
                                    {t('roleMapUserDetailsModal.heading.school', props.school?.name ?? 'Trust staff')}
                                </h3>
                            </Col>
                        </ConditionalFragment>
                        <Col></Col>
                        <Col xs="auto">
                            <Badge>
                                {t('roleMapUserDetailsModal.heading.score', 'Total role points: {{score}}', { score: score?.score - score.pinnedRoleScore /* Hide the pinning logic from the score */ })}
                            </Badge>
                        </Col>
                    </Row>
                    <div>
                        {
                            pinButton ?
                            <Button type="button" color="primary" onClick={() => toggleRolePinLink() }>
                                <FontAwesomeIcon icon="thumbtack" style={{ paddingRight: "0.25rem" }} />
                                    {t('roleMapUserDetailsModal.heading.pin', 'Unpin')}
                            </Button>
                        
                            :
                                <Button type="button" color="primary" outline onClick={() => toggleRolePinLink()}>
                                    <FontAwesomeIcon icon="thumbtack" style={{ paddingRight: "0.25rem" }} />
                                    {t('roleMapUserDetailsModal.heading.pin', 'Pin to front')}
                                </Button>

                        }
                    </div>
                </ModalHeader>

                <ModalBody>
                    <Row>
                        <Col xs="auto">
                            <img className="role-map-part modal-image"
                                src={userBlobReferenceUrl ?? '/img/user-solid.svg'}
                                alt={''}
                                style={{ objectFit: 'contain' }}
                            />
                        </Col>
                        <Col>
                            <h4>{t('roleMapUserDetailsModal.personalDetails', 'Personal details')}</h4>
                            <HtmlDisplay html={userProfile?.descriptionHtml ?? '<i>User has provided no details.</i>'} />
                            <h4>{t('roleMapUserDetailsModal.strengths', 'Strengths')}</h4>
                            <HtmlDisplay html={userProfile?.strengthsHtml ?? '<i>User has provided no Strengths.</i>'} />
                            <h4>{t('roleMapUserDetailsModal.gaps', 'Gaps')}</h4>
                            <HtmlDisplay html={userProfile?.gapsHtml ?? '<i>User has provided no Gaps.</i>'} />
                        </Col>
                    </Row>

                    <Row style={{ marginTop: "1rem" }}>
                        <Col>
                            <h4>
                                {t('roleMapUserDetailsModal.currentRole', 'Current roles')}
                                <> </>
                                <Badge style={{ fontSize: '0.7rem', }}>
                                    {t('roleMapUserDetailsModal.scoreContribution', 'Role points: {{points}}', { points: score.currentRoleScore })}
                                </Badge>
                            </h4>
                            {
                                currentRoles?.map((role) => {
                                    if (!role) {
                                        return null;
                                    }

                                    return (
                                        <div>
                                            {role.name}
                                        </div>
                                    );
                                })
                            }
                        </Col>

                        <Col>
                            <h4>
                                {t('roleMapUserDetailsModal.aspirationalRole', 'Aspirational roles')}
                                <> </>
                                <Badge style={{ fontSize: '0.7rem', }}>
                                    {t('roleMapUserDetailsModal.scoreContribution', 'Role points: {{points}}', { points: score.aspirationalRoleScore })}
                                </Badge>
                            </h4>
                            {
                                aspirationalRoles?.map((role) => {
                                    if (!role) {
                                        return null;
                                    }

                                    return (
                                        <div>
                                            {role.name}
                                        </div>
                                    );
                                })
                            }
                        </Col>
                    </Row>

                    <Row style={{ marginTop: "1rem" }}>
                        <Col>
                            <h4>
                                {t('roleMapUserDetailsModal.currentQualifications', 'Current qualifications')}
                                <> </>
                                <Badge style={{ fontSize: '0.7rem', }}>
                                    {t('roleMapUserDetailsModal.scoreContribution', 'Role points: {{points}}', { points: score.keyQualificationsScore + score.requiredQualificationsScore + score.recommendedQualificationsScore })}
                                </Badge>
                            </h4>
                            <RequirementTagSelector readOnly tags={props.userRequirements?.filter(it => it.requirementType === RequirementType.Qualification)} />
                        </Col>
                        <Col>
                            <h4>
                                {t('roleMapUserDetailsModal.currentExperiences', 'Current experience')}
                                <> </>
                                <Badge style={{ fontSize: '0.7rem', }}>
                                    {t('roleMapUserDetailsModal.scoreContribution', 'Role points: {{points}}', { points: score.requiredExperiencesScore + score.recommendedExperiencesScore })}
                                </Badge>
                            </h4>
                            <RequirementTagSelector readOnly tags={props.userRequirements?.filter(it => it.requirementType === RequirementType.Experience)} />
                        </Col>
                    </Row>
                    <Row style={{ marginTop: "1rem" }}>
                        <Col>
                            <h4>
                                {t('roleMapUserDetailsModal.currentSkills', 'Current skills')}
                                <> </>
                                <Badge style={{ fontSize: '0.7rem', }}>
                                    {t('roleMapUserDetailsModal.scoreContribution', 'Role points: {{points}}', { points: score.requiredSkillsScore + score.recommendedSkillsScore })}
                                </Badge>
                            </h4>
                            <RequirementTagSelector readOnly tags={props.userRequirements?.filter(it => it.requirementType === RequirementType.Skill)} />
                        </Col>
                        <Col>
                            <h4>
                                {t('roleMapUserDetailsModal.currentAttributes', 'Current attributes')}
                                <> </>
                                <Badge style={{ fontSize: '0.7rem', }}>
                                    {t('roleMapUserDetailsModal.scoreContribution', 'Role points: {{points}}', { points: score.requiredAttributesScore + score.recommendedAttributesScore })}
                                </Badge>
                            </h4>
                            <RequirementTagSelector readOnly tags={props.userRequirements?.filter(it => it.requirementType === RequirementType.Attribute)} />
                        </Col>
                    </Row>  
                    <Row style={{marginTop: "1rem"} }>
                        <h4>
                            {t('roleMapUserDetailsModal.currentPersonnelFlags', 'Current personnel flags')}
                            <> </>
                            <Badge style={{ fontSize: '0.7rem', }}>
                                {t('roleMapUserDetailsModal.scoreContribution', 'Role points: {{points}}', { points: score.personnelFlagsScore })}
                            </Badge>
                        </h4>
                        <ConditionalFragment showIf={!!currentPersonnelFlags?.length}>
                            <Row>
                                {
                                    allRoles?.map((role, index) => {
                                        if (personnelFlagUsers?.filter(it => it.organisationalRoleId === role.id).length) {
                                            return (
                                                <Col key={index}>
                                                    <h5 >{t('userMapUsrDetailsModal.currentPersonnelFlags.role', role.name)}</h5>
                                                    <TagSelector readOnly tags={currentPersonnelFlags?.filter(it => personnelFlagUsers?.find(pfu => it.id === pfu.personnelFlagId && pfu.organisationalRoleId === role.id))} />
                                                </Col>
                                            )

                                        }
                                        else {
                                            return (<div key={index}></div>)
                                        }
                                    })
                                }
                            </Row>
                        </ConditionalFragment>                       
                    </Row>

                    <MainContainer style={{  border: "solid 2px #ccc", marginTop: "1rem" }}>
                        <h4>
                            {t('roleMapUserDetails.personnelFlagList', 'Personnel flags')}
                            <ButtonToggle style={{ margin: "1rem" }} color="primary" outline={isFlagListOpen ? false : true} onClick={() => toggleFlagList()}>
                                {t('roleMapUserDetailsModal.flagListModal', isFlagListOpen ? 'Hide' : 'Show')}
                                <> </>
                                <FontAwesomeIcon icon={isFlagListOpen ? "caret-up" : "caret-down"} />
                            </ButtonToggle>
                        </h4>
                        <Collapse isOpen={isFlagListOpen}>
                            <SearchInput value={search} onChange={e => setSearch(e.currentTarget.value)} />

                            <CardsOrTable
                                viewMode={"table"}
                                items={items ?? []}
                                tableHeadings={[
                                    t('roleMapUserDetailsModal.selected.heading', 'Selected'),
                                    t('roleMapUserDetailsModal.name.heading', 'Name'),
                                    t('roleMapUserDetailsModal.description.heading', 'Description'),
                                ]}
                                columns={[
                                    // Selected
                                    (item, view) => {
                                        const itemIsSelected = isSelected(item.id);

                                        return (
                                            <>
                                                <ButtonGroup>
                                                    <Button color="primary" outline={!itemIsSelected} onClick={() => toggleSelection(item.id)}>
                                                        {
                                                            itemIsSelected ? t('roleMapUserDetailsModal.personnelFlag.selected', 'Selected') : t('roleMapUserDetailsModal.personnelFlag.select', 'Select')
                                                        }
                                                    </Button>
                                                    <ConditionalFragment showIf={itemIsSelected}>
                                                        <Button color="primary" outline={!itemIsSelected} onClick={() => toggleSelection(item.id)}>
                                                            <FontAwesomeIcon icon="times" />
                                                        </Button>
                                                    </ConditionalFragment>
                                                </ButtonGroup>
                                            </>
                                        );
                                    },

                                    // Name
                                    (item, view) => {
                                        return item.name;
                                    },

                                    // Description
                                    (item, view) => {
                                        return <HtmlDisplay html={item.descriptionHtml} />;
                                    },
                                ]}
                            />
                        </Collapse>

                    </MainContainer>

                </ModalBody>

                <ModalFooter>
                    <ButtonAsync color="primary" onClick={() => closeModal({ selectedIds: selectedIds, cancelled: false  })} isExecuting={isSaving ?? false}
                        executingChildren={<><Spinner size="sm" /> {t('common.adding', 'Adding...')}</>}>
                        <FontAwesomeIcon icon="save" />
                        <> </>
                        {t('roleMapUserDetailsModal.addSelected.add', 'Add {{count}} selected Personnel {{pluralFlagOrNo}}', { count: selectedIds.filter(it => !initialSelectedIds?.find(item => it === item)).length, pluralFlagOrNo: selectedIds.length === 1 ? 'flag' : 'flags' })}
                    </ButtonAsync>

                    <Button type="button" color="primary" outline onClick={() => closeModal({ selectedIds: [], cancelled: true })}>
                        {t('common.cancel', 'Cancel')}
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
};

