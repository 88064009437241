

/**
 * Generate a URL that will launch the passed in URL but also track it as a AnalyticEvent in the database
 * @param url
 * @param learningContentId
 * @returns
 */
export function generateTrackedUrl(url: string, learningContentId?: string) {
    return `/link/to/${encodeURIComponent(url)}/${learningContentId ? `?learningContentId=${encodeURIComponent(learningContentId ?? '')}` : ''}`;
}