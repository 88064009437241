/**
 * Pass in a character limit of a string and it will return the string truncated if > than the numberOfChars appended with ellipsis (...). 
 * Also, trims any whitespace.
 * @param numberOfChars
 * @param text
 * @returns
 */
export const truncateText = (numberOfChars: number, text: string): string => {
    if (text.length <= numberOfChars) return text;

    // Trim is added to remove any trailing white space, before appending the ellipsis (...)
    return `${text.substring(0, numberOfChars).trim()}...`;
}