import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useValidatorCallback } from 'pojo-validator-react';
import { ValidatedInput } from 'pojo-validator-reactstrap';
import { ConditionalFragment } from 'react-conditionalfragment';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useAsyncCallback } from 'react-use-async-callback';
import { Button, Row, Col, Form, FormGroup, Label, Spinner } from 'reactstrap';
import { ButtonAsync } from 'reactstrap-buttonasync';
import { Requirement, requirementDefaultValues } from '../../../api/main/models/Requirement';
import { useRequirement } from '../../../api/main/requirements/useRequirement';
import { useSaveRequirementMutation } from '../../../api/main/requirements/useSaveRequirementMutation';
import { AlertOnErrors } from '../../../shared/alertOnErrors';
import { HtmlEditor } from '../../../shared/htmlEditor';
import { useChanges } from '../../../shared/useChanges';
import { Banner } from '../../shared/banner/Banner';
import { FormButtons } from '../../shared/formButtons/FormButtons';
import { LoadingIndicator } from '../../shared/loadingIndicator/LoadingIndicator';
import { MainContainer } from '../../shared/mainContainer/MainContainer';

interface EditRequirementProps {
    isCreate?: boolean,
    onCreateDefaultValues?: () => Partial<Requirement>,
    type: string
}

/**
 * Create and a new question.
 */
export const CreateRequirement = (props: EditRequirementProps) => (<EditRequirement isCreate={true} {...props} />);

export const EditRequirement = (props: EditRequirementProps) => {
    const {
        isCreate,
        onCreateDefaultValues,
        type
    } = props

    const { t } = useTranslation();
    const { id } = useParams<{ id: string | undefined }>();
    const navigate = useNavigate();

    // Load all data
    const {
        data: {
            model: storeModel,
        },
        errors: loadErrors, isLoading,
    } = useRequirement(id);

    // Model (Requirement)
    const { model, change, changes } = useChanges(storeModel, isCreate ? { ...requirementDefaultValues(), ...(onCreateDefaultValues ? onCreateDefaultValues() : {}) } : undefined);
    const [saveRequirement, { errors: saveErrors }] = useSaveRequirementMutation();

    // Main model validation.
    const [validate, validationErrors] = useValidatorCallback((validation, fieldsToCheck) => {
        const rules = {
            name: () => !model?.name ? t('editRequirement.errors.name', 'Name cannot be empty') : '',
        };
        validation.checkRules(rules, fieldsToCheck);
    }, [model]);

    // Save everything
    const [saveForm, { isExecuting: isSaving, errors: saveFormErrors }] = useAsyncCallback(async () => {
        if (!model) {
            return;
        }

        if (!validate()) {
            return;
        }

        // Save the main model
        await saveRequirement(model.id, { ...changes }, isCreate ?? false);

        // Go back to previous screen.
        navigate(-1);
    }, [validate, saveRequirement, model, changes, isCreate, id, navigate]);

    return (
        <>
            <Banner>
                    <Row>
                        <Col>
                            <h1>
                                {
                                    isCreate ? t('editRequirement.createHeading.default', 'Add ') + type
                                        : t('editRequirement.editHeading.default', 'Edit ') + type
                                }
                            </h1>
                            <h3>{model?.name}</h3>
                        </Col>
                        <ConditionalFragment showIf={isLoading}>
                            <Col xs="auto">
                                <LoadingIndicator size="sm" />
                            </Col>
                        </ConditionalFragment>
                    </Row>
            </Banner>

            <MainContainer>
                <AlertOnErrors errors={[
                    loadErrors,
                    saveFormErrors, saveErrors,
                ]} />

                <Form onSubmit={e => { e.preventDefault(); saveForm(); }}>
                    <FormGroup>
                        <Label htmlFor="name">{t('editRequirement.name.label', 'Name')}</Label>
                        <ValidatedInput name="name" type="text" value={model?.name ?? ''} onChange={e => change({ name: e.currentTarget.value })} onBlur={e => validate('name')} validationErrors={validationErrors['name']} />
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor="descriptionHtml">{t('editRequirement.description.label', 'Description')}</Label>
                        <HtmlEditor value={model.descriptionHtml ?? ''} onChange={e => change({ descriptionHtml: e })}></HtmlEditor>
                    </FormGroup>

                    <FormButtons>
                        <ConditionalFragment showIf={!isLoading}>
                            <ButtonAsync color="primary" isExecuting={isSaving} onClick={() => saveForm()}
                                executingChildren={<><Spinner size="sm" /> {t('common.saving', 'Saving...')}</>}>
                                <FontAwesomeIcon icon="save" />
                                <> </>
                                {t('common.save', 'Save')}
                            </ButtonAsync>
                        </ConditionalFragment>

                        <Button type="button" color="primary" outline onClick={e => navigate(-1)}>
                            {t('common.cancel', 'Cancel')}
                        </Button>
                    </FormButtons>
                </Form>

            </MainContainer>
        </>
    )

}