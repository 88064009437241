import { PodcastsList, PodcastsListProps } from "../PodcastsList";

/*
 * Component to view a list of admin podcasts
*/
export const AdminPodcastsList = (props: PodcastsListProps) => (<PodcastsList isAdmin {...props} />);

/*
 * Component to view a list of trust podcasts
*/
export const TrustPodcastsList = (props: PodcastsListProps) => (<PodcastsList isTrust {...props} />);

/*
 * Component to view a list of school podcasts
*/
export const SchoolPodcastsList = (props: PodcastsListProps) => (<PodcastsList {...props} />);