import * as React from "react";
import { useLazyQuery } from "@apollo/client";
import { gql } from "graphql-tag";
import { mainApiConfig } from "../../../../configure/mainApiConfig";
import { ViewOrganisationalRoleSupportingDataQuery, ViewOrganisationalRoleSupportingDataQueryVariables } from "../../generated/graphql";
import { AsyncLoadOptions, AsyncLoadResult } from "../../../../shared/abstractStore";
import { requirementFieldsFragment } from "../../models/Requirement";
import { learningContentFieldsFragment } from "../../models/LearningContent";
import { Guid } from "guid-string";

/**
 * Get all the supporting data needed to view an organisational role under career.
 * @param id
 * @param options
 * @returns
 */
export function useViewOrganisationalRoleSupportingData(schoolId?: string | null, trustId?: string | null, options: AsyncLoadOptions = {}): AsyncLoadResult<ViewOrganisationalRoleSupportingDataQuery> {
    const [load, { data, loading, error, refetch }] = useLazyQuery<ViewOrganisationalRoleSupportingDataQuery, ViewOrganisationalRoleSupportingDataQueryVariables>(
        gql`
        query viewOrganisationalRoleSupportingDataQuery($schoolId: ID!, $trustId: ID!) {
            requirements {
                ...requirementFields
            }

            learningContents(schoolId: $schoolId, trustId: $trustId) {
                ...learningContentFields
            }
        }

        ${requirementFieldsFragment}
        ${learningContentFieldsFragment}
        `,
        {
            variables: {
                schoolId: schoolId ?? Guid.empty,
                trustId: trustId ?? Guid.empty
            },
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load]);

    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error
    }), [data, refresh, loading, error]);
}