/**
 * Configuration for the role map calculation.
 * 
 * This lets us update scoring logic without needing to change the code elsewhere.
 */
export const roleMapCalculationConfiguration = {
    /**
     * Points awarded for each key qualification of a role the user has.
     */
    pointsPerKeyQualification: 300,

    /**
     * Points awarded for each required qualification of a role the user has.
     */
    pointsPerRequiredQualification: 100,
    /**
     * Points awarded for each recommended qualification of a role the user has.
     */
    pointsPerRecommendedQualification: 20,
    /**
     * Points awarded for each required experience of a role the user has.
     */
    pointsPerRequiredExperience: 75,
    /**
     * Points awarded for each recommended experience of a role the user has.
     */
    pointsPerRecommendedExperience: 15,
    /**
     * Points awarded for each required attribute of a role the user has.
     */
    pointsPerRequiredAttribute: 10,
    /**
     * Points awarded for each recommended attribute of a role the user has.
     */
    pointsPerRecommendedAttribute: 5,
    /**
     * Points awarded for each required skill of a role the user has.
     */
    pointsPerRequiredSkill: 10,
    /**
     * Points awarded for each recommended skill of a role the user has.
     */
    pointsPerRecommendedSkill: 5,
    /**
     * Points awarded for each recommended skill of a role the user has.
     */
    pointsForCurrentRole: 400,
    /**
     * Points awarded for each recommended skill of a role the user has.
     */
    pointsForAspirationalRole: 100,
    /**
     * Points for when the user is pinned for a role (not displayed in the total, just used for ordering).
     */
    pointsForPinnedRole: 9000,
    /**
     * The minimum number of points a user must have to be shown for a role.
     * This is to prevent users with very low scores from being shown when they are really not appropriate, e.g. a teaching assistant for a headteacher role.
     */
    minimumPointsToShowForRole: 50,
};
