import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { Button, ButtonGroup, Card, CardBody, Col, Row } from "reactstrap";
import { useToggleState } from "use-toggle-state";
import { CardHeaderCollapse } from "../../shared/cardHeaderCollapse/CardHeaderCollapse";
import { FormButtons } from "../../shared/formButtons/FormButtons";
import { LearningContent } from "../../../api/main/generated/graphql";
import { LearningContentViewer } from "../../shared/learningContentViewer/LearningContentViewer";
import { LearningContentType, learningContentTypeDisplayName } from "../../../api/main/models/constants/LearningContentType";
import { ConditionalFragment } from "react-conditionalfragment";
export interface RoleLearningContentComponentProps {
    content: LearningContent | undefined,
    moveUp: () => void,
    canMoveUp: boolean,
    moveDown: () => void,
    canMoveDown: boolean,
    remove: () => void,

    isOpenInitially?: boolean,
}

/**
 * Content from the learning content library that is associated with a role.
 * @param props
 */
export const RoleLearningContentComponent = (props: RoleLearningContentComponentProps) => {
    const {
        content,
        moveUp,
        canMoveUp,
        moveDown,
        canMoveDown,
        remove,

        isOpenInitially = false,
    } = props;
    const { t } = useTranslation();

    // Self management of if we are expanded or not.
    const [isOpen, toggleOpen] = useToggleState(isOpenInitially);

    // Render the UI
    //
    return (
        <Card>
            <CardHeaderCollapse isOpen={isOpen} toggle={toggleOpen}>
                <Row>
                    <Col>
                        {content?.name}
                        <div className="text-muted">
                            {learningContentTypeDisplayName(content?.learningContentType as LearningContentType, t)}
                        </div>
                    </Col>
                </Row>
            </CardHeaderCollapse>

            <ConditionalFragment showIf={isOpen}>
                <CardBody>
                    <FormButtons noPadding className="mb-1">
                        <ButtonGroup>
                            <Button color="primary" outline onClick={() => moveUp()} disabled={!canMoveUp}>
                                <FontAwesomeIcon icon="caret-up" />
                                <> </>
                                {t('editOrganisationalRole.roleLearningContentTab.roleLearningContentComponent.up', 'Up')}
                            </Button>
                            <Button color="primary" outline onClick={() => moveDown()} disabled={!canMoveDown}>
                                <FontAwesomeIcon icon="caret-down" />
                                <> </>
                                {t('editOrganisationalRole.roleLearningContentTab.roleLearningContentComponent.down', 'Down')}
                            </Button>
                            <Button color="danger" outline onClick={() => remove()}>
                                <FontAwesomeIcon icon="trash-alt" />
                                <> </>
                                {t('assessmentItemComponent.delete', 'Remove item')}
                            </Button>
                        </ButtonGroup>
                    </FormButtons>

                    <hr />

                    <LearningContentViewer content={content} />
                    {/* Refactored into LearningContentViewer above */}
                    { /* // */}
                    {/*<ConditionalFragment showIf={content?.learningContentType.toLowerCase().includes("course") ?? false}>*/}
                    {/*    <ViewCourse courseId={content?.id ?? ""} />*/}
                    {/*</ConditionalFragment>*/}

                    {/*<ConditionalFragment showIf={content?.learningContentType.toLowerCase().includes("video") ?? false}>*/}
                    {/*    <ViewVideo videoId={content?.id ?? ""} />*/}
                    {/*</ConditionalFragment>*/}

                    {/*<ConditionalFragment showIf={content?.learningContentType.toLowerCase().includes("podcast") ?? false}>*/}
                    {/*    <ViewPodcast podcastId={content?.id ?? ""} />*/}
                    {/*</ConditionalFragment>*/}

                    {/*<ConditionalFragment showIf={content?.learningContentType.toLowerCase().includes("event") ?? false}>*/}
                    {/*    <ViewEvent eventId={content?.id ?? ""} />*/}
                    {/*</ConditionalFragment>*/}

                    {/*<ConditionalFragment showIf={content?.learningContentType.toLowerCase().includes("book") ?? false}>*/}
                    {/*    <ViewBook bookId={content?.id ?? ""} />*/}
                    {/*</ConditionalFragment>*/}

                    {/*<ConditionalFragment showIf={content?.learningContentType.toLowerCase().includes("standard") ?? false}>*/}
                    {/*    <ViewStandard standardId={content?.id ?? ""} />*/}
                    {/*</ConditionalFragment>*/}
                </CardBody>
            </ConditionalFragment>
        </Card>
    );
};