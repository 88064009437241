import { IdentityRoles } from "../../../configure/security/IdentityRoles";
import { RouteEntry } from "../../../shared/routes";
import { DeletePersonnelFlag } from "./DeletePersonnelFlag";
import { CreatePersonnelFlag, EditPersonnelFlag } from "./EditPersonnelFlag";
import { PersonnelFlagsList } from "./PersonnelFlagsList";



/**
 * Routes that should be added to configure/routeConfig.ts to provide access to these components via routing
 */
export const personnelFlagRoutes: Array<RouteEntry> = [
    { path: '/administration/personnel-flags', element: <PersonnelFlagsList />, authorize: true, requireRole: IdentityRoles.Administration, },
    { path: '/administration/personnel-flags/add', element: <CreatePersonnelFlag />, authorize: true, requireRole: IdentityRoles.Administration, },
    { path: '/administration/personnel-flags/edit/:id', element: <EditPersonnelFlag />, authorize: true, requireRole: IdentityRoles.Administration, },
    { path: '/administration/personnel-flags/delete/:id', element: <DeletePersonnelFlag />, authorize: true, requireRole: IdentityRoles.Administration, },

];