import { RouteEntry } from "../../shared/routes";
import { IdentityRoles } from "../../configure/security/IdentityRoles";
import { ManageTrustIndex } from "./ManageTrustIndex";
import { ManageTrustNavigation } from "./ManageTrustNavigation";
import { SchoolsList } from "../administration/schools/SchoolsList";
import { CreateSchool, EditSchool } from "../administration/schools/EditSchool";
import { DeleteSchool } from "../administration/schools/DeleteSchool";
import { DeleteCourse } from "../administration/courses/DeleteCourse";
import { DeleteVideo } from "../administration/videos/DeleteVideo";
import { DeletePodcast } from "../administration/podcasts/DeletePodcast";
import { DeleteEvent } from "../administration/events/DeleteEvent";
import { DeleteBook } from "../administration/books/DeleteBook";
import { DeleteStandard } from "../administration/standards/DeleteStandard";
import { DeleteUser } from "../users/DeleteUser";
import { EditTrust } from "../administration/trusts/EditTrust";
import { TrustUsersListContainer } from "./containers/userListContainers/TrustUsersListContainer";
import { TrustCreateUserContainer } from "./containers/userListContainers/TrustCreateUserContainer";
import { TrustEditUserContainer } from "./containers/userListContainers/TrustEditUserContainer";
import { CreateTrustVideo, EditTrustVideo } from "../administration/videos/containers/EditVideoContainers";
import { TrustVideosList } from "../administration/videos/containers/VideoListContainers";
import { TrustStandardsList } from "../administration/standards/containers/StandardsListContainers";
import { CreateTrustStandard, EditAdminStandard } from "../administration/standards/containers/EditStandardContainers";
import { TrustPodcastsList } from "../administration/podcasts/containers/PodcastsListContainers";
import { CreateTrustPodcast, EditTrustPodcast } from "../administration/podcasts/containers/EditPodcastContainers";
import { CreateTrustEvent, EditTrustEvent } from "../administration/events/containers/EditEventContainers";
import { TrustEventsList } from "../administration/events/containers/EventListContainers";
import { TrustCoursesList } from "../administration/courses/containers/CourseListContainers";
import { CreateTrustCourse, EditTrustCourse } from "../administration/courses/containers/EditCourseContainers";
import { TrustBooksList } from "../administration/books/containers/BookListContainer";
import { CreateTrustBook, EditAdminBook } from "../administration/books/containers/EditBookContainers";
import { TrustCustomiseOrganisationalProfileContainer } from "./containers/organisationProfileContainers/TrustCustomiseOrganisationProfileContainer";
import { TrustEditOrganisationProfileContainer } from "./containers/organisationProfileContainers/TrustEditOrganisationalProfileContainer";
import { TrustCustomiseSchoolsListContainer } from "./containers/organisationProfileContainers/TrustCustomiseSchoolsListContainer";
import { SchoolEditOrganisationProfileContainer } from "../manageSchools/containers/organisationalProfileContainers/SchoolEditOrganisationalProfileContainer";
import { TrustImportUsersContainer } from "./containers/ImportTrustStaffContainer";
import { TrustRoleMapContainer } from "./containers/roleMapContainers/TrustRoleMapContainer";
import { TrustUserMapContainer } from "./containers/userMapContainers/TrustUserMapContainer";

export const manageTrustRoutes: Array<RouteEntry> = [
    { path: '/manage-trust', element: <ManageTrustIndex />, authorize: true, requireRole: IdentityRoles.ManageTrust },
    // Trust details
    { path: '/manage-trust/trust-details/:id', element: <EditTrust />, authorize: true, requireRole: IdentityRoles.ManageTrust },

    // Schools
    { path: '/manage-trust/schools', element: <SchoolsList />, authorize: true, requireRole: IdentityRoles.ManageTrust },
    { path: '/manage-trust/schools/add', element: <CreateSchool />, authorize: true, requireRole: IdentityRoles.ManageTrust },
    { path: '/manage-trust/schools/edit/:id', element: <EditSchool />, authorize: true, requireRole: IdentityRoles.ManageTrust },
    { path: '/manage-trust/schools/delete/:id', element: <DeleteSchool />, authorize: true, requireRole: IdentityRoles.ManageTrust },

    // Staff
    { path: '/manage-trust/staff', element: <TrustUsersListContainer />, authorize: true, requireRole: IdentityRoles.ManageTrust },
    { path: '/manage-trust/staff/add', element: <TrustCreateUserContainer />, authorize: true },
    { path: '/manage-trust/staff/edit/:id', element: <TrustEditUserContainer />, authorize: true },
    { path: '/manage-trust/staff/delete/:id', element: <DeleteUser />, authorize: true },
    // Import Staff
    { path: '/manage-trust/staff/import', element: <TrustImportUsersContainer />, authorize: true },

    // Learning Content
    // Courses
    { path: '/manage-trust/courses', element: <TrustCoursesList />, authorize: true, requireRole: IdentityRoles.ManageTrust },
    { path: '/manage-trust/courses/add', element: <CreateTrustCourse onCreateDefaultValues={() => ({ learningContentType: "Course" })} />, authorize: true, requireRole: IdentityRoles.ManageTrust },
    { path: '/manage-trust/courses/edit/:id', element: <EditTrustCourse />, authorize: true, requireRole: IdentityRoles.ManageTrust },
    { path: '/manage-trust/courses/delete/:id', element: <DeleteCourse />, authorize: true, requireRole: IdentityRoles.ManageTrust },
    // Videos
    { path: '/manage-trust/videos', element: <TrustVideosList />, authorize: true, requireRole: IdentityRoles.ManageTrust },
    { path: '/manage-trust/videos/add', element: <CreateTrustVideo />, authorize: true, requireRole: IdentityRoles.ManageTrust },
    { path: '/manage-trust/videos/edit/:id', element: <EditTrustVideo />, authorize: true, requireRole: IdentityRoles.ManageTrust },
    { path: '/manage-trust/videos/delete/:id', element: <DeleteVideo />, authorize: true, requireRole: IdentityRoles.ManageTrust },
    // Podcasts
    { path: '/manage-trust/podcasts', element: <TrustPodcastsList />, authorize: true, requireRole: IdentityRoles.ManageTrust },
    { path: '/manage-trust/podcasts/add', element: <CreateTrustPodcast />, authorize: true, requireRole: IdentityRoles.ManageTrust },
    { path: '/manage-trust/podcasts/edit/:id', element: <EditTrustPodcast />, authorize: true, requireRole: IdentityRoles.ManageTrust },
    { path: '/manage-trust/podcasts/delete/:id', element: <DeletePodcast />, authorize: true, requireRole: IdentityRoles.ManageTrust },
    // Events
    { path: '/manage-trust/events', element: <TrustEventsList />, authorize: true, requireRole: IdentityRoles.ManageTrust },
    { path: '/manage-trust/events/add', element: <CreateTrustEvent onCreateDefaultValues={() => ({ learningContentType: "Event" })} />, authorize: true, requireRole: IdentityRoles.ManageTrust },
    { path: '/manage-trust/events/edit/:id', element: <EditTrustEvent />, authorize: true, requireRole: IdentityRoles.ManageTrust },
    { path: '/manage-trust/events/delete/:id', element: <DeleteEvent />, authorize: true, requireRole: IdentityRoles.ManageTrust },
    // Books
    { path: '/manage-trust/books', element: <TrustBooksList />, authorize: true, requireRole: IdentityRoles.ManageTrust },
    { path: '/manage-trust/books/add', element: <CreateTrustBook />, authorize: true, requireRole: IdentityRoles.ManageTrust },
    { path: '/manage-trust/books/edit/:id', element: <EditAdminBook />, authorize: true, requireRole: IdentityRoles.ManageTrust },
    { path: '/manage-trust/books/delete/:id', element: <DeleteBook />, authorize: true, requireRole: IdentityRoles.ManageTrust },
    // Standards
    { path: '/manage-trust/standards', element: <TrustStandardsList />, authorize: true, requireRole: IdentityRoles.ManageTrust },
    { path: '/manage-trust/standards/add', element: <CreateTrustStandard />, authorize: true, requireRole: IdentityRoles.ManageTrust },
    { path: '/manage-trust/standards/edit/:id', element: <EditAdminStandard />, authorize: true, requireRole: IdentityRoles.ManageTrust },
    { path: '/manage-trust/standards/delete/:id', element: <DeleteStandard />, authorize: true, requireRole: IdentityRoles.ManageTrust },
    // Customise
    { path: '/manage-trust/customise', element: <TrustCustomiseOrganisationalProfileContainer />, authorize: true, requireRole: IdentityRoles.ManageTrust },
    { path: '/manage-trust/customise/:id', element: <TrustEditOrganisationProfileContainer />, authorize: true, requireRole: IdentityRoles.ManageTrust },
    { path: '/manage-trust/customize-schools', element: <TrustCustomiseSchoolsListContainer />, authorize: true, requireRole: IdentityRoles.ManageTrust },
    { path: '/manage-trust/customize-schools/:id', element: <SchoolEditOrganisationProfileContainer />, authorize: true, requireRole: IdentityRoles.ManageTrust },

    //Role Map
    { path: '/manage-trust/role-map', element: <TrustRoleMapContainer />, authorize: true, requireRole: IdentityRoles.ManageTrust },

    //User Map
    { path: '/manage-trust/career-map', element: <TrustUserMapContainer />, authorize: true, requireRole: IdentityRoles.ManageTrust },
];

export const manageTrustSidebarRoutes: Array<RouteEntry> = [
    { path: '/manage-trust/*', element: (<ManageTrustNavigation />), authorize: true, requireRole: IdentityRoles.ManageTrust },
];
