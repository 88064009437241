import { NavItem } from 'reactstrap';
import { AuthorizeContainer } from '../../../shared/authorizeRoute';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IdentityRoles } from '../../../configure/security/IdentityRoles';
import { NavLinkTrackActive } from '../../shared/navLinkTrackActive/NavLinkTrackActive';
import { ConditionalFragment } from 'react-conditionalfragment';
import { useCurrentUserOrEmulatedSubscription } from '../../../globalState/subscriptions/useCurrentUserOrEmulatedSubscription';

/**
 * Primary navigation menu for the app. 
 */
export const HeaderNavigation = () => {
    const { t } = useTranslation();

    const subscription = useCurrentUserOrEmulatedSubscription(); // Grabs current/emulated subscription

    return (
        <>
            <AuthorizeContainer>
                <NavItem>
                    <NavLinkTrackActive to="/my-profile">
                        <FontAwesomeIcon icon="home" className="nav-icon"  />
                        <span>{t('navigation.myProfile', 'My profile')}</span>
                    </NavLinkTrackActive>
                </NavItem>
            </AuthorizeContainer>

            <AuthorizeContainer>
                <NavItem>
                    <NavLinkTrackActive to="/grow">
                        <FontAwesomeIcon icon="seedling" className="nav-icon" />
                        <span>{t('navigation.grow', 'Grow')}</span>
                    </NavLinkTrackActive>
                </NavItem>
                <NavItem>
                    <NavLinkTrackActive to="/career">
                        <FontAwesomeIcon icon="road" className="nav-icon" />
                        <span>{t('navigation.career', 'Career')}</span>
                    </NavLinkTrackActive>
                </NavItem>
                <NavItem>
                    <NavLinkTrackActive to="/cpd">
                        <FontAwesomeIcon icon="newspaper" className="nav-icon" />
                        <span>{t('navigation.cpd', 'CPD')}</span>
                    </NavLinkTrackActive>
                </NavItem>
            </AuthorizeContainer>

            <AuthorizeContainer requireRole={IdentityRoles.ManageSchool}>
                <ConditionalFragment showIf={!!subscription?.schoolId}>
                    <NavItem>
                        <NavLinkTrackActive to={`/manage-school/school-details/${subscription?.schoolId}`}>
                            <FontAwesomeIcon icon="school" className="nav-icon" />
                            <span>{t('navigation.manageSchool', 'School overview')}</span>
                        </NavLinkTrackActive>
                    </NavItem>
                </ConditionalFragment>
            </AuthorizeContainer>

            <AuthorizeContainer requireRole={IdentityRoles.ManageTrust}>
                <ConditionalFragment showIf={!!subscription?.trustId}>
                    <NavItem>
                        <NavLinkTrackActive to={`/manage-trust/trust-details/${subscription?.trustId}`}>
                            <FontAwesomeIcon icon="building-columns" className="nav-icon" />
                        <span>{t('navigation.manageTrust', 'Trust overview')}</span>
                    </NavLinkTrackActive>
                    </NavItem>
                </ConditionalFragment>
            </AuthorizeContainer>

            <AuthorizeContainer requireRole={IdentityRoles.Administration}>
                <NavItem>
                    <NavLinkTrackActive to="/administration">
                        <FontAwesomeIcon icon="cogs" className="nav-icon" />
                        <span>{t('navigation.administration', 'Administration')}</span>
                    </NavLinkTrackActive>
                </NavItem>
            </AuthorizeContainer>
        </>
    );
};
