import { useTranslation } from "react-i18next";
import { NavItem } from "reactstrap";
import { NavLinkTrackActive } from "../shared/navLinkTrackActive/NavLinkTrackActive";

/**
 * Navigation under the Profile area.
 */
export const ProfileNavigation = () => {
    const { t } = useTranslation();

    return (
        <>
            <NavItem>
                <NavLinkTrackActive to="details">
                    {t('profile.profile', 'Profile')}
                </NavLinkTrackActive>
            </NavItem>
            <NavItem>
                <NavLinkTrackActive to="change-email">
                    {t('profile.changeEmail', 'Email')}
                </NavLinkTrackActive>
            </NavItem>
            <NavItem>
                <NavLinkTrackActive to="change-password">
                    {t('profile.changePassword', 'Change password')}
                </NavLinkTrackActive>
            </NavItem>
        </>
    );
};
