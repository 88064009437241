import { EditVideo, EditVideoProps } from "../EditVideo";


/*
 * Component to create an admin Video
*/
export const CreateAdminVideo = (props: EditVideoProps) => (<EditVideo isAdmin isCreate={true} {...props} />);

/*
 * Component to create a Video inside a trust
*/
export const CreateTrustVideo = (props: EditVideoProps) => (<EditVideo isTrust isCreate={true} {...props} />);

/*
 * Component to create a Video inside a school
*/
export const CreateSchoolVideo = (props: EditVideoProps) => (<EditVideo isCreate={true} {...props} />);

/*
 * Component to edit an admin Video
*/
export const EditAdminVideo = (props: EditVideoProps) => (<EditVideo isAdmin {...props} />);

/*
 * Component to edit a Video inside a trust
*/
export const EditTrustVideo = (props: EditVideoProps) => (<EditVideo isTrust {...props} />);

/*
 * Component to edit a Video inside a school
*/
export const EditSchoolVideo = (props: EditVideoProps) => (<EditVideo {...props} />);

/*
 * Component to view a Video
*/
export const ViewVideo = (props: EditVideoProps) => (<EditVideo isView={true} {...props} />);
