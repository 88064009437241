import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useValidatorCallback } from 'pojo-validator-react';
import { ValidatedInput } from 'pojo-validator-reactstrap';
import { useMemo, useState } from 'react';
import { ConditionalFragment } from 'react-conditionalfragment';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useAsyncCallback } from 'react-use-async-callback';
import { Button, Row, Col, Form, FormGroup, Label, Spinner, ButtonGroup, NavItem, NavLink, Input } from 'reactstrap';
import { ButtonAsync } from 'reactstrap-buttonasync';
import { BlobUploadService } from '../../../api/main/blobReferences/BlobUploadService';
import { useBlobReference } from '../../../api/main/blobReferences/useBlobReference';
import { useLearningContentsSchoolTrustFilter } from '../../../api/main/learningContents/viewModels/useLearningContentsSchoolTrustFilter';
import { OrganisationalRoleLinkType } from '../../../api/main/models/constants/OrganisationalRoleLinkType';
import { RequirementLinkType } from '../../../api/main/models/constants/RequirementLinkType';
import { RequirementType } from '../../../api/main/models/constants/RequirementType';
import { OrganisationalRole, organisationalRoleDefaultValues } from '../../../api/main/models/OrganisationalRole';
import { OrganisationalRoleLink, organisationalRoleLinkDefaultValues } from '../../../api/main/models/OrganisationalRoleLink';
import { RequirementLink, requirementLinkDefaultValues } from '../../../api/main/models/RequirementLink';
import { useOrganisationalRoleGroups } from '../../../api/main/organisationalRoleGroups/useOrganisationalRoleGroups';
import { useDeleteOrganisationalRoleLinkMutation } from '../../../api/main/organisationalRoleLinks/useDeleteOrganisationalRoleLinkMutation';
import { useSaveOrganisationalRoleLinkMutation } from '../../../api/main/organisationalRoleLinks/useSaveOrganisationalRoleLinkMutation';
import { useSaveOrganisationalRoleMutation } from '../../../api/main/organisationalRoles/useSaveOrganisationalRoleMutation';
import { useEditOrganisationalRoleSupportingData } from '../../../api/main/organisationalRoles/viewModels/useEditOrganisationalRoleSupportingData';
import { useEditOrganisationalRoleViewModel } from '../../../api/main/organisationalRoles/viewModels/useEditOrganisationalRoleViewModel';
import { useDeleteRequirementLinkMutation } from '../../../api/main/requirementLinks/useDeleteRequirementLinkMutation';
import { useSaveRequirementLinkMutation } from '../../../api/main/requirementLinks/useSaveRequirementLinkMutation';
import { AlertOnErrors } from '../../../shared/alertOnErrors';
import { HtmlEditor } from '../../../shared/htmlEditor';
import { useChanges, useChangesArray } from '../../../shared/useChanges';
import { Banner } from '../../shared/banner/Banner';
import { FileUploadButton } from '../../shared/fileUploadButton/FileUploadButton';
import { FormButtons } from '../../shared/formButtons/FormButtons';
import { LoadingIndicator } from '../../shared/loadingIndicator/LoadingIndicator';
import { MainContainer } from '../../shared/mainContainer/MainContainer';
import { PillsNavBar } from '../../shared/pillsNavBar/PillsNavBar';
import { StickyToolbar } from '../../shared/stickyToolbar/StickyToolbar';
import { UploadedImagePreview } from '../../shared/uploadedImagePreview/UploadedImagePreview';
import { RequirementTagSelector } from '../requirements/RequirementTagSelector';
import { OrganisationalRoleTagSelector } from './OrganisationalRoleTagSelector';
import { RoleLearningContentTab } from './RoleLearningContentTab';

interface EditOrganisationalRoleProps {
    isCreate?: boolean,
    onCreateDefaultValues?: () => Partial<OrganisationalRole>,
}

/**
 * Create a new organisational role.
 */
export const CreateOrganisationalRole = (props: EditOrganisationalRoleProps) => (<EditOrganisationalRole isCreate={true} {...props} />);

/**
 * Edit an existing organisational role.
 * @param props
 * @returns
 */
export const EditOrganisationalRole = (props: EditOrganisationalRoleProps) => {
    const {
        isCreate,
        onCreateDefaultValues,
    } = props

    const { t } = useTranslation();
    const { id } = useParams<{ id: string | undefined }>();
    const navigate = useNavigate();

    // Load all data
    const {
        data: {
            model: storeModel,
            requirementLinks: storeRequirementLinks,
            organisationalRoleLinks: storeOrganisationalRoleLinks,
        },
        errors: loadErrors, isLoading: _isLoading, refresh: refreshData,
    } = useEditOrganisationalRoleViewModel(id);

    const {
        data: {
            requirements,
            organisationalRoles,
        },
        errors: loadSupportingDataErrors, isLoading: isLoadingSupportingData,
    } = useEditOrganisationalRoleSupportingData();

    const {
        data: {
            items: learningContents,
        }, isLoading: isLoadingLearningContents, errors: loadLearningContentsErrors,
    } = useLearningContentsSchoolTrustFilter(null, null);

    const {
        data: {
            items: organisationalRoleGroups,
        }, isLoading: isLoadingOrganisationalRoleGroups, errors: loadOrganisationalRoleGroupsErrors,
    } = useOrganisationalRoleGroups();

    const roleGroups = useMemo(() => {
        return organisationalRoleGroups?.filter(g => !g.archived);
    }, [organisationalRoleGroups]);

    // Show as loading if we're loading anything.
    const isLoading = _isLoading || isLoadingSupportingData || isLoadingOrganisationalRoleGroups;


    // Split requirements into skills and attributes.
    const { skills, attributes, qualifications, experiences } = useMemo(() => {
        return {
            skills: requirements?.filter(item => item.requirementType === RequirementType.Skill),
            attributes: requirements?.filter(item => item.requirementType === RequirementType.Attribute),
            qualifications: requirements?.filter(item => item.requirementType === RequirementType.Qualification),
            experiences: requirements?.filter(item => item.requirementType === RequirementType.Experience),
        }
    }, [requirements]);
    
    // Model (OrganisationalRole)
    const { model, change, changes } = useChanges(storeModel, isCreate ? { ...organisationalRoleDefaultValues(), ...(onCreateDefaultValues ? onCreateDefaultValues() : {}) } : undefined);
    const [saveOrganisationalRole, { errors: saveErrors }] = useSaveOrganisationalRoleMutation();

    // Requirement links.
    const requirementLinkManager = useChangesArray<RequirementLink, string>(storeRequirementLinks, item => item.id);
    const [saveRequirementLink, { errors: saveRequirementLinkErrors, }] = useSaveRequirementLinkMutation();
    const [removeRequirementLink, { errors: removeRequirementLinkErrors, }] = useDeleteRequirementLinkMutation();

    const organisationalRoleLinks = storeOrganisationalRoleLinks?.filter(item => item.organisationalRoleLinkType !== OrganisationalRoleLinkType.LearningForRole);
    const learningOrganisationalRoleLinks = storeOrganisationalRoleLinks?.filter(item => item.organisationalRoleLinkType === OrganisationalRoleLinkType.LearningForRole);

    // OrganisationalRoleLinks for next roles
    const organisationalRoleLinkManager = useChangesArray<OrganisationalRoleLink, string>(organisationalRoleLinks, item => item.id);
    const [saveOrganisationalRoleLink, { errors: saveOrganisationalRoleLinkErrors, }] = useSaveOrganisationalRoleLinkMutation();
    const [removeOrganisationalRoleLink, { errors: removeOrganisationalRoleLinkErrors, }] = useDeleteOrganisationalRoleLinkMutation();

    // OrganisationalRoleLinks for learning content
    const learningOrganisationalRoleLinkManager = useChangesArray<OrganisationalRoleLink, string>(learningOrganisationalRoleLinks, item => item.id);
    const [saveLearningOrganisationalRoleLink, { errors: saveLearningOrganisationalRoleLinkErrors, }] = useSaveOrganisationalRoleLinkMutation();
    const [removeLearningOrganisationalRoleLink, { errors: removeLearningOrganisationalRoleLinkErrors, }] = useDeleteOrganisationalRoleLinkMutation();

    // Exclude current role from role link tag selectors
    const { filteredOrganisationalroles } = useMemo(() => {
        return {
            filteredOrganisationalroles: organisationalRoles?.filter(item => item.id !== model.id)
        }
    }, [organisationalRoles, model.id])


    // Returns true if the requirement link is selected (with this type).
    const isRequirementLinkSelected = (requirementId: string, requirementLinkType: RequirementLinkType) => {
        return !!requirementLinkManager.model.find(item => item.requirementId === requirementId && item.requirementLinkType === requirementLinkType);
    };

    // Returns true if the organisational role link is selected (with this type)
    const isOrganisationalRoleLinkSelected = (organisationalRoleId: string, organisationalRoleLinkType: OrganisationalRoleLinkType) => {
        return !!organisationalRoleLinkManager.model.find(item => item.targetId === organisationalRoleId && item.organisationalRoleLinkType === organisationalRoleLinkType);
    };

    // Toggles a requirement link with the type provided.
    const toggleRequirementLink = (requirementId: string, requirementLinkType: RequirementLinkType) => {
        const existing = requirementLinkManager.model.find(item => item.requirementId === requirementId && item.requirementLinkType === requirementLinkType);
        if (existing) {
            requirementLinkManager.removeFor(existing.id);
        } else {
            requirementLinkManager.addFor({ ...requirementLinkDefaultValues(), targetId: model.id, targetType: 'OrganisationalRole', requirementId: requirementId, requirementLinkType: requirementLinkType });
        }
    };

    // Toggles a organisational role link with the type provided
    const toggleOrganisationalRoleLink = (organisationalRoleId: string, organisationalRoleLinkType: OrganisationalRoleLinkType) => {
        const existing = organisationalRoleLinkManager.model.find(item => item.targetId === organisationalRoleId && item.organisationalRoleLinkType === organisationalRoleLinkType);
        if (existing) {
            organisationalRoleLinkManager.removeFor(existing.id);
        } else {
            organisationalRoleLinkManager.addFor({ ...organisationalRoleLinkDefaultValues(), targetId: organisationalRoleId, targetType: 'OrganisationalRole', organisationalRoleId: model.id, organisationalRoleLinkType: organisationalRoleLinkType });
        }
    };

    //const selectedRoleGroups = useMemo(() => {
    //    var roleGroupLinks = organisationalRoleLinkManager.model?.filter(item => item.organisationalRoleLinkType === OrganisationalRoleLinkType.RoleInOrganisationalRoleGroup);
    //    if (!!roleGroupLinks) {
    //        return roleGroups?.filter(item => roleGroupLinks.find(it => it.targetId === item.id));
    //    }
    //}, [organisationalRoleLinkManager, roleGroups]);

    const isRoleGroupSelected = (organisationalRoleGroupId: string) => {
        return !!organisationalRoleLinkManager.model.find(it => it.targetId === organisationalRoleGroupId && it.organisationalRoleLinkType === OrganisationalRoleLinkType.RoleInOrganisationalRoleGroup);
    };

    const setSelectedRoleGroup = (organisationalRoleGroupId: string) => {
        const existing = organisationalRoleLinkManager.model.find(it => it.targetId === organisationalRoleGroupId && it.organisationalRoleLinkType === OrganisationalRoleLinkType.RoleInOrganisationalRoleGroup);
        if (existing) {
            organisationalRoleLinkManager.removeFor(existing.id);
        }
        else {
            organisationalRoleLinkManager.addFor({ ...organisationalRoleLinkDefaultValues(), targetId: organisationalRoleGroupId, targetType: "OrganisationalRoleGroup", organisationalRoleId: model.id, organisationalRoleLinkType: OrganisationalRoleLinkType.RoleInOrganisationalRoleGroup });
        }
        refreshData();
    };

    // Main model validation.
    const [validate, validationErrors] = useValidatorCallback((validation, fieldsToCheck) => {
        const rules = {
            name: () => !model?.name ? t('editOrganisationalRole.errors.name', 'Name cannot be empty') : '',
        };
        validation.checkRules(rules, fieldsToCheck);
    }, [model]);

    // Save everything
    const [saveForm, { isExecuting: isSaving, errors: saveFormErrors }] = useAsyncCallback(async () => {
        if (!model) {
            return;
        }

        if (!validate()) {
            return;
        }

        // Save the main model
        await saveOrganisationalRole(model.id, { ...changes }, isCreate ?? false);

        // Save any changes to the requirement links.
        for (const requirementLink of requirementLinkManager.added) { saveRequirementLink(requirementLink.id, requirementLink, true); }
        for (const requirementLink of requirementLinkManager.updated) { saveRequirementLink(requirementLink.id, requirementLink, false); }
        for (const requirementLink of requirementLinkManager.removed) { removeRequirementLink(requirementLink.id); }
        requirementLinkManager.markAsSaved();

        // Save any changes to the organisational role links
        for (const organisationalRoleLink of organisationalRoleLinkManager.added) { saveOrganisationalRoleLink(organisationalRoleLink.id, organisationalRoleLink, true); }
        for (const organisationalRoleLink of organisationalRoleLinkManager.updated) { saveOrganisationalRoleLink(organisationalRoleLink.id, organisationalRoleLink, false); }
        for (const organisationalRoleLink of organisationalRoleLinkManager.removed) { removeOrganisationalRoleLink(organisationalRoleLink.id); }
        organisationalRoleLinkManager.markAsSaved();

        // Save any changes to the learning organisational role links   
        for (const learningOrganisationalRoleLink of learningOrganisationalRoleLinkManager.added) { saveLearningOrganisationalRoleLink(learningOrganisationalRoleLink.id, learningOrganisationalRoleLink, true); }
        for (const learningOrganisationalRoleLink of learningOrganisationalRoleLinkManager.updated) { saveLearningOrganisationalRoleLink(learningOrganisationalRoleLink.id, learningOrganisationalRoleLink, false); }
        for (const learningOrganisationalRoleLink of learningOrganisationalRoleLinkManager.removed) { removeLearningOrganisationalRoleLink(learningOrganisationalRoleLink.id); }
        learningOrganisationalRoleLinkManager.markAsSaved();

        // Go back to previous screen.
        navigate(-1);
    }, [validate, saveOrganisationalRole, model, changes, isCreate, id, navigate,
        requirementLinkManager, saveRequirementLink, removeRequirementLink,
        learningOrganisationalRoleLinkManager, saveLearningOrganisationalRoleLink, removeLearningOrganisationalRoleLink,
    ]);

    // Clear image functionality
    const [clearImage, { isExecuting: isClearingImage }] = useAsyncCallback(async () => {
        change({ imageBlobReferenceId: null });
    }, [change]);

    // Image upload
    const { data: { model: image }, errors: imageLoadErrors } = useBlobReference(model?.imageBlobReferenceId);
    const [onUploadImage, { errors: imageUploadErrors, isExecuting: isUploadingImage }] = useAsyncCallback(async (files: FileList | null) => {
        if (!files) {
            return;
        }
        let uploadService: BlobUploadService = new BlobUploadService("api/blobs");
        let result = await uploadService.upload(files);

        if (!!result) {
            change({ imageBlobReferenceId: result.id });
        }
    }, [change]);

    // Tab tracking and UI matching to specific question types.
    const [activeTab, setActiveTab] = useState<'main' | 'learningContent'>('main');

    return (
        <>
            <Banner>
                <Row>
                    <Col xs={12} md="auto">
                        <h1>
                            {
                                isCreate ? t('editOrganisationalRole.createHeading.default', 'Add Role')
                                    : t('editOrganisationalRole.editHeading.default', 'Edit Role')
                            }
                        </h1>
                        <h3>{model?.name}</h3>
                    </Col>
                    <Col>
                        <StickyToolbar>
                            <PillsNavBar>
                                <NavItem>
                                    <NavLink active={activeTab === 'main'} onClick={() => setActiveTab('main')}>
                                        <> <FontAwesomeIcon icon="id-card" className="nav-icon" /> {t('editOrganisationalRole.roleTab', 'Role')}</>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink active={activeTab === 'learningContent'} onClick={() => setActiveTab('learningContent')}>
                                        <> <FontAwesomeIcon icon="archive" className="nav-icon" /> {t('editOrganisationalRole.learningContentTab', 'Growth resources')}</>
                                    </NavLink>
                                </NavItem>
                            </PillsNavBar>
                        </StickyToolbar>
                    </Col>
                    <ConditionalFragment showIf={isLoading}>
                        <Col xs="auto">
                            <LoadingIndicator size="sm" />
                        </Col>
                    </ConditionalFragment>
                </Row>
            </Banner>

            <MainContainer>
                <AlertOnErrors errors={[
                    loadErrors, loadSupportingDataErrors,
                    saveFormErrors, saveErrors,
                    saveRequirementLinkErrors, removeRequirementLinkErrors,
                    imageLoadErrors, imageUploadErrors,
                    saveOrganisationalRoleLinkErrors, removeOrganisationalRoleLinkErrors,
                    saveLearningOrganisationalRoleLinkErrors, removeLearningOrganisationalRoleLinkErrors,
                    loadOrganisationalRoleGroupsErrors,
                    
                ]} />

                <ConditionalFragment showIf={activeTab === 'main'}>

                    <Form onSubmit={e => { e.preventDefault(); saveForm(); }}>
                        <FormGroup>
                            <Row>
                                <Col>
                                    <Label htmlFor="name">{t('editOrganisationalRole.name.label', 'Name')}</Label>
                                    <ValidatedInput name="name" type="text" value={model?.name ?? ''} onChange={e => change({ name: e.currentTarget.value })} onBlur={e => validate('name')} validationErrors={validationErrors['name']} />
                                </Col>
                            </Row>
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="descriptionHtml">{t('editOrganisationalRole.description.label', 'Description')}</Label>
                            <HtmlEditor value={model.descriptionHtml ?? ''} onChange={e => change({ descriptionHtml: e })}></HtmlEditor>
                        </FormGroup>

                        <FormGroup>
                            <Label htmlFor="imageBlobReferenceId">{t('editOrganisationalRole.imageBlobReferenceId', 'Image')}</Label>
                            <UploadedImagePreview size="lg" src={image?.url ?? ''} />
                            <Row>
                                <Col>
                                    <ButtonGroup>
                                        <FileUploadButton
                                            color={'primary'}
                                            isExecuting={isUploadingImage}
                                            executingChildren={<><Spinner size="sm" /> {t('common.uploading', 'Uploading...')}</>}
                                            onUpload={onUploadImage}
                                            outline={false}>
                                            <FontAwesomeIcon icon="image" />
                                            <> </>
                                            {t('editOrganisationalRole.uploadButtonText', 'Upload an image')}
                                        </FileUploadButton>
                                        <ButtonAsync color="primary"
                                            outline
                                            isExecuting={isClearingImage}
                                            type="button"
                                            onClick={clearImage}
                                            executingChildren={<><Spinner size="sm" /> {t('editOrganisationalRole.clearingImage', 'Clearing image...')}</>}>
                                            {t('editOrganisationalRole.clearImageButton', 'Clear image')}
                                        </ButtonAsync>
                                    </ButtonGroup>
                                </Col>
                            </Row>
                        </FormGroup>


                        <h5>{t('editOrganisationalRole.qualifications.heading', 'Qualifications')}</h5>
                        <FormGroup>
                            <Label htmlFor="requiredQualifications">{t('editOrganisationalRole.qualifications.key.label', 'Key qualifications')}</Label>
                            <RequirementTagSelector input tags={qualifications}
                                isSelected={tag => isRequirementLinkSelected(tag.id, RequirementLinkType.KeyQualification)}
                                toggle={tag => toggleRequirementLink(tag.id, RequirementLinkType.KeyQualification)}
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label htmlFor="requiredQualifications">{t('editOrganisationalRole.qualifications.required.label', 'Required qualifications')}</Label>
                            <RequirementTagSelector input tags={qualifications}
                                isSelected={tag => isRequirementLinkSelected(tag.id, RequirementLinkType.RequiredQualification)}
                                toggle={tag => toggleRequirementLink(tag.id, RequirementLinkType.RequiredQualification)}
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label htmlFor="requiredQualifications">{t('editOrganisationalRole.qualifications.recommended.label', 'Recommended qualifications')}</Label>
                            <RequirementTagSelector input tags={qualifications}
                                isSelected={tag => isRequirementLinkSelected(tag.id, RequirementLinkType.RecommendedQualification)}
                                toggle={tag => toggleRequirementLink(tag.id, RequirementLinkType.RecommendedQualification)}
                            />
                        </FormGroup>

                        <h5>{t('editOrganisationalRole.experiences.heading', 'Experience')}</h5>
                        <FormGroup>
                            <Label htmlFor="requiredExperiences">{t('editOrganisationalRole.experiences.required.label', 'Required experience')}</Label>
                            <RequirementTagSelector input tags={experiences}
                                isSelected={tag => isRequirementLinkSelected(tag.id, RequirementLinkType.RequiredExperience)}
                                toggle={tag => toggleRequirementLink(tag.id, RequirementLinkType.RequiredExperience)}
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label htmlFor="requiredExperiences">{t('editOrganisationalRole.experiences.recommended.label', 'Recommended experience')}</Label>
                            <RequirementTagSelector input tags={experiences}
                                isSelected={tag => isRequirementLinkSelected(tag.id, RequirementLinkType.RecommendedExperience)}
                                toggle={tag => toggleRequirementLink(tag.id, RequirementLinkType.RecommendedExperience)}
                            />
                        </FormGroup>

                        
                        <h5>{t('editOrganisationalRole.skills.heading', 'Skills')}</h5>
                        <FormGroup>
                            <Label htmlFor="requiredSkills">{t('editOrganisationalRole.skills.required.label', 'Required skills')}</Label>
                            <RequirementTagSelector input tags={skills}
                                isSelected={tag => isRequirementLinkSelected(tag.id, RequirementLinkType.RequiredSkill)}
                                toggle={tag => toggleRequirementLink(tag.id, RequirementLinkType.RequiredSkill)}
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label htmlFor="requiredSkills">{t('editOrganisationalRole.skills.recommended.label', 'Recommended skills')}</Label>
                            <RequirementTagSelector input tags={skills}
                                isSelected={tag => isRequirementLinkSelected(tag.id, RequirementLinkType.RecommendedSkill)}
                                toggle={tag => toggleRequirementLink(tag.id, RequirementLinkType.RecommendedSkill)}
                            />
                        </FormGroup>

                        <h5>{t('editOrganisationalRole.attributes.heading', 'Attributes')}</h5>
                        <FormGroup>
                            <Label htmlFor="requiredAttributes">{t('editOrganisationalRole.attributes.required.label', 'Required attributes')}</Label>
                            <RequirementTagSelector input tags={attributes}
                                isSelected={tag => isRequirementLinkSelected(tag.id, RequirementLinkType.RequiredAttribute)}
                                toggle={tag => toggleRequirementLink(tag.id, RequirementLinkType.RequiredAttribute)}
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label htmlFor="requiredAttributes">{t('editOrganisationalRole.attributes.recommended.label', 'Recommended attributes')}</Label>
                            <RequirementTagSelector input tags={attributes}
                                isSelected={tag => isRequirementLinkSelected(tag.id, RequirementLinkType.RecommendedAttribute)}
                                toggle={tag => toggleRequirementLink(tag.id, RequirementLinkType.RecommendedAttribute)}
                            />
                        </FormGroup>


                        <h5>{t('editOrganisationalRole.organisationalRoleGroups.heading', 'Role groups')}</h5>
                        <FormGroup>
                            <OrganisationalRoleTagSelector input tags={roleGroups}
                                isSelected={tag => isRoleGroupSelected(tag.id)}
                                toggle={tag => setSelectedRoleGroup(tag.id)}
                            />
                        </FormGroup> 

                        <h5>{t('editOrganisationalRole.organisationalRoleLinks.heading', 'Role links')}</h5>
                        <FormGroup>
                            <Label htmlFor="mainNextRole">{t('editOrganisationalRole.organisationalRoleLinks.mainNextRole.label', 'Primary next roles')}</Label>
                            <OrganisationalRoleTagSelector input tags={filteredOrganisationalroles}
                                isSelected={tag => isOrganisationalRoleLinkSelected(tag.id, OrganisationalRoleLinkType.MainNextRole)}
                                toggle={tag => toggleOrganisationalRoleLink(tag.id, OrganisationalRoleLinkType.MainNextRole)}
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label htmlFor="nextRole">{t('editOrganisationalRole.organisationalRoleLinks.nextRole.label', 'Secondary next roles')}</Label>
                            <OrganisationalRoleTagSelector input tags={filteredOrganisationalroles}
                                isSelected={tag => isOrganisationalRoleLinkSelected(tag.id, OrganisationalRoleLinkType.NextRole)}
                                toggle={tag => toggleOrganisationalRoleLink(tag.id, OrganisationalRoleLinkType.NextRole)}
                            />
                        </FormGroup> 

                    </Form>
                </ConditionalFragment>

                <ConditionalFragment showIf={activeTab === 'learningContent'}>
                    <RoleLearningContentTab
                        model={model}
                        learningOrganisationalRoleLinkManager={learningOrganisationalRoleLinkManager}
                        allLearningContent={learningContents}
                        initialSelectedIds={learningOrganisationalRoleLinks?.map(item => item.targetId)}
                        isLoading={isLoading}
                    />
                </ConditionalFragment>

                <FormButtons>
                    <ConditionalFragment showIf={!isLoading}>
                        <ButtonAsync color="primary" isExecuting={isSaving} onClick={() => saveForm()}
                            executingChildren={<><Spinner size="sm" /> {t('common.saving', 'Saving...')}</>}>
                            <FontAwesomeIcon icon="save" />
                            <> </>
                            {t('common.save', 'Save')}
                        </ButtonAsync>
                    </ConditionalFragment>

                    <Button type="button" color="primary" outline onClick={e => navigate(-1)}>
                        {t('common.cancel', 'Cancel')}
                    </Button>
                </FormButtons>
            </MainContainer>
        </>
    )

}