import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Guid } from "guid-string";
import moment from "moment";
import React from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next"
import { LinkContainer } from "react-router-bootstrap";
import { useNavigate } from "react-router-dom";
import { Waypoint } from "react-waypoint";
import { Badge, Button, ButtonDropdown, ButtonGroup, CardBody, CardImg, CardTitle, Col, DropdownItem, DropdownMenu, DropdownToggle, Row } from "reactstrap";
import { useToggleStateArray } from "use-toggle-state";
import { useSpecificLearningContents } from "../../../api/main/learningContents/viewModels/useSpecificLearningContents";
import { getLearningContentTypes, LearningContentType, learningContentTypeDisplayName } from "../../../api/main/models/constants/LearningContentType";
import { usePreferredListViewMode } from "../../../globalState/preferredListViewMode/usePreferredListViewMode";
import { useCurrentUserOrEmulatedSubscription } from "../../../globalState/subscriptions/useCurrentUserOrEmulatedSubscription";
import { AlertOnErrors } from "../../../shared/alertOnErrors";
import { HtmlDisplay } from "../../../shared/htmlEditor";
import { useReplaceSearchParamsEffect, useSearchParams } from "../../../shared/useURLSearchParams";
import { truncateText } from "../../../utilities/truncateText";
import { Banner } from "../../shared/banner/Banner";
import { CardsOrTable } from "../../shared/cardsOrTable/CardsOrTable";
import { CardsOrTableToggle } from "../../shared/cardsOrTable/CardsOrTableToggle";
import { LoadingIndicator } from "../../shared/loadingIndicator/LoadingIndicator";
import { MainContainer } from "../../shared/mainContainer/MainContainer";
import { NoResultsFound } from "../../shared/noResultsFound/NoResultsFound";
import { SearchInput } from "../../shared/searchInput/SearchInput";
import { StickyToolbar } from "../../shared/stickyToolbar/StickyToolbar";

export interface VideosListProps {

    isAdmin?: boolean,
    isTrust?: boolean,

}

/**
 * List of all Videos
 * @returns
 */
export const VideosList = (props: VideosListProps) => {

    const {
        isAdmin = false,
        isTrust = false,
    } = props;

    const { t } = useTranslation();
    const navigate = useNavigate();

    //Load data
    const {
        data: {
            items: allItems,
        }, isLoading, errors: loadingErrors, fetchMore, hasMore
    } = useSpecificLearningContents(null, getLearningContentTypes("Video"), { pageSize: undefined });

    const [isMenuOpen, toggleMenuOpen] = useToggleStateArray();

    //Use preferred view mode for lists
    const [viewMode] = usePreferredListViewMode();

    // Search
    const { search: searchParam } = useSearchParams();
    const [search, setSearch] = React.useState<string>(searchParam ?? '');

    // Keep the URL up to date with the search text
    useReplaceSearchParamsEffect({ search: search });

    const subscription = useCurrentUserOrEmulatedSubscription(); // Grabs current/emulated subscription

    // Filter by the search
    const items = React.useMemo(() => {
        let ret = (allItems ?? []);
      
        let lowerSearch = search.toLocaleLowerCase();
        if (lowerSearch) {
            // Just filtering by all the string values that aren't ids to start with. Likely won't need them all for search.
            ret = ret.filter(item =>
                item.name.toLocaleLowerCase().indexOf(lowerSearch) >= 0
            );
        }

        if (isAdmin) {
            // If we are on the administration version, we want to filter out content from trusts and schools
            ret = ret.filter(item => item.trustId === null && item.schoolId === null);
        }

        if (isTrust && !isAdmin) {
            // If we are viewing from inside a trust we want to only show content from that trust.
            ret = ret.filter(item => item.trustId === subscription?.trustId);
        }

        if (!isTrust && !isAdmin) {
            // If we are viewing from inside a school we want to only show content from that school.
            ret = ret.filter(item => item.schoolId === subscription?.schoolId);
        }
        
        return ret;
    }, [allItems, search, isAdmin, isTrust, subscription]);

    function getImageLinkForCard(id: string | null | undefined)
    {
        const image = !Guid.isEmpty(id) ? { id: id, url: `/api/blobs/redirectToUrl/${id}`, filename: id ?? '' } : undefined;

        return image?.url;
    }

    //Render the UI
    //
    return (
        <>
            <Banner fluid>
                <StickyToolbar>
                    <Row>
                        <Col>
                            <h1>
                                {t('videosList.title', 'Videos')}
                            </h1>
                        </Col>
                        <ConditionalFragment showIf={isLoading}>
                            <Col>
                                <LoadingIndicator size="sm" />
                            </Col>
                        </ConditionalFragment>
                    </Row>
                    <Row>
                        <Col>
                            <SearchInput value={search} onChange={e => setSearch(e.currentTarget.value)} />
                        </Col>
                        <Col xs="auto">
                            <Row>
                                <ButtonGroup>
                                    <LinkContainer to={'add'}>
                                        <Button color="primary">
                                            <FontAwesomeIcon icon="plus" /><> {t('videosList.add', 'Add')}</>
                                        </Button>
                                    </LinkContainer>
                                </ButtonGroup>
                            </Row>
                        </Col>
                        <Col xs={12} md="auto">
                            <CardsOrTableToggle />
                        </Col>
                    </Row>
                </StickyToolbar>
            </Banner>
            <MainContainer fluid>
                <AlertOnErrors errors={[
                    loadingErrors
                ]} />

                <CardsOrTable
                    viewMode={viewMode}
                    items={items}
                    tableHeadings={[
                        null, /* Handles the card specific function for which w don't want a table column */
                        t('videosList.name.heading', 'Name'),
                        t('videosList.learningContentType.heading', 'Growth resource type'),
                        t('videosList.description.heading', 'Description'),
                        t('videosList.publishDate.heading', 'Publish date'),
                        t('videosList.cpdMinutes.heading', 'CPD mins')
                    ]}
                    columns={[
                        // We handle the card layout as a specific column ignored from the table
                        (item, view) => {
                            // This column doesn't show in the table
                            if (view !== 'cards') {
                                return null;
                            }
                            return (
                                <>
                                    <CardTitle tag="h5">{truncateText(70,item.name)}</CardTitle>
                                    <CardBody tag="h6" style={{ paddingLeft: "0",}}>
                                        <Row>
                                            <Col>
                                                <CardImg src={getImageLinkForCard(item.imageBlobReferenceId) ?? ''} style={{ maxHeight: "160px", width: "auto", maxWidth: "100%", }}></CardImg>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                {t('common.dateTime', moment(item.publishDate).isBefore(moment.now()) ? 'Published {{dateTime, DD/MM/YYYY}}' : 'Will publish {{dateTime, DD/MM/YYYY}}', { dateTime: moment(item.publishDate) })}
                                            </Col>
                                        </Row>
                                        <ConditionalFragment showIf={item.descriptionHtml !== ''}>
                                            <Row style={{ margin: '1rem -1rem' }}>
                                                <HtmlDisplay html={truncateText(60, item.descriptionHtml)} />
                                            </Row>
                                        </ConditionalFragment>
                                    </CardBody>
                                </>
                            );
                        },

                        // The remaining columns are for table mode only
                        // Name
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return item.name;
                        },

                        // Learning Content Type
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return <Badge color="primary">{learningContentTypeDisplayName(item.learningContentType as LearningContentType, t)}</Badge>;
                        },

                        // Description
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return <HtmlDisplay html={item.descriptionHtml} />;
                        },

                        // PublishDate
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return <>{t('common.date', '{{date, DD/MM/YYYY}}', { date: moment(item.publishDate) })}</>;
                        },

                        // CPD Minutes
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return item.cPDMinutes;
                        },
                    ]}

                    buttons={(item) => (
                        <ButtonGroup>
                            <LinkContainer to={`edit/${item.id}`}>
                                <Button color="primary">
                                    <FontAwesomeIcon icon="edit" />
                                    <>{t('common.edit', 'Edit')}</>
                                </Button>
                            </LinkContainer>
                            <ButtonDropdown isOpen={isMenuOpen(item.id)} toggle={() => toggleMenuOpen(item.id)}>
                                <DropdownToggle color="primary" caret>
                                    <span className="visually-hidden">{t('common.menuDropdown', 'More')}</span>
                                </DropdownToggle>
                                <DropdownMenu end>
                                    <LinkContainer to={`delete/${item.id}`}>
                                        <DropdownItem className="text-danger">
                                            <FontAwesomeIcon icon="trash" />
                                            <>{t('common.delete', 'Delete')}</>
                                        </DropdownItem>
                                    </LinkContainer>
                                </DropdownMenu>
                            </ButtonDropdown>
                        </ButtonGroup>
                    )}
                    onItemClick={item => navigate(`edit/${item.id}`)}
                />

                <ConditionalFragment showIf={isLoading && !items?.length}>
                    <LoadingIndicator fullWidth />
                </ConditionalFragment>
                <ConditionalFragment showIf={!isLoading && !items?.length}>
                    <NoResultsFound search={search} />
                </ConditionalFragment>
                <ConditionalFragment showIf={!isLoading && hasMore()}>
                    <Waypoint key={items?.length ?? 0} onEnter={fetchMore} />
                    <LoadingIndicator fullWidth />
                </ConditionalFragment>
            </MainContainer>
        </>
    );

}