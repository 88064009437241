import { TFunction } from "i18next";

/**
 * Types of Requirement.
 */
export enum RequirementType {
    Skill = "Skill",
    Attribute = "Attribute",
    Qualification = "Qualification",
    Experience = "Experience",
}

/**
 * Return the display text for a RequirementType.
 * @param t
 */
export function requirementTypeDisplayName(type: RequirementType | string | undefined | null, t: TFunction): string {
    switch (type) {
        case RequirementType.Skill: return t('requirementTypeDisplayName.skill', 'Skill');
        case RequirementType.Attribute: return t('requirementTypeDisplayName.attribute', 'Attribute');
        case RequirementType.Qualification: return t('requirementTypeDisplayName.qualification', 'Qualification');
    }

    return t('requirementTypeDisplayName.unknown', 'Unknown');
}
