import { EditCourse, EditCourseProps } from "../EditCourse";

/**
 * Create a new admin Course
 * @param props
 */
export const CreateAdminCourse = (props: EditCourseProps) => (<EditCourse isAdmin isCreate={true} {...props} />);

/**
 * Create a new trust Course
 * @param props
 */
export const CreateTrustCourse = (props: EditCourseProps) => (<EditCourse isTrust isCreate={true} {...props} />);

/**
 * Create a new school Course
 * @param props
 */
export const CreateSchoolCourse = (props: EditCourseProps) => (<EditCourse isCreate={true} {...props} />);

/*
 * Component to edit an admin Course
*/
export const EditAdminCourse = (props: EditCourseProps) => (<EditCourse isAdmin {...props} />);

/*
 * Component to edit a Course inside a trust
*/
export const EditTrustCourse = (props: EditCourseProps) => (<EditCourse isTrust {...props} />);

/*
 * Component to edit a Course inside a school
*/
export const EditSchoolCourse = (props: EditCourseProps) => (<EditCourse {...props} />);

/*
 * Component to view a Course
*/
export const ViewCourse = (props: EditCourseProps) => (<EditCourse isView={true} {...props} />);