import * as React from 'react';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useUpdateOrganisationProfileMutation } from './useUpdateOrganisationProfileMutation';
import { OrganisationProfile } from '../models/OrganisationProfile';
import { useCreateOrganisationProfileMutation } from './useCreateOrganisationProfileMutation';
import { OrganisationProfileCreateInput, OrganisationProfileUpdateInput } from '../generated/graphql';

/**
 * Get a callback to save a OrganisationProfile in the store using either a create or update.
 */
export function useSaveOrganisationProfileMutation(options: SaveInStoreOptions<OrganisationProfile, string> = {}) {
    const [_create, createStatus] = useCreateOrganisationProfileMutation();
    const create = React.useCallback((model: Partial<OrganisationProfile>) => _create(model as OrganisationProfileCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateOrganisationProfileMutation();
    const update = React.useCallback((id: string, changes: Partial<OrganisationProfile>) => _update(id, changes as OrganisationProfileUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
