import { BooksList, BooksListProps } from "../BooksList";

/*
 * Component to view a list of admin books
*/
export const AdminBooksList = (props: BooksListProps) => (<BooksList isAdmin {...props} />);

/*
 * Component to view a list of trust books
*/
export const TrustBooksList = (props: BooksListProps) => (<BooksList isTrust {...props} />);

/*
 * Component to view a list of school books
*/
export const SchoolBooksList = (props: BooksListProps) => (<BooksList {...props} />);