import { IdentityRoles } from "../../../configure/security/IdentityRoles";
import { RouteEntry } from "../../../shared/routes";
import { AdminCoursesList } from "./containers/CourseListContainers";
import { CreateAdminCourse, EditAdminCourse } from "./containers/EditCourseContainers";
import { DeleteCourse } from "./DeleteCourse";



/**
 * Route that should be added to configure/routeConfig.ts to provide access to these components via routing
 */
export const courseRoutes: Array<RouteEntry> = [
    { path: '/administration/courses', element: <AdminCoursesList  />, authorize: true, requireRole: IdentityRoles.Administration, },
    { path: '/administration/courses/add', element: <CreateAdminCourse onCreateDefaultValues={() => ({ learningContentType: "Course" })} />, authorize: true, requireRole: IdentityRoles.Administration, },
    { path: '/administration/courses/edit/:id', element: <EditAdminCourse />, authorize: true, requireRole: IdentityRoles.Administration, },
    { path: '/administration/courses/delete/:id', element: <DeleteCourse />, authorize: true, requireRole: IdentityRoles.Administration, },

];