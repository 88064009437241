import { NavItem } from 'reactstrap';
import { AuthorizeContainer } from '../../shared/authorizeRoute';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IdentityRoles } from '../../configure/security/IdentityRoles';
import { NavTitle } from '../shared/navTitle/NavTitle';
import { SidebarRequired } from '../layout/sidebar/sidebarRequired/SidebarRequired';
import { NavLinkTrackActive } from '../shared/navLinkTrackActive/NavLinkTrackActive';
import { useCurrentUserOrEmulatedSubscription } from '../../globalState/subscriptions/useCurrentUserOrEmulatedSubscription';

/**
 * Manage Trust navigation menu for the app. 
 */
export const ManageTrustNavigation = () => {
    const { t } = useTranslation();

    return (
        <>
            <SidebarRequired />
            <AuthorizeContainer requireRole={IdentityRoles.ManageTrust}>
                <NavTitle>
                    {t('trustNavigation.heading.trust', 'Trust')}
                </NavTitle>
                <NavItem>
                    <NavLinkTrackActive to={`/manage-trust/trust-details/${useCurrentUserOrEmulatedSubscription()?.trustId}`}>
                        <FontAwesomeIcon icon="clipboard-list" className="nav-icon" />
                        {t('trustNavigation.trustDetails', 'Trust details')}
                    </NavLinkTrackActive>
                </NavItem>
                <NavItem>
                    <NavLinkTrackActive to="/manage-trust/schools">
                        <FontAwesomeIcon icon="school" className="nav-icon" />
                        {t('trustNavigation.schools', 'Schools')}
                    </NavLinkTrackActive>
                </NavItem>
                <NavItem>
                    <NavLinkTrackActive to="/manage-trust/staff">
                        <FontAwesomeIcon icon="users" className="nav-icon" />
                        {t('trustNavigation.trustStaff', 'Trust staff')}
                    </NavLinkTrackActive>
                </NavItem>

                <NavTitle>
                    {t('trustNavigation.heading.talentMapping', 'Talent mapping')}
                </NavTitle>
                <NavItem>
                    <NavLinkTrackActive to="/manage-trust/role-map">
                        <FontAwesomeIcon icon="scale-unbalanced" className="nav-icon" />
                        {t('trustNavigation.documents', 'Role fulfilment')}
                    </NavLinkTrackActive>
                </NavItem>
                <NavItem>
                    <NavLinkTrackActive to="/manage-trust/career-map">
                        <FontAwesomeIcon icon="scale-unbalanced" className="nav-icon" />
                        {t('trustNavigation.careerFullfilment', 'Career fulfilment')}
                    </NavLinkTrackActive>
                </NavItem>

                <NavTitle>
                    {t('trustNavigation.heading.trustLearningLibrary', 'Trust growth resources')}
                </NavTitle>
                <NavItem>
                    <NavLinkTrackActive to="/manage-trust/courses">
                        <FontAwesomeIcon icon="chalkboard-teacher" className="nav-icon" />
                        {t('trustNavigation.courses', 'Courses')}
                    </NavLinkTrackActive>
                </NavItem>
                <NavItem>
                    <NavLinkTrackActive to="/manage-trust/videos">
                        <FontAwesomeIcon icon="video" className="nav-icon" />
                        {t('trustNavigation.videos', 'Videos')}
                    </NavLinkTrackActive>
                </NavItem>
                <NavItem>
                    <NavLinkTrackActive to="/manage-trust/podcasts">
                        <FontAwesomeIcon icon="microphone-alt" className="nav-icon" />
                        {t('trustNavigation.podcasts', 'Podcasts')}
                    </NavLinkTrackActive>
                </NavItem>
                <NavItem>
                    <NavLinkTrackActive to="/manage-trust/events">
                        <FontAwesomeIcon icon="calendar-alt" className="nav-icon" />
                        {t('trustNavigation.events', 'Events')}
                    </NavLinkTrackActive>
                </NavItem>
                <NavItem>
                    <NavLinkTrackActive to="/manage-trust/books">
                        <FontAwesomeIcon icon="book" className="nav-icon" />
                        {t('trustNavigation.books', 'Books')}
                    </NavLinkTrackActive>
                </NavItem>
                <NavItem>
                    <NavLinkTrackActive to="/manage-trust/standards">
                        <FontAwesomeIcon icon="file-alt" className="nav-icon" />
                        {t('trustNavigation.standards', 'Standards')}
                    </NavLinkTrackActive>
                </NavItem>
                <NavTitle>
                    {t('trustNavigation.heading.customise', 'Customise')}
                </NavTitle>
                <NavItem>
                    <NavLinkTrackActive to="/manage-trust/customise">
                        <FontAwesomeIcon icon="list-check" className="nav-icon" />
                        {t('trustNavigation.customiseTrust', 'Customise trust')}
                    </NavLinkTrackActive>
                </NavItem>
                <NavItem>
                    <NavLinkTrackActive to="/manage-trust/customize-schools">
                        <FontAwesomeIcon icon="list-check" className="nav-icon" />
                        {t('trustNavigation.customiseSchools', 'Customise schools')}
                    </NavLinkTrackActive>
                </NavItem>
            </AuthorizeContainer>
        </>
    );
};
