import React from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "reactstrap";
import { RequirementLinkType } from "../../api/main/models/constants/RequirementLinkType";
import { OrganisationalRole } from "../../api/main/models/OrganisationalRole";
import { OrganisationalRoleLink } from "../../api/main/models/OrganisationalRoleLink";
import { PersonnelFlag } from "../../api/main/models/PersonnelFlag";
import { Profile } from "../../api/main/models/Profile";
import { Requirement } from "../../api/main/models/Requirement";
import { RequirementLink } from "../../api/main/models/RequirementLink";
import { School } from "../../api/main/models/School";
import { usePersonnelFlagUsersArray } from "../../api/main/personnelFlagUsers/usePersonnelFlagUsersArray";
import { RenderIfVisible } from "../../shared/useIsElementVisible";
import { HorizontalScroll } from "../shared/horizontalScroll/HorizontalScroll";
import "./roleMapPart.scss";
import { roleMapUserScoreSort } from "./roleMapUserScoreSort";
import { RoleMapUserTile } from "./roleMapUserTile";

export interface RoleMapPartProps
{
    role: OrganisationalRole | undefined,
    roleLinks: Array<OrganisationalRoleLink>,
    users: Array<Profile>,
    allPersonnelFlags: Array<PersonnelFlag>,
    allRequirements: Array<Requirement>,
    requirementLinks: Array<RequirementLink>,
    isTrust?: boolean,
    school?: School | null,
    allRoles?: Array<OrganisationalRole>,
    refreshRoleLinks: () => Promise<void>,
    allUserOrganisationRoleLinks: Array<OrganisationalRoleLink>;
}

export const RoleMapPart = (props: RoleMapPartProps) =>
{
    const { t } = useTranslation();
    const { role, roleLinks, users } = props;
    

    // Extract the userIds from the users into their own array
    const userIds = React.useMemo(() => users.map(u => u.userId), [users]);

    // Use userIds to fetch each user's personnel flag links
    const {
        data: {
            items: personnelFlagUsers,
        }, errors: personnelFlagUsersErrors, refresh: refreshPersonnelFlagUsers
    } = usePersonnelFlagUsersArray(userIds, { pageSize: undefined });
   
    //run the users through the roleMapUserScoreSort function to get an ordered list of users based on their score.
    const usersForRole = React.useMemo(() => {
        const resolvedUsers = users ?? [];
        const roleUserOrganisatilRoleLinks = props.allUserOrganisationRoleLinks?.filter(item => item.organisationalRoleId === role?.id) ?? [];
        const allRoleLinks = [...(roleLinks ?? []), ...(roleUserOrganisatilRoleLinks ?? [])];
        const ret = roleMapUserScoreSort(resolvedUsers, role, allRoleLinks, personnelFlagUsers, props.allPersonnelFlags, props.allRequirements, props.requirementLinks);
        return ret;
    }, [roleLinks, users, props.allPersonnelFlags, props.allRequirements, props.requirementLinks, personnelFlagUsers, role]);

    return (
        <>
            <div className="role-map-part role-part">
                <Row>
                    <Col className="role-map-part role-column" style={{ maxWidth: '195px', maxHeight: '245px' }}>
                        <img className="role-map-part role-image" src={role?.imageBlobReferenceId? `/api/blobs/redirectToUrl/${role?.imageBlobReferenceId}`: ''} alt={''} />
                        <h6 style={{ fontSize: '0.9rem' }} className="role-map-part role-heading">{t('roleMapRow.heading', `${role?.name}`)}</h6>
                    </Col>
                    
                    <Col style={{ minWidth: '70%' }}>
                        <HorizontalScroll>
                            <div style={{ display: "flex", justifyItems: "center" }}>
                                {
                                    usersForRole.length ? usersForRole.map((userRoleScore, index) => {
                                        const user = userRoleScore.user;

                                        return (
                                            <RenderIfVisible key={index} fallback={<div style={{ width: '15%' }}></div>}>
                                                <RoleMapUserTile key={index}
                                                    user={user}
                                                    role={role}
                                                    roleLinks={roleLinks?.filter(item => item.targetId === user.userId) ?? []}
                                                    personnelFlagUsers={personnelFlagUsers?.filter(item => item.userId === user.userId)}
                                                    allPersonnelFlags={props.allPersonnelFlags}
                                                    refreshPersonnelFlagUsers={refreshPersonnelFlagUsers}
                                                    refreshRoleLinks={props.refreshRoleLinks}
                                                    isTrust={props.isTrust}
                                                    school={props.school}
                                                    userRequirements={props.allRequirements?.filter(item =>
                                                        props.requirementLinks?.find(it => item.id === it.requirementId && it.targetId === user.userId)
                                                    )}
                                                    allRoles={props.allRoles}
                                                    score={userRoleScore}
                                                    userOrganisationRoleLinks={props.allUserOrganisationRoleLinks?.filter(item => item.targetId === user.userId)}
                                                />
                                            </RenderIfVisible>
                                        )
                                    })
                                        : null
                                }
                            </div>
                        </HorizontalScroll>
                    </Col>
                </Row>
            </div>
        </>
    )
}