import { RouteEntry } from "../../shared/routes";
import { Career } from "./Career";
import { ConsumeFile } from "./ConsumeFile";
import { ViewCareerRole } from "./ViewCareerRole";

export const careerRoutes: Array<RouteEntry> = [
    { path: '/career', element: <Career />, authorize: true, },
    { path: '/career/view-role/:id', element: <ViewCareerRole />, authorize: true, },

    // Render LearningContent
    { path: '/career/learning-content/file/:blobId/:thumbnailId', element: <ConsumeFile />, authorize: true },
    { path: '/career/learning-content/file/:blobId', element: <ConsumeFile />, authorize: true },
    { path: '/career/learning-content/url/:url', element: <ViewCareerRole />, authorize: true },
];