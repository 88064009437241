import { Button, Form, Label, FormGroup, Spinner, Input, Row, Col } from 'reactstrap';
import { AlertOnErrors } from '../../../shared/alertOnErrors';
import { LoadingIndicator } from '../../shared/loadingIndicator/LoadingIndicator';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MainContainer } from '../../shared/mainContainer/MainContainer';
import { useParams, useNavigate } from 'react-router';
import { FormButtons } from '../../shared/formButtons/FormButtons';
import { ButtonAsync } from 'reactstrap-buttonasync';
import { useAsyncCallback } from 'react-use-async-callback';
import { ConditionalFragment } from 'react-conditionalfragment';
import { Banner } from '../../shared/banner/Banner';
import { useTrust } from '../../../api/main/trusts/useTrust';
import { useDeleteTrustMutation } from '../../../api/main/trusts/useDeleteTrustMutation';
import { useOrganisationProfile } from '../../../api/main/organisationProfiles/useOrganisationProfile';

/**
 * Delete a Trust
 */
export const DeleteTrust = () => {
    const { t } = useTranslation();
    const { id } = useParams<{ id: string; }>();
    const navigate = useNavigate();

    // Load the data
    const { data: { model }, isLoading: _isLoading, errors: loadErrors } = useTrust(id);
    const [remove, { errors: removeErrors }] = useDeleteTrustMutation();

    // Load the supporting data
    const {
        data: {
            model: organisationProfile
        }, isLoading: isLoadingSupportingData, errors: supportingDataLoadErrors
    } = useOrganisationProfile(model?.organisationProfileId);
    const isLoading = _isLoading || isLoadingSupportingData;

    // Remove form
    const [onRemoveFormSubmit, { isExecuting: isRemoving, errors: removeFormErrors }] = useAsyncCallback(async () => {
        if (!model) {
            return;
        }

        await remove(model.id);

        navigate(-1);
    }, [model, navigate]);

    // Render the UI
    //
    return (
        <>
            <Banner>
                <Row>
                    <Col>
                        <h1>{t('deleteTrust.heading.default', 'Are you sure you want to delete this trust?')}</h1>
                    </Col>
                    <ConditionalFragment showIf={isLoading}>
                        <Col xs="auto">
                            <LoadingIndicator size="sm" />
                        </Col>
                    </ConditionalFragment>
                </Row>
            </Banner>

            <MainContainer>
                <AlertOnErrors errors={[
                    loadErrors,
                    removeFormErrors,
                    removeErrors,
                    supportingDataLoadErrors
                ]} />

                <Form onSubmit={e => { e.preventDefault(); onRemoveFormSubmit(); }}>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label htmlFor="name">{t('deleteTrust.name.label', 'Name')}</Label>
                                <Input name="name" type="text" readOnly value={model?.name ?? ''} />
                            </FormGroup>
                        </Col>

                        <Col>
                            <FormGroup>
                                <Label htmlFor="organisation">{t('deleteTrust.organisation.label', 'Organisation profile')}</Label>
                                <Input name="organisation" type="text" readOnly value={organisationProfile?.name ?? ''} />
                            </FormGroup>
                        </Col>
                    </Row>

                    <FormButtons>
                        <ConditionalFragment showIf={!isLoading}>
                            <ButtonAsync color="danger" isExecuting={isRemoving}
                                executingChildren={<><Spinner size="sm" /> {t('common.deleting', 'Deleting...')}</>}>
                                <FontAwesomeIcon icon="trash" />
                                <> {t('common.delete', 'Delete')}</>
                            </ButtonAsync>
                        </ConditionalFragment>
                        <Button type="button" color="primary" outline onClick={e => navigate(-1)}>
                            {t('common.cancel', 'Cancel')}
                        </Button>
                    </FormButtons>
                </Form>
            </MainContainer>
        </>
    );
};
