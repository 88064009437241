import { RouteEntry } from "../../shared/routes";
import { IdentityRoles } from "../../configure/security/IdentityRoles";
import { ManageSchoolIndex } from "./ManageSchoolIndex";
import { ManageSchoolNavigation } from "./ManageSchoolNavigation";
import { DeleteCourse } from "../administration/courses/DeleteCourse";
import { DeleteVideo } from "../administration/videos/DeleteVideo";
import { DeletePodcast } from "../administration/podcasts/DeletePodcast";
import { DeleteEvent } from "../administration/events/DeleteEvent";
import { DeleteBook } from "../administration/books/DeleteBook";
import { DeleteStandard } from "../administration/standards/DeleteStandard";
import { EditSchool } from "../administration/schools/EditSchool";
import { DeleteUser } from "../users/DeleteUser";
import { SchoolUsersListContainer } from "./containers/userListContainers/SchoolUsersListContainer";
import { SchoolCreateUserContainer } from "./containers/userListContainers/SchoolCreateUserContainer";
import { SchoolEditUserContainer } from "./containers/userListContainers/SchoolEditUserContainer";
import { CreateSchoolVideo, EditSchoolVideo } from "../administration/videos/containers/EditVideoContainers";
import { SchoolVideosList } from "../administration/videos/containers/VideoListContainers";
import { CreateSchoolStandard, EditSchoolStandard } from "../administration/standards/containers/EditStandardContainers";
import { SchoolStandardsList } from "../administration/standards/containers/StandardsListContainers";
import { SchoolPodcastsList } from "../administration/podcasts/containers/PodcastsListContainers";
import { CreateSchoolPodcast, EditSchoolPodcast } from "../administration/podcasts/containers/EditPodcastContainers";
import { CreateSchoolEvent, EditSchoolEvent } from "../administration/events/containers/EditEventContainers";
import { SchoolEventsList } from "../administration/events/containers/EventListContainers";
import { SchoolCoursesList } from "../administration/courses/containers/CourseListContainers";
import { CreateSchoolCourse, EditSchoolCourse } from "../administration/courses/containers/EditCourseContainers";
import { CreateSchoolBook, EditSchoolBook } from "../administration/books/containers/EditBookContainers";
import { SchoolBooksList } from "../administration/books/containers/BookListContainer";
import { SchoolCustomiseOrganisationalProfileContainer } from "./containers/organisationalProfileContainers/SchoolCustomiseOrganisationalProfileContainer";
import { SchoolEditOrganisationProfileContainer } from "./containers/organisationalProfileContainers/SchoolEditOrganisationalProfileContainer";
import { SchoolRoleMapContainer } from "./containers/roleMapContainers/SchoolRoleMapContainer";
import { SchoolImportUsersContainer } from "./containers/ImportSchoolStaffContainer";
import { SchoolUserMapContainer } from "./containers/userMapContainers/SchoolUserMapContainer";



export const manageSchoolRoutes: Array<RouteEntry> = [
    { path: '/manage-school', element: <ManageSchoolIndex />, authorize: true, requireRole: IdentityRoles.ManageSchool },
    // School details and staff
    { path: '/manage-school/school-details/:id', element: <EditSchool />, authorize: true, requireRole: IdentityRoles.ManageSchool },
    // Staff
    { path: '/manage-school/staff', element: <SchoolUsersListContainer />, authorize: true, requireRole: IdentityRoles.ManageSchool },
    { path: '/manage-school/staff/add', element: <SchoolCreateUserContainer />, authorize: true, requireRole: IdentityRoles.ManageSchool },
    { path: '/manage-school/staff/edit/:id', element: <SchoolEditUserContainer />, authorize: true, requireRole: IdentityRoles.ManageSchool },
    { path: '/manage-school/staff/delete/:id', element: <DeleteUser />, authorize: true, requireRole: IdentityRoles.ManageSchool },
    // Import Staff
    { path: '/manage-school/staff/import', element: <SchoolImportUsersContainer />, authorize: true, requireRole: IdentityRoles.ManageSchool },

    // Learning Content
    // Courses
    { path: '/manage-school/courses', element: <SchoolCoursesList />, authorize: true, requireRole: IdentityRoles.ManageSchool },
    { path: '/manage-school/courses/add', element: <CreateSchoolCourse onCreateDefaultValues={() => ({ learningContentType: "Course" })} />, authorize: true, requireRole: IdentityRoles.ManageSchool },
    { path: '/manage-school/courses/edit/:id', element: <EditSchoolCourse />, authorize: true, requireRole: IdentityRoles.ManageSchool },
    { path: '/manage-school/courses/delete/:id', element: <DeleteCourse />, authorize: true, requireRole: IdentityRoles.ManageSchool },
    // Videos
    { path: '/manage-school/videos', element: <SchoolVideosList />, authorize: true, requireRole: IdentityRoles.ManageSchool },
    { path: '/manage-school/videos/add', element: <CreateSchoolVideo />, authorize: true, requireRole: IdentityRoles.ManageSchool },
    { path: '/manage-school/videos/edit/:id', element: <EditSchoolVideo />, authorize: true, requireRole: IdentityRoles.ManageSchool },
    { path: '/manage-school/videos/delete/:id', element: <DeleteVideo />, authorize: true, requireRole: IdentityRoles.ManageSchool },
    // Podcasts
    { path: '/manage-school/podcasts', element: <SchoolPodcastsList />, authorize: true, requireRole: IdentityRoles.ManageSchool },
    { path: '/manage-school/podcasts/add', element: <CreateSchoolPodcast />, authorize: true, requireRole: IdentityRoles.ManageSchool },
    { path: '/manage-school/podcasts/edit/:id', element: <EditSchoolPodcast />, authorize: true, requireRole: IdentityRoles.ManageSchool },
    { path: '/manage-school/podcasts/delete/:id', element: <DeletePodcast />, authorize: true, requireRole: IdentityRoles.ManageSchool },
    // Events
    { path: '/manage-school/events', element: <SchoolEventsList />, authorize: true, requireRole: IdentityRoles.ManageSchool },
    { path: '/manage-school/events/add', element: <CreateSchoolEvent onCreateDefaultValues={() => ({ learningContentType: "Event" })} />, authorize: true, requireRole: IdentityRoles.ManageSchool },
    { path: '/manage-school/events/edit/:id', element: <EditSchoolEvent />, authorize: true, requireRole: IdentityRoles.ManageSchool },
    { path: '/manage-school/events/delete/:id', element: <DeleteEvent />, authorize: true, requireRole: IdentityRoles.ManageSchool },
    // Books
    { path: '/manage-school/books', element: <SchoolBooksList />, authorize: true, requireRole: IdentityRoles.ManageSchool },
    { path: '/manage-school/books/add', element: <CreateSchoolBook />, authorize: true, requireRole: IdentityRoles.ManageSchool },
    { path: '/manage-school/books/edit/:id', element: <EditSchoolBook />, authorize: true, requireRole: IdentityRoles.ManageSchool },
    { path: '/manage-school/books/delete/:id', element: <DeleteBook />, authorize: true, requireRole: IdentityRoles.ManageSchool },
    // Standards
    { path: '/manage-school/standards', element: <SchoolStandardsList />, authorize: true, requireRole: IdentityRoles.ManageSchool },
    { path: '/manage-school/standards/add', element: <CreateSchoolStandard />, authorize: true, requireRole: IdentityRoles.ManageSchool },
    { path: '/manage-school/standards/edit/:id', element: <EditSchoolStandard />, authorize: true, requireRole: IdentityRoles.ManageSchool },
    { path: '/manage-school/standards/delete/:id', element: <DeleteStandard />, authorize: true, requireRole: IdentityRoles.ManageSchool },

    // Customise
    { path: '/manage-school/customise', element: <SchoolCustomiseOrganisationalProfileContainer />, authorize: true, requireRole: IdentityRoles.ManageSchool },
    { path: '/manage-school/customise/:id', element: <SchoolEditOrganisationProfileContainer />, authorize: true, requireRole: IdentityRoles.ManageSchool },

    //Role Map
    { path: '/manage-school/role-map', element: <SchoolRoleMapContainer />, authorize: true, requireRole: IdentityRoles.ManageSchool },

    //User Map
    { path: '/manage-school/career-map', element: <SchoolUserMapContainer />, authorize: true, requireRole: IdentityRoles.ManageSchool },
];

export const manageSchoolSidebarRoutes: Array<RouteEntry> = [
    { path: '/manage-school/*', element: (<ManageSchoolNavigation />), authorize: true, requireRole: IdentityRoles.ManageSchool },
];
