import { IdentityRoles } from "../../../configure/security/IdentityRoles";
import { RouteEntry } from "../../../shared/routes";
import { DeleteStandard } from "./DeleteStandard";
import { CreateAdminStandard, EditAdminStandard } from "./containers/EditStandardContainers";
import { AdminStandardsList } from "./containers/StandardsListContainers";

export const standardRoutes: Array<RouteEntry> = [
    { path: '/administration/standards/add', element: <CreateAdminStandard/>, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/standards/edit/:id', element: <EditAdminStandard />, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/standards/delete/:id', element: <DeleteStandard />, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/standards', element: <AdminStandardsList/>, authorize: true, requireRole: IdentityRoles.Administration },
];