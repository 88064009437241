import * as React from 'react';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useUpdateTrustMutation } from './useUpdateTrustMutation';
import { Trust } from '../models/Trust';
import { useCreateTrustMutation } from './useCreateTrustMutation';
import { TrustCreateInput, TrustUpdateInput } from '../generated/graphql';

/**
 * Get a callback to save a Trust in the store using either a create or update.
 */
export function useSaveTrustMutation(options: SaveInStoreOptions<Trust, string> = {}) {
    const [_create, createStatus] = useCreateTrustMutation();
    const create = React.useCallback((model: Partial<Trust>) => _create(model as TrustCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateTrustMutation();
    const update = React.useCallback((id: string, changes: Partial<Trust>) => _update(id, changes as TrustUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
