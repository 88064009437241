import gql from 'graphql-tag';
import { Guid } from 'guid-string';
import { OrganisationalRoleFieldsFragment } from '../generated/graphql';

export const organisationalRoleFieldsFragment = gql`
    fragment organisationalRoleFields on OrganisationalRole {
        id
        name
        descriptionHtml
        imageBlobReferenceId
        archived
        trustId
        schoolId
    }
`;

export type OrganisationalRole = Omit<OrganisationalRoleFieldsFragment, '__typename' | 'organisationalRole'>;

/**
 * Default values.
 */
export const organisationalRoleDefaultValues = (): Partial<OrganisationalRole> => ({
    id: Guid.newGuid(),
    name: '',
    descriptionHtml: '',
    imageBlobReferenceId: null,
    archived: false,
    trustId: null,
    schoolId: null,
});