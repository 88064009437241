import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Card, Col, Row } from "reactstrap";
import { OrganisationalRole } from "../../api/main/models/OrganisationalRole";
import { truncateText } from "../../utilities/truncateText";

export interface UserMapRoleTileProps {
    role: OrganisationalRole,
    pinned: boolean,
}

export const UserMapRoleTile = (props: UserMapRoleTileProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const {
        role,
        pinned
    } = props;

    // Render the UI
    //
    return (
        <div style={{ minWidth: '180px' }}>
            <Card style={{ border: 0, cursor: 'pointer', backgroundColor: 'transparent' }} onClick={e => navigate(`/career/view-role/${role?.id}`)}>
                <div style={{ padding: "1rem" }}>
                    <ConditionalFragment showIf={pinned}>
                        <FontAwesomeIcon icon="thumbtack" style={{ position: "relative", zIndex: 9000, left: "8rem", bottom: "0rem", maxWidth: "1rem", height: "auto", color: '#9D5097' }} />
                    </ConditionalFragment>
                    <ConditionalFragment showIf={!pinned}>
                        <FontAwesomeIcon icon="thumbtack" style={{ position: "relative", zIndex: 9000, left: "8rem", bottom: "0rem", maxWidth: "1rem", height: "auto", color: '#FFFFFF' }} />
                    </ConditionalFragment>
                    <Row>
                        <Col>
                        </Col>
                        <Col xs="auto">
                            <img className="user-map-part-role-image" src={role?.imageBlobReferenceId ? `/api/blobs/redirectToUrl/${role?.imageBlobReferenceId}`: ''} alt={''} />
                        </Col>
                        <Col>
                        </Col>
                    </Row>
                    <div className="user-map-part-role-heading">{t('roleMapRow.heading', `${truncateText(30, role.name)}`)}</div>
                </div>
            </Card>
        </div>
    )
}