import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Card, Col, Row } from "reactstrap";
import { useToggleState } from "use-toggle-state";
import { OrganisationalRoleLinkType } from "../../api/main/models/constants/OrganisationalRoleLinkType";
import { RequirementLinkType } from "../../api/main/models/constants/RequirementLinkType";
import { OrganisationalRole } from "../../api/main/models/OrganisationalRole";
import { PersonnelFlag } from "../../api/main/models/PersonnelFlag";
import { Profile } from "../../api/main/models/Profile";
import { Requirement } from "../../api/main/models/Requirement";
import { RequirementLink } from "../../api/main/models/RequirementLink";
import { School } from "../../api/main/models/School";
import { useOrganisationalRoleLinksByTargetId } from "../../api/main/organisationalRoleLinks/useUserOrganisationalRoleLinks";
import { usePersonnelFlagUsers } from "../../api/main/personnelFlagUsers/usePersonnelFlagUsers";
import { RenderIfVisible } from "../../shared/useIsElementVisible";
import { truncateText } from "../../utilities/truncateText";
import { HorizontalScroll } from "../shared/horizontalScroll/HorizontalScroll";
import "./userMapPart.scss";
import { userMapRoleScoreSort } from "./userMapRoleScoreSort";
import { UserMapRoleTile } from "./userMapRoleTile";
import { UserMapUserDetailsModal } from "./userMapUserDetailsModal";
export interface UserMapPartProps {
    user: Profile,
    roles: Array<OrganisationalRole>,
    allRequirements: Array<Requirement>,
    allRequirementLinks: Array<RequirementLink>,
    allPersonnelFlags: Array<PersonnelFlag>,
    isTrust?: boolean,
    school?: School | null,
}

export const UserMapPart = (props: UserMapPartProps) => {
    const { t } = useTranslation();
    const {
        user,
        roles,
        allRequirements,
        allRequirementLinks,
        allPersonnelFlags,
        isTrust = false,
        school,
    } = props;

    // Get the user's personnelFlagUsers
    const {
        data: {
            items: personnelFlagUsers,
        }, isLoading: personnelFlagUsersLoading, errors: personnelFlagUsersErrors, refresh: refreshPersonnelFlagUsers
    } = usePersonnelFlagUsers();

    // Get the user's organisational role links
    const {
        data: {
            items: roleLinks,
        }, isLoading: roleLinksLoading, errors: roleLinksErrors, refresh: refreshRoleLinks
    } = useOrganisationalRoleLinksByTargetId({}, user?.id);

    const userPersonnelFlagUsers = useMemo(() => {
        return personnelFlagUsers?.filter(it => it.userId === user.userId);
    }, [personnelFlagUsers, user])

    const rolesForUser = React.useMemo(() => {
        let ret = roles ?? [];

        // If we don't have the required data, return an empty array
        if (!allRequirementLinks) {
            return [];
        }

        ret = userMapRoleScoreSort(user, ret, roleLinks, userPersonnelFlagUsers, allPersonnelFlags, allRequirements, allRequirementLinks);

        return ret;
    }, [user, roles, roleLinks, allPersonnelFlags, allRequirements, allRequirementLinks, userPersonnelFlagUsers]);


    // Handle toggling of the modal
    const [manageUserDetailsModalOpen, _toggleUserDetailsModal] = useToggleState(false);
    const toggleUserDetailsModal = useCallback(() => {
        _toggleUserDetailsModal();
    }, [_toggleUserDetailsModal]);

    // Get the user's personnel flags from the personnelFlagUsers
    const userPersonnelFlags = useMemo(() => {
        return allPersonnelFlags?.filter(item => personnelFlagUsers?.find(it => it.personnelFlagId === item.id));
    }, [allPersonnelFlags, personnelFlagUsers]);
    // Get the initial selected ids
    const initialSelectedIds = useMemo(() => {
        return userPersonnelFlags?.map(it => it.id);
    }, [userPersonnelFlags]);

    const loading = personnelFlagUsersLoading || roleLinksLoading;

    return (
        <>
            <div className="user-map-part-user-part">
                <Row>
                    <Col className="user-map-part-user-column" style={{ maxWidth: '195px', maxHeight: '245px' }} >
                        <Card style={{ border: 0, cursor: 'pointer', backgroundColor: 'transparent' }} onClick={e => toggleUserDetailsModal()}>
                            <img className="user-map-part-user-image" src={user?.photoBlobReferenceId ? `/api/blobs/redirectToUrl/${user?.photoBlobReferenceId}` : '/img/user-solid.svg'} alt={''}
                                style={{ objectFit: 'contain', }}
                            />
                            <h6 style={{ fontSize: '0.9rem' }} className="user-map-part-user-heading">{t('userMapRow.heading', `${truncateText(15, user.firstName)} ${truncateText(15, user.lastName)}`)}</h6>
                        </Card>
                    </Col>

                    <Col style={{ minWidth: '70%' }}>
                        <HorizontalScroll>
                            <div style={{ display: "flex", justifyItems: "center" }}>
                                {
                                    rolesForUser?.length ? rolesForUser?.map((role, index) => {
                                        return (
                                            <RenderIfVisible key={index} fallback={<div style={{ width: '15%' }}></div>}>
                                                <UserMapRoleTile key={index}
                                                    role={role}
                                                    pinned={!!roleLinks?.find(it => it.organisationalRoleLinkType === OrganisationalRoleLinkType.RoleMapUserPin
                                                        && it.targetId === user?.userId && it.organisationalRoleId === role?.id)}
                                                />
                                            </RenderIfVisible>
                                            
                                        )
                                    }) : null
                                }
                            </div>
                        </HorizontalScroll>
                    </Col>
                </Row>

                <UserMapUserDetailsModal
                    isOpen={manageUserDetailsModalOpen}
                    toggle={toggleUserDetailsModal}
                    userProfile={user}
                    userBlobReferenceUrl={user?.photoBlobReferenceId ? `/blobs/redirectToUrl/${user?.photoBlobReferenceId}` : '/img/user-solid.svg'}
                    allPersonnelFlags={allPersonnelFlags}
                    personnelFlagUsers={userPersonnelFlagUsers}
                    initialSelectedIds={initialSelectedIds}
                    roles={roles}
                    isTrust={isTrust}
                    school={school}
                    userRequirements={allRequirements?.filter(item =>
                        allRequirementLinks?.find(it => item.id === it.requirementId && it.targetId === user.userId
                            && (it.requirementLinkType === RequirementLinkType.UserAttribute
                                || it.requirementLinkType === RequirementLinkType.UserQualification
                                || it.requirementLinkType === RequirementLinkType.UserSkill)))}
                />
            </div>
        </>
    )
};