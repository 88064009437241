import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useValidatorCallback } from 'pojo-validator-react';
import { ValidatedInput } from 'pojo-validator-reactstrap';
import * as React from 'react';
import { ConditionalFragment } from 'react-conditionalfragment';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useAsyncCallback } from 'react-use-async-callback';
import { Col, FormGroup, Label, Row, Form, Spinner, Button } from 'reactstrap';
import { ButtonAsync } from 'reactstrap-buttonasync';
import { UserLearningEvent } from '../../api/main/generated/graphql';
import { userLearningEventDefaultValues } from '../../api/main/models/UserLearningEvent';
import { useSaveUserLearningEventMutation } from '../../api/main/userLearningEvents/useSaveUserLearningEventMutation';
import { useUserLearningEvent } from '../../api/main/userLearningEvents/useUserLearningEvent';
import { useCurrentUser } from '../../api/main/users/useCurrentUser';
import { AlertOnErrors } from '../../shared/alertOnErrors';
import { HtmlEditor } from '../../shared/htmlEditor';
import { useChanges } from '../../shared/useChanges';
import { Banner } from '../shared/banner/Banner';
import { FormButtons } from '../shared/formButtons/FormButtons';
import { ISODateTimeInput } from '../shared/isoDateTimeInput/ISODateTimeInput';
import { LoadingIndicator } from '../shared/loadingIndicator/LoadingIndicator';
import { MainContainer } from '../shared/mainContainer/MainContainer';

// Currently looks like an admin screen so I can see what the data is. Will need to be changed to a user facing screen.

export interface EditCpdProps {
    isCreate?: boolean,
    onCreateDefaultValues?: () => Partial<UserLearningEvent>,
}

/**
 * Create a new CPD
 * @param props
 */
export const CreateCpd = (props: EditCpdProps) => (<EditCpd isCreate={true} {...props} />);

export const EditCpd = (props: EditCpdProps) => {

    const {
        isCreate,
        onCreateDefaultValues,
    } = props;

    const { t } = useTranslation();
    const { id } = useParams<{ id: string | undefined; }>();
    const navigate = useNavigate();

    // Load the data
    const {
        data: {
            model: storeModel,
        }, isLoading, errors: loadErrors
    } = useUserLearningEvent(id);

    const currentUser = useCurrentUser();

    const { model, change, changes } = useChanges(storeModel, isCreate ? { ...userLearningEventDefaultValues(), ...(onCreateDefaultValues ? onCreateDefaultValues() : {}) } : undefined);
    const [saveCpd, { errors: saveErrors }] = useSaveUserLearningEventMutation();

    // Main model validation
    const [validate, validationErrors] = useValidatorCallback((validation, fieldsToCheck) => {
        const rules = {
            name: () => !model?.externalName ? t('editCpd.errors.nameRequired', 'Name is required') : '',
            learningEventType: () => !model?.learningEventType ? t('editCpd.errors.contentTypeRequired', 'Growth resource type cannot be empty') : '',
            minutes: () => (model?.cPDMinutes === 0 || !model?.cPDMinutes) ? t('editCpd.errors.minutesRequired', 'CPD minutes is required') : '',
        };
        validation.checkRules(rules, fieldsToCheck);
    }, [model,]);

    // Really not a good way to do this, however it doesnt work to change on the save callback, so we have to do it here.
    React.useEffect(() => {

        if (isCreate && !!currentUser && changes.userId === "") {
            change({ userId: currentUser.id });
        }

    }, [change, isCreate, currentUser, changes.userId]);

    // Save the form
    const [saveForm, { isExecuting: isSaving, errors: saveFormErrors }] = useAsyncCallback(async () => {

        if (!model) {
            return;
        }
        if (!validate()) {
            return;
        }

        // Save the main model.
        await saveCpd(model.id, { ...changes }, isCreate ?? false);

        // Go back to previous screen.
        navigate(-1);
    }, [validate, saveCpd, model, changes, currentUser, isCreate, navigate,]);

    return (
        <>
            <Banner>
                <Row>
                    <Col xs={12} md="auto">
                        <h1>
                            {
                                isCreate ? t('editCpd.createHeading.default', 'Add CPD')
                                    : t('editCpd.editHeading.default', 'Edit CPD')
                            }
                        </h1>
                    </Col>
                    <Col>
                        <Button style={{ float: "right" }} onClick={() => navigate(`/cpd/delete/${id}`)}>
                            <FontAwesomeIcon icon="trash" />
                            <> {t('common.delete', 'Delete')}</>
                        </Button>
                    </Col>
                    <ConditionalFragment showIf={isLoading}>
                        <Col xs="auto">
                            <LoadingIndicator size="sm" />
                        </Col>
                    </ConditionalFragment>
                </Row>
            </Banner>

            <MainContainer>
                <AlertOnErrors
                    errors={[
                        loadErrors,
                        saveFormErrors,
                        saveErrors,
                    ]}
                />

                <Form onSubmit={e => { e.preventDefault(); saveForm(); } }>
                    <Row>
                        <FormGroup>
                            <Label htmlFor="externalName">{t('editCpd.externalName.label', 'Name')}</Label>
                            <ValidatedInput name="externalName" type="text" value={model?.externalName ?? ""} onChange={e => change({ externalName: e.currentTarget.value })} onBlur={e => validate('name')} validationErrors={validationErrors['name']} />
                        </FormGroup>
                    </Row>

                    <Row>
                        <FormGroup>
                            <Label htmlFor="cpdLearningEventType">{t('editCpd.learningEventType', 'CPD resource type')}</Label>
                            <ValidatedInput name="learningEventType" type="select" value={model.learningEventType ?? ''} onChange={(e) => change({ learningEventType: e.currentTarget.value })} onBlur={e => validate('learningEventType')} validationErrors={validationErrors['learningEventType']}>
                                <option value="">{t('select CPD type')}</option>
                                <option key="course" value="Course">{t('editCpd.course', 'Course')}</option>
                                <option key="video" value="Video">{t('editCpd.video', 'Video')}</option>
                                <option key="podcast" value="Podcast">{t('editCpd.podcast', 'Podcast')}</option>
                                <option key="event" value="Event">{t('editCpd.event', 'Event')}</option>
                                <option key="book" value="Book">{t('editCpd.book', 'Book')}</option>
                                <option key="standard" value="Standard">{t('editCpd.standard', 'Standard')}</option>
                            </ValidatedInput>
                        </FormGroup>
                    </Row>

                    <Row>
                        <FormGroup>
                            <Label htmlFor="eventDate">{t('editCpd.eventDate.label', 'Event date')}</Label>
                            <ISODateTimeInput name="eventDate" type="date" value={model?.eventDate ?? ''} onChange={e => change({ eventDate: e.currentTarget.value })}/>
                        </FormGroup>
                    </Row>

                    <Row>
                        <FormGroup>
                            <Label htmlFor="cpdMinutes">{t('editCpd.cpdMinutes.label', 'CPD minutes')}</Label>
                            <ValidatedInput name="cpdHours" type="number" value={model?.cPDMinutes ?? 0} onChange={e => change({ cPDMinutes: e.currentTarget.valueAsNumber })} onBlur={e => validate('minutes')} validationErrors={validationErrors['minutes']} />
                        </FormGroup>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label htmlFor="externalDescriptionHtml">{t('editCpd.description.label', 'Description')}</Label>
                                <HtmlEditor size="sm" value={model?.externalDescriptionHtml ?? ''} onChange={text => change({ externalDescriptionHtml: text })} />
                            </FormGroup>
                        </Col>
                    </Row>

                    <FormButtons>
                        <ConditionalFragment showIf={!isLoading}>
                            <ButtonAsync color="primary" isExecuting={isSaving} onClick={() => saveForm()}
                                executingChildren={<><Spinner size="sm" /> {t('common.saving', 'Saving...')}</>}>
                                <FontAwesomeIcon icon="save" />
                                <> </>
                                {t('common.save', 'Save')}
                            </ButtonAsync>
                        </ConditionalFragment>

                        <Button type="button" color="primary" outline onClick={e => navigate(-1)}>
                            {t('common.cancel', 'Cancel')}
                        </Button>
                    </FormButtons>

                </Form>
            </MainContainer>
        </>
    );

}
