import { ImportUsersBase } from "../../users/import/ImportUsersBase";

/**
 * Container for importing users at the trust level.
 * @returns
 */
export const SchoolImportUsersContainer = () => {
    return (
        <ImportUsersBase isSchool={true} />
    );
};