import { IdentityRoles } from "../../../configure/security/IdentityRoles";
import { RouteEntry } from "../../../shared/routes";
import { CreateAdminPodcast, EditAdminPodcast } from "./containers/EditPodcastContainers";
import { AdminPodcastsList } from "./containers/PodcastsListContainers";
import { DeletePodcast } from "./DeletePodcast";


/**
 * Route that should be added to configure.routeConfig.ts to provide access to these components via routing
 */
export const podcastRoutes: Array<RouteEntry> = [
    { path: '/administration/podcasts', element: <AdminPodcastsList/>, authorize: true, requireRole: IdentityRoles.Administration, },
    { path: '/administration/podcasts/add', element: <CreateAdminPodcast />, authorize: true, requireRole: IdentityRoles.Administration, },
    { path: '/administration/podcasts/edit/:id', element: <EditAdminPodcast />, authorize: true, requireRole: IdentityRoles.Administration, },
    { path: '/administration/podcasts/delete/:id', element: <DeletePodcast />, authorize: true, requireRole: IdentityRoles.Administration, }
];