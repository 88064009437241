import * as React from 'react';
import { Button, Row, Col, ButtonGroup, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, CardTitle, CardSubtitle } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRequirements } from '../../../api/main/requirements/useRequirements';
import { useReplaceSearchParamsEffect, useSearchParams } from '../../../shared/useURLSearchParams';
import { useNavigate } from "react-router-dom";
import { useToggleStateArray } from "use-toggle-state";
import { ConditionalFragment } from 'react-conditionalfragment';
import { LoadingIndicator } from '../../shared/loadingIndicator/LoadingIndicator';
import { MainContainer } from '../../shared/mainContainer/MainContainer';
import { Banner } from '../../shared/banner/Banner';
import { StickyToolbar } from '../../shared/stickyToolbar/StickyToolbar';
import { AlertOnErrors } from '../../../shared/alertOnErrors';
import { SearchInput } from '../../shared/searchInput/SearchInput';
import { LinkContainer } from 'react-router-bootstrap';
import { CardsOrTable } from '../../shared/cardsOrTable/CardsOrTable';
import { CardsOrTableToggle } from '../../shared/cardsOrTable/CardsOrTableToggle';
import { usePreferredListViewMode } from '../../../globalState/preferredListViewMode/usePreferredListViewMode';
import { Waypoint } from 'react-waypoint';
import { NoResultsFound } from '../../shared/noResultsFound/NoResultsFound';
import { HtmlDisplay } from '../../../shared/htmlEditor';
import { truncateText } from '../../../utilities/truncateText';
import { RequirementType } from '../../../api/main/models/constants/RequirementType';

interface RequirementListProps {
    type: string;
}

/**
 * List of Requirements
 */
export const RequirementList = (props: RequirementListProps) => {
    const {
        type
    } = props;

    const { t } = useTranslation();

    // Load data
    const { data: { items: allItems }, isLoading, errors: loadingErrors, fetchMore, hasMore } = useRequirements({ pageSize: undefined, type: type });


    const navigate = useNavigate();

    const [isMenuOpen, toggleMenuOpen] = useToggleStateArray();

    // Use preferred view mode for lists
    const [viewMode] = usePreferredListViewMode();

    const { search: searchParam } = useSearchParams();
    const [search, setSearch] = React.useState<string>(searchParam ?? '');

    // Keep the URL up to date with the search text
    useReplaceSearchParamsEffect({ search: search });

    // Filter by the search
    const items = React.useMemo(() => {
        let ret = (allItems ?? []);

        let lowerSearch = search.toLocaleLowerCase();
        if (lowerSearch) {
            // Just filtering by all the string values that aren't ids to start with. Likely won't need them all for search.
            ret = ret.filter(item =>
                item.name.toLocaleLowerCase().indexOf(lowerSearch) >= 0
            );
        }
        return ret;
    }, [allItems, search,]);

    //Render the UI
    //
    return (
        <>
            <Banner fluid>
                <StickyToolbar>
                    <Row>
                        <Col>
                            <h1>
                                {
                                    type === RequirementType.Experience ? t('administratorsRequirementList.heading.experience', 'Experience') // We don't pluralise experience.
                                    : t('administratorsRequirementList.heading.dynamic', '{{ requirement }}s', { requirement: type })
                                }
                            </h1>
                        </Col>
                        <ConditionalFragment showIf={isLoading}>
                            <Col xs="auto">
                                <LoadingIndicator size="sm" />
                            </Col>
                        </ConditionalFragment>
                        <Col xs="auto">
                            <CardsOrTableToggle />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <SearchInput value={search} onChange={e => setSearch(e.currentTarget.value)} />
                        </Col>
                        <Col xs="auto">
                            <Row>
                                <ButtonGroup>
                                    <LinkContainer to={'add'}>
                                        <Button color="primary">
                                            <FontAwesomeIcon icon="plus" /><>{t('administratorsRequirementList.add', 'Add')}</>
                                        </Button>
                                    </LinkContainer>
                                </ButtonGroup>
                            </Row>
                        </Col>
                    </Row>
                </StickyToolbar>
            </Banner>
            <MainContainer fluid>
                <AlertOnErrors errors={loadingErrors} />
                <CardsOrTable
                    viewMode={viewMode}
                    items={items}
                    tableHeadings={[
                        null, /* Handles the card specific function for which we don't want a table column. */
                        t('administratorsRequirementList.name.heading', 'Name'),
                        t('administratorsRequirementList.description.heading', 'Description'),
                    ]}
                    columns={[
                        // We handle the card layout as a specific column ignored from the table
                        (item, view) => {
                            // This column doesn't show in the table
                            if (view !== 'cards') {
                                return null;
                            }
                            return (
                                <>
                                    <CardTitle tag="h5">{item.name}</CardTitle>

                                    <CardSubtitle>
                                        <HtmlDisplay html={truncateText(200, item.descriptionHtml)} />
                                    </CardSubtitle>
                                </>
                            );
                        },

                        // Name
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return item.name;
                        },

                        // Description
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return <HtmlDisplay html={item.descriptionHtml } />
                        },
                    ]}

                    buttons={(item) => (
                        <ButtonGroup>
                            <LinkContainer to={`edit/${item.id}`}>
                                <Button color="primary">
                                    <FontAwesomeIcon icon="edit" />
                                    <>{t('common.edit', 'Edit')}</>
                                </Button>
                            </LinkContainer>
                            <ButtonDropdown isOpen={isMenuOpen(item.id)} toggle={() => toggleMenuOpen(item.id)}>
                                <DropdownToggle color="primary" caret>
                                    <span className="visually-hidden">{t('common.menuDropdown', 'More')}</span>
                                </DropdownToggle>
                                <DropdownMenu end>
                                    <LinkContainer to={`delete/${item.id}`}>
                                        <DropdownItem className="text-danger">
                                            <FontAwesomeIcon icon="trash" />
                                            <>{t('common.delete', 'Delete')}</>
                                        </DropdownItem>
                                    </LinkContainer>
                                </DropdownMenu>
                            </ButtonDropdown>
                        </ButtonGroup>
                    )}
                    onItemClick={item => navigate(`edit/${item.id}`)}
                />

                <ConditionalFragment showIf={isLoading && !items?.length}>
                    <LoadingIndicator fullWidth />
                </ConditionalFragment>
                <ConditionalFragment showIf={!isLoading && !items?.length}>
                    <NoResultsFound search={search} />
                </ConditionalFragment>
                <ConditionalFragment showIf={!isLoading && hasMore()}>
                    <Waypoint key={items?.length ?? 0} onEnter={fetchMore} />
                    <LoadingIndicator fullWidth />
                </ConditionalFragment>
            </MainContainer>
        </>
    );

};
