import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { Button, Col, FormText, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { RequirementType } from "../../api/main/models/constants/RequirementType";
import { OrganisationalRole } from "../../api/main/models/OrganisationalRole";
import { PersonnelFlag } from "../../api/main/models/PersonnelFlag";
import { PersonnelFlagUser } from "../../api/main/models/PersonnelFlagUser";
import { Profile } from "../../api/main/models/Profile";
import { Requirement } from "../../api/main/models/Requirement";
import { School } from "../../api/main/models/School";
import { useSchool } from "../../api/main/schools/useSchool";
import { HtmlDisplay } from "../../shared/htmlEditor";
import { TagSelector } from "../../shared/tagSelector/TagSelector";
import { RequirementTagSelector } from "../administration/requirements/RequirementTagSelector";
import { NoResultsFound } from "../shared/noResultsFound/NoResultsFound";


export interface UserMapUserDetailsModalCloseEventArgs {
    selectedIds: Array<string>,
    cancelled: boolean,
    removedIds: Array<string>,
}

export interface UserMapUserDetailsModalProps {
    isOpen: boolean,
    toggle: () => void,

    userProfile: Profile | null | undefined,
    userBlobReferenceUrl: string | null | undefined,
    allPersonnelFlags: Array<PersonnelFlag> | null | undefined,
    personnelFlagUsers: Array<PersonnelFlagUser> | null | undefined,

    initialSelectedIds: Array<string> | null | undefined,

    roles: Array<OrganisationalRole> | null | undefined,

    isTrust?: boolean,
    school?: School | null,

    userRequirements?: Array<Requirement>,
};

export const UserMapUserDetailsModal = (props: UserMapUserDetailsModalProps) => {
    const {
        isOpen,
        toggle,
        userProfile,
        userBlobReferenceUrl,
        allPersonnelFlags,
        personnelFlagUsers,
        initialSelectedIds,
        roles,
        isTrust = false,
        school,
        userRequirements
    } = props;
    const { t } = useTranslation();

    // Filtering PersonnelFlags
    const [search, setSearch] = useState<string>("");

    // Allow items to be searched.
    const items = useMemo(() => {
        if (!allPersonnelFlags) {
            return allPersonnelFlags;
        }


        let ret = allPersonnelFlags;


        // Filter by search
        let lowerSearch = search.toLowerCase();
        if (lowerSearch) {
            ret = ret.filter(item =>
                item.name.toLowerCase().indexOf(lowerSearch) >= 0
                || item.descriptionHtml.toLowerCase().indexOf(lowerSearch) >= 0
            );
        }

        return ret;
    }, [allPersonnelFlags, search]);

    // Add the selected PersonnelFlags
    const [selectedIds, setSelectedIds] = useState<Array<string>>([]);
    const toggleSelection = useCallback((id: string) => {
        setSelectedIds(prevState => {
            const existing = prevState.find(it => it === id);
            if (existing) {
                return prevState.filter(it => it !== id);
            }

            return [
                ...prevState,
                id
            ];
        });
    }, [setSelectedIds]);

    // Check if a PersonnelFlag is selected
    const isSelected = useCallback((id: string) => {
        if (!!selectedIds.find(it => it === id)) {
            return true;
        }

        return false;
    }, [selectedIds]);

    // Close the modal and return the results
    const closeModal = useCallback(async (event?: { selectedIds: Array<string>, cancelled?: boolean; }) => {
        //if (onClose) {
        //    const thisSelectedIds = event?.selectedIds ?? selectedIds;

        //    const externalEvent: RoleMapUserDetailsModalCloseEventArgs = {
        //        ...(event ?? {}),

        //        selectedIds: thisSelectedIds,
        //        cancelled: event?.cancelled ?? false,
        //        removedIds: event?.cancelled ? [] : initialSelectedIds?.filter(id => !selectedIds.find(it => it === id)) ?? [],
        //    };
        //    await onClose(externalEvent);
        //}

        // Close the modal
        toggle();
    }, [toggle]);

    // Set the initial selected ids
    useEffect(() => {
        if (!initialSelectedIds?.length) return;

        setSelectedIds(initialSelectedIds);
    }, [initialSelectedIds]);

    const currentPersonnelFlags = useMemo(() => {
        return allPersonnelFlags?.filter(it => initialSelectedIds?.find(iS => it.id === iS))
    }, [allPersonnelFlags, initialSelectedIds]);

    const {
        data: {
            model: userSchool,
        }, isLoading: userSchoolLoading, errors: userSchoolErrors
    } = useSchool(userProfile?.schoolId);

    //Render the UI
    //
    return (
        <>
            <Modal
                isOpen={isOpen}
                toggle={toggle}
                size="xl"
                zIndex={9999}
            >
                <ModalHeader toggle={toggle}>
                    <Row>
                        <Col xs="auto" style={!!isTrust ? { borderRight: "solid 1px #ccc" } : {}}>
                            <h3 className="role-map-part-role-heading">
                                {t('roleMapUserDetailsModal.heading.name', userProfile?.firstName + " " + userProfile?.lastName)}
                            </h3>
                        </Col>
                        <ConditionalFragment showIf={!!isTrust}>
                            <Col>
                                <h3 className="role-map-part-role-heading">
                                    {t('roleMapUserDetailsModal.heading.school', userSchool?.name ?? 'Trust staff')}
                                </h3>
                            </Col>
                        </ConditionalFragment>
                    </Row>
                </ModalHeader>

                <ModalBody>
                    <Row>
                        <Col xs="auto">
                            <img className="user-map-part-modal-image"
                                src={userBlobReferenceUrl ?? '/img/user-solid.svg'}
                                alt={''}
                            />
                        </Col>
                        <Col>
                            <h4>{t('roleMapUserDetailsModal.personalDetails', 'Personal details')}</h4>
                            <HtmlDisplay html={userProfile?.descriptionHtml ?? '<i>User has provided no details.</i>'} />
                            <h4>{t('roleMapUserDetailsModal.strengths', 'Strengths')}</h4>
                            <HtmlDisplay html={userProfile?.strengthsHtml ?? '<i>User has provided no Strengths.</i>'} />
                            <h4>{t('roleMapUserDetailsModal.gaps', 'Gaps')}</h4>
                            <HtmlDisplay html={userProfile?.gapsHtml ?? '<i>User has provided no Gaps.</i>'} />
                        </Col>
                    </Row>
                    <Row style={{ marginTop: "1rem" }}>
                        <Col>
                            <h4>{t('roleMapUserDetailsModal.currentSkills', 'Current skills')}</h4>
                            <RequirementTagSelector readOnly tags={userRequirements?.filter(it => it.requirementType === RequirementType.Skill)} />
                        </Col>
                        <Col>
                            <h4>{t('roleMapUserDetailsModal.currentAttributes', 'Current attributes')}</h4>
                            <RequirementTagSelector readOnly tags={userRequirements?.filter(it => it.requirementType === RequirementType.Attribute)} />
                        </Col>
                        <Col>
                            <h4>{t('roleMapUserDetailsModal.currentQualifications', 'Current qualifications')}</h4>
                            <RequirementTagSelector readOnly tags={userRequirements?.filter(it => it.requirementType === RequirementType.Qualification)} />
                        </Col>
                    </Row>
                    <Row style={{ marginTop: "1rem" }}>
                        <h4>{t('roleMapUserDetailsModal.currentPersonnelFlags', 'Current personnel flags')}</h4>
                        <ConditionalFragment showIf={!!currentPersonnelFlags?.length}>
                            <Row>
                                {
                                    roles?.map((role, index) => {
                                        if (personnelFlagUsers?.filter(it => it.organisationalRoleId === role.id).length) {
                                            return (
                                                <Col key={index}>
                                                    <h5 >{t('userMapUsrDetailsModal.currentPersonnelFlags.role', role.name)}</h5>
                                                    <TagSelector readOnly tags={currentPersonnelFlags?.filter(it => personnelFlagUsers?.find(pfu => it.id === pfu.personnelFlagId && pfu.organisationalRoleId === role.id))} />
                                                </Col>
                                            )

                                        }
                                        else {
                                            return (<div key={index}></div>)
                                        }
                                    })
                                }
                            </Row>
                        </ConditionalFragment>
                        <ConditionalFragment showIf={allPersonnelFlags?.filter(it => initialSelectedIds?.find(iS => it.id === iS)).length === 0}>
                            <NoResultsFound />
                        </ConditionalFragment>

                    </Row>
                    <FormText>{t('userMapUserDetailsModal.changingPersonnelFlags', 'Personnel flags can be configured in Role fulfillment') }</FormText>
                </ModalBody>

                <ModalFooter>
                    <Button type="button" color="primary" outline onClick={() => closeModal({ selectedIds: [], cancelled: true })}>
                        {t('common.close', 'Close')}
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    )
};