import { RouteEntry } from "../shared/routes";
import { apiAuthorizationRoutes } from "../components/api-authorization/routes";
import { accountRoutes } from "../components/account/routes";
import { profileRoutes } from "../components/profiles/routes";
import { homeRoutes } from "../components/home/routes";
import { administrationRoutes, administrationSidebarRoutes } from "../components/administration/routes";
import { userRoutes } from "../components/users/routes";
import { PageNotFound } from "../components/pageNotFound/PageNotFound";
import { manageTrustRoutes, manageTrustSidebarRoutes } from "../components/manageTrusts/routes";
import { manageSchoolRoutes, manageSchoolSidebarRoutes } from "../components/manageSchools/routes";
import { personnelFlagRoutes } from "../components/administration/personnelFlags/routes";
import { requirementRoutes } from "../components/administration/requirements/routes";
import { courseRoutes } from "../components/administration/courses/routes";
import { roleRoutes } from "../components/administration/organisationalRoles/routes";
import { videoRoutes } from "../components/administration/videos/routes";
import { trustRoutes } from "../components/administration/trusts/routes";
import { schoolRoutes } from "../components/administration/schools/routes";
import { organisationProfileRoutes } from "../components/administration/organisationProfiles/routes";
import { eventRoutes } from "../components/administration/events/routes";
import { bookRoutes } from "../components/administration/books/routes";
import { podcastRoutes } from "../components/administration/podcasts/routes";
import { standardRoutes } from "../components/administration/standards/routes";
import { careerRoutes } from "../components/career/routes";
import { cpdRoutes } from "../components/cpd/routes";
import { growRoutes } from "../components/grow/routes";
import { myProfileRoutes } from "../components/myProfile/routes";
import { organisationalRoleGroupRoutes } from "../components/administration/organisationalRoleGroups/routes";
import { linkRoutes } from "../components/links/routes";
import { analyticEventRoutes } from "../components/administration/analyticEvents/routes";


/**
 * All routes used by the application.
 */
export const appRoutes: Array<RouteEntry> = [
    ...apiAuthorizationRoutes,
    ...accountRoutes,
    ...homeRoutes,
    ...profileRoutes,

    // Reminaing routes in alphabetical order
    ...administrationRoutes,
    ...bookRoutes,
    ...careerRoutes,
    ...courseRoutes, 
    ...cpdRoutes,
    ...eventRoutes,
    ...growRoutes,
    ...linkRoutes,
    ...myProfileRoutes,
    ...organisationProfileRoutes,
    ...personnelFlagRoutes,
    ...podcastRoutes,
    ...requirementRoutes,
    ...roleRoutes,
    ...organisationalRoleGroupRoutes,
    ...schoolRoutes,
    ...standardRoutes,
    ...trustRoutes,
    ...userRoutes,
    ...videoRoutes,
    ...analyticEventRoutes,

    ...manageTrustRoutes,
    ...manageSchoolRoutes,


    { path: '*', element: <PageNotFound /> }
];

/**
 * Routes used to populate the sidebar.
 */
export const sidebarRoutes: Array<RouteEntry> = [
    ...administrationSidebarRoutes,
    ...manageTrustSidebarRoutes,
    ...manageSchoolSidebarRoutes,
    { path: '*', element: <></>}
];