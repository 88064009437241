import * as React from "react";
import { Row, Col, Form, FormGroup, Label, Spinner, Alert, ButtonGroup } from "reactstrap";
import { useChanges } from "../../shared/useChanges";
import { useValidatorCallback } from "pojo-validator-react";
import { ValidatedInput } from "pojo-validator-reactstrap";
import { ButtonAsync } from "reactstrap-buttonasync"
import { useAuthenticatedState } from "../../api/api-authorization/useAuthenticatedState";
import { LoadingIndicator } from "../shared/loadingIndicator/LoadingIndicator";
import { Guid } from "guid-string";
import { useProfile } from "../../api/main/profiles/useProfile";
import { AlertOnErrors } from "../../shared/alertOnErrors";
import { useTranslation } from "react-i18next";
import { FormButtons } from "../shared/formButtons/FormButtons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAsyncCallback } from "react-use-async-callback";
import { MainContainer } from "../shared/mainContainer/MainContainer";
import { ConditionalFragment } from "react-conditionalfragment";
import { useSaveProfileMutation } from "../../api/main/profiles/useSaveProfileMutation";
import { HtmlEditor } from "../../shared/htmlEditor";
import { UploadedImagePreview } from "../shared/uploadedImagePreview/UploadedImagePreview";
import { useBlobReference } from "../../api/main/blobReferences/useBlobReference";
import { BlobUploadService } from "../../api/main/blobReferences/BlobUploadService";
import { FileUploadButton } from "../shared/fileUploadButton/FileUploadButton";
import { useNavigate } from "react-router-dom";
import { useCurrentUserProfilesCache } from "../../globalState/currentUserDetails/useCurrentUserProfileCache";

/**
 * Allow the user to change their personal details on their profile.
 */
export const ProfileDetails = () => {
    const { t } = useTranslation();
    const [hasSaved, setHasSaved] = React.useState<boolean>(false);
    const [isCreate, setIsCreate] = React.useState<boolean>(false);
    const { user } = useAuthenticatedState({ includeUser: true});
    const { data: { model: storeModel, }, isLoading, errors: loadErrors, refresh: refreshProfile } = useProfile(user?.sub ?? '', {
        isUserId: true,
        lazy: true,
        onCompleted: (data) => {
            // If we don't find a record in the database, create one ready to save.
            if (!data) {
                setIsCreate(true);
                change({ id: Guid.newGuid(), userId: user?.sub ?? '' });
            }
        } });
    const { model, change, changes } = useChanges(storeModel);
    const [save, { isExecuting: isSaving, errors: saveErrors }] = useSaveProfileMutation({ afterSaving: () => setIsCreate(false) })

    const [validate, validationErrors] = useValidatorCallback((validation, fieldsToCheck) => {
        if (!model) {
            return;
        }

        const rules = {
            firstName: () => !model.firstName ? t('profileDetails.firstNameRequired', 'First name is required') : '',
            lastName: () => !model.lastName ? t('profileDetails.lastNameRequired', 'Last name is required') : '',
        };

        validation.checkRules(rules, fieldsToCheck);
    }, [model]);

    // Profile image upload (photo)
    const { data: { model: image }, errors: imageLoadErrors } = useBlobReference(model?.photoBlobReferenceId);
    const [onUploadImage, { errors: imageUploadErrors, isExecuting: isUploadingImage }] = useAsyncCallback(async (files: FileList | null) => {
        if (!files) {
            return;
        }
        let uploadService: BlobUploadService = new BlobUploadService("api/blobs");
        let result = await uploadService.upload(files);

        if (!!result) {
            change({ photoBlobReferenceId: result.id });
        }
    }, [change]);

    const imageUrl = image?.url;
    // Clear image functionality
    const [clearImage, { isExecuting: isClearingImage }] = useAsyncCallback(async () => {
        change({ photoBlobReferenceId: null });
    }, [change]);

    // Only load the profile once we know the user.
    React.useEffect(() => {
        if (!user) {
            return;
        }

        refreshProfile();
    }, [user, refreshProfile]);

    const navigate = useNavigate();
    const [, setCurrentUserProfileCache] = useCurrentUserProfilesCache();

    const [saveForm] = useAsyncCallback(async () => {
        if (!validate()) {
            return;
        }

        await save(model.id, changes, isCreate);

        setHasSaved(true);

        // Clear any saved profile so it gets reloaded with the changes.
        setCurrentUserProfileCache({ isAuthenticated: false, isLoading: false, profile: null });


        // Go back to the previous screen (because we can get here from the main My Profile screen).
        navigate(-1);
    }, [save, model, setHasSaved, validate, isCreate, changes, navigate, setCurrentUserProfileCache ]);

    // Render the UI.
    //

    return (
        <MainContainer>
            <ConditionalFragment showIf={isLoading}>
                <LoadingIndicator fullWidth />
            </ConditionalFragment>

            <Form onSubmit={async e => { e.preventDefault(); await saveForm(); }}>
                <AlertOnErrors errors={[
                    loadErrors,
                    saveErrors,
                    imageLoadErrors,
                    imageUploadErrors
                ]} />

                {
                    hasSaved ? (
                        <Alert color="success">
                            {t('profileDetails.hasSaved', 'Your details have been updated.')}
                        </Alert>
                        ): null
                }

                <Row>
                    <Col>
                        <FormGroup>
                            <Label htmlFor="firstName">{t('profileDetails.firstName', 'First name')}</Label>
                            <ValidatedInput name="firstName" type="text" value={model.firstName ?? ''} onChange={e => change({ firstName: e.currentTarget.value })} onBlur={e => validate('firstName')} validationErrors={validationErrors['firstName']} />
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="lastName">{t('profileDetails.lastName', 'Last name')}</Label>
                            <ValidatedInput name="lastName" type="text" value={model.lastName ?? ''} onChange={e => change({ lastName: e.currentTarget.value })} onBlur={e => validate('lastName')} validationErrors={validationErrors['lastName']} />
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="descriptionHtml">{t('profileDetails.description', 'Bio')}</Label>
                            <HtmlEditor value={model.descriptionHtml ?? ''} onChange={e => change({ descriptionHtml: e })}></HtmlEditor>
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label htmlFor="profilePicture">{t('profileDetails.profilePicture', 'Profile picture')}</Label>
                            <UploadedImagePreview size="lg" src={imageUrl ?? ''} />
                            <Row>
                                <Col>
                                    <ButtonGroup>
                                        <FileUploadButton
                                            color={'primary'}
                                            isExecuting={isUploadingImage}
                                            executingChildren={<><Spinner size="sm" /> {t('common.uploading', 'Uploading...')}</>}
                                            onUpload={onUploadImage}
                                            outline={false}>
                                            <FontAwesomeIcon icon="image" />
                                            <> </>
                                            {t('profileDetails.uploadButtonText', 'Upload an image') }
                                        </FileUploadButton>
                                        <ButtonAsync color="primary"
                                            outline
                                            isExecuting={isClearingImage}
                                            type="button"
                                            onClick={clearImage}
                                            executingChildren={<><Spinner size="sm" /> {t('profileDetails.clearingImage', 'Clearing image...')}</>}>
                                            {t('profileDetails.clearImageButton', 'Clear image')}
                                        </ButtonAsync>
                                    </ButtonGroup>
                                </Col>
                            </Row>
                        </FormGroup>
                    </Col>  
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label htmlFor="personalStatementHtml">{t('profileDetails.personalStatement', 'Personal statement')}</Label>
                            <HtmlEditor value={model.personalStatementHtml ?? ''} onChange={e => change({ personalStatementHtml: e })}></HtmlEditor>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label htmlFor="strengthsHtml">{t('profileDetails.strengths', 'Strengths')}</Label>
                            <HtmlEditor value={model.strengthsHtml ?? ''} onChange={e => change({ strengthsHtml: e })}></HtmlEditor>
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label htmlFor="gapsHtml">{t('profileDetails.gaps', 'Gaps')}</Label>
                            <HtmlEditor value={model.gapsHtml ?? ''} onChange={e => change({ gapsHtml: e })}></HtmlEditor>
                        </FormGroup>
                    </Col>
                </Row>
                <FormButtons>
                    <ButtonAsync color="primary" isExecuting={isSaving}
                        executingChildren={<><Spinner size="sm" /> {t('common.saving', 'Saving...')}</>}>
                        <FontAwesomeIcon icon="save" />
                        <> {t('common.save', 'Save')}</>
                    </ButtonAsync>
                </FormButtons>
            </Form>
        </MainContainer>
    );
};
