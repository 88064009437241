import gql from 'graphql-tag';
import { Guid } from 'guid-string';
import { SchoolFieldsFragment } from '../generated/graphql';

export const schoolFieldsFragment = gql`
    fragment schoolFields on School {
        id
        name
        trustId
        organisationProfileId
        descriptionHtml
        imageBlobReferenceId
        archived
        subscriptionOwnerFirstName
        subscriptionOwnerLastName
        subscriptionOwnerEmail
        subscriptionOwnerId
    }
`;

export type School = Omit<SchoolFieldsFragment, '__typename' | 'school'>;

/**
 * Default values.
 */
export const schoolDefaultValues = (): Partial<School> => ({
    id: Guid.newGuid(),
    name: '',
    trustId: null,
    organisationProfileId: undefined,
    descriptionHtml: '',
    imageBlobReferenceId: null,
    archived: false,
    subscriptionOwnerFirstName: '',
    subscriptionOwnerLastName: '',
    subscriptionOwnerEmail: '',
    subscriptionOwnerId: '',
});