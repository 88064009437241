import { TagSelector, TagSelectorProps } from "../../../shared/tagSelector/TagSelector";

/**
 * Tag selector component for selecting and deselecting requirements.
 * 
 * This covers both skills and attributes.
 * @param props
 * @returns
 */
export const RequirementTagSelector = (props: TagSelectorProps) => {
    return (
        <TagSelector {...props} color="dark"
        />
    );
};