import { useTranslation } from "react-i18next";
import { RoleGroup, roleGroupDisplayName } from "../../../api/main/models/constants/RoleGroup";
import { UsersList } from "../UsersList";



export const AdminUsersListContainer = () => {
    const { t } = useTranslation();
    return (
        <UsersList filterByRoleGroups={[roleGroupDisplayName(RoleGroup.Administrator, t)]} />
    );
};